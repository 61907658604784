import React, { Component, Fragment } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { ArrowUp, X } from 'react-feather';
import { withSize } from 'react-sizeme';
import { Modal, Toast } from 'antd-mobile';

class SignatureInput extends Component {
	constructor (props) {
		super(props);
		this.state = {
			isModalShow: false,
			preview: props.defaultPreview,
			isPreviewDefault: false,
		};
	}
	componentDidUpdate (prevProps, prevState) {
		if(prevState.isModalShow !== this.state.isModalShow) {
			if(this.state.isModalShow) {
				this._undoSignature();
				if(this.props.value)
					this.sigCanvas.fromData(this.props.value);
			} 
		}	
		if(this.props.defaultPreview !== prevProps.defaultPreview) {
			if(this.props.defaultPreview && !this.state.isPreviewDefault) 
			{
				this.setState({
					preventDefault: true,
					preview: this.props.defaultPreview
				});
			}
		}
	}
	showModal = key => (e) => {
		e.preventDefault(); 
		this.setState({
			[key]: true,
		});
	}
	onClose = key => () => {
		this.setState({
			[key]: false,
		});
	}
	renderButton = () => {
		return (
			<div href="#" onClick={this.showModal('isModalShow')}>
				<div className="bit-link-signature"  style={{height: '200px'}} >
					{this.state.preview && <img style={{width:'auto', height: '100%'}} src={this.state.preview} alt="your signature here"/>}
				</div>
			</div>
		);	
	}
	_saveSignature = () => {
		const {
			onComplete
		} = this.props;

		const {
			// getCanvas,
			isEmpty,
			toData,
			getTrimmedCanvas,
		} = this.sigCanvas;

		if(isEmpty()) return Toast.info('signature is empty');

		// const canv = getCanvas();
		const trimmedCanv = getTrimmedCanvas();
		const arrData = toData();
		const base64data = trimmedCanv.toDataURL();
		onComplete(base64data, arrData);
		this.setState({
			preview: base64data
		}, () => {
			this.onClose('isModalShow')();
		});
	}
	_undoSignature = () => {
		this.sigCanvas.clear();
	}
	renderCanvas = () => {
		let {
			width
		} = this.props.size;
		
		return (
			<Modal 
				popup
				animationType="slide-up"
				visible={this.state.isModalShow}
				onClose={this.onClose('isModalShow')}
				className="bit-link-modal-wrapper"
			>
				<div className="bit-link-main-wrap">
					<div className="bit-link-modal-header bit-link-cust-header">
						<div>
							<h3 className="bit-black-color">Tanda Tangan</h3>
						</div>
						<div>
							<X color="#666" size={16} onClick={this.onClose('isModalShow')}/>
						</div>	
					</div>
					<div className="bit-link-main-content bit-link-custom-content">

						<div className="signature-canvas-box">
							<div>
								<SignatureCanvas
									backgroundColor={"rgba(255,255,255,1)"}
									ref={ref => { this.sigCanvas = ref; }}
									penColor='black'
									canvasProps={{ width: width, height: 320, className: 'sigCanvas' }}
								/>
							</div>
							<div className="signature-canvas-ket">
							Silahkan tanda tangan digital di dalam kotak ini <ArrowUp size="12" color="#6b6b6b" />
							</div>
						</div>
						<div className="bit-link-fixed-foot">
							<div className="tw-btn-box">
								<div className="tw-btn">
									<span onClick={this._undoSignature}>Clear</span>
								</div>
								<div className="tw-btn primary">
									<span onClick={this._saveSignature} >Save</span>

								</div>
							</div>
						</div>	
					</div>
					
				</div>
			</Modal>
		);
	}
	render() {
		return (
			<Fragment>
				{this.renderCanvas()}
				{this.renderButton()}
			</Fragment>);
	}
}

SignatureInput.defaultProps = {
	value: '',
	onComplete: () => {},
	defaultPreview: ''
};

export default withSize()(SignatureInput);