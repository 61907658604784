import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

import isEmptyObject from './../../../utils/isEmptyObject';
import { handleResponseErrorCase, handleResponseGeneral} from './../../../utils/handleResponse';

import { serviceBankList } from './../../../services/bank.services';


const defaultProps = {
	is_fetching: false,
	err: '',
	data: [],
	keyword: '',
};


const [request, failure, success, changeFilter] = [
	'LOAD_BANKPICKER_REQUEST',
	'LOAD_BANKPICKER_FAILURE',
	'LOAD_BANKPICKER_SUCCESS',
	'CHANGE_BANKPICKER_FILTER',
].map(createAction);

const reducer = createReducer({
	[request]: (state) => {
		return update(state, {
			is_fetching: {$set: true}
		});
	},
	[failure]: (state, payload) => {
		const { err } = payload;
		return update(state, {
			is_fetching: {$set: false},
			err: {$set: err}
		});
	},
	[success]: (state, payload) => {
		const { data } = payload;
		return update(state, {
			is_fetching: {$set: false},
			data: {$set: data}
		});
	},
	[changeFilter]: (state, payload) => {
		const { keyword } = payload;
		return update(state, {
			keyword: {$set: keyword}
		});
	}
}, defaultProps);

export {
	changeFilter
};

export function getLoadBank () {
	return (dispatch, getState) => {
		const {
			bankPicker: {
				data
			}
		} = getState();
		if(data.length > 0) return; 
		const req = serviceBankList()
			.then(payload => {

				if(isEmptyObject(payload)) {
					return dispatch(failure({err: 'Something is wrong'}));
				}
                
				const dataResponse = handleResponseGeneral(payload);
                
				const {
					data: _data,
				} = dataResponse;

				dispatch(success({
					data: _data
				}));

			});
            
		handleResponseErrorCase(req, errObj => {
			const {
				err, // message from error case
			} = errObj;
			return dispatch(failure({ err }));
		});
        
	};
}

export default reducer;