import React, { Component, Fragment } from 'react';
import { Modal, ActivityIndicator } from 'antd-mobile';
import { connect } from 'react-redux';

import EmailChangeForm from './EmailChangeForm';
import EmailStatusDetail from './EmailStatusDetail';
import { getUserEmail } from '../../entities/user.selector';
import list from '../../services/apiconstant';
import IconSendEmail from './../../assets/images/emailSend.png';
import { isLoadingSelector } from '../../services/apimanager/apimanager.selector';

import { servicePostResendEmail } from './../../services/email.services';
import { failureveremail } from '../../entities/user.reducer';

class ModalEmailVerify extends Component {
	state = {
		open: false,
		step: 0, // 0 menu, 1 change email
		isResend: false,
		email: '',
		isError: false,
		errorMessage: '',
	}
	componentDidMount () {
		this.setState({
			step: 0,
			isResend: false,
			email: this.props.email,
		});
	}
	closeModal = () => () => {
		this.setState({
			step: 0,
			open: false,
		});

		// trigger after modal closed
		setTimeout(() => {
			this.setState({
				isResend: false,
				isError: false,
				errorMessage: '',
			});
		}, 10);
	}
	updateEmail = ({email, isError = false, errorMessage = ''}) => {
		this.setState({ email, isError, errorMessage });
	}
	openModal = () => () => {
		this.setState({
			open: true	
		});
	}
	handleChangeEmail = () => {
		this.setState({
			step: 1
		});
	}
	handleResendEmail = () => {
		const {
			email,
			dispatch
		} = this.props;
		return servicePostResendEmail({email})
			.then(response => {
				this.setState({
					isResend: true
				});
			}).catch((err) => {
				this.setState({
					isError: true,
					errorMessage: err?.response?.data?.message
				});
				dispatch(failureveremail({err: err?.response?.data?.message}));
			});
	}
	renderSuccess = () => {

		return (
			<Fragment>
				<div className="bit-modal-email-sent">
					<div
						className="login-register-icon"
						style={{ textAlign: 'left' }}
					>
						<img src={IconSendEmail} alt="icon change email" />
					</div>
					
					<div
						className="login-register-heading"
						style={{ textAlign: 'left' }}
					>
							Email Verifikasi Berhasil Dikirim, Silahkan Cek Email Anda.
					</div>
					<div
						onClick={this.closeModal()}
					>
						<div className="bit-link-btn-white-full text-center">
							kembali ke halaman utama
						</div>	
					</div>
				</div>	
			</Fragment>
		);
	}

	renderFailed = () => {

		return (
			<Fragment>
				<div className="bit-modal-email-sent">
					<div
						className="login-register-icon"
						style={{ textAlign: 'left' }}
					>
						<img src={IconSendEmail} alt="icon change email" />
					</div>
					
					<div
						className="login-register-heading red"
						style={{ textAlign: 'left', fontSize: 14 }}
					>
						{this.state.errorMessage}
					</div>
					<div
						onClick={this.closeModal()}
					>
						<div className="bit-link-btn-white-full  text-center">
							kembali ke halaman utama
						</div>	
					</div>
				</div>	
			</Fragment>
		);
	}

	renderBody = () => {
		
		if(this.state.isResend)
			return this.renderSuccess();
		
		if(this.state.isError)
			return this.renderFailed();
		
		let body = null;
		switch (this.state.step) {
			case 0: 
				body = <EmailStatusDetail 
					email={this.state.email}
					onChangeEmail={this.handleChangeEmail}
					onResendEmail={this.handleResendEmail}
				/>;
				break;
			case 1:
				body = <EmailChangeForm 
					onBack={this.closeModal()}
					onSubmit={(props) => this.updateEmail(props)}
				/>;
				break;
			default: 
				body = <EmailStatusDetail 
					email={this.state.email}
					
				/>;
		}
		return body;
	}
	renderModal = () => {
		const {
			open
		} = this.state;
		return (
			<Modal
				popup
				visible={open}
				onClose={this.closeModal()}
				animationType="slide-up"
				isOpen={open} 
				onRequestClose={this.closeModal()}
				className="bit-link-buy-modal"
			>
				{this.renderBody()}
			</Modal>
		);
	}
	renderButton = () => {
		return this.props.renderButton(this.openModal());
	}
	render() {
		return (
			<Fragment>
				{this.renderModal()}
				{this.renderButton()}
				<ActivityIndicator 
					toast
					text="Loading..."
					animating={this.props.loading}
				/>
			</Fragment>
		);
	}
}

ModalEmailVerify.defaultProps = {
	renderButton: (onOpen) => {
		return (null);
	},
	loading: false
};

const mapStateToProps = (state) => {
	return {
		email: getUserEmail(state),
		loading: isLoadingSelector(state, list.emailVerification),
	};
};

export default connect(mapStateToProps)(ModalEmailVerify);