const educations = [ // bukan index array
	'SD',
	'SMP',
	'SMA',
	'Diploma',
	'S1',
	'S2',
	'S3',
	'Lainnya'
];
const earningsperyears = [ // bukan index array
	'< 10 Juta / tahun',
	'Rp10 Juta - 50 Juta / tahun',
	'> Rp50 Juta - 100 Juta / tahun',
	'> Rp100 Juta - 500 Juta / tahun',
	'> Rp500 Juta - 1 Miliar / tahun',
	'> Rp1 Miliar / tahun'
];
const earningsources = [
	'Gaji',
	'Keuntungan Bisnis',
	'Bunga Simpanan',
	'Warisan',
	'Dari Orang Tua / Anak',
	'Undian',
	'Dari Suami / istri',
	'Tabungan',
	'Hasil Investasi',
	'Lainnya'
];

const datastore = {
	educations,
	earningsperyears,
	earningsources
};

export function getValue (name, key) {
	return datastore[name][key];
}