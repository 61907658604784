import React, { Component, Fragment } from 'react';
import './PinInput.css';

import DeleteKey from './../../../assets/images/delete-key.svg';

class PinInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isPinSubmit: props.isPinSubmitDefault,
			pin: []
		};
	}
	componentDidUpdate(prevProps, prevState) {
		const {
			errorMessage: _errMessage,
		} = prevProps;

		const {
			errorMessage,
		} = this.props;

		if(errorMessage !== _errMessage && errorMessage) {
			if(this.state.isPinSubmit) {
				this.clearPin();
			}
		} 

	}
	handleChangePin = num => {
		const { pin } = this.state;
		const {
			onChangePin,
			max,
		} = this.props;
		
		if(this.checkInputDisabled()) return;
		if(this.state.isPinSubmit) 
			this.setState({
				isPinSubmit: false
			});
		this.setState({
			pin: [...pin, num]
		});

		if(max === pin.length + 1) {
			const formattedPin = pin.join('') + num; //convert to string
			onChangePin(formattedPin);
			this.submitThePin(formattedPin);
		}
	}
	clearPin = () => {
		this.setState({
			pin: []
		});
	}
	handleDeletePin = () => {
		if(this.checkInputDisabled()) return;
		let newArray = [...this.state.pin];
		newArray.pop();
		this.setState({
			pin: newArray
		});
	}
	checkInputDisabled = () => {
		const { pin } = this.state;
		const { max } = this.props;
		return pin.length === max;
	}
	submitThePin = async (pin) => {
		const { onSubmitPin } = this.props;
		try {
			await onSubmitPin(pin);
		} catch (error) {
			this.clearPin();
		}
		this.setState({
			isPinSubmit: true
		});
	}
	renderDot = () => {
		const {
			descLabel,
			errorMessage,
			max,
		} = this.props;
		const {
			isPinSubmit,
			pin,
		} = this.state;
		return (
			<div className="bit-link-new-pin">	
				<div className="bit-new-pin">
					{
						[...Array(max)].map((e, i) => (
							<div key={i} className={`${pin.length > i ? 'done' : ''}`}></div>
						))
					}
				</div>
				<br />
				<div className="errorspan">{isPinSubmit && errorMessage}</div>
				<div className="bit-pin-desc">
					{descLabel}
				</div>
			</div>
		);
	}
	renderKeyboard = () => {
		return (
			<div className="bit-custom-keyboard">
				<div onClick={() => this.handleChangePin(1)}>1</div>
				<div onClick={() => this.handleChangePin(2)}>2</div>
				<div onClick={() => this.handleChangePin(3)}>3</div>
				<div onClick={() => this.handleChangePin(4)}>4</div>
				<div onClick={() => this.handleChangePin(5)}>5</div>
				<div onClick={() => this.handleChangePin(6)}>6</div>
				<div onClick={() => this.handleChangePin(7)}>7</div>
				<div onClick={() => this.handleChangePin(8)}>8</div>
				<div onClick={() => this.handleChangePin(9)}>9</div>
				<div></div>
				<div onClick={() => this.handleChangePin(0)}>0</div>
				<div onClick={this.handleDeletePin}>
					<img src={DeleteKey} alt="delete" />
				</div>
			</div>
		);
	}
	render() {

		return (
			<Fragment>
				{this.renderDot()}
				{this.renderKeyboard()}
			</Fragment>
		);
	}
}

PinInput.defaultProps = {
	//action
	onBack: ()=>{},
	onSubmitPin: async ()=>{},
	onChangePin: async ()=>{},
	max: 6,
	pin: [],
	isPinSubmitDefault: false,
};

export default (PinInput);