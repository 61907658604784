import history from './../../../utils/history';
import { servicePostPinSetup, servicePostPinVerify } from '../../../services/user.services';

import Analytics from './../../../utils/Analytics';

export function postSubmitPin (pin, pin_confirm) {
	return async dispatch => {
		const toSend = {
			pin,
			pin_confirm
		};
		// if empty restore user from network
		return servicePostPinSetup(toSend)
			.then(response => {
				if(!response) {
					return alert('response is empty');
				}
				Analytics.recordEvent({
					page: 'register',
					path: 'complete',
				});
				history.push('/success-connected');
				setTimeout(() => {
					history.push('/');
				}, 3000);

			});
			
		
	};
}

export function postCheckPin (pin) {
	
	return async dispatch => {

		const toSend = {
			pin,
		};

		// if empty restore user from network
		return servicePostPinVerify(toSend)
			.then(response => {
				if(!response) {
					return alert('response is empty');
				}
				// let data = handleResponseUserPartner(response);
				// dispatch(setUserPartner(data));
				history.push('/success-connected');
				
				setTimeout(() => {
					history.push('/index-home');
				}, 3000);
			})
			.catch(err => {
				console.log('error response', err);
			});
		
	};
}