import React, { Component, Fragment } from 'react';
import { Modal, ActivityIndicator } from 'antd-mobile';
import { X } from 'react-feather';

import {
	enableSyariah,
	disableSyariah,
} from './syariah.reducer';

import { getSyariahPreference } from '../../../entities/user.selector';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { serviceSyariahDisable, serviceSyariahEnable } from '../../../services/syariah.services';
import apiconstant from '../../../services/apiconstant';
import { isLoadingSelector } from '../../../services/apimanager/apimanager.selector';

class SettingFlagSyariah extends Component {
	state = {
		open: false,
	}
	handleClose = () => this.setState({
		open: false
	})
	handleOpen = () => this.setState({
		open: true
	})
	handleDisable = () => {
		serviceSyariahDisable()
			.then(() => this.props.disableSyariah());
		this.handleClose();
	}
	handleToggle = () => {
		if(!this.props.syariah) 
			return serviceSyariahEnable()
				.then(() => this.props.enableSyariah());
		this.handleOpen();
	}
	renderContent = () => {
		return (
			<Fragment>
				{this.props.onRenderButton(this.handleOpen)}
				<div>
					<input 
						type="checkbox" 
						id="switch" 
						checked={this.props.syariah? true: false}
						onChange={this.handleToggle}
					/><label htmlFor="switch">Toggle</label>
				</div>
				<Modal
					popup
					visible={this.state.open}
					onClose={this.handleClose}
					animationType="slide-up"
					className="bit-link-modal-setting"
				>
					<div className="bit-link-modal-header">
						<div>
							<h3>Nonaktifkan Preferensi Syariah</h3>
						</div>
						<div>
							<X color="#666" size={16} onClick={this.handleClose}/>
						</div>	
					</div>	 
					<div className="bit-modal-setting-content">
						<p>Yakin untuk menonaktifkan preferensi Syariah?</p>
						<div className="bit-link-dual-button">
							<button onClick={this.handleClose}>Batal</button>
							<button onClick={this.handleDisable}>Yakin</button>
						</div>  
					</div>
				</Modal>  
			</Fragment>
		);
	}
	render () {
		return (
			<Fragment>
				{/* {this.props.onRenderButton(this.handleOpen('open'))} */}
				
				{this.renderContent()}

				<ActivityIndicator 
					toast
					text={'Permintaan sedang di proses'}
					animating={this.props.isLoading}
				/>
			</Fragment>
		);
	}
}

SettingFlagSyariah.defaultProps = {
	onRenderButton : (handleOpen) => {
		return <div onClick={handleOpen}>Preferensi Syariah</div>;
	}
};

const mapStateToProps = (state) => {
	const isSyariah = getSyariahPreference(state);
	const {
		userPreferenceUpdate,
	} = apiconstant;
	return {
		syariah: isSyariah,
		isLoading: isLoadingSelector(state, userPreferenceUpdate),
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	enableSyariah,
	disableSyariah,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SettingFlagSyariah);