import React, {Component} from 'react';
import BuyModal from './../../components/Common/BuyModal/UnselectedBuyModal';
import SellReksa from './../../assets/images/sell-reksa.svg';

import {
	Link
} from 'react-router-dom';

import {
	ChevronRight
} from 'react-feather';
import { connect } from 'react-redux';

class ViewShortcutBuyAndSell extends Component {
	render () {
		const {transactionConfiguration} = this.props;
		const enableSell = transactionConfiguration?.enable_redeem;
		return (
			<div className="bit-link-card">
				<div className="bit-link-main-portfolio nopadding">
					<div className="bit-link-transaction">
						<BuyModal
							portfolioId={this.props.categoryId}
						/>
						<div className="bit-link-transaction-action" role="button" aria-disabled={!enableSell}>
							<Link  to={`/portfolio/${this.props.categoryId}/details`} >
								<img src={SellReksa} alt="link aja"/>
								<p>Jual Reksadana</p>
							</Link>
						</div>
					</div>
				</div>		
				<Link to={`/history-transaction`}>
					<div className="bit-link-card-button">
						<span>Lihat Riwayat Transaksi</span>
						<ChevronRight height="18" color="#E82529"/>
					</div>
				</Link>	
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	transactionConfiguration: state?.user?.profile?.transaction_configuration
});

export default connect(mapStateToProps)(ViewShortcutBuyAndSell);