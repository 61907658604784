const { REACT_APP_AWS_BIBIT_BUCKET, REACT_APP_API_URL } = process.env;

export default {
	
	partner_key_generate: '/urltoken',
	partner_key_check: '/check',
	partner_signature: '/signature',
	userProfile: '/user/profile',
	authVerify: '/auth/register/email/verify',
	emailVerification: '/auth/register/email/resend',
	emailChangeVerify: '/auth/change/email/verify',
	emailChangeRequest: '/auth/change/email/request',
	emailChangeUpdate: '/auth/change/email/update',
	emailRegisterChange: '/auth/register/email/change',
	bankList: '/tools/list/bank',
	fallback_upload: REACT_APP_API_URL + '/tools/s3/upload',
	awsToken: '/tools/s3/token',
	awsTokenV2: '/tools/s3/tokenV2',
	awsTokenV2NoAuth: '/tools/s3/tokenV2/noauth',
	awsBucket: REACT_APP_AWS_BIBIT_BUCKET,
	registerSubmit: '/auth/register/submit',
	loginOtp: '/auth/login/otp',
	loginPhonenumber: '/auth/login/phone',
	registerOtp: '/auth/register/otp',
	registerPhonenumber: '/auth/register/phone',
	pinSetup: '/auth/pin/setup',
	pinCheck: '/auth/login/pin',
	pinVerify: '/auth/login/pin',
	portfolio: '/portfolio',
	portfolioSwitch: '/portfolio/category/switch',
	authCheckPin: '/auth/check/pin',

	allproducts: '/products',

	portfolioChart: categoryId => `/portfolio/category/${categoryId}/chart`,
	portfolioCategorySymbol: (categoryId, symbol) => `/portfolio/category/${categoryId}/${symbol}`,
	portfolioCategory: category => `/portfolio/category/${category}`,
	
	products: symbol => `/products/${symbol}`,
	productsChart: symbol => `/products/${symbol}/chart`,
	productsChartAum: symbol => `/products/${symbol}/chart/aum`,

	userBalanceLinkaja: '/user/balance',	
	orderBuy: '/order/buy',	

	orderHistory: '/order/history',
	orderSellPreview: '/order/sell/preview',
	orderSell: '/order/sell',
	orderList: '/order/list',

	orderDetailByCode: code => `/order/details/${code}`,
	orderUploadImageByCode: code => `/order/payment/${code}/upload`,
	orderSubmitConfirmation: code => `/order/payment/${code}/confirm`,
	symbolDocuments: code => `/products/${code}/files`,

	otpRequest: '/auth/otp/request',
	otpVerify: '/auth/otp/verify',

	userPreferenceUpdate: '/user/preference/update',
	userBankList: '/user/bank',
	userRequestChangeBank: '/user/change/bank/request',
	userChangeBankUpdate: '/user/change/bank/update',
	userChangeBankAdd: '/user/change/bank/add', 
	userChangeBankValidateRequest: '/user/change/bank/verify',
	userDefaultBankUpdate: '/user/bank/default',
};