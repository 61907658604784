import Api from './api';
import {add, remove, add_error, remove_error, remove_all_error} from './../services/apimanager/apimanager.reducer';
import store from './../Store';
import getSafely from './safely';
import history from './history';

import { openErrorModal } from './../components/Global/GlobalErrorModal/errorModal.reducer';

import {clearLocalSession} from './index';

// send the request url to redux store
Api._interceptorRequest = request => {
	// Modify request here
	store.dispatch(add(request.url));
	store.dispatch(remove_error(request.url));
	
	return request;
};

Api._interceptorRequestError = err => {
	return Promise.reject(err);
};

// remove the requset url from redux store
Api._interceptorResponse = (response) => {
	setTimeout(() =>
		store.dispatch(remove(response.url))
	, 500);
	return response;
};

Api._interceptorResponseError = (err) => {
	
	// const config = getSafely(['config'], err);
	const url = getSafely(['config', 'url'], err);
	

	if(url)
		store.dispatch(remove(url));
	
	const message_error = getSafely(['response','data', 'message'], err);
	const data_error = getSafely(['response','data'], err);
	const status_error = getSafely(['response','status'], err);
	
	store.dispatch(add_error({
		url,
		message: message_error,
		data: data_error,
		status: status_error,
		text: err.toString()
	}));
	
	if (!message_error && !data_error && !status_error ){

		store.dispatch(openErrorModal({}));
		throw err;
	}

	if(err.response && err.response.status === 503) {
		const errorMessageObj = {
			type: 'MAINTENANCE',
			message: 'Silahkan coba beberapa saat lagi.',
		};
		store.dispatch(openErrorModal(errorMessageObj));
		return Promise.reject(err);
	}

	if (err.response && err.response.status >= 500) {
		const errorMessageObj = {
			type: 'SYSTEM_ERROR',
			message: err.response.data && err.response.data.message ? err.response.data.message : 'Silahkan coba beberapa saat lagi',
		};
		store.dispatch(openErrorModal(errorMessageObj));
		return Promise.reject(err);
	}

	if(err.response && err.response.status === 422){
		return Promise.reject(err);
	}

	if(err.response && err.response.status === 401) {
		clearLocalSession();
	}

	return Promise.reject(err);

};

history.listen((props) => {
	store.dispatch(remove_all_error());
});

export default Api;