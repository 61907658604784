export default function normalizeOptionUserData (profile) {
	let {
		income,
		income_source,
		education,
		occupation,
		marital_status,
		religion,
		preferences,
		need_edd,
		config = {},
		transaction_configuration = {}
	} = profile;

	income = (isNaN(income))? 0: (income-1);
	income_source = (isNaN(income_source))? 0: (income_source-1);
	education = (isNaN(education))? 0: (education-1);
	occupation = (isNaN(occupation))? 0: (occupation-1);
	marital_status = (isNaN(marital_status))? 0: (marital_status-1);
	religion = (isNaN(religion))? 0: (religion-1);
	need_edd = (need_edd)? need_edd: 0;
	return {
		...profile,
		preferences,
		income,
		income_source,
		education,
		occupation,
		marital_status,
		religion,
		need_edd,
		config,
		transaction_configuration
	};
}