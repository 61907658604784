import React, {Component, isValidElement, Fragment} from 'react';

import history from './../../utils/history';
import Er404 from './../../assets/images/404.svg';
import GlobalLoader from './GlobalLoader';

class GlobalRedirect extends Component {
	constructor (props) {
		super(props);
		this.state = {
			loading: true,
			target: null,
		};
	}
	componentDidMount () {
		const route = this.props.routes.find(_route => {
			return _route.status === this.props.status;
		});
		if (!route) {
			const target = this.renderError();
			return this.setState({
				loading: false,
				target,
			});
		}
		if(typeof route.target == 'string') {
			history.push(route.target);
		}
		this.setState({target: route.target, loading: false});
	}
	renderError = () => (
		<div className="bit-link-error-404">
			<img src={Er404} alt="error 404"/>
		</div>
	);
	handleTarget = (Target) => {
		if(typeof Target === 'function') {
			const Ret = Target();
			if(isValidElement(Ret))
				return Ret;
			else
				return this.renderError();
		} 
		else if(isValidElement(Target)) {
			return Target;
		} 
		else if (typeof Target === 'string') {
			return <Fragment>Redirecting..</Fragment>; 
		}
		else {
			return <Target/>;
		}
	}
	render() {
		return this.state.loading? <GlobalLoader />: this.handleTarget(this.state.target);
	}
} 

GlobalRedirect.defaultProps = {
	routesLength: 0,
	routes: [],
	status: 0
};

export const factoryRedirect = (params) => ({status}) => {
	return <GlobalRedirect
		routesLength={params.length}
		routes={params}
		status={status}
	/>; 
};