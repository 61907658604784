import * as stringHelper from './string-helper';

/**
 * Get the basename for our browser history router from the public url given
 * @param {String} publicUrl 
 */
export default function getHistoryBasename(publicUrl) {
	const publicUrlLastSegment = '/' + publicUrl.substring(publicUrl.lastIndexOf('/') + 1);
	const { removeTrailingSlash } = stringHelper;
	return removeTrailingSlash(publicUrlLastSegment);
}