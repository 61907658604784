import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd-mobile';
import { bindActionCreators } from 'redux';
import * as errorModalActions from './errorModal.reducer.js';
import MinSellNotMetImage from '../../../assets/images/image_neraca.svg';
import GeneralErrorImage from '../../../assets/images/ErrorImage.svg';
import MaintenanceImage from '../../../assets/images/maintenance.svg';
import UserNotActiveImage from '../../../assets/images/ErrorIncompleteRegistration.svg';
import LinkAjaNotConnected from '../../../assets/images/pencarian-link-aja.svg';
import IncompleteRegistrationImage from '../../../assets/images/ImageErrorKYC.svg';
import TransactionErrorImage from '../../../assets/images/image-error-payment-gopay.svg';
import './Error.css';

const mapStateToProps = state => ({
	type: state.errorModal.errorType,
	visible: state.errorModal.visible,
	message: state.errorModal.message,
	description: state.errorModal.description,
});

const mapDispatchToProps = dispatch => bindActionCreators(errorModalActions, dispatch);

export const INSUFFICENT_FUND_LINKAJA = 'INSUFFICENT_FUND_LINKAJA';

class IndexErrorModal extends React.PureComponent {
	handleCloseModal = () => {
		const { closeErrorModal } = this.props;
		closeErrorModal();
	}

	decideTitle = (type) => {
		const typeChecked = String(type || '').toUpperCase();
		switch (typeChecked) {
			case 'TRANSACTION_EXPIRED':
				return 'Pembayaran belum berhasil';
			case 'SELL_WITH_NO_UNITS':
				return 'Unit Reksadana yang Tersedia untuk Dijual Tidak Ada.';
			case 'MAINTENANCE':
				return 'Bentar Ya. Mesinnya Lagi Diupgrade.';
			case 'MINIMUM_SELL':
			case 'MIN_SELL_NOT_MET':
				return 'Minimum Penjualan Tidak Terpenuhi';
			case 'EDD_PROCESSED': 
				return 'EDD kamu sedang di proses.';
			case 'USER_NOT_ACTIVE':
				return 'Registrasi Dalam Proses.';
			case 'INCOMPLETE_REGISTRATION':
				return 'Selesaikan Registrasimu';
			case 'REQUEST_ENTITY_TOO_LARGE':
				return 'Gagal Meng-upload Gambar';
			case INSUFFICENT_FUND_LINKAJA:
				return '';	
			case 'FAILED_CREATE_TOKEN_SELL':
			case 'USER_FUND_CODE_NOT_EXISTS':
			default:
				return 'Duh Ada Sekrup yang Lepas.';
		}
	}

	decideImage = (type) => {
		const typeChecked = String(type).toUpperCase();
		switch (typeChecked) {
			case 'TRANSACTION_EXPIRED':
				return TransactionErrorImage;
			case 'MINIMUM_SELL':
			case 'MIN_SELL_NOT_MET':
				return MinSellNotMetImage;
			case 'USER_NOT_ACTIVE':
				return UserNotActiveImage;
			case 'MAINTENANCE':
				return MaintenanceImage;
			case 'INCOMPLETE_REGISTRATION':
				return IncompleteRegistrationImage;
			case INSUFFICENT_FUND_LINKAJA:
				return LinkAjaNotConnected;
			default:
				return GeneralErrorImage;
		}
	}

	decideDescription = (type) => {
		const { message } = this.props;
		const typeChecked = String(type).toUpperCase();
		switch (typeChecked) {
			case 'MAINTENANCE':
				return 'Silahkan coba beberapa saat lagi.';
			case 'USER_NOT_ACTIVE':
				return 'Akun Bibit kamu dalam proses pembuatan, kamu masih belum dapat melakukan investasi sekarang.';
			case 'INCOMPLETE_REGISTRATION':
				return 'Datamu masih belum lengkap. Mohon lanjutkan pendaftaran.';
			case INSUFFICENT_FUND_LINKAJA:
				return 'Pencairan dana ke LinkAja sedang mengalami gangguan, silahkan cairkan dana ke rekening bank Kamu.';	
			case 'REQUEST_ENTITY_TOO_LARGE': 
				return 'Ukuran gambar yang kamu upload melebihi batas maksimum 20 MB';	
			case 'MINIMUM_SELL':
			case 'MIN_SELL_NOT_MET':
				return (
					<div className='robo-sell-error'>
						<p>Mohon naikkan jumlah dana yang ingin kamu cairkan.</p>
						<br />
						<p>Jika jumlah keseluruhan reksa dana yang kamu miliki di bawah minimal penjualan, kamu dapat menjual seluruh reksa dana yang kamu miliki.</p>
					</div>
				);
			default:
				return message ? message : 'Mohon coba beberapa saat lagi.';
		}
	}

	decideAction = (type) => {
		const typeChecked = String(type).toUpperCase();
		if(typeChecked === INSUFFICENT_FUND_LINKAJA)
			return <button onClick={this.props.onClickAction} className="bit-link-btn-red">Okay</button>;
		return null;
		// switch (typeChecked) {
		// 	case INSUFFICENT_FUND_LINKAJA: 
		// 		return (
		// 			<button onClick={this.props.onClickAction} className="bit-link-btn-red">Okay</button>
		// 		);
		// 	default: 
		// 		return (null);
		// }
	}

	render() {
		const { visible, type } = this.props;
		return (
			<Modal
				popup
				closable
				animationType="slide-up"
				visible={visible}
				onClose={this.handleCloseModal}
				className="bc-modal-body errorModal"
			>
				{/* <span onClick={this.handleCloseModal} className="modal-close-new"><X size="16" color="#6b6b6b" /></span> */}
				<div><img className="errorImg" src={this.decideImage(type)} alt="Error" /></div>
				<div className="errorModalTtl">{this.decideTitle(type)}</div>
				<div>{this.decideDescription(type)}</div>
				{this.decideAction(type)}
			</Modal>
		);
	}
}

IndexErrorModal.defaultProps = {
	onClickAction: () => {}
};

export default connect(mapStateToProps, mapDispatchToProps)(IndexErrorModal);