/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import {createBase64UploadPromise, formatUploadFileName, fileToBase64, LIMIT_UPLOAD_FILE} from './../../utils/Aws';

import { isLoadingSelector, isErrorSelector } from './../../services/apimanager/apimanager.selector';
import { handleResponseGeneral } from './../../utils/handleResponse';

import apiconstant from '../../services/apiconstant';
import getSafely from '../../utils/safely';
import { recordCustomEvent } from '../../utils/Analytics';
import BaseImagePicker from '../Base/BaseImagePicker';
import { dispatchErrorModal } from '../../utils/dispatcher';

import GoodTabungan from './../../assets/images/goodBankBook.jpg';
import BadTabungan from './../../assets/images/falseBankBook.jpg';
import { X, Check } from 'react-feather';

class BankAccountPicker extends Component {
	handleUpload = async (image, handleCloseModal) => {

		const {
			userid,
		} = this.props;

		
		const KEY_NAME = 'bank_account';


		const filename = formatUploadFileName(KEY_NAME);

		if(image?.size > LIMIT_UPLOAD_FILE){
			return dispatchErrorModal({type:'REQUEST_ENTITY_TOO_LARGE'});
		}


		try {
			
			const base64Image = await fileToBase64(image);
 
			const dataResponseImage = createBase64UploadPromise(
				// path_filename,
				filename,
				base64Image,
				true,
				{
					uploadfor: 'bank_account',
					code: userid,
					token: this.props.token,
				},
			);

			dataResponseImage
				.then(response => {
					const generalResponse = handleResponseGeneral(response);
					const url = getSafely(['data', 'url'], generalResponse);
					this.props.onComplete(url);
					recordCustomEvent({
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "bank_account_upload_success"
					});
					handleCloseModal();
				})
				.catch((err) => {
					console.error(err);
					recordCustomEvent({
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "bank_accountk_upload_failed"
					});
					this.props.onCatch(err);
				});
			
		} catch (error) {
			
			dispatchErrorModal();
			Sentry.withScope(function(scope) {
				scope.setTag("image", image);
				scope.setLevel('warning');
				// will be tagged with my-tag="my value"
				scope.setExtra('error', error);
				scope.setExtra('image', image);
				Sentry.captureException(new Error('fileToBase64 input image'));
			});

		}

	}
	render () {
		return (
			<BaseImagePicker
				{...this.props}
				onPickerChange={(files, handleCloseModal) => {
					const file = files[0];
					this.handleUpload(file, handleCloseModal);
				}}
				onPickerChangeAfter={() => {
					let logObject = this.props.value? {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "bank_account_revision_image_chosen"
					}:{
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "bank_account_image_chosen"
					};
					recordCustomEvent(logObject);
				}}
				onClickButtonImageAfter={() => {
					let logObject = this.props.value? {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "bank_account_image_revision_clicked"
					}: {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "bank_account_image_clicked"
					};
					recordCustomEvent(logObject);
				}}
				renderButtonPick={(handleClick, imagePicked) => {
					return (
						<button onClick={handleClick} className="bit-link-btn-red-full margin-top-20">{
							imagePicked? 'Ganti Gambar': 'Upload Buku Tabungan'
						}</button>
					);
				}}
				renderModalContent={() => {
					return (
						<div className="edd-box">
							<div className="edd-title">Petunjuk untuk upload Buku Tabungan</div>
							<div className="edd-box-img">
								<div className="edd-photo">
									<span className="greenback">
										<Check size="14" color="#fff" />
									</span>
									<img src={GoodTabungan} alt="" />
								</div>
								<div className="edd-photo">
									<span className="redback">
										<X size="14" color="#fff" />
									</span>
									<img src={BadTabungan} alt="" />
								</div>
							</div>
							<div className="eddlist">
								<ol>
									<li>
								Pastikan Buku Tabungan yang di unggah terbaca dengan jelas.
									</li>
									<li>
								Nama pemilik di buku tabungan harus sama dengan nama pemilik rekening sebelumnya.
									</li>
								</ol>
							</div>
							<div>
							Data kamu terlindungi sepenuhnya oleh sistem kami dan akan tetap
							rahasia, hanya kamu yang memiliki akses penuh.
							</div>
						</div>
					);
				}}
			/>
		);
	}
}

BankAccountPicker.defaultProps = {
	onComplete: () => {},
	onCatch: () => {},
	value: ''
};

const mapStateToProps = state => {
	const {
		awsToken,
		awsBucket,
	} = apiconstant;
	return {
		isLoading: isLoadingSelector(state, awsToken) || isLoadingSelector(state, awsBucket),
		error: isErrorSelector(state, awsBucket) || isLoadingSelector(state, awsBucket),
	};
};

export default connect(mapStateToProps)(BankAccountPicker);