import React, { Component } from 'react';
import { serviceUserBankValidateRequest } from '../../../services/user.services';
import GlobalLoader from '../../Global/GlobalLoader';
import Page404 from '../../Error/Page404';
import { withRouter } from 'react-router-dom';
import ChangeBankForm from '../../Landing/ChangeBankForm';
import ChangeBankPin from './ChangeBankPin';
import ChangeBankInfo from '../../Landing/ChangeBankInfo';
import store from './../../../Store';
import {
	openErrorModal
} from './../../Global/GlobalErrorModal/errorModal.reducer';

export const getDynamicParam = (props, key) => (key)? props.match.params[key]: props.match.params;

const checkValidateFromToken = (token) => 
	serviceUserBankValidateRequest(token);

class ChangeBank extends Component {
	state = {
		loading: true,
		error: '',
		sequence: 0,
		data: null,
	}
	componentDidMount () {

		const token = getDynamicParam(this.props, 'code');

		return checkValidateFromToken(token)
			.then(resp => {
				const {
					data
				} = resp;
				this.setState({
					data: {...data},
					loading: false
				});
			})
			.catch((err = {response: {data: { type: '' }}}) => {
				if(!err?.response?.data) {
					this.setState({
						error: 'default_type',
						loading: false,
					});
					return;
				};
				const {
					response: {
						data: {
							type
						}
					}
				} = err;
				if(type) {
					this.setState({
						error: type,
						loading: false
					});
				}else{
					this.setState({
						error: 'default_type',
						loading: false,
					});
				}

			});
			
	}
	renderLoading = () => {
		return (
			<div className="bit-link-wrap">
				<div className="bit-link-header" />
				<div className="bit-link-content-centered">	
					<GlobalLoader/>
				</div>
			</div>
		);
	}
	renderError = () => {
		return (<Page404 />);
	}
	renderResultPage = () => {
		if(this.state.error)
			return (<Page404 />);
		return <ChangeBankInfo />;
	}
	renderPage = () => {
		let page = (null);
		switch (this.state.sequence) {
			case 1:
				page = <ChangeBankForm 
					{...this.state.data}
					onSuccessSubmit={() => {
						this.setState({
							sequence: this.state.sequence + 1,
							loading: false,
						});
					}}
					onErrorSubmit={(err) => {
						store.dispatch(openErrorModal({
							type: err?.response?.data?.type,
							message: err?.response?.data?.message
						}));
						this.setState({
							sequence: this.state.sequence,
							loading: false,
						});
					}}
				/>;
				break;
			case 2: 
				page = this.renderResultPage();
				break;
			default: 
				page = <ChangeBankPin 
					{...this.state.data}
					{...this.props}
					onSubmitPin={pin => {
						this.setState({
							data: {
								...this.state.data,
								pin: pin
							},
							sequence: this.state.sequence + 1,
						});
					}}
				/>;
		}
		return (page);
	}
	render () {
		if(this.state.loading)
			return this.renderLoading();
		if(this.state.error)
			return this.renderError();
		return this.renderPage();
	}
}

export default withRouter(ChangeBank);