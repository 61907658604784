/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import { ChevronLeft } from 'react-feather';
import ChangeBank from './../../assets/images/emailSend.png';
import { connect } from 'react-redux';
import { getUserEmail } from '../../entities/user.selector';
import { serviceUserBankRequest } from '../../services/user.services';
import { ActivityIndicator } from 'antd-mobile';
import { obscureText } from '../../utils';

class ChangeBankSendVerif extends Component {
	state = {
		loading: false
	}
	handleSend = () => {
		this.setState({loading : true});
		serviceUserBankRequest(this.props.email)
			.then(() => {
				this.props.history.push('/changebanksent');
				this.setState({loading : true});
			}).catch(() => {
				this.setState({loading : true});
			});
	} 
	render() {
		const { 
			history,
			email,
		} = this.props;
		return (
			<div className="bit-link-main-wrap bit-change-send-verif">
				<div className="bit-link-main-header">
					<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
					<div>Tambah Akun Bank</div>
					<div/>
				</div>
				<div className="bit-link-main-content">
					<div class="login-register-icon">
						<img src={ChangeBank} alt="change bank"/>
					</div>
					<div class="login-register-heading">
					Kirim Email Verifikasi Ke Alamat Ini
					</div>
					<div>
						<span>{obscureText(email)}</span>
					</div>
					<div className="bit-link-bottom-stay-content">
						<button onClick={this.handleSend} className="bit-link-btn-red-full margin-top-20">Kirim</button>
					</div>
				</div>
				<ActivityIndicator 
					toast
					text={'Permintaan sedang di proses'}
					animating={this.state.loading}
				/>
			</div>
		);
	}
} 

const mapStateToProps = props => {
	const email = getUserEmail(props);
	return {
		email
	};
};

export default connect(mapStateToProps)(ChangeBankSendVerif);