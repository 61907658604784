import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
	visible: false,
	errorType: '',
	message: '',
	description: '',
};

const [
	openErrorModal,
	closeErrorModal,
] = [
	'OPEN_ERROR_MODAL',
	'CLOSE_ERROR_MODAL',
].map(createAction);

const errorModal = createReducer({
	[openErrorModal]: (state, payload) => {
		return update(state, {
			visible: { $set: true },
			errorType: { $set: String(payload.type).toUpperCase() },
			message: { $set: payload.message },
			description: { $set: payload.description },
		});
	},
	[closeErrorModal]: (state) => {
		return update(state, {
			visible: { $set: false },
		});
	},
}, defaultState);

export {
	openErrorModal,
	closeErrorModal,
};

export default errorModal;