import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import {Check, X, ChevronRight } from 'react-feather'; 
import {createBase64UploadPromise, formatUploadFileName, fileToBase64,  LIMIT_UPLOAD_FILE} from './../../utils/Aws';

import { isLoadingSelector, isErrorSelector } from './../../services/apimanager/apimanager.selector';
import { handleResponseGeneral } from './../../utils/handleResponse';

import apiconstant from '../../services/apiconstant';
import getSafely from '../../utils/safely';
import GoodSelf from './../../assets/images/an1.jpg';
import BadSelf from './../../assets/images/an2.jpg';
import { recordCustomEvent } from '../../utils/Analytics';
import BaseImagePicker from '../Base/BaseImagePicker';
import { dispatchErrorModal } from '../../utils/dispatcher';

class IdentitySelfiePicker extends Component {

	handleUpload = async (image, handleCloseModal) => {

		const {
			userid,
		} = this.props;

		const KEY_NAME = 'edd';


		let filename = formatUploadFileName(KEY_NAME);

		if(image?.size > LIMIT_UPLOAD_FILE){
			return dispatchErrorModal({type:'REQUEST_ENTITY_TOO_LARGE'});
		}

		try {

			const base64Image = await fileToBase64(image);

			const dataResponseImage = createBase64UploadPromise(
				filename,
				base64Image,
				true,
				{ 
					uploadfor: 'edd',
					code: userid
				}
			);
	
			dataResponseImage
				.then(response => {
					const generalResponse = handleResponseGeneral(response);
					const url = getSafely(['data', 'url'], generalResponse);
					recordCustomEvent({
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "component_upload_identity_success"
					});
					this.props.onComplete(url);
					recordCustomEvent({
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "edd_upload_success"
					});
					handleCloseModal();
				})
				.catch(err => {
					console.error(err);
					recordCustomEvent({
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "edd_upload_failed"
					});
				});

		} catch (error) {
			dispatchErrorModal();
			Sentry.withScope(function(scope) {
				scope.setTag("image", image);
				scope.setLevel('warning');
				// will be tagged with my-tag="my value"
				scope.setExtra('error', error);
				scope.setExtra('image', image);
				Sentry.captureException(new Error('fileToBase64 input image'));
			});
		}

	}

	render () {
		return (
			<BaseImagePicker 
				{...this.props}
				textLoading='Uploading Selfie'
				onPickerChange={(files, handleCloseModal) => {
					const file = files[0];
					this.handleUpload(file, handleCloseModal);
				}}
				onPickerChangeAfter={() => {
					let logObject = this.props.value? {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "edd_image_revision_chosen"
					}:{
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "edd_image_chosen"
					};
					recordCustomEvent(logObject);
				}}
				onClickButtonImageAfter={() => {
					let logObject = this.props.value? {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "edd_image_revision_clicked"
					}: {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "edd_image_clicked"
					};
					recordCustomEvent(logObject);
				}}
				renderButton={({value, buttonTitle, showModal}) => {
					return (
						<a href="/" onClick={showModal}>
							<div className="bit-link-upload-form" >
								<div className="bit-link-col-1">
									<span>
										{value && <img src={value} alt="your identity"/>}
									</span>
								</div>
								<div className="bit-link-col-1">
									{buttonTitle ||'Foto Selfie KTP'}
								</div>
								<ChevronRight size="18" color="#b5b5b5" />
							</div>
						</a>
					);	
				}}
				renderButtonPick={(handleClick, imagePicked) => {
					return (
						<button 
							onClick={handleClick} 
							className="bit-link-btn-red-full"
						>{imagePicked?  'Ganti Foto': 'Upload Selfie Ktp'}</button>
					);
				}}
				renderModalContent={() => {
					return (
						<div className="edd-box">
							<div className="edd-title">Petunjuk untuk selfie dengan E-KTP</div>
							<div className="edd-box-img">
								<div className="edd-photo">
									<span className="greenback">
										<Check size="14" color="#fff" />
									</span>
									<img src={GoodSelf} alt="" />
								</div>
								<div className="edd-photo">
									<span className="redback">
										<X size="14" color="#fff" />
									</span>
									<img src={BadSelf} alt="" />
								</div>
							</div>
							<div className="eddlist">
								<ol>
									<li>Posisikan KTP di bawah dagu, jangan menutupi muka</li>
									<li>
									Pastikan keseluruhan muka dan KTP kamu berada di dalam area
									bingkai foto
									</li>
									<li>
									Pastikan hasil foto muka dan KTP kamu terlihat jelas dan tidak
									berbayang
									</li>
								</ol>
							</div>
							<div>
						Data kamu terlindungi sepenuhnya oleh sistem kami dan akan tetap
						rahasia, hanya kamu yang memiliki akses penuh.
							</div>
						</div>
					);
				}}
			/>
		);
	}

}

IdentitySelfiePicker.defaultProps = {
	onComplete: () => {},
	onCatch: () => {},
	value: ''
};

const mapStateToProps = state => {
	const {
		awsToken,
		awsBucket,
	} = apiconstant;
	return {
		isLoading: isLoadingSelector(state, awsToken) || isLoadingSelector(state, awsBucket),
		error: isErrorSelector(state, awsBucket) || isLoadingSelector(state, awsBucket),
	};
};

export default connect(mapStateToProps)(IdentitySelfiePicker);