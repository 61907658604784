import update from 'immutability-helper';
import { createAction } from 'redux-act';
import { Toast } from 'antd-mobile';

const TIMEOUT_POPUP_TOGGLE = 3000;

const [
	enableSyariah,
	disableSyariah
] = [
	'ENABLE_SYARIAH',
	'DISABLE_SYARIAH'
].map(createAction);

const syariahReducer = {
	[enableSyariah]: (state) => {
		return update(state, {
			profile: {
				preference: {
					linkaja_syariah: {
						$set: 1
					}
				}
			}
		});
	},
	[disableSyariah]: (state) => {
		return update(state, {
			profile: {
				preference: {
					linkaja_syariah: {
						$set: 0
					}
				}
			}
		});
	}
};

export default syariahReducer;

const popupToastParams = {
	content: '',
	duration: 10,
};

function enableSyariahWithRedirect () {
	return dispatch => {
		dispatch(enableSyariah());
		popupToastParams.content = 'Sukses mengaktifkan Syariah. Harap tunggu..';
		Toast.success(popupToastParams.content, popupToastParams.duration);
		setTimeout(() => window.location.reload(), TIMEOUT_POPUP_TOGGLE);
	};
}

function disableSyariahWithRedirect () {
	return dispatch => {
		dispatch(disableSyariah());
		popupToastParams.content = 'Sukses nonaktifkan Syariah. Harap tunggu..';
		Toast.success(popupToastParams.content, popupToastParams.duration);
		setTimeout(() => window.location.reload(), TIMEOUT_POPUP_TOGGLE);
	};
}

export {
	enableSyariahWithRedirect as enableSyariah,
	disableSyariahWithRedirect as disableSyariah,
};