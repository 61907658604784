import React, { Component, Fragment } from 'react';
import { ChevronRight, X, Check } from 'react-feather';
import { Modal, ActivityIndicator } from 'antd-mobile';

import GoodKTP from './../../assets/images/correctUploadKTP.jpg';
import BadKTP from './../../assets/images/wrongUploadKTP.jpg';

class BaseImagePicker extends Component {
	state = {
		isModalShow: false
	}
	handleClose = (e) => {
		if(e) e.preventDefault();
		this.setState({
			isModalShow: false
		});
	}
	handleOpen = (e) => {
		if(e) e.preventDefault();
		this.setState({
			isModalShow: true
		});
	}
	renderModal = () => {
		return (
			<Modal
				popup
				animationType="slide-up"
				visible={this.state.isModalShow}
				onClose={this.handleClose}
				className="bit-link-modal-wrapper"
			>
				<div className="bit-link-main-wrap">
					{/* <div className="bit-link-main-header">
						<div/>
						<div>{title || 'Upload KTP'}</div>
						<X height="18" color="#95989A" className="bit-link-left-arrow" onClick={this.onClose('isModalShow')}/>
					</div> */}
					<div className="bit-link-modal-header bit-link-cust-header">
						<div>
							<h3 className="bit-black-color">{this.props.modalTitle || this.props.title}</h3>
						</div>
						<div>
							<X color="#666" size={16} onClick={this.handleClose}/>
						</div>	
					</div>
					<div className="bit-link-main-content bit-link-custom-content">
						{this.props.renderModalContent()}
						<div className="bit-link-cust-margin">
							{this.renderPicker()}
						</div>	
						{/* <div className="bit-link-fixed-foot">
						</div>	 */}
					</div>
					<ActivityIndicator
						toast
						text={this.props.textLoading}
						animating={this.props.isLoading}
					/>
				</div>
			</Modal>
		);
	}
	handleButtonPick = e => {
		e.preventDefault();
		this.uploadRef.value = null;
		this.uploadRef.click();
		this.props.onClickButtonImageAfter();
	}
	renderPicker = () => {
		return (
			<Fragment>
				<input 
					ref={ref => this.uploadRef = ref}
					id="file"
					type="file"
					accept="image/*"
					capture="user"
					style={{display: "none"}}
					onChange={e => {
						e.preventDefault();
						const {files} = e.currentTarget;
						this.props.onPickerChange(files, this.handleClose);
						if(files && files.length>0) this.props.onPickerChangeAfter(this.handleClose);
					}}
				/>
				{
					this.props.value && 
					<div className="bit-link-uploaded-img">
						<img src={this.props.value} alt="your uploaded identity here"/>
					</div>
				}
				{
					this.props.renderButtonPick(this.handleButtonPick, this.props.value)
				}
			</Fragment>
		);
	}
	render () {
		const {
			value,
			buttonTitle,
		} = this.props;
		return (
			<Fragment>
				{this.renderModal()}
				{this.props.renderButton({
					value,
					buttonTitle, 
					showModal: this.handleOpen,
				})}
			</Fragment>
		);
	}

}

BaseImagePicker.defaultProps = {
	buttonTitle: 'Foto KTP',
	isLoading: false,
	textLoading: 'Uploading Identity',
	modalTitle: '',
	renderButtonPick: (handleClick, imagePicked) => {
		return (
			<button 
				onClick={handleClick} 
				className="bit-link-btn-red-full"
			>{imagePicked?  'Ganti Foto': 'Upload Ktp'}</button>
		);
	},
	renderModalContent: () => {
		return (
			<div className="edd-box">
				<div className="edd-title">Petunjuk untuk upload Foto E-KTP</div>
				<div className="edd-box-img">
					<div className="edd-photo">
						<span className="greenback">
							<Check size="14" color="#fff" />
						</span>
						<img src={GoodKTP} alt="" />
					</div>
					<div className="edd-photo">
						<span className="redback">
							<X size="14" color="#fff" />
						</span>
						<img src={BadKTP} alt="" />
					</div>
				</div>
				<div className="eddlist">
					<ol>
						<li>
									Pastikan KTP yang di unggah jelas dan sesuai dengan identitas
									kamu.
						</li>
						<li>
									Jangan sampai foto yang di unggah terpotong atau kurang jelas.
						</li>
						<li>
									Pastikan KTP yang kamu unggah masih berlaku
						</li>
					</ol>
				</div>
				<div>
							Data kamu terlindungi sepenuhnya oleh sistem kami dan akan tetap
							rahasia, hanya kamu yang memiliki akses penuh.
				</div>
			</div>
		);
	},
	renderButton: ({value, buttonTitle, showModal}) => {
		return (
			<a href="/" onClick={showModal}>
				<div className="bit-link-upload-form" >
					<div className="bit-link-col-1">
						<span>
							{value && <img src={value} alt="your ktp" />}
						</span>
					</div>
					<div className="bit-link-col-1">
						{buttonTitle ||'Foto KTP'}
					</div>
					<ChevronRight size="18" color="#b5b5b5" />
				</div>
			</a>
		);
	},
	onPickerChange: () => {},
	onPickerChangeAfter: () => {},
	onClickButtonImageAfter: () => {},
};

export default BaseImagePicker;