import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

import history from './../../../utils/history';
// import isEmptyObject from './../../../utils/isEmptyObject';
import {
	handleResponseAuthorization,
	// handleResponseErrorCase,
	handleResponseGeneral,
} from './../../../utils/handleResponse';

import getSafely from '../../../utils/safely';

import { setUserPartner } from './../../../entities/userpartner.reducer';
import { Toast } from 'antd-mobile';
import { serviceRegisterPhoneNumber, serviceLoginPhoneNumber, serviceOtpLogin, serviceOtpRegister } from '../../../services/user.services';

const defaultState = {
	otp: '',
	flagpin: 0,
	resend: false,
	resendtime: '',
}; 

const [
	setOtpResponse
] = [
	'SET_OTP_RESPONSE',
].map(createAction);

const reducer = createReducer({
	[setOtpResponse]: (state, payload) => {
		const {
			resend,
			resendtime
		} = payload;
		return update(state, {
			resend: {$set: resend},
			resendtime: {$set: resendtime},
		});
	} 
}, defaultState);

function factoryPostNumberAsync (fnService) {
	return () => async (dispatch, getState) => {
		const {
			userpartner		
		} = getState();
		const { profile } = userpartner;
		const COUNTRY_CODE = 62;
		const phone = getSafely(['phone'], profile);
		const toSend = {
			phone,
			code: COUNTRY_CODE,
		};

		const req = fnService(toSend)
			.then(payload => getSafely(['data'], payload))
			.then(payload => {
				const data = getSafely(['data'], payload);
				const otp = getSafely(['otp'], data);
				if(otp)
					Toast.info(otp);
				if(data)
					dispatch(setOtpResponse(data));
			}).catch(err => {
				console.error(err);
			});

		return req;
	};
}

function factoryPostConfirmNumberAsync (fnService, next) {
	return (otp) => async (dispatch, getState) => {
		const {
			userpartner		
		} = getState();
		const { profile } = userpartner;
		const COUNTRY_CODE = 62;
		const phone = getSafely(['phone'], profile);
		const toSend = {
			phone,
			code: COUNTRY_CODE,
			otp,
		};

		const req = fnService(toSend)
			.then(async payload => {
				if(!payload) return false;
				const {
					data,
				} = handleResponseGeneral(payload);
				const {
					user_partner_status
				} = data;
				await handleResponseAuthorization(payload);
				dispatch(setUserPartner({
					user_partner_status: user_partner_status
				}));

				if(typeof next === 'string')
					return history.push(next);
				
				if(typeof next === 'function') 
					return next();

			}).catch(err => {
				console.log('error ', err);
			});

		return req;
	};
}

const debugService = (name) => (service) => (args) => {
	return service(args);
};

// create request otp
export const postRegisterNumberAsync = factoryPostNumberAsync(serviceRegisterPhoneNumber);
export const postLoginNumberAsync = factoryPostNumberAsync(serviceLoginPhoneNumber);

// confirm otp
export const postConfirmLoginNumberAsync = factoryPostConfirmNumberAsync(debugService('serviceOtpLogin')(serviceOtpLogin), '/input-pin');
export const postConfirmRegisterNumberAsync = factoryPostConfirmNumberAsync(debugService('serviceOtpRegister')(serviceOtpRegister), '/register-form');


export default reducer;
