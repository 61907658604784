import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

import { handleResponseGeneral } from '../utils/handleResponse';
import { serviceFetchOrderHistory } from '../services/product.services';

// Initial State
const defaultState = {
	byId: {},
	allIds: [],
};

// Actions
const [
	addOrderEntry,
	orderSetNewPaymentReceipt,
	addMultipleOrderEntry,
	addOrderId,
] = [
	'ADD_ORDER_ENTRY',
	'ORDER_SET_NEW_PAYMENT_RECEIPT',
	'ADD_MULTIPLE_ORDER_ENTRY',
	'ADD_ORDER_ID',
].map(createAction);

// Reducer
const orders = createReducer(
	{
		// Adding a mutual fund data to the fund data catalog
		[addOrderEntry]: (state, payload) => {
			if (payload.robogroup) {
				return update(state, {
					byId: {
						$set: {
							...state.byId,
							[payload.robogroup]: {
								...payload,
							},
						},
					},
				});
			}

			return update(state, {
				byId: {
					$set: {
						...state.byId,
						[payload.code]: {
							...payload,
						},
					},
				},
			});
		},

		// Set new payment receipt image
		[orderSetNewPaymentReceipt]: (state, payload) => {
			if (payload.robogroup) {
				const updatedItemIndex = state.byId[
					payload.robogroup
				].items.findIndex(el => el.code === payload.code);
				return update(state, {
					byId: {
						[payload.robogroup]: {
							items: {
								$set: state.byId[payload.robogroup].items.map(
									(item, index) => {
										if (index !== updatedItemIndex)
											return item;
										return {
											...item,
											payment_receipt: payload.url,
										};
									},
								),
							},
						},
					},
				});
			}

			return update(state, {
				byId: {
					[payload.code]: {
						payment_receipt: { $set: payload.url },
					},
				},
			});
		},

		// Adding multiple mutual fund data
		[addMultipleOrderEntry]: (state, payload) => {
			const orderEntries = payload.reduce((data, currValue) => {
				if (currValue.robogroup) {
					return {
						...data,
						[currValue.robogroup]: currValue,
					};
				}
				return {
					...data,
					[currValue.code]: currValue,
				};
			}, {});
			return update(state, {
				byId: {
					$set: {
						...state.byId,
						...orderEntries,
					},
				},
			});
		},

		// Adding new id to fund data catalog
		[addOrderId]: (state, payload) => {
			return update(state, {
				allIds: [...state.allIds, payload.symbol],
			});
		},
	},
	defaultState,
);

export {
	addOrderEntry,
	addMultipleOrderEntry,
	addOrderId,
	orderSetNewPaymentReceipt,
};

export function fetchOrderList () {
	return dispatch => {
		return serviceFetchOrderHistory()
			.then(response => {
				const generalResponse = handleResponseGeneral(response);
				const { data } = generalResponse;
				dispatch(addMultipleOrderEntry(data));
			});
	};
}

export default orders;
