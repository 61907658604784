import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import {
	ChevronRight
} from 'react-feather';
// import classNames from 'classnames';
import {
	fetchPortfolioCategories,
} from './../../../entities/userportfolio.reducer';
import {
	getPortfolioCategories,
} from './../../../entities/userportfolio.selector';

// import BuyModal from './../../Common/BuyModal/BuyModal';

import { 
	numberToGeneralValueFormat, 
	numberToPercentagePointChangeFormat 
} from './../../../utils/string-helper';
import { isColorMinus } from '../../../utils';

class ViewPortfolioOverviewCard extends Component {
	componentDidMount () {
		this.props.fetchPortfolioCategories();
	}
	_isMinus = (str) => {
		return str.indexOf('-') > -1;
	}
	renderCards = () => {

		return this.props.portfolio.map((item, index) => {
			
			const displayPorto = numberToGeneralValueFormat(
				item.totalporto,
				{
					mantissa: 0,
				},
			);

			const displayProfitloss = numberToGeneralValueFormat(
				item.profitloss, 
				{
					mantissa: 0,
				}
			);

			const displayPercentage = numberToPercentagePointChangeFormat(
				item.percentage
			); 

			// const marketvalue = item.marketvalue || 0;

			// Request panji 
			// if(!displayPorto) {
			// 	return (null);
			// }

			return (
				<div key={index} className="bit-link-card">
					<div className="bit-link-main-portfolio">
						<div className="home-portfolio-top">
							<label>Total Investasi</label>
							<div className="bit-portfolio-value">
								<span>Rp</span>
								<span>{displayPorto || 0}</span>
							</div>
						</div>
						<div className="home-portfolio-bottom">
							<div>
								<label>Keuntungan</label>
								<p className={isColorMinus(displayPercentage)}>Rp {displayProfitloss || 0}</p>
							</div>
							<div className="time-weighted-return">
								<label>Tingkat Keuntungan</label>
								<div>
									<span>
									</span>
									<p className={isColorMinus(displayPercentage)}>{displayPercentage || '0%'}</p>
								</div>
							</div>
						</div>
					</div>	
					<Link to={`/portfolio/${item.id}/details`}>
						<div className="bit-link-card-button">
							<span>Lihat Investasi</span>
							<ChevronRight height="18" color="#E82529"/>
						</div>
					</Link>	
				</div>
			);	
		});

	}
	render() {
		return (
			<Fragment>
				{this.renderCards()}
			</Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		portfolio: getPortfolioCategories(state),
	};
};
const mapDispatchToProps = dispatch => bindActionCreators({
	fetchPortfolioCategories,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewPortfolioOverviewCard);