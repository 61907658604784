/**
	
	API 
	import api from './api'

	api.get

 */
import axios from 'axios';
import Storage from './Storage';
import getSafely from './safely';

const { 
	REACT_APP_API_URL
} = process.env;

const URL = REACT_APP_API_URL;

const TIMEOUT = 100000;

// function handleErrors (response) {
// 	if(!response.ok) 
// 		console.error(response.statusText);
// 	return response;
// }

// /**
//  * Enhancer Interceptor Request 
//  * @param {*} fn 
//  */
// export function interceptorRequest (fn, fnError = () => Promise.reject()) {
// 	axios.interceptors.request.use(fn, fnError);
// }

// /**
//  * Enhancer Interceptor Response 
//  * @param {*} fn 
//  */
// export function interceptorResponse (fn, fnError = () => Promise.reject()) {
// 	axios.interceptors.response.use(fn, fnError);
// }

export const seekCredential = async () => {
	return await Storage.getObject('ac', 'access');
};

const createRequester = ({config, interceptorRequest, interceptorRequestError, interceptorResponse, interceptorResponseError}) => {
	const instance = axios.create();
	instance.interceptors.request.use(interceptorRequest, interceptorRequestError);
	instance.interceptors.response.use(interceptorResponse, interceptorResponseError);
	return instance.request(config);
};

/**
 * store interceptor function here
 */
const fnStack = {};

const Api = {
	
	post: async (endpoint, bodyparam) => {

		let url = URL + endpoint;

		let options = {
			method: 'POST',
		};
		
		return await Api.call({
			url, 
			data: {
				...bodyparam	
			},
			options,
		});
		
	},

	get: async (endpoint, queryparam = {}) => {

		let url = URL + endpoint;

		let options = {
			method: 'GET',
		};

		return await Api.call({
			url, 
			params: {
				...queryparam	
			},
			options,
		});
		
	},

	call: async (obj, errHandling = false) => {
		
		let ac = await seekCredential();

		const options = getSafely(['options'], obj);
		
		if(!getSafely(['headers'], options))
			options['headers'] = {};
		
		if(!getSafely(['headers', 'Content-Type'], options))
			options['headers']['Content-Type'] = 'application/json';
		
		if(ac) ac = 'Bearer ' + ac;
		if(ac) options.headers['Authorization'] = ac;

		const config = {
			url: obj.url,
			timeout: TIMEOUT,
			headers: options.headers,
			data: JSON.stringify(obj.data || {}),
			method: options.method || 'get',
			params: obj.params,
			responseType: options.responseType? options.responseType:'json',
			// cancelToken: new CancelToken(function (c) {
			// 	cancel = c;
			// }),
			validateStatus: function (status) {
				return status >= 200 && status < 300; // default
			},
		};

		const instance = createRequester({
			config,
			interceptorRequest: fnStack['interceptorRequest'],
			interceptorRequestError: fnStack['interceptorRequestError'],
			interceptorResponse: fnStack['interceptorResponse'],
			interceptorResponseError: fnStack['interceptorResponseError'],
		});

		return instance;

	},

	setInterceptorRequest: fn => {
		fnStack['interceptorRequest'] = fn;
		return Api;
	},
	setInterceptorRequestError: fn => {
		fnStack['interceptorRequestError'] = fn;
		return Api;
	},
	
	setInterceptorResponse: fn => {
		fnStack['interceptorResponse'] = fn;
		return Api;
	},
	setInterceptorResponseError: fn => {
		fnStack['interceptorResponseError'] = fn;
		return Api;
	}

};

export default Api;