/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import LeftArrow from './../../assets/images/left-arrow.svg';

class TermsCondition extends Component {
	  render() {
	  	const { history } = this.props;
	  	return (
	  		<div>
	  			<div className="bit-link-main-wrap">
	  				<div className="bit-link-main-header">
	  					<div onClick={() => history.goBack()}>
							  <img src={LeftArrow} alt="left arrow"/>
						</div>
	  					<div>Terms & Conditions</div>
	  					<div />
	  				</div>
	  				<div className="bit-link-main-content">   
					  <div className="terms-conditions">
							<h3>SYARAT DAN KETENTUAN PEMBUKAAN REKENING EFEK</h3>

							<h3>PT BIBIT TUMBUH BERSAMA</h3>

							<p>
								Untuk Pembukaan Rekening Efek di PT Bibit Tumbuh Bersama yang
								bertindak sebagai Agen Penjual Efek Reksa Dana ("Agen Penjual")
								atau sebagai Mitra Distribusi melalui fasilitas transaksi online
								(sebagaimana didefinisikan dibawah ini) bagi pihak (Pihak) pemohon
								("Nasabah") yang menandatangani Formulir Pembukaan Rekening yang
								telah disediakan PT Bibit Tumbuh Bersama berlaku syarat-syarat dan
								ketentuan-ketentuan (“Syarat dan Ketentuan”) sebagai berikut.
							</p>

							<p>
								Dengan disetujuinya permohonan pembukaan rekening Efek oleh PT
								Bibit Tumbuh Bersama, maka PT Bibit Tumbuh Bersama berdasarkan
								perintah transaksi melalui fasilitas transaksi online (sebagaimana
								didefinisikan dibawah ini) memproses pembelian (subscription),
								penjualan (redemption) serta pengalihan (switching) unit-unit
								penyertaan Reksa Dana yang dikelola para Manajer Investasi yang
								menjadi rekanan PT Bibit Tumbuh Bersama. Pemohon ("Nasabah") tetap
								berkewajiban untuk mematuhi ketentuan yang berlaku terhadap
								tiap-tiap Efek, serta ketentuan peraturan perundang-undangan yang
								berlaku di Republik Indonesia.
							</p>

							<ul>
								<li>
									<h4>Definisi</h4>

									<p>
										Kecuali rangkaian kata-kata menentukan lain, istilah yang
										digunakan dalam Syarat Dan Ketentuan Pembukaan Rekening ini
										memiliki definisi dan maksud sebagai berikut:
									</p>

									<ul>
										<li>
											<p>
												<b>"Agen Penjual" berarti PT Bibit Tumbuh Bersama</b>,
												yaitu pihak yang telah memperoleh ijin sesuai Peraturan
												Yang Belaku untuk bertindak selaku agen dalam melakukan
												penawaran dan perantara transaksi Reksa Dana, serta
												merupakan pemilik dan penyelenggara portal investasi Bibit
												di Android dan IOS sebagai agen elektronik penyedia
												fasilitas transaksi Online;
											</p>
										</li>

										<li>
											<p>
												<b>"Badan"</b> berarti suatu badan atau lembaga yang
												menurut Peraturan Yang Berlaku memenuhi syarat untuk
												menjadi Nasabah;
											</p>
										</li>
										<li>
											<p>
												<b>"Bank Kustodian"</b> berarti Bank Umum yang telah
												mendapat persetujuan Otoritas Jasa Keuangan (untuk
												selanjutnya disingkat OJK) untuk menyelenggarakan kegiatan
												usaha sebagai Kustodian, yaitu memberikan jasa penitipan
												efek (termasuk Penitipan Kolektif atas Efek yang dimiliki
												bersama oleh lebih dari satu Pihak yang kepentingannya
												diwakili oleh Kustodian) dan harta lain yang berkaitan
												dengan Efek serta jasa lain, termasuk menerima deviden,
												bunga, dan hak-hak lain, menyelesaikan transaksi Efek, dan
												mewakili pemegang rekening yang menjadi nasabahnya;
											</p>
										</li>
										<li>
											<p>
												<b>"Fasilitas Transaksi Online"</b> berarti fasilitas
												transaksi Reksa Dana secara elektronik yang disediakan dan
												diselenggarakan oleh PT Bibit Tumbuh Bersama melalui
												aplikasi Bibit di Android dan iOS.
											</p>
										</li>
										<li>
											<p>
												<b>"Hari Bursa"</b> berarti hari diselenggarakannya
												perdagangan Efek di Bursa Efek, yaitu Senin sampai dengan
												Jumat, kecuali hari tersebut merupakan libur nasional atau
												dinyatakan hari libur oleh Bursa Efek;
											</p>
										</li>
										<li>
											<p>
												<b>"Manajer Investasi"</b> berarti perusahaan manajer
												investasi pengelola Reksa Dana yang telah mengadakan
												perjanjian kerjasama dengan PT Bibit Tumbuh Bersama
												sehingga menjadi rekanan PT Bibit Tumbuh Bersama;
											</p>
										</li>
										<li>
											<p>
												<b>"Nasabah"</b> berarti pihak-pihak baik berupa
												Perorangan atau Badan, yang bekerjasama dengan PT Bibit
												Tumbuh Bersama untuk memperoleh layanan jasa atau produk
												yang ditawarkan oleh PT Bibit Tumbuh Bersama saat ini
												maupun di masa mendatang;
											</p>
										</li>
										<li>
											<p>
												<b>"Nilai Aktiva Bersih"</b> berarti Nilai pasar yang
												wajar dari suatu Efek dan kekayaan lain dari Reksa Dana
												dikurangi seluruh kewajibannya sesuai dengan Peraturan
												Bapepam Nomor: IV.C.2, Lampiran Keputusan Ketua Bapepam
												Nomor: Kep-24/PMK/2004 tanggal 19 Agustus 2004 tentang
												Nilai Pasar Wajar Dari Efek Dalam Portofolio Reksa Dana.
												Nilai Aktiva Bersih Reksa Dana dihitung dan diumumkan
												setiap Hari Bursa;
											</p>
										</li>
										<li>
											<p>
												<b>"Otoritas Jasa Keuangan (OJK)"</b> berarti Lembaga
												Negara independen yang dibentuk berdasarkan UU nomor 21
												Tahun 2011 yang befungsi menyelenggarakan sistem
												pengaturan dan pengawasan yang terintegrasi terhadap
												keseluruhan kegiatan di dalam sektor jasa keuangan yang
												bebas dari campur tangan pihak lain, yang mempunyai
												fungsi, tugas dan wewenang pengaturan, pengawasan,
												pemeriksaan, dan penyidikan. OJK didirikan untuk
												menggantikan peran Bapepam-LK;
											</p>
										</li>
										<li>
											<p>
												<b>"Pembelian"</b> berarti tindakan yang dilakukan Nasabah
												atau Pemegang Unit Penyertaan untuk membeli Unit
												Penyertaan Reksa Dana sesuai dengan syarat dan ketentuan
												yang berlaku;
											</p>
										</li>
										<li>
											<p>
												<b>"Penjualan Kembali"</b> berarti tindakan yang dilakukan
												Nasabah atau Pemegang Unit Penyertaan untuk menjual Unit
												Penyertaan Reksa Dana sesuai dengan syarat dan ketentuan
												yang berlaku;
											</p>
										</li>
										<li>
											<p>
												<b>"Pengalihan"</b> berarti tindakan yang dilakukan
												Nasabah atau Pemegang Unit Penyertaan untuk mengalihkan
												dari satu Unit Penyertaan Reksa Dana ke Unit Penyertaan
												Reksa Dana lainnya yang masih dalam Manajer Investasi dan
												Bank Kustodian yang sama;
											</p>
										</li>
										<li>
											<p>
												<b>"Perorangan"</b> berarti orang perorangan yang memenuhi
												syarat sesuai Peraturan Yang Berlaku untuk menjadi
												Nasabah;
											</p>
										</li>
										<li>
											<p>
												<b>"Peraturan Yang Berlaku"</b> berarti peraturan
												perundang-undangan yang berlaku di Republik Indonesia
												khususnya di bidang pasar modal, termasuk namun tidak
												terbatas pada peraturan dan kebijakan yang dikeluarkan
												oleh Otoritas Jasa Keuangan serta ketentuan dan
												asosiasi-asosiasi dengan siapa PT Bibit Tumbuh Bersama
												berhubungan dan tergabung dan Peraturan dari Menteri
												Keuangan Republik Indonesia;
											</p>
										</li>
										<li>
											<p>
												<b>"Perusahaan Efek"</b> berarti perusahaan efek
												sebagiamana dimaksud dalam undang-undang mengenai pasar
												modal yang melakukan kegiatan usaha sebagai penjamin emisi
												efek, perantara pedagang efek termasuk yang khusus
												memasarkan efek Reksa Dana dan/atau Manajer Investasi;
											</p>
										</li>
										<li>
											<p>
												<b>"Reksa Dana"</b> berarti produk Reksa Dana yang
												dikelola oleh Manajer Investasi yang menjadi rekanan PT
												Bibit Tumbuh Bersama;
											</p>
										</li>
										<li>
											<p>
												<b>"Unit Penyertaan"</b> berarti satuan unit yang
												merupakan kepemilikan atas Reksa Dana oleh Nasabah.
											</p>
										</li>
									</ul>
								</li>
								<li>
									<h4>Ketentuan Umum</h4>

									<p>
										Seluruh Syarat dan Ketentuan ini berlaku bagi Pemohon yang
										membuka Rekening Efek di PT Bibit Tumbuh Bersama.
									</p>

									<ul>
										<li>
											<p>
												<b>II.1 Ketentuan dan Tata Cara</b>
											</p>

											<p>
												Ketentuan dan tata cara pembukaan Rekening Efek di PT
												Bibit Tumbuh Bersama melalui fasilitas transaksi online
												adalah sebagaimana ditetapkan oleh PT Bibit Tumbuh Bersama
												dengan memperhatikan ketentuan yang berlaku, serta dapat
												diubah sewaktu-waktu tanpa pemberitahuan terlebih dahulu
												kepada Pemohon. Dengan ini, Pemohon tunduk dan mengikatkan
												diri untuk membuat, menandatangani, memberikan, dan
												melengkapi semua data, perjanjian, maupun dokumen lainnya
												yang diperlukan oleh PT Bibit Tumbuh Bersama sehubungan
												adanya perubahan tersebut.
											</p>

											<p>
												Selain Syarat dan Ketentuan ini, Pemohon akan terikat
												dengan syarat dan ketentuan lain dalam transaksi Efek di
												PT Bibit Tumbuh Bersama termasuk didalamnya Syarat dan
												Ketentuan Penggunaan Fasilitas Transaksi Online serta
												syarat dan ketentuan lain yang telah ditentukan untuk
												masing-masing Efek.
											</p>
										</li>

										<li>
											<p>
												<b>II.2 Kelengkapan Rekening & Pernyataan Nasabah</b>
											</p>

											<p>
												Pemohon dapat membuka Rekening Efek di PT Bibit Tumbuh
												Bersama setelah mengisi Formulir Pembukaan Rekening secara
												lengkap dan melengkapi semua dokumen-dokumen identitas
												Pemohon sesuai dengan ketentuan yang berlaku. Pemohon
												dengan ini menerangkan dan menyatakan telah membaca,
												mengetahui, memahami, dan menyetujui sepenuhnya Syarat dan
												Ketentuan. Dengan menyetujui dan menandatangani Formulir
												Pembukaan Rekening, maka Pemohon tunduk dan terikat pada
												Syarat dan Ketentuan Pembukaan Rekening Efek, Ketentuan
												Transaksi, Tata Cara Transaksi, serta ketentuan-ketentuan
												lain yang ditetapkan oleh PT Bibit Tumbuh Bersama, beserta
												perubahan atau ketentuan tambahannya jika ada, untuk
												keperluan pelaksanaannya.
											</p>
										</li>

										<li>
											<p>
												<b>III.3 Permohonan/Penolakan/Pembatalan</b>
											</p>

											<p>
												PT Bibit Tumbuh Bersama mempunyai hak untuk menolak
												Permohonan Pembukaan Rekening jika:
											</p>
											<ul>
												<li>
													<p>
														Formulir Pembukaan Rekening tidak diisi secara lengkap
														atau tidak secara benar;
													</p>
												</li>
												<li>
													<p>
														Dokumen pendukung identitas Pemohon tidak lengkap atau
														tidak benar;
													</p>
												</li>
												<li>
													<p>
														Pemohon melakukan tindakan yang tidak sesuai dengan
														cara yang disyaratkan dalam Formulir Pembukaan
														Rekening.
													</p>
												</li>
											</ul>
										</li>

										<li>
											<p>
												<b>II.4 Prinsip Mengenal Nasabah (Know Your Client)</b>
											</p>

											<p>
												Sesuai dengan peraturan mengenai Prinsip Mengenal Nasabah
												sebagaimana dimuat dalam peraturan OJK POJK No. 12
												/POJK.01/2017 Tentang Penerapan Program Anti Pencucian
												Uang dan Pencegahan Pendanaan Terorisme di Sektor Jasa
												Keuangan Oleh Penyedia Jasa Keuangan di Sektor Pasar
												Modal, dengan mengajukan dan mengisi Formulir Pembukaan
												Rekening Efek, maka Pemohon setuju untuk tunduk dan
												mematuhi hal-hal sebagai berikut:
											</p>

											<p>
												Melakukan wawancara baik secara tatap muka langsung atau
												melalui layanan fasilitas lainnya yang telah ditentukan
												oleh PT Bibit Tumbuh Bersama. PT Bibit Tumbuh Bersama
												berhak meminta informasi mengenai latar belakang dan
												identitas Pemohon, maksud dan tujuan pembukaan rekening
												oleh Pemohon, serta informasi lain yang memungkinkan PT
												Bibit Tumbuh Bersama untuk dapat mengetahui profil Pemohon
												termasuk namun tidak terbatas pada, meminta identitas
												pihak lain dalam hal Pemohon bertindak untuk dan atas nama
												pihak lain.
											</p>

											<p>
												Memberikan informasi mengenai dirinya sendiri atau pihak
												yang menerima manfaat dalam hal Pemohon bertindak untuk
												dan atas nama pihak lain, atau pihak yang mewakili Pemohon
												dalam hal melakukan transaksi dengan memberikan keterangan
												dan dokumen-dokumen pendukung yang diperlukan sehubungan
												dengan pembukaan rekening Efek, hubungan hukum, penugasan
												serta kewenangan bertindak untuk dan atas nama pihak lain
												yang dimaksud.
											</p>

											<p>
												Dengan menandatangani Formulir Pembukaan Rekening, Pemohon
												memberikan persetujuan dan kuasa kepada PT Bibit Tumbuh
												Bersama untuk dapat melakukan verifikasi perihal kebenaran
												dan ketepatan informasi yang diberikan oleh Pemohon dari
												setiap sumber yang dianggap layak oleh PT Bibit Tumbuh
												Bersama.
											</p>
										</li>

										<li>
											<h4>II.5 Keterbukaan Informasi Mengenai Pemohon</h4>

											<p>
												Dengan ini, Pemohon memberikan persetujuan kepada PT Bibit
												Tumbuh Bersama untuk memberikan informasi mengenai data
												dan informasi Pemohon apabila diminta oleh pihak pihak
												yang tersebut di bawah ini:
											</p>

											<ul>
												<li>
													Bank Kustodian ("Bank Kustodian") dan/atau Manajer
													Investasi dalam rangka untuk pelaksanaan transaksi Reksa
													Dana oleh Pemohon;
												</li>
												<li>
													PT Bibit Tumbuh Bersama melalui aplikasi Android dan IOS
													maupun melalui fasilitas lainnya dalam rangka
													pelaksanaan transaksi Reksa Dana secara online oleh
													Pemohon;
												</li>
												<li>
													Otoritas Jasa Keuangan ("OJK") dan instansi lainnya
													berdasarkan peraturan perundang-undangan yang berlaku di
													Republik Indonesia yang membutuhkan informasi mengenai
													Pemohon;
												</li>
												<li>
													Pemohon berjanji tidak akan melakukan tindakan apapun
													yang dapat menyebabkan PT Bibit Tumbuh Bersama tidak
													dapat memberikan informasi di atas. Untuk keperluan
													tersebut, dengan ini Pemohon membebaskan PT Bibit Tumbuh
													Bersama dari segala kerugian dan tuntutan hukum yang
													mungkin diderita oleh Pemohon sebagai akibat dari
													disampaikannya informasi mengenai Pemohon kepada
													pihak-pihak tersebut diatas PT Bibit Tumbuh Bersama.
												</li>
												<li>
													Persetujuan dan kuasa yang diberikan oleh Pemohon kepada
													PT Bibit Tumbuh Bersama sebagaimana dimaksud dalam ayat
													1 di atas tidak dapat ditarik kembali sepanjang Pemohon
													melakukan transaksi online dengan PT Bibit Tumbuh
													Bersama, yang secara sah dapat dilakukan PT Bibit Tumbuh
													Bersama tanpa diperlukan tambahan surat persetujuan dan
													kuasa tersendiri dari Pemohon.
												</li>
											</ul>
										</li>
									</ul>
								</li>
								<li>
									<h4>Ketentuan Umum Transaksi</h4>

									<ul>
										<li>
											<p>
												<b>III.1 Instruksi</b>
											</p>

											<p>
												Pemohon dapat melakukan instruksi baik untuk pembelian
												(subscription), penjualan (redemption) dan pengalihan
												(switching) Unit Penyertaan sesuai dan berdasarkan pada
												ketentuan yang terdapat di dalam Prospektus setiap Reksa
												Dana. Setiap instruksi yang dikirimkan dan/atau diberikan
												oleh Pemohon dianggap sah apabila diterima oleh PT Bibit
												Tumbuh Bersama dan dilakukan sesuai dengan tata cara yang
												ditetapkan oleh PT Bibit Tumbuh Bersama. Pemohon wajib
												menyampaikan atau memberikan instruksi kepada PT Bibit
												Tumbuh Bersama melalui media atau sarana yang ditentukan
												PT Bibit Tumbuh Bersama. PT Bibit Tumbuh Bersama tidak
												bertanggung jawab atas instruksi yang disampaikan oleh
												Pemohon melalui media atau sarana diluar yang ditentukan
												oleh PT Bibit Tumbuh Bersama. PT Bibit Tumbuh Bersama
												tidak bertanggung jawab untuk memeriksa dan membuktikan
												keaslian, keabsahan, asal, atau hal-hal lain yang
												menyangkut setiap instruksi yang disampaikan secara
												elektronik oleh Pemohon, atau memeriksa identitas dari
												orang atau orang-orang yang memberikan instruksi tersebut
											</p>
										</li>

										<li>
											<p>
												<b>III.2 Pelaksanaan Instruksi</b>
											</p>

											<p>
												Semua instruksi Pemohon yang diterima oleh PT Bibit Tumbuh
												Bersama akan langsung dilaksanakan, namun eksekusi atas
												instruksi tersebut tergantung kepada kondisi dan prosedur
												transaksi, sebagaimana yang disebutkan pada Bagian IV
												mengenai TATA CARA TRANSAKSI di bawah ini, serta Syarat
												dan Ketentuan yang telah ditentukan untuk masing-masing
												Efek. Setiap instruksi Pemohon yang telah disampaikan
												kepada PT Bibit Tumbuh Bersama tidak dapat diubah dan
												dicabut kembali.
											</p>
										</li>

										<li>
											<p>
												<b>III.3 Pembatalan dan Perubahan Instruksi</b>
											</p>

											<p>
												Instruksi Pemohon hanya dapat dibatalkan dan/atau diubah
												apabila transaksi atas instruksi tersebut masih belum
												memperoleh persetujuan dari PT Bibit Tumbuh Bersama.
											</p>
										</li>

										<li>
											<p>
												<b>III.4 Penolakan Instruksi</b>
											</p>

											<p>
												PT Bibit Tumbuh Bersama berhak menolak perintah-perintah
												Pemohon berdasarkan kebijakannya sendiri yang dianggap
												benar dan baik, apabila Pemohon melanggar ketentuan di
												dalam Prospektus dan/atau persyaratan dan ketentuan yang
												ditetapkan oleh PT Bibit Tumbuh Bersama di Formulir
												Pembukaan Rekening ini atau dokumen tertulis lain [sesuai
												dengan POJK dokumen harus ditentukan yang mana saja, untuk
												memenuhi aspek kesetaraan dalam perjanjian] yang resmi
												diterbitkan oleh PT Bibit Tumbuh Bersama.
											</p>
										</li>
									</ul>
								</li>
								<li>
									<h4>Cara Transaksi</h4>

									<ul>
										<li>
											<p>
												<b>IV.1 Tata Cara Pembelian (Subscription)</b>
											</p>

											<p>
												Pembelian atas Unit Penyertaan dapat dilakukan setelah
												Permohonan Pembukaan Rekening disetujui PT Bibit Tumbuh
												Bersama. Pemohon mengisi Formulir Elektronik Pembelian
												Unit penyertaan ("Formulir Elektronik Pembelian") setelah
												menentukan pilihan produk Reksa Dana yang akan dibeli.
												Jika Formulir Elektronik Pembelian telah diisi
												selambat-lambatnya pukul 13.00 WIB dan dana telah
												ditransfer ke rekening Reksa Dana sebelum batas waktu yang
												ditetapkan pada masing-masing Prospektus Reksa Dana, maka
												transaksi tersebut akan diproses menggunakan NAB per Unit
												Penyertaan pada hari bursa yang sama. Pemohon akan
												mendapatkan Unit Penyertaan berdasarkan NAB yang dihitung
												oleh Bank Kustodian pada akhir hari bursa tersebut.
												Formulir Elektronik Pembelian atau pembayaran pembelian
												Unit Penyertaan yang diterima setelah batas waktu tersebut
												akan diproses menggunakan harga NAB per Unit Penyertaan
												pada hari bursa berikutnya. PT Bibit Tumbuh Bersama,
												Manajer Investasi dan Bank Kustodian hanya akan memproses
												pembelian Unit Penyertaan jika semua persyaratan tersebut
												telah dipenuhi dan pembayaran telah efektif di rekening
												Reksa Dana.
											</p>
										</li>

										<li>
											<p>
												<b>IV.2 Tata Cara Penjualan Kembali (Redemption)</b>
											</p>

											<p>
												Pemegang Unit Penyertaan dapat menjual kembali Unit
												Penyertaan sesuai dengan cara dan persyaratan sebagaimana
												ditentukan oleh PT Bibit Tumbuh Bersama. Setiap permohonan
												untuk menjual kembali Unit Penyertaan tersebut harus
												dilengkapi dengan informasi mengenai jumlah Unit
												Penyertaan yang akan dijual kembali atau nilai dana yang
												akan dijual kembali. Permohonan untuk menjual kembali Unit
												Penyertaan dapat dilakukan dengan mengisi Formulir
												Elektronik Penjualan Unit Penyertaan ("Formulir Elektronik
												Penjualan"). Formulir Elektronik Penjualan atau instruksi
												penjualan yang disampaikan kepada PT Bibit Tumbuh Bersama
												paling lambat pukul 13.00 WIB akan diproses dengan
												menggunakan NAB pada hari bursa yang sama. Formulir
												Elektronik Penjualan yang diterima setelah pukul 13.00 WIB
												akan dihitung berdasarkan harga NAB per Unit Penyertaan
												pada hari bursa berikutnya PT Bibit Tumbuh Bersama,
												Manajer Investasi dan Bank Kustodian hanya akan memproses
												permohonan penjualan kembali Unit Penyertaan jika semua
												persyaratan tersebut telah dipenuhi. Hasil penjualan hanya
												akan dikirimkan ke rekening Pemohon pada Bank yang
												tercatat di PT Bibit Tumbuh Bersama maksimal 7 (tujuh)
												hari bursa.
											</p>
										</li>

										<li>
											<p>
												<b>IV.3 Tata Cara Pengalihan (Switching)</b>
											</p>

											<p>
												Pemegang Unit Penyertaan dapat melakukan pengalihan
												(switching ) Unit Penyertaan yang dimilikinya dengan Unit
												Penyertaan Reksa Dana lain sesuai ketentuan yang
												ditetapkan oleh PT Bibit Tumbuh Bersama. Setiap permohonan
												untuk melakukan pengalihan tersebut harus dilengkapi
												dengan informasi mengenai jumlah Unit Penyertaan yang akan
												dialihkan atau nilai dana yang akan dialihkan dari
												keseluruhan Unit Penyertaan yang dimiliki. Permohonan
												untuk melakukan pengalihan dapat dilakukan dengan mengisi
												Formulir Elektronik Pengalihan Unit Penyertaan ("Formulir
												Pengalihan/Switching"). Formulir yang diterima oleh PT
												Bibit Tumbuh Bersama hingga pukul 13.00 WIB akan diproses
												dengan menggunakan NAB pada hari bursa yang sama. Formulir
												Pengalihan/Switching yang diterima setelah pukul 13.00 WIB
												akan dihitung berdasarkan harga NAB per Unit Penyertaan
												pada hari bursa berikutnya. PT Bibit Tumbuh Bersama,
												Manajer Investasi dan Bank Kustodian hanya akan memproses
												permohonan pengalihan Unit Penyertaan jika semua
												persyaratan tersebut telah dipenuhi.
											</p>
										</li>
									</ul>
								</li>
								<li>
									<h4>Konfirmasi, Laporan Bulanan dan Pemberitahuan</h4>

									<ul>
										<li>
											<p>
												<b>V.1 Konfirmasi Transaksi</b>
											</p>

											<p>
												Atas setiap transaksi yang dilakukan oleh Pemohon PT Bibit
												Tumbuh Bersama akan memberikan informasi sementara kepada
												Pemohon melalui fasilitas transaksi online
												selambat-lambatnya 2 (dua) x 24 (dua puluh empat) jam
												setelah transaksi. Konfirmasi resmi yang merupakan bukti
												kepemilikan atas Efek dan bukti transaksi yang sah akan
												diterbitkan oleh Bank Kustodian dan disampaikan sesuai
												ketentuan Efek yang bersangkutan.
											</p>
										</li>

										<li>
											<p>
												<b>V.2 Laporan Bulanan</b>
											</p>

											<p>
												Bank kustodian akan mengirimkan laporan bulanan kepada
												Pemegang Efek untuk setiap transaksi yang dilakukan selama
												1 (satu) bulan. Laporan Bulanan kepada Pemegang Efek akan
												dikirimkan ke alamat sebagaimana dicantumkan dalam
												Formulir Pembukaan Rekening Efek di PT Bibit Tumbuh
												Bersama atau melalui media lain yang ditentukan PT Bibit
												Tumbuh Bersama. Pemegang Efek berhak merubah alamat surat
												menyuratnya dengan pemberitahuan selambat-lambatnya 14
												(empat belas) hari bursa sebelum akhir bulan bersangkutan.
											</p>
										</li>

										<li>
											<p>
												<b>V.3 Pemberitahuan</b>
											</p>

											<p>
												Semua pemberitahuan dan komunikasi dari Pemohon atau dari
												PT Bibit Tumbuh Bersama, dilaksanakan dengan risiko pada
												Pemohon PT Bibit Tumbuh Bersama tidak bertanggung jawab
												atas ketidaktepatan, interupsi, kesalahan, keterlambatan,
												atau kegagalan dalam transmisi atau pengiriman suatu
												komunikasi elektronik, atau bentuk komunikasi lainnya,
												atau untuk kegagalan penerimaan atau tidak berfungsinya
												peralatan dan alat komunikasi yang dimiliki Pemohon.
											</p>
										</li>

										<li>
											<p>
												<b>V.4 Penutupan Rekening</b>
											</p>

											<p>
												PT Bibit Tumbuh Bersama memiliki hak untuk menutup
												rekening di PT Bibit Tumbuh Bersama apabila Pemohon tidak
												memiliki Efek.
											</p>
										</li>

										<li>
											<p>
												<b>V.5 Indemnifikasi</b>
											</p>

											<p>
												PT Bibit Tumbuh Bersama tidak bertanggung jawab atas
												segala kerugian, kehilangan, tuntutan, maupun gugatan yang
												dialami Pemohon yang mungkin timbul sebagai akibat
												penyampaian informasi dari Pemohon yang tidak lengkap atau
												akibat tidak dilaksanakannya instruksi Pemohon. Antara
												lain pembatalan, perubahan instruksi (untuk instruksi yang
												belum dijalankan) yang disampaikan kepada PT Bibit Tumbuh
												Bersama, kecuali jika kerugian tersebut terjadi akibat
												kesalahan yang disengaja dan kelalaian oleh PT Bibit
												Tumbuh Bersama selaku penyedia layanan transaksi Efek
												online.
											</p>

											<p>
												Pemohon secara sadar bersedia bertanggung jawab secara
												penuh atas segala tindakan yang dilakukannya dan
												membebaskan PT Bibit Tumbuh Bersama dan anak perusahaan,
												afiliasi, pemegang saham, direksi, pejabat, karyawan dan
												pemberi lisensi ("Pihak PT Bibit Tumbuh Bersama ")
												sepenuhnya dari segala tuntutan, kerugian, pembayaran
												maupun biaya apapun yang timbul yang diderita oleh Pemohon
												baik secara langsung maupun tidak langsung, yang berkaitan
												dengan dilaksanakannya atau tidak dilaksanakannya
												instruksi dari Pemohon, termasuk antara lain biaya
												penasehat hukum dan biaya perkara.
											</p>
										</li>

										<li>
											<p>
												<b>V.6 Force Majeure</b>
											</p>

											<p>
												PT Bibit Tumbuh Bersama tidak dapat diminta
												pertanggungjawabannya untuk suatu keterlambatan atau
												tertundanya pemenuhan kewajiban berdasarkan Syarat dan
												Ketentuan ini, yang diakibatkan oleh suatu sebab yang
												berada diluar kemampuan atau kekuasaan PT Bibit Tumbuh
												Bersama (force majeure), sepanjang pemberitahuan tertulis
												mengenai hal itu disampaikan kepada Pemohon dalam waktu
												tidak lebih dan 3x24 (tiga kali dua puluh empat) jam sejak
												timbulnya force majeure tersebut. Yang dimaksud dengan
												force majeure dalam syarat dan ketentuan ini antara lain,
												namun tidak terbatas pada, peristiwa-peristiwa kebakaran,
												bencana alam (seperti gempa bumi, banjir, angin topan,
												petir), pemogokan massal, huru-hara, peperangan, adanya
												perubahan terhadap peraturan perundang-undangan, dan
												kondisi di bidang ekonomi, keuangan dan pasar modal,
												kerusakan sistem transaksi PT Bibit Tumbuh Bersama,
												pembatasan yang dilakukan otoritas pasar modal dan bursa
												efek serta terganggunya sistem perdagangan kliring dan
												penyelesaian transaksi.
											</p>
										</li>

										<li>
											<p>
												<b>V.7 Pemohon Meninggal Dunia</b>
											</p>

											<p>
												Jika Pemohon meninggal dunia, maka ahli waris atau pihak
												yang diberikan kuasa untuk mewakili Pemohon wajib
												menyampaikan pemberitahuan kepada PT Bibit Tumbuh Bersama
												mengenai ahli waris sah yang ditunjuk dalam hal Unit
												Penyertaan akan dijual kembali atau dialihkan ke Pemegang
												Efek lain dengan menyampaikan akta kematian, surat
												keterangan ahli waris, akta wasiat, dan dokumen lain yang
												menurut pertimbangan PT Bibit Tumbuh Bersama diperlukan
												untuk mengetahui (para) ahli waris yang berhak atas
												Rekening Efek Pemohon yang telah meninggal dunia tersebut.
											</p>

											<p>
												Dengan penyerahan Rekening Efek Pemohon yang meninggal
												dunia kepada (para) ahli waris atau pelaksana wasiat
												sesuai dengan peraturan perundang-undangan yang berlaku
												sebagaimana yang disebutkan dalam surat keterangan hak
												waris atau surat wasiat atau dokumen lainnya, maka PT
												Bibit Tumbuh Bersama akan menutup Rekening Efek atas nama
												Pemohon dan PT Bibit Tumbuh Bersama dibebaskan sepenuhnya
												dari segala tuntutan, tanggung jawab, dan/atau klaim dalam
												bentuk apapun sehubungan dengan penyerahan kekayaan
												Rekening Efek Pemohon dimaksud.
											</p>
										</li>
									</ul>
								</li>
								<li>
									<h4>Lain-lain</h4>

									<ul>
										<li>
											<p>
												<b>VI.1 Hal lain dan Perubahan</b>
											</p>

											<p>
												Hal-hal yang belum diatur oleh Syarat dan Ketentuan ini
												atau apabila ada hal-hal yang perlu diubah, ditambah atau
												diganti akan dibuat aturannya oleh PT Bibit Tumbuh Bersama
												sesuai dengan ketentuan internal dan peraturan
												perundang-undangan yang berlaku.
											</p>
										</li>

										<li>
											<p>
												<b>VI.2 Kewenangan Menandatangani</b>
											</p>

											<p>
												Pemohon dengan ini menyatakan dan menjamin bahwa Pemohon
												dan/atau pihak yang ditunjuk/diberi kewenangan untuk
												menandatangani Formulir Pembukaan Rekening berhak dan
												mempunyai kewenangan untuk membuat dan menandatangani
												Formulir Pembukaan Rekening, dan karenanya terikat kepada
												Syarat dan Ketentuan ini serta peraturan
												perundang-undangan yang berlaku.
											</p>
										</li>

										<li>
											<p>
												<b>VI.3 Pemberi Kuasa</b>
											</p>

											<p>
												Pemohon dapat memberikan kuasa kepada pihak (Pihak) yang
												ditunjuk oleh Pemohon untuk menjalankan hak-hak yang
												timbul atas Rekening Efek PT Bibit Tumbuh Bersama,
												termasuk memberikan instruksi kepada PT Bibit Tumbuh
												Bersama, berdasarkan ketentuan yang ditetapkan PT Bibit
												Tumbuh Bersama.
											</p>
										</li>

										<li>
											<p>
												<b>VI.4 Hukum yang Berlaku</b>
											</p>

											<p>
												Mengenai Syarat dan Ketentuan ini dan segala akibat
												hukumnya akan berlaku hukum Negara Republik Indonesia.
											</p>
										</li>

										<li>
											<p>
												<b>VI.5 Penyelesaian Perselisihan dan Domisili Hukum</b>
											</p>

											<p>
												Semua perselisihan dan perbedaan pendapat yang mungkin
												timbul dalam pelaksanaan Perjanjian akan diselesaikan
												secara musyawarah, dan apabila timbul persoalan yang tidak
												dapat diselesaikan secara musyawarah, dalam 20 (dua puluh)
												hari setelah terjadinya perselisihan maka perselisihan
												pengaduan konsumen wajib diselesaikan terlebih dahulu
												dengan PT Bibit Tumbuh Bersama. Jika tidak tercapai
												kesepakatan penyelesaian pengaduan, maka konsumen dan
												pihak PT Bibit Tumbuh Bersama dapat melakukan penyelesaian
												sengketa melalui mediasi Badan Arbitrase Pasar Modal
												Indonesia (BAPMI) berdasarkan ketentuan sebagaimana diatur
												dalam Undang Undang No. 30 Tahun 1999 tentang Arbitrase
												dan Alternatif Penyelesaian Sengketa, atau Pengadilan
												Negeri.
											</p>
										</li>
									</ul>
								</li>
							</ul>

							<h3>SYARAT DAN KETENTUAN PENGGUNAAN</h3>

							<h3>Syarat & Ketentuan Umum</h3>

							<p>
								Selamat datang di Situs dan Aplikasi BIBIT. Berikut ini adalah
								Syarat dan Ketentuan ("Syarat dan Ketentuan") yang mengatur
								penggunaan BIBIT. Dengan menggunakan Bibit, Anda (sebagai
								"Pengguna") setuju terhadap Syarat dan Ketentuan ini, serta setuju
								terhadap Kebijakan Privasi yang dipublikasikan pada situs dan
								aplikasi BIBIT.
							</p>

							<ul>
								<li>
									<p>
										<b>1. Tujuan</b>
									</p>

									<p>
										BIBIT dimiliki dan dikelola oleh PT Bibit Tumbuh Bersama.
										BIBIT memungkinkan penggunanya untuk berinvestasi di produk
										reksa dana. Pengguna BIBIT dianggap sebagai nasabah BIBIT
										setelah pengguna tersebut menyelesaikan transaksi pertamanya
										di BIBIT. Namun pengguna BIBIT tetap merupakan subyek dari
										Syarat dan Ketentuan ini walaupun belum menjadi nasabah BIBIT.
									</p>
								</li>

								<li>
									<p>
										<b>2. Hak Atas Kekayaan Intelektual</b>
									</p>

									<p>
										Hak Cipta konten termasuk tetapi tidak terbatas tulisan,
										gambar, animasi, data, dan video yang ada pada BIBIT dipegang
										oleh PT Bibit Tumbuh Bersama. Hak Cipta ini dilindungi oleh
										Undang-Undang Hak Cipta dan peraturan lain yang terkait serta
										traktat-traktat internasional tentang hak cipta. Setiap orang
										dilarang untuk menggunakan konten diluar ketentuan yang
										diperbolehkan secara tegas oleh peraturan perundang-undangan,
										termasuk menggandakan, mendistribusikan, mengubah, menyiarkan,
										menggunakan kembali, memindahkan ataupun membuat karya
										derivatif dari BIBIT tanpa izin tertulis dari PT Bibit tumbuh
										Bersama
									</p>
								</li>

								<li>
									<p>
										<b>3. Kelayakan</b>
									</p>

									<p>
										Dengan membuat akun Pengguna di BIBIT, Anda menunjukkan dan
										menjamin bahwa anda merupakan Warga Negara Indonesia (WNI),
										penduduk Republik Indonesia, dan berumur 17 tahun atau lebih
										pada saat pembuatan akun pengguna BIBIT.
									</p>
								</li>

								<li>
									<p>
										<b>4. Jaminan Mengenai Konten</b>
									</p>
									<p>
										Segala informasi yang dipublikasikan pada situs atau aplikasi
										BIBIT hanya dapat digunakan untuk tujuan informasi dan bukan
										sebagai saran, rekomendasi atau ajakan untuk menjual atau
										membeli suatu produk investasi tertentu yang terdapat dalam
										situs ini. PT Bibit Tumbuh Bersama berusaha dengan itikad baik
										untuk memberikan informasi yang akurat, namun tidak menjamin
										bahwa informasi yang diberikan adalah tanpa adanya kesalahan,
										kelalaian, ketidakakuratan teknis atau faktual ataupun
										kesalahan ketik. Ketentuan ini untuk menegaskan agar tidak
										dianggap atau seolah-olah agar dianggap sebagai prospektus
										atau informasi lainnya yang bersifat mengikat. Informasi yang
										tersedia dalam situs ini tidak dimaksudkan sebagai pengganti
										suatu informasi produk investasi seperti prospektus dan
										informasi resmi lainnya, namun semata-mata hanya sebagai
										informasi tambahan dan pelengkap.
									</p>
								</li>

								<li>
									<p>
										<b>5. Non Liabilitas</b>
									</p>

									<p>
										Informasi yang tersedia dalam situs atau aplikasi ini
										disediakan dengan "sebagaimana adanya " dan "sebagaimana
										tersedia". PT Bibit Tumbuh Bersama beserta anak perusahaan,
										pihak terafiliasi, pemegang saham, pengelola dan karyawannya
										tidak dapat dimintai pertanggungjawaban secara hukum, baik
										pidana maupun perdata, atas kerugian yang dialami oleh
										pengguna akibat menggunakan informasi ini baik karena adanya
										kesalahan dan ketidakakuratan informasi yang tersedia dalam
										situs ini atau karena sebab lainnya. PT Bibit Tumbuh Bersama
										berusaha untuk memperbaharui informasi dalam situs ini, namun
										demikian PT Bibit Tumbuh Bersama tidak mempunyai kewajiban
										untuk memperbaharui informasi yang terdapat dalam situs dan
										aplikasi BIBIT ini. Oleh karena itu, Anda tetap berkewajiban
										untuk membaca prospektus dan materi lainnya yang relevan
										sebelum memutuskan untuk membeli atau menjual suatu produk
										informasi.
									</p>
								</li>

								<li>
									<p>
										<b>6. Tautan ke Media Pihak Ketiga</b>
									</p>

									<p>
										Situs atau aplikasi BIBIT dapat memuat tautan ke situs pihak
										ketiga yang tidak berada dalam kuasa PT Bibit Tumbuh Bersama.
										Tautan ini tidak menunjukkan adanya dukungan atau rekomendasi
										terhadap layanan ataupun produk dari pihak ketiga tersebut.
										Anda setuju untuk tidak meminta pertanggungjawaban PT Bibit
										Tumbuh Bersama atas kesalahan yang terjadi pada layanan atau
										produk yang ditawarkan pada Media Pihak Ketiga tersebut. Anda
										memahami bahwa Media Pihak Ketiga tersebut dapat memiliki
										Syarat dan Ketentuan yang berbeda serta tingkat keamanan yang
										lebih rendah.
									</p>
								</li>

								<li>
									<p>
										<b>7. Pernyataan Pandangan Ke Depan</b>
									</p>

									<p>
										Setiap analisa proyeksi, ataupun pernyataan yang merupakan
										prediksi suatu produk investasi di masa datang bukan merupakan
										indikasi kinerja masa yang akan datang. Kinerja masa lalu
										tidak dapat dijadikan suatu pedoman untuk kinerja masa datang.
										Anda disarankan mencari bantuan tenaga profesional untuk
										mendapatkan saran perpajakan, nasehat hukum, akuntansi
										termasuk risiko investasi yang terdapat didalamnya sebelum
										melakukan keputusan investasi.
									</p>
								</li>

								<li>
									<p>
										<b>8. Risiko</b>
									</p>

									<p>
										Mohon diperhatikan bahwa berbagai jenis investasi yang
										terdapat dalam situs ini melibatkan berbagai tingkatan risiko
										dan tidak terdapat jaminan bahwa investasi tertentu cocok,
										sesuai atau menguntungkan bagi Anda. Setiap produk investasi
										mengandung risiko dan Anda wajib mencari informasi sebelum
										memutuskan keputusan investasi. Kinerja masa lalu tidak
										mencerminkan kinerja di masa datang.
									</p>
								</li>

								<li>
									<p>
										<b>9. Penggunaan Data</b>
									</p>

									<p>
										Penggunaan data Anda diatur setiap waktu oleh Kebijakan
										Privasi yang terdapat di "Kebijakan Privasi". Pada proses
										operasional normal, data tersebut dapat diberikan kepada pihak
										ketiga, dimana penggunaan data tersebut tidak selalu diatur
										oleh Kebijakan Privasi yang sama. Anda setuju untuk tidak
										meminta pertanggungjawaban PT Bibit Tumbuh Bersama untuk
										kerugian yang diakibatkan penggunaan data Anda oleh pihak
										ketiga tersebut. Izin untuk memberikan data Anda akan diminta
										sewaktu dibutuhkan.
									</p>
								</li>

								<li>
									<p>
										<b>10. Alat Analisis Keuangan</b>
									</p>

									<p>
										Setiap alat analisis keuangan yang terdapat dalam situs ini
										merupakan alat bantu dan hanya memberikan gambaran informasi
										investasi secara umum serta analisis keuangan berdasarkan data
										yang Anda berikan. Dengan memperhitungkan segala risiko
										investasi termasuk diantaranya risiko ekonomi dan risiko
										pasar, tidak ada jaminan bahwa investasi tersebut akan
										mencapai tujuannya sebagaimana ditampilkan oleh alat analisa
										tersebut. Perbedaan strategi yang diterapkan ke dalam suatu
										produk investasi dapat memberikan pengaruh yang signifikan
										terhadap gambaran hasil investasi tersebut. Imbal hasil
										investasi dapat dipengaruhi antara lain oleh strategi atau
										tujuan investasi, kondisi pasar dan kondisi ekonomi termasuk
										kondisi tingkat suku bunga, periode investasi dan kondisi
										pasar secara umum.
									</p>
								</li>

								<li>
									<p>
										<b>11. Perubahan pada Syarat dan Ketentuan</b>
									</p>

									<p>
										PT Bibit Tumbuh Bersama dapat mengubah Syarat dan Ketentuan
										ini dan Kebijakan Privasi sewaktu-waktu tanpa pemberitahuan
										sebelumnya. Dengan berlanjutnya Anda menggunakan BIBIT
										menandakan bahwa Anda menerima perubahan Syarat dan Ketentuan
										tersebut.
									</p>
								</li>

								<li>
									<p>
										<b>12. Aturan Hukum yang Berlaku dan Yuridiksi</b>
									</p>

									<p>
										Konten yang ada pada situs ini dan yang ada pada Ketentuan
										akan tunduk kepada aturan hukum negara Republik Indonesia.
										Setiap perselisihan yang terkait dengan atau muncul dari situs
										ini akan tunduk dan diatur berdasarkan aturan hukum yang sama.
									</p>
								</li>
							</ul>
							<h3>
								Syarat & Ketentuan Penggunaan Portal Transaksi Reksa Dana
								Online BIBIT
							</h3>

							<ul>
								<li>
									<p><b>1. Informasi Nasabah</b></p>
									<p>
										Setiap informasi yang kami peroleh dari Nasabah melalui
										akses fasilitas Transaksi Online BIBIT dari waktu ke waktu
										akan diperlakukan sesuai dengan ketentuan-ketentuan dalam
										“Kebijakan Privasi”.
									</p>
								</li>

								<li>
									<p><b>2. User ID dan PIN</b></p>
									<p>
										Dalam menggunakan fasilitas Transaksi Online BIBIT,
										Nasabah akan diminta untuk mengisi User ID berupa nomor
										handphone Nasabah dan PIN (menggunakan angka) untuk
										melakukan verifikasi terhadap identitas Nasabah. PT Bibit
										Tumbuh Bersama menyarankan agar Nasabah tidak membuat PIN
										yang berkaitan dengan nama, kerabat, tempat dan tanggal
										lahir, alamat, nomor telepon, nomor kartu tanda penduduk,
										maupun nomor paspor Nasabah. Nasabah bertanggung jawab
										penuh untuk menjaga kerahasiaan dan keamanan dari User ID
										dan PIN Nasabah, dan PT Bibit Tumbuh Bersama tidak
										bertanggung jawab atas penggunaan User ID dan PIN Nasabah
										oleh pihak yang tidak bertanggung jawab maupun atas
										kerugian yang timbul akibat penggunaan fasilitas Transaksi
										Online dengan menggunakan User ID dan PIN Nasabah.
									</p>
								</li>

								<li>
									<p><b>3. Ketepatan Informasi Transaksi</b></p>
									<p>
										Sebelum atau pada saat Nasabah menggunakan fasilitas
										Transaksi Online BIBIT, Nasabah akan dihadapkan pada layar
										konfirmasi dimana Nasabah bertanggung jawab atas setiap
										data yang diisi pada saat menggunakan fasilitas Transaksi
										Online BIBIT termasuk namun tidak terbatas pada jumlah
										pembelian, pembelian kembali, maupun pengalihan Unit
										Penyertaan Reksa Dana yang dikehendaki Nasabah. PT Bibit
										Tumbuh Bersama tidak bertanggung jawab atas setiap
										ketepatan data-data yang diisi oleh Nasabah pada saat
										menggunakan fasilitas Transaksi Online BIBIT, maupun atas
										setiap kerugian yang timbul akibat pengisian data-data
										tersebut oleh Nasabah.
									</p>
								</li>

								<li>
									<p>
										<b>
											4. Hak PT Bibit Tumbuh Bersama Untuk Menunda,
											Menghentikan, atau Membatalkan Transaksi Nasabah
										</b>
									</p>
									<p>
										PT Bibit Tumbuh Bersama mempunyai kewenangan tanpa perlu
										memberikan suatu pemberitahuan terhadap Nasabah, untuk
										menunda, menghentikan, atau membatalkan transaksi Nasabah
										melalui fasilitas Transaksi Online BIBIT apabila diketahui
										atau dianggap oleh PT Bibit Tumbuh Bersama bahwa transaksi
										Nasabah akan digunakan untuk suatu tindak pidana, atau
										kekayaan Nasabah diperoleh karena suatu tindak pidana,
										maupun karena diperintahkan oleh otoritas yang berwenang.
									</p>
								</li>

								<li>
									<p><b>5. Profil Risiko Nasabah</b></p>

									<ul>
										<li>
											<p>
												a. Profil risiko nasabah sebagaimana tercantum dalam
												fasilitas transaksi online BIBIT hanyalah acuan untuk
												mengetahui jenis investasi yang sesuai dengan karakter
												investasi nasabah.
											</p>
										</li>

										<li>
											<p>
												b. Profil risiko nasabah sebagaimana tercantum dalam
												fasilitas transaksi online BIBIT tidak dapat dijadikan
												acuan sebagai keberhasilan atau kepastian hasil
												investasi yang diharapkan oleh Nasabah.
											</p>
										</li>
									</ul>
								</li>

								<li>
									<p><b>6. Kebijakan Pembelian</b></p>

									<p>
										Dengan melakukan pembelian (subscription) Unit Penyertaan
										Reksa Dana melalui fasilitas transaksi online BIBIT,
										Nasabah menyatakan bahwa Nasabah telah membaca dan
										mengerti isi dari Prospektus Reksa Dana yang bersangkutan
										termasuk namun tidak terbatas mengenai biaya-biaya yang
										ada termasuk biaya pengelolaan (management fee) serta
										risiko-risiko yang ada dengan melakukan investasi pada
										Reksa Dana sebagaimana tercantum dalam Prospektus Reksa
										Dana yang bersangkutan.
									</p>
								</li>

								<li>
									<p><b>7. Cut-Off Time</b></p>
									<p>
										Nasabah mengerti bahwa pembelian (subscription) Unit
										Penyertaan Reksa Dana yang dilakukan oleh Nasabah melalui
										fasilitas transaksi online ini mempunyai cut off time
										untuk penggunaan Nilai Aktiva Bersih (NAB) Reksa Dana yang
										berbeda dengan pelaksanaan pembelian (subscription) Unit
										Penyertaan secara langsung karena diperlukannya waktu dari
										sistem fasilitas transaksi online untuk melakukan
										pengolahan pesanan pembelian (subscription) Unit
										Penyertaan Reksa Dana. Adapun cut off time penggunaan NAB
										Unit Penyertaan Reksa Dana melalui fasilitas transaksi
										online ini adalah sebagai berikut:
									</p>
									<ul>
										<li>
											<p>
												a. Pesanan pembelian (subscription) Unit Penyertaan
												melalui fasilitas transaksi online BIBIT dapat
												dilakukan sejak Pukul 12.01 PM H+0, dimana pembelian
												pada Pukul 12.01 PM H-1 s/d Pukul 12.00 PM H+0 akan
												menggunakan NAB Unit Penyertaan Reksa Dana yang
												diumumkan dalam Koran harian di hari bursa
												dilakukannya pesanan tersebut.
											</p>
										</li>
										<li>
											<p>
												b. Pesanan pembelian (subscription) Unit Penyertaan
												melalui fasilitas transaksi online BIBIT yang
												dilakukan setelah Pukul 12.00 PM atau Hari Libur Bursa
												akan menggunakan NAB Unit Penyertaan Reksa Dana yang
												diumumkan di hari bursa berikutnya.
											</p>
										</li>
									</ul>
								</li>

								<li>
									<p>
										<b>
										8. Kebijakan Penjualan Kembali Reksa Dana oleh Nasabah
										(redemption)
										</b>
									</p>
									<p>
										Nasabah mengerti bahwa penjualan kembali (redemption) Unit
										Penyertaan Reksa Dana hanya dapat dilakukan dengan basis
										jumlah Unit Penyertaan yang hendak dijual oleh Nasabah. PT
										Bibit Tumbuh Bersama tidak dapat menerima redemption Unit
										Penyertaan Reksa Dana dengan basis nilai nominal. Estimasi
										hasil penjualan kembali (redemption) Unit Penyertaan Reksa
										Dana sebagaimana ditampilkan dalam fasilitas transaksi
										online ini adalah suatu perkiraan dimana realisasi dari
										pelaksanaan penjualan kembali (redemption) dapat berbeda
										sesuai dengan Nilai Aktiva Bersih (NAB) yang berlaku pada
										saat realisasi penjualan kembali (redemption). Nasabah
										mengerti bahwa penjualan kembali (redemption) Unit
										Penyertaan Reksa Dana yang dilakukan oleh Nasabah melalui
										fasilitas transaksi online BIBIT mempunyai cut off time
										untuk penggunaan Nilai Aktiva Bersih (NAB) Reksa Dana yang
										berbeda dengan pelaksanaan penjualan kembali (redemption)
										Unit Penyertaan secara langsung karena diperlukannya waktu
										dari sistem fasilitas transaksi online untuk melakukan
										pengolahan pesanan penjualan kembali (redemption) Unit
										Penyertaan Reksa Dana. Adapun cut off time penggunaan NAB
										Unit Penyertaan Reksa Dana melalui fasilitas transaksi
										online ini adalah sebagai berikut:
									</p>
									<ul>
										<li>
											<p>
												a. Pesanan penjualan kembali (redemption) Unit
												Penyertaan melalui fasilitas transaksi online BIBIT
												dapat dilakukan sejak Pukul 12.01 H+0, dimana
												penjualan kembali (redemption) pada Pukul 12.01 PM H-1
												s/d Pukul 12.00 PM H+0 akan menggunakan NAB Unit
												Penyertaan Reksa Dana yang diumumkan di hari bursa
												dilakukannya pesanan tersebut.
											</p>
										</li>
										<li>
											<p>
												b. Pesanan penjualan kembali (redemption) Unit
												Penyertaan melalui fasilitas transaksi online yang
												dilakukan setelah Pukul 12.00 PM atau Hari Libur Bursa
												akan menggunakan NAB Unit Penyertaan Reksa Dana yang
												diumumkan di hari bursa berikutnya.
											</p>
										</li>
									</ul>
								</li>

								<li>
									<p><b>9. Kebijakan Pengalihan</b></p>
									<p>
										Nasabah mengerti bahwa pengalihan (switching) dari satu
										Reksa Dana ke Reksa Dana lainnya hanya dapat dilakukan
										dengan basis jumlah Unit Penyertaan Reksa Dana yang hendak
										dialihkan dan hanya berlaku dari dan ke dalam Reksa Dana
										yang menyediakan fitur pengalihan (switching) tersebut
										sebagaimana diatur dalam masing-masing Prospektus Reksa
										Dana. PT Bibit Tumbuh Bersama tidak dapat menerima
										pengalihan Unit Penyertaan Reksa Dana dengan basis nilai
										nominal. Estimasi kepemilikan Unit Penyertaan dari hasil
										pengalihan (switching) dari satu Reksa Dana ke Reksa Dana
										lainnya sebagaimana ditampilkan dalam fasilitas transaksi
										online ini adalah suatu perkiraan dimana realisasi dari
										pelaksanaan pengalihan (switching) dapat berbeda sesuai
										dengan Nilai Aktiva Bersih dari masing-masing Unit
										Penyertaan Reksa Dana yang berlaku pada saat realisasi
										pengalihan (switching). Dengan melakukan pengalihan
										(switching) Reksa Dana melalui fasilitas transaksi online
										ini, Nasabah menyatakan bahwa Nasabah telah membaca dan
										mengerti isi dari Prospektus Reksa Dana yang bersangkutan
										termasuk namun tidak terbatas mengenai biaya-biaya yang
										ada termasuk biaya pengelolaan (management fee) serta
										risiko-risiko yang ada dengan melakukan investasi pada
										Reksa Dana sebagaimana tercantum dalam Prospektus Reksa
										Dana yang bersangkutan. Nasabah mengerti bahwa pengalihan
										(switching) Unit Penyertaan Reksa Dana yang dilakukan oleh
										Nasabah melalui fasilitas transaksi online ini mempunyai
										cut off time untuk penggunaan Nilai Aktiva Bersih (NAB)
										Reksa Dana yang berbeda dengan pelaksanaan pengalihan
										(switching) secara langsung karena diperlukannya waktu
										dari sistem fasilitas transaksi online untuk melakukan
										pengolahan pesanan pengalihan (switching) Reksa Dana.
										Adapun cut off time penggunaan NAB Unit Penyertaan Reksa
										Dana melalui fasilitas transaksi online ini adalah sebagai
										berikut:
									</p>
									<ul>
										<li>
											<p>
												a. Pesanan pengalihan (switching) Unit Penyertaan
												melalui fasilitas transaksi online BIBIT dapat
												dilakukan sejak Pukul 12.01 PM H+0, dimana pengalihan
												(switching) pada Pukul 12.01 PM H-1 s/d Pukul 12.00 PM
												H+0 akan menggunakan NAB Unit Penyertaan Reksa Dana
												yang diumumkan di hari bursa dilakukannya pesanan
												tersebut.
											</p>
										</li>
										<li>
											<p>
												b. Pesanan pengalihan (switching) Unit Penyertaan
												melalui fasilitas transaksi online yang dilakukan
												setelah Pukul 12.00 PM atau Hari Libur Bursa akan
												menggunakan NAB Unit Penyertaan Reksa Dana yang
												diumumkan di hari bursa berikutnya.
											</p>
										</li>
									</ul>
								</li>
								<li>
									<p><b>10 Open Account</b></p>
									<p>
										Nasabah sepenuhnya menyadari dan mengakui akan bertanggung
										jawab sepenuhnya atas setiap kerugian maupun tuntutan yang
										timbul terhadap PT Bibit Tumbuh Bersama atas akibat
										ketidakbenaran data dan informasi yang Nasabah sampaikan
										kepada PT Bibit Tumbuh Bersama melalui fasilitas transaksi
										online BIBIT.
									</p>
								</li>

								<li>
									<p><b>11. Enhanced Due Diligence</b></p>
									<p>
										Sesuai dengan PERATURAN OTORITAS JASA KEUANGAN NOMOR
										12/POJK.01/2017 TENTANG PENERAPAN PROGRAM ANTI PENCUCIAN
										UANG DAN PENCEGAHAN PENDANAAN TERORISME DI SEKTOR JASA
										KEUANGAN, selain proses pendaftaran normal, sebagai
										penyedia jasa keuangan yang tunduk kepada regulasi OJK, PT
										Bibit Tumbuh Bersama diwajibkan untuk melakukan proses
										Enhanced Due Diligence nasabah baru dan lama yang masuk ke
										dalam kategori profile resiko medium sampai tinggi.
									</p>
								</li>

								<li>
									<p><b>12. Penggunaan Robo Advisor</b></p>

									<p>
										BIBIT menyediakan layanan robo advisor sebagai bantuan
										bagi pengguna Bibit dalam menentukan alokasi aset Reksa
										Dana yang optimal bagi tiap-tiap Nasabah. Alokasi aset
										Reksa Dana ini hanya sebagai saran bagi Nasabah dalam
										berinvestasi Reksa Dana di BIBIT dan bukan kewajiban untuk
										diikuti oleh Nasabah. PT Bibit Tumbuh Bersama dapat
										sewaktu-waktu dan tanpa pemberitahuan terlebih dahulu
										mengubah atau menghilangkan layanan robo advisor BIBIT.
										Dalam penggunaan robo advisor BIBIT, Nasabah dapat memilih
										Reksa Dana untuk tiap asset class, tapi jika karena satu
										dan lain hal seperti minimum pembelian yang tidak
										terpenuhi maka pilihan Reksa Dana Nasabah akan diganti ke
										produk Reksa Dana yang bisa memenuhi minimum transaksi
										Nasabah. Dalam penggunaan robo advisor di BIBIT, PT Bibit
										Tumbuh Bersama tidak menjamin nilai imbal hasil yang pasti
										untuk para Nasabahnya. PT Bibit Tumbuh Bersama tidak
										bertanggung jawab atas segala kerugian, kehilangan,
										tuntutan maupun gugatan yang dialami Nasabah yang mungkin
										timbul sebagai akibat dari penggunaan robo advisor BIBIT.
									</p>
								</li>
							</ul>

							<h3>Referral Bonus</h3>
							<ul>
								<li>Promo Bonus Referral ini hanya berlaku di aplikasi Bibit (Android/iOS).</li>
								<li>Promo Bonus Referral dalam bentuk unit Reksa dana. </li>
								<li>Pendaftar baru (Referrer) yang menggunakan kode referral yang sah saat mendaftar akan langsung mendapatkan Bonus Referral sebesar Rp 50.000 dan hanya bisa mengikuti promo ini 1 (satu) kali.</li>
								<li>Pemberi kode (Referral) akan mendapatkan Bonus Referral sebesar Rp 25.000 per pendaftar baru yang menggunakan kode referalnya.</li>
								<li>Bonus referral sebesar Rp 50.000 dapat dipakai saat melakukan pembelian robo dengan mengaktifkan toggle “Gunakan cashback” di bagian input jumlah investasi.</li>
								<li>Proses pembelian Reksa Dana menggunakan saldo referral akan dilaksanakan setelah Semua pembelian utama berhasil.</li>
								<li>Jika ada salah satu dari pembelian utama gagal dan/atau ditolak dan/atau expired, maka pembelian Reksa Dana menggunakan saldo referral akan dibatalkan. Saldo yang dibatalkan akan kembali digabungkan dengan saldo referral saat itu untuk dapat dipakai pada pembelian robo berikutnya.</li>
								<li>Bonus Referral sebesar Rp 25.000 untuk pemberi kode (referral) didapat setelah referrer sukses melakukan pembelian dan pembayaran reksa dana pertamanya.</li>
								<li>Bonus Referral akan dibelikan unit Reksa Dana Simas Saham Unggulan.</li>
								<li>Bonus Referral akan masuk ke dalam portofolio dalam waktu 3 - 14 hari kerja.</li>
								<li>Promo Referral bonus unit reksa dana ini tersedia terbatas.</li>
								<li>Promo Referral tidak bisa digabungkan dengan promo lainnya.</li>
								<li>Hanya pengguna Bibit yang sudah memiliki akun dan sudah terverifikasi email dan handphonenya yang bisa menggunakan promo ini.</li>
								<li>Jumlah maksimum Bonus Referral yang bisa didapatkan oleh pengguna Bibit akan ditentukan kemudian setelah program ini berjalan.</li>
								<li>Jumlah maksimum transaksi yang menggunakan Bonus Referral akan ditentukan kemudian setelah program ini berjalan.</li>
								<li>Bibit berhak membatalkan atau menolak transaksi yang terindikasi penipuan.</li>
								<li>Bibit berhak melakukan pembatalan transaksi/membatalkan penggunaan promo sewaktu-waktu, tanpa pemberitahuan terlebih dahulu kepada user.</li>
								<li>Bibit berhak mengubah syarat dan ketentuan promo sewaktu-waktu.</li>
							</ul>

							<h3>Disclaimer</h3>
							<p>
								Reksa Dana adalah produk pasar modal dan bukan merupakan produk
								Bank, tidak dijamin oleh Bank dan tidak termasuk simpanan yang
								merupakan objek program penjaminan Pemerintah atau penjaminan
								simpanan. Reksa Dana bukan merupakan produk PT Bibit Tumbuh
								Bersama. PT Bibit Tumbuh Bersama hanya sebagai agen penjual efek
								Reksa Dana. Reksa Dana merupakan produk yang dikelola oleh
								Manajer Investasi. Investasi melalui Reksa Dana mengandung
								risiko. Calon pemodal wajib membaca dan memahami prospektus
								sebelum memutuskan untuk berinvestasi melalui Reksa Dana.
								Kinerja masa lalu tidak mencerminkan kinerja masa datang. PT
								Bibit Tumbuh Bersama tidak bertanggung jawab atas segala
								kerugian, kehilangan, tuntutan maupun gugatan yang dialami
								Nasabah yang mungkin timbul sebagai akibat penyampaian informasi
								dari Nasabah yang tidak lengkap atau akibat tidak
								dilaksanakannya instruksi Nasabah, antara lain pembatalan,
								perubahan instruksi (untuk instruksi yang belum dijalankan) yang
								disampaikan kepada PT Bibit Tumbuh Bersama kecuali jika kerugian
								tersebut terbukti akibat kesalahan dan/atau kelalaian PT Bibit
								Tumbuh Bersama. Dengan membeli Reksa Dana melalui PT Bibit
								Tumbuh Bersama sebagai agen penjual efek Reksa Dana, Nasabah
								secara sadar bersedia bertanggung jawab secara penuh atas segala
								tindakan yang dilakukannya, dan membebaskan PT Bibit Tumbuh
								Bersama, termasuk anak perusahaan, afiliasi, pemegang saham,
								direksi, pejabat, karyawan sepenuhnya dari segala tuntutan,
								kerugian, pembayaran maupun ongkos apapun yang timbul yang
								diderita oleh PT Bibit Tumbuh Bersama atau yang diajukan kepada
								PT Bibit Tumbuh Bersama baik secara langsung maupun tidak
								langsung, termasuk antara lain biaya penasehat hukum dan biaya
								perkara, yang berkaitan dengan dilaksanakannya atau tidak
								dilaksanakannya instruksi dari Nasabah oleh Manager Investasi.
							</p>
						</div>
	  				</div>
	  			</div>
	  		</div>
	  	);
	  }
} 

export default TermsCondition;