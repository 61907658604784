import React, { Component, Fragment } from 'react';
import getSafely from '../../../utils/safely';

import './GlobalDocumentsModal.css';
import DocumentModal from './DocumentModal';
import { ArrowDownCircle, Loader } from 'react-feather';
import { handleResponseGeneral } from '../../../utils/handleResponse';

import { serviceFetchDocumentBySymbol } from "./../../../services/product.services";


function fetchDocumentsSymbol (symbol) {
	return serviceFetchDocumentBySymbol(symbol)
		.then(response => {
			return handleResponseGeneral(response);
		});
}

class GlobalDocumentsModal extends Component {
	state={
		prospectus: [],
		prospectusLength: 0,
		fundfacts: [],
		fundFactsLength: 0,
		loading: true
	}
	componentDidMount () {
		const symbols = this.props.symbols.join(',');
		fetchDocumentsSymbol(symbols)
			.then(response => {
				const {
					data
				} = response;
				const prospectus = getSafely(['prospectus'], data); 
				const prospectusLength = prospectus? prospectus.length: 0; 
				const fundfacts = getSafely(['factsheets'], data); 
				const fundfactsLength = fundfacts? fundfacts.length: 0; 
				return this.setState({
					prospectus: prospectus || [],
					prospectusLength: prospectusLength,
					fundfacts: fundfacts || [],
					fundfactsLength: fundfactsLength,
				});
			})
			.then(() => {
				this.setState({
					loading: false
				});
			});
	}
	renderLoading () {
		return (
			<Loader />
		);
	}
	render() {
		const {
			prospectus,
			fundfacts
		} = this.state;
		if(this.state.loading)
			return this.renderLoading();
		if(this.state.symbols)
			return null;
		return (
			<Fragment>
				<DocumentModal title='Prospectus' documents={[...prospectus]} renderButton={open => {
					return <div onClick={open}><p>Prospektus</p><ArrowDownCircle width="13" color="#FF0000"/></div>;
				}} />
				<DocumentModal title='Fund Fact Sheet' documents={[...fundfacts]} renderButton={open => {
					return <div onClick={open}><p>Fund Fact Sheet</p><ArrowDownCircle width="13" color="#FF0000"/></div>;
				}} />
			</Fragment>
		);
	}
}

GlobalDocumentsModal.defaultProps = {
	fundfacts: [],
	prospectus: [],
};


export default GlobalDocumentsModal;