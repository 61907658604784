import getSafely from "../utils/safely";

export function getUserPortfolioStatus (state) {
	const portfolio = state.user;
	const {portfolio_status} = portfolio;
	return portfolio_status;
}

export function getPartnerCallback (state) {
	const callback = getSafely(['userpartner', 'callback_url'], state);
	return callback;
}

export function getPartnerStatus (state) {
	const status = getSafely(['userpartner', 'status'], state);
	return status;
}