/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { ChevronLeft } from 'react-feather';
import Schroder from './../../../assets/images/schroder.svg';

import { handleResponseGeneral } from '../../../utils/handleResponse';
import getSafely from '../../../utils/safely';
import { numberToGeneralValueFormat } from '../../../utils/string-helper';
import InvestmentCompanyLogo from '../../Common/InvestmentCompanyLogo';
import GlobalLoader from '../../Global/GlobalLoader';
import { getStatusLoadingSelector } from '../../../services/apimanager/apimanager.selector';
import { translated } from '../../../entities/order.selector';
import { Calendar } from 'antd-mobile';
import { serviceFetchOrderDetailByCode } from '../../../services/product.services';
import apiconstant from '../../../services/apiconstant';


const fetchHistoryTransactionDetail = (code) => {
	return serviceFetchOrderDetailByCode(code)
		.catch(err => {
			console.log('error catch ', err);
		});
};

function translateEstAmount (amount) {
	amount = numberToGeneralValueFormat(amount);
	return amount;
}

class ViewHistoryTransactionDetail extends Component {
	state = {
		data: null,
		data_length: 0,
	}
	static _getTransactionId = props => {
		return getSafely(['match', 'params', 'transactionId'], props);
	}
	componentDidMount () {
		const transactionId = ViewHistoryTransactionDetail._getTransactionId(this.props);
		fetchHistoryTransactionDetail(transactionId)
			.then(response => {

				const { data } = handleResponseGeneral(response) || {};
				
				const translatedData = data? translated(data): [];

				this.setState({
					data: translatedData,
					data_length: translatedData.length,
				});

			})
			.catch(err => {
				console.error(err);
				this.setState({
					data: [],
					data_length: 0
				});
			});
	}
	renderLoading = () => {
		return (
			<div className="bit-link-loader-wrap">
				<div>
					<GlobalLoader />
				</div>	
			</div>
		);
	}
	render() {

		const { history, isloading } = this.props;

		const code = getSafely(['code'], this.state.data);
		const name = getSafely(['product', 'name'], this.state.data);
		const type = getSafely(['product', 'type'], this.state.data);
		const ojkCode = getSafely(['product', 'investment_manager','ojkCode'], this.state.data);
		const estAmount = getSafely(['estAmount'], this.state.data);
		const sellAmount = getSafely(['sell_amount'], this.state.data);

		const lastupdate_text = getSafely(['lastupdate_text'], this.state.data);
		const status_text = getSafely(['status_text'], this.state.data);

		let outputAmount = estAmount || sellAmount;
		outputAmount = translateEstAmount(outputAmount);
		return (
			<div className="bit-link-main-wrap">
				<div className="bit-link-main-header">
					<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
					<div>Order Detail</div>
					<div></div>
				</div>
				<div className="bit-link-main-content">
					<div className="bit-link-order-card">
						{isloading? this.renderLoading(): <Fragment><div className="bit-link-order-card-top date">
							<div>
								<span>No. Order: </span>
								<span className="font-bold">{code}</span>
							</div>
							<div>	
								<span><Calendar height="14px" color="#666666"/>{lastupdate_text}</span>
							</div>	
						</div>	
						<div className="bit-link-order-card-bottom">	  
						  <div className="bit-reksa-item">
								<div className="bit-reksa-pic">
									<InvestmentCompanyLogo
										type="investment-manager"
										size="square"
										ojkCode={ojkCode}
									/>
									<img src={Schroder} alt="reksa pic"/>
								</div>
								<div>
									<h3>{name}</h3>
									<p>{type}</p>
								</div>
							</div>
							<div className="bit-link-order-nominal">
								<div className="gray-text">Jumlah Investasi</div>
								<div className="font-bold">Rp. {outputAmount}</div>
							</div>	
							<div className="bit-link-transaction-status-detail">{status_text}</div>
					  </div></Fragment>	}
				  </div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownprops) => {
	const transactionId = ViewHistoryTransactionDetail._getTransactionId(ownprops);
	const isloading = getStatusLoadingSelector(state, [
		apiconstant.orderDetailByCode(transactionId)
	]);
	return {
		isloading
	};
};

export default connect(mapStateToProps)(ViewHistoryTransactionDetail);