import store from './../Store';
import { openErrorModal } from '../components/Global/GlobalErrorModal/errorModal.reducer';

const { dispatch } = store;

const dispatcher = (...params) => dispatch(...params);

export default dispatcher;

export const dispatchErrorModal = (errObjModal) => {
	let dispatcherObject = {type: '', message: ''};
	if(errObjModal) {
		dispatcherObject.type = errObjModal.type? errObjModal.type: '';
		dispatcherObject.message = errObjModal.message? errObjModal.message: '';
	}
	store.dispatch(openErrorModal(dispatcherObject));
};
