import getSafely, { getSafelyWithDefault } from "../utils/safely";

const getUserState = (state) => {
	const { user } = state;
	return user;
};

export const getPinLength = (state) => {
    
	const user = getUserState(state);
	const pin_length = getSafely(['profile', 'pin_length'], user);
	return pin_length;
     
};

export const getUserProfile = state => {
	return getSafely(['user', 'profile'], state);
};

export const getUserId = state => {
	return getSafely(['user', 'profile', 'id'], state);
};

export const getUserActive = state => {
	return getSafely(['user', 'profile', 'active'], state);
};

export const getUserPin = state => {
	return getSafely(['user', 'profile', 'pin'], state);
};

export const getUserPhone = state => {
	return getSafely(['user', 'profile', 'phone'], state);
};

export const getUserProfileStatus = state => {
	return getSafely(['user', 'profile', 'status'], state);
};

export const getUserEmail = (state) => {
	const user = getUserState(state);
	const email = getSafely(['profile', 'email'], user);
	return email;
};

export const getValidEmailStatus = (state) => {
	const validemail = getSafely(['user', 'profile', 'validemail'], state);
	return validemail;
};

export const getUserPhoneNumber = (state) => {
	const user = getUserState(state);
	const phonenumber = getSafely(['profile', 'phone'], user);
	return phonenumber;
};

export const getUserPreference = (state) => {
	return getSafely(['user', 'profile', 'preference'], state);
};

export const getSyariahPreference = (state) => {
	const preference = getUserPreference(state);
	return getSafelyWithDefault(['linkaja_syariah'], preference, null);
};