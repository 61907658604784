import React, {Component} from 'react';
import { Modal } from 'antd-mobile';

import LinkAjaNotConnected from '../../../assets/images/pencarian-link-aja.svg';

class ModalInsufficentFund extends Component {
	render () {
		return <Modal
			popup
			closable
			animationType="slide-up"
			visible={this.props.visible}
			onClose={this.props.onClose}
			onRequestClose={this.props.onClose}
			className="bc-modal-body errorModal"
		>
			<div className="bit-link-modal-pencairan"> 
				<img className="errorImg" src={LinkAjaNotConnected} alt="Error" />
				<div className="errorModalTtl"></div>
				<div>Pencairan dana ke LinkAja sedang mengalami gangguan, silahkan cairkan dana ke rekening bank Kamu.</div>
				<br/>
				<button onClick={this.props.onClick} className="bit-link-btn-red">Okay</button>
			</div>
		</Modal>;
	}
}

ModalInsufficentFund.defaultProps = {
	visible: false,
	onClick: () => {},
	onClose: () => {}
};


export default ModalInsufficentFund;