import React, { PureComponent, Fragment } from 'react';
import {
	connect
} from 'react-redux';
import {
	bindActionCreators,
	compose,
} from 'redux';

import { withRouter, Link } from 'react-router-dom';

import {
	ChevronLeft,
} from 'react-feather';

import InvestmentCompanyLogo from './../../Common/InvestmentCompanyLogo';

import { 
	numberToGeneralValueFormat, 
	numberToPercentagePointChangeFormat 
} from './../../../utils/string-helper';

import {
	fetchPortfolioCategoriesDetailData,
} from './../../../entities/userportfolio.reducer';
import {
	getUserPortfolioCategory,
	getPortfolioItems,
} from './../../../entities/userportfolio.selector';

import SellModal from './../../Common/SellModal/SelectedSellModal';

import getSafely from '../../../utils/safely';
import BuyModal from '../../Common/BuyModal/TopUpBuyModal';
import GlobalHeader from '../../Global/GlobalHeader/GlobalHeader';
import { Badge } from 'antd-mobile';
import InfoModal from '../../Common/InfoModal/InfoModal';
import { isColorMinus } from '../../../utils';
import Puller from '../../Puller/Puller';

class PortfolioPendingCard extends PureComponent {
	render() {
		const { data } = this.props;

		// Return empty if no data
		if (!data) return false;

		const ojkCode = data && data.product && data.product.investment_manager ?
			data.product.investment_manager.ojkCode : '';
		const fundName = data && data.product ? data.product.name : '';
		const fundType = data && data.product ? data.product.type : '';
		const fundSymbol = data && data.product ? data.product.symbol : '';

		return (
			<Link to={`/reksadana/${fundSymbol}`} onClick={(e) => e.preventDefault()} className="porto-card-content porto-pending">
				<div className="bit-link-order-card margin-bottom-20">	
					<div className="bit-link-order-card-bottom bit-link-pending">	  
						<div className="bit-reksa-item">
							<div className="bit-reksa-pic">
								<InvestmentCompanyLogo
									type="investment-manager"
									size="square"
									ojkCode={ojkCode}
								/>
							</div>
							<div className="porto-card-name">
								<h3>{fundName}</h3>
								<p>{fundType}</p>
							</div>
						</div>
						<div className="bit-link-order-nominal">
							<div className="gray-text">Amount Invested</div>
							<div className="font-bold">Rp{numberToGeneralValueFormat(data.pending)},-</div>
						</div>	
					</div>	
				</div>
			</Link>
		);
	}
}

class ViewPortfolioDetail extends PureComponent {
	componentDidMount () {
		const {
			match
		} = this.props;
		const categoryId = getSafely(['params', 'categoryId'], match);
		this.props.fetchPortfolioCategoriesDetailData(categoryId);
	}

	renderRealItemCard = () => {
		const portfolio = this.props.portfolio? this.props.portfolio: [];
		const realPortfolio = portfolio.filter(item => item.units > 0);
		const {transactionConfiguration} = this.props;
		const enableBuy = transactionConfiguration?.enable_subs; 
		const enableSell = transactionConfiguration?.enable_redeem;

		return realPortfolio.map(item => {
			const {
				current,
				invested,
				profitloss,
				percentage,
				units,
				product,
			} = item;

			const {
				name,
				type,
				investment_manager
			} = product;

			const {
				ojkCode
			} = investment_manager;

			const displayCurrent = numberToGeneralValueFormat(
				current,
				{
					mantissa: 0,
				},
			);
			const displayInvested = numberToGeneralValueFormat(
				invested, 
				{
					mantissa: 0,
				}
			);
			const displayProfitloss = numberToGeneralValueFormat(
				profitloss, 
				{
					mantissa: 2,
				}
			);
			const displayPercentage = numberToPercentagePointChangeFormat(
				percentage
			);
			const displayUnit = numberToGeneralValueFormat(units, {
				mantissa: 4,
			});
			
			return (
				<div key={name} className="bit-link-card">
					<div className="bit-link-porto-top">
						<div className="bit-reksa-item">
							<div className="bit-reksa-pic">
								<InvestmentCompanyLogo
									type="investment-manager"
									size="square"
									ojkCode={ojkCode}
								/>
							</div>
							<div>
								<h3>{name}</h3>
								<p>{type}</p>
							</div>
						</div>
						<div className="bit-link-reksa-info">
							<div className="bit-link-reksa-info-row">
								<div>Investasi Saat Ini</div>
								<div className="font-bold">{`Rp${displayCurrent}`}</div>
							</div>
							<div className="bit-link-reksa-info-row">
								<div>Jumlah Investasi</div>
								<div className={isColorMinus(displayProfitloss)}>{`Rp${displayInvested}`}</div>
							</div>
							<div className="bit-link-reksa-info-row">
								<div>Keuntungan</div>
								<div className={`font-green-bibit ${isColorMinus(displayProfitloss)}`}>{`Rp${displayProfitloss || 0}(${displayPercentage || '0%'})`}</div>
							</div>
							<div className="bit-link-reksa-info-row">
								<div>Unit</div>
								<div>{`${displayUnit}`}</div>
							</div>
						</div>
					</div>	
					<div className="bit-link-twin-button">
						<SellModal 
							portfolioId={this.props.categoryId}
							productIds={[product.symbol]}
							renderButton={(openModal) => {
								return (
									<button 
										className='bit-link-transaction-sell bit-link-transaction-action'
										aria-disabled={!enableSell}
										onClick={openModal("sell-1")}>
											Jual
									</button>
								);
							}}
						/>
						<BuyModal 
							portfolioId={this.props.categoryId}
							productIds={[product.symbol]}
							renderButton={(openModal) => {
								return (
									<button 
										className='bit-link-transaction-buy bit-link-transaction-action'
										aria-disabled={!enableBuy}
										onClick={openModal('buy-1')}>
											Beli Lagi
									</button>
								);
							}}
						/>
					</div>
				</div>
			);
		});
	}
	
	renderPendingItemCard = () => {
		
		const portfolio = this.props.portfolio? this.props.portfolio: [];
		const realPortfolio = portfolio.filter(item => item.units > 0);
		const pendingPortfolio = portfolio.filter(item => item.pending > 0);
		return (
			<Fragment>
				{realPortfolio.length > 0 && pendingPortfolio.length > 0 && <div className="pendingBar"/>}
				{pendingPortfolio.length > 0 && <div className="badge-porto-pending-wrap">
					<Badge className="badge-porto-pending" text="Menunggu Penerimaan Unit" />
					<InfoModal title="" closable className="profile-risiko ">
						<div className="profile-risiko-modal">
							<div className="bit-link-modal-header">
								<div>
									<h3>Tinggal Tenang Nunggu Unit</h3>
								</div>
							</div>	 
							<p>
								Pembayaran kamu sudah berhasil kami terima. Pembelian kamu juga sudah diproses. Kamu sudah bisa tenang dan tinggal tunggu penerimaan unit reksadana kamu maksimal 2 hari kerja.
							</p>
						</div>
					</InfoModal>
				</div>}
				{pendingPortfolio.map((item, index) => (
					<PortfolioPendingCard key={item.product ? item.product.symbol : index} data={item} />
				))}
			</Fragment>
		);
	
	}

	renderItemCard = () => {
		return (
			<div className="bit-link-puller-half-wrap">
				<Puller
					loading={this.props.isLoading}
					onRefresh={() => {
						const {
							match
						} = this.props;
						const categoryId = getSafely(['params', 'categoryId'], match);
						return this.props.fetchPortfolioCategoriesDetailData(categoryId);
					}}
				>
					{this.renderRealItemCard()}
					{this.renderPendingItemCard()}
				</Puller>
			</div>	
		);		
	}
	render() {
		const { history } = this.props;
		const {
			totalporto,
			profitloss,
			percentage,
		} = this.props;
		const displayPorto = numberToGeneralValueFormat(
			totalporto,
			{
				mantissa: 0,
			},
		);
		const displayProfitloss = numberToGeneralValueFormat(
			profitloss, 
			{
				mantissa: 0,
			}
		);
		const displayPercentage = numberToPercentagePointChangeFormat(
			percentage
		);
		return (
			<div className="bit-link-main-wrap">
				<div className="bit-link-main-header">
					<GlobalHeader 
						title='Portfolio'
						renderButtonLeft={() => <ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>}
					/>
				</div>
				<div className="bit-link-main-content padding-top-5">
					<div className="bit-link-main-portfolio portfolio-main-bg">
						<div className="home-portfolio-top">
							<label>Total Investasi</label>
							<div className="bit-portfolio-value">
								<span>Rp</span>
								<span>{displayPorto || 0}</span>
							</div>
						</div>
						<div className="home-portfolio-bottom">
							<div>
								<label>Keuntungan</label>
								<p className={isColorMinus(displayProfitloss)}>Rp {displayProfitloss || 0}</p>
							</div>
							<div className="time-weighted-return">
								<label>Tingkat Keuntungan</label>
								<div>
									<span>
									</span>
									<p className={isColorMinus(displayProfitloss)}>{displayPercentage || '0%'}</p>
								</div>
							</div>
						</div>
					</div>
					{this.renderItemCard()}
				</div>
					
			</div>
		);
	}
}

const mapStateToProps = (state, ownprops) => {
	const {
		match: {
			params: {
				categoryId
			}
		}
	} = ownprops;
	return {
		// get status portfolio
		...getUserPortfolioCategory(state, categoryId),
		portfolio: getPortfolioItems(state),
		categoryId: categoryId,
		transactionConfiguration: state?.user?.profile?.transaction_configuration
	};
};

const mapStateToDispatch = dispatch => bindActionCreators({
	fetchPortfolioCategoriesDetailData,
}, dispatch);

const composed = compose(
	withRouter,
	connect(mapStateToProps, mapStateToDispatch),
);

export default composed(ViewPortfolioDetail);