import React from 'react';
export default function GlobalLoader (props) {
	const {
		label
	} = props;
	return (
		<div className="bit-link-loader">
			<span />
			<span />
			<span />
			<span />
			<p>{label}</p>
		</div>
	);
}