import { createBrowserHistory } from 'history';

import getHistoryBasename from './getHistoryBasename';

const basename = getHistoryBasename(process.env.PUBLIC_URL);

const history = createBrowserHistory({
	basename: basename
});

(function(_history){
	var pushState = _history.pushState;
	_history.pushState = function(state) {
		if (typeof _history.onpushstate == "function") {
			_history.onpushstate({state: state});
		}
		// ... whatever else you want to do
		// maybe call onhashchange e.handler
		return pushState.apply(_history, arguments);
	};
})(window.history);

export default history;