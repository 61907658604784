import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { Formik, Form, ErrorMessage } from 'formik';

import ActivityIndicator from 'antd-mobile/lib/activity-indicator';

import getSafely from './../../../utils/safely';
import parseQuery from './../../../utils/parseQuery';

import OtpInput from './../../Common/OtpInput';

import { isErrorSelector, isLoadingSelector } from './../../../services/apimanager/apimanager.selector';
import { 
	postConfirmLoginNumberAsync as _postConfirmLoginNumberAsync, 
	postConfirmRegisterNumberAsync as _postConfirmRegisterNumberAsync, 
	postLoginNumberAsync as _postLoginNumberAsync,
	postRegisterNumberAsync as _postRegisterNumberAsync,
} from './ViewOtpVerification.reducer';

import list from './../../../services/apiconstant';

import LogoBibit from './../../../assets/images/logo-bibit.svg';
import GlobalHeader from '../../Global/GlobalHeader/GlobalHeader';

class ViewOtpVerification extends Component {
	state={
		reset: false
	};
	componentDidMount() {
		const {
			location: {
				search
			}
		} = this.props;
		const _search = parseQuery(search);
		this.setState({	
			querystring: _search
		}, () => {
			this.sendNumber();
		});
	}
	sendNumber = () => {
		const {
			postLoginNumberAsync,
			postRegisterNumberAsync,
		} = this.props; 
		!this.isRegister()?
			postLoginNumberAsync():
			postRegisterNumberAsync();
	}
	isRegister = () => {
		const {
			querystring
		} = this.state;
		const register = getSafely(['register'], querystring);
		return register==='true';
	}
	handleAutoSubmit = (otp) => {

		const {
			postConfirmLoginNumberAsync,
			postConfirmRegisterNumberAsync
		} = this.props;
		!this.isRegister()? 
			postConfirmLoginNumberAsync(otp): 
			postConfirmRegisterNumberAsync(otp);

	}
	render() {

		const {
			counter,
			resend,
			phonenumber,
			isloading,
		} = this.props;
		
		return (
			<div>
				<div className="bit-link-wrap">
					<div className="bit-link-header">
						<GlobalHeader />
					</div>
					<div className="bit-link-register">
						<div className="bit-link-title-reg">
							<h5>Reksa Dana</h5>
							<h1>Verifikasi Nomor Ponsel</h1>
							<p>{`Masukkan kode yang dikirim melalui SMS ke nomor handphone kamu (+62) ${phonenumber}`}</p>
						</div>  
						<Formik
							initialValues={{ 
								otp: '', 
							}}
							validate={values => {
								const errors = {};
						
								if(!values.otp){
									errors.otp = 'This is required';
								}
								return errors;
							}}
							onSubmit={(values, { setSubmitting }) => {
								setSubmitting(false);
								this.handleAutoSubmit(values.otp);
							}}
						>  
							{({isSubmitting}) => (
								<Form>
									<div className="bit-link-pin-left">
										<OtpInput 
											name="otp"
											autosubmit={this.handleAutoSubmit}
											counter={counter}
											resendflag={resend}
											resendotp={this.sendNumber}
										/>
										<ErrorMessage name="otp" />
										{this.props.err && <p>{this.props.err.message || this.props.err.text}</p>}
									</div>
									<button type="submit" className="bit-link-btn-red-full margin-top-15">Proses</button>
								</Form>
							)} 
						</Formik>
						<ActivityIndicator 
							toast
							text="Loading..."
							animating={isloading}
						/>
					</div>
					<div className="bit-link-footer">
						<div>Powered by :</div> 
						<div><img src={LogoBibit} alt="logo bibit" /></div>
					</div>
				</div>
			</div>
		);
	}
} 

ViewOtpVerification.defaultProps = {
	counter: Date.now(),
	resend: false,
};

const mapStateToProps = state => {

	const {
		userpartner: {
			profile
		},
		viewotpverification: {
			resendtime,
			resend,
		},
	} = state;

	const phonenumber = getSafely(['phone'], profile);
	const fullname = getSafely(['fullname'], profile);
	/**
	 * MOCK DATA 
	 */
	// let resendtime = moment(moment(Date.now()).add(10, 'minutes')).toISOString();
	// const counter = resendtime;
	
	const counter = resendtime * 1000;
	
	const err = 
		isErrorSelector(state, list.registerPhonenumber) || 
		isErrorSelector(state, list.loginPhonenumber) || 
		isErrorSelector(state, list.loginOtp) ||
		isErrorSelector(state, list.registerOtp);
	
	const isloading = 
		isLoadingSelector(state, list.registerPhonenumber) || 
		isLoadingSelector(state, list.loginPhonenumber) || 
		isLoadingSelector(state, list.loginOtp) ||
		isLoadingSelector(state, list.registerOtp);
	
	return {
		counter: counter,
		resend: resend,
		fullname,
		phonenumber,
		err: err,
		isloading: isloading,
	};

};

const mapDispatchToProps = dispatch => bindActionCreators({
	postConfirmLoginNumberAsync: _postConfirmLoginNumberAsync,
	postLoginNumberAsync: _postLoginNumberAsync,
	postConfirmRegisterNumberAsync: _postConfirmRegisterNumberAsync,
	postRegisterNumberAsync: _postRegisterNumberAsync,
}, dispatch);

const composed = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
);
export default composed(ViewOtpVerification);