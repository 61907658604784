import Api from '../utils/newapimanager';
import list from "../services/apiconstant";

// const api = new Api();
const api = Api;

export const servicePostPartnerKeyCheck = ({urltoken}) => 
	api.post(list.partner_key_check, {urltoken});

export const serviceFetchUserBalanceLinkaja = () => 
	api.get(list.userBalanceLinkaja);