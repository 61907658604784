import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import list from './../../services/apiconstant';
import { isLoadingSelector, isErrorSelector } from './../../services/apimanager/apimanager.selector';

import { restoreInitialUser } from './../../entities/user.reducer';

import GlobalLoader from './../Global/GlobalLoader';

class AuthUser extends Component {
	componentDidMount () {
		this.props.restoreInitialUser();
	}
	renderLoader = () => {
		return (
			<div className="bit-link-loader-wrap">
				<div>
					<GlobalLoader />
				</div>	
			</div>);
	}
	render () {
		return (
			<Fragment>
				{this.props.isloading? this.renderLoader(): this.props.onRender()}
			</Fragment>
		);
	}
}

AuthUser.defaultProps = {
	onRender: () => {},
};

const mapStateToProps = state => {
	
	return {
		isloading: isLoadingSelector(state, list.userProfile),
		err: isErrorSelector(state, list.userProfile),
	};
    
};

const mapDispatchToProps = dispatch => bindActionCreators({
	restoreInitialUser
}, dispatch);

const PAuthUser = connect(mapStateToProps, mapDispatchToProps)(AuthUser);

const WithAuthUser = (Comp) => (props) => {
	return <PAuthUser 
		{...props}
		onRender={_props => {
			return <Comp 
				{..._props}
			/>;
		}} 
	/>;
};


export default WithAuthUser;