import Storage from './Storage';
import Toast from 'antd-mobile/lib/toast';
import isEmpty from './isEmpty';

const _getDataResponse = (resp) => {
	if (resp && resp.data)
		return resp.data;
	return null;
};

function _getFormErrors (payload) {
	if(payload.errors) return payload.errors;
	return null;
}

export { isEmpty };

/*
	append .catch on promise ajax
	return Promise object
*/
export function handleResponseErrorCase (p, cb) {
	return p.catch((error) => {
		console.log('register catch');
		// Error
		if (error.response) {
			console.log('error case1');
			// The request was made and the server responded with a status code
			const {
				data
			} = error.response;
			// console.log('error.response ', data);
			const message = (data.message) ? data.message : 'Something is wrong';
			const err_form = _getFormErrors(data);
			return cb({ loading: false, err: message, raw: data, err_form });
		} else if (error.request) {
			console.log('error case2');
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			console.log(error.request);
			return cb({ loading: false, err: 'The request was made but no response was received' });
		} else {
			// Something happened in setting up the request that triggered an Error
			console.log('error case3');
			console.log('Error', error.message);
			return cb({ loading: false, err: error.message });
		}

	});
}

/**
 * General response handler from API
 *
 * @export
 * @param {Payload} payload  payload from API
 * @returns
 */
export function handleResponseGeneral (payload) {
	const resp = _getDataResponse(payload);
	if(!resp) {
		return null;
	};
	return {
		...resp
	};
}

export function handleResponseOtp (payload) {
	const resp = _getDataResponse(payload);
	if(!resp) return null;
	const {
		message,
		data: {
			otp,
			resendtime,
			resend
		}
	} = resp;
	if (otp) {
		// This will only appear on staging or dev environemnt
		Toast.info(otp);

		// Experimenting with document fragment. It's said this give better performance for DOM
		// manipulation.
		// @see https://developer.mozilla.org/en-US/docs/Web/API/Document/createDocumentFragment
		const fragment = document.createDocumentFragment();
		const devOtpSecret = document.createElement('div');
		devOtpSecret.classList.add('hide');
		devOtpSecret.textContent = otp;
		devOtpSecret.setAttribute("id", "devOtp");
		fragment.appendChild(devOtpSecret);

		const pageBody = document.querySelector('div#root');
		pageBody.appendChild(fragment);
	}
	return {
		otp,
		message,
		resend,
		resendtime
	};

}

export function handleResponseUserPartner (payload) {
	
	const resp = _getDataResponse(payload);
	if(!resp) return null;

	const {
		data
	} = _getDataResponse(payload);

	const {
		token,
		...rest
	} = data;


	/* set token to localstorage */
	Storage.setObject('ac', token);

	/* set access partner to localstorage */
	Storage.setObject('ap', rest);

	return {
		...data
	};

}

/*
	extract data from network request
	return Object
*/
export async function handleResponseAuthorization (payload) {
	const resp = _getDataResponse(payload);
	if(!resp) return null;

	const {
		data
	} = _getDataResponse(payload);

	const flagpin = data.pin;
	const token = data.token;
	const roboid = data.roboid;

	/* set token to localstorage */
	await Storage.setObject('ac', token);

	/* set robo id to localstorage */
	await Storage.setObject('rb', roboid);

	return {
		...data,
		flagpin,
	};
	
}

export function handleResponseAws (payload) {

	const resp = _getDataResponse(payload);
	if(!resp) return null;

	const {
		data: {
			bucket,
			key,
			url
		}
	} = _getDataResponse(payload);

	return {
		bucket,
		key,
		url
	};

}
