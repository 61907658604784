import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import { handleResponseGeneral } from '../utils/handleResponse';
import { serviceFetchReksadanaById, serviceFetchAllReksadana } from '../services/product.services';



// Initial State
const defaultState = {
	byId: {},
	allIds: [],
};

// Actions
const [addReksadanaEntry, addMultipleReksadanaEntry, addReksadanaId] = [
	'ADD_REKSADANA_ENTRY',
	'ADD_MULTIPLE_REKSADANA_ENTRY',
	'ADD_REKSADANA_ID',
].map(createAction);

// Reducer
const reksadana = createReducer(
	{
		// Adding a mutual fund data to the fund data catalog
		[addReksadanaEntry]: (state, payload) => {
			return update(state, {
				byId: {
					$set: {
						...state.byId,
						[payload.symbol]: {
							...payload,
						},
					},
				},
			});
		},

		// Adding multiple mutual fund data
		[addMultipleReksadanaEntry]: (state, payload) => {
			const byIds = {};	
			const allIds = payload.map(item => {
				byIds[item.symbol] = item;
				return item.symbol;
			});
			return update(state, {
				byId: {
					$set: {
						...state.byId,
						...byIds,
					},
				},
				allIds: {
					$set: [...allIds]
				}
			});	
		},

		// Adding new id to fund data catalog
		[addReksadanaId]: (state, payload) => {
			return update(state, {
				allIds: [...state.allIds, payload.symbol],
			});
		},
	},
	defaultState,
);

export { addReksadanaEntry, addMultipleReksadanaEntry, addReksadanaId };

// SELECTOR
export const getReksadanaBySymbol = (state, symbol) => state.entities.reksadana.byId[symbol];

/**
 * Add new reksadana entry if not exist
 */
export function	fetchReksadanaEntry(fundId) {
	return (dispatch, getState) => {
		if (!fundId) {
			// On no fundid given, return empty
			return Promise.resolve({});
		}

		return serviceFetchReksadanaById(fundId)
			.then(response => {
				const productData = response.data.data;
				dispatch(addReksadanaEntry(productData));
			})
			.catch(err => console.error(err));
	};
}

export function fetchProducts () {
	return dispatch => {
		return serviceFetchAllReksadana()
			.then(response => {
				const {
					data
				} = handleResponseGeneral(response);
				dispatch(addMultipleReksadanaEntry(data));
			});

	};
}

export default reksadana;