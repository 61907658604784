/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import { ChevronLeft } from 'react-feather';
import Schroder from './../../../assets/images/schroder.svg';

class OrderDetail extends Component {

	  render() {
	  	const { history } = this.props;
	  	return (
	  		<div className="bit-link-main-wrap">
	  			<div className="bit-link-main-header">
	  				<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
	  				<div>Order Detail</div>
	  				<div></div>
	  			</div>
	  			<div className="bit-link-main-content">
	  				<div className="bit-link-order-card">
				      <div className="bit-link-order-card-top">
					  	  <span>No. Order: </span>
						  <span class="font-bold">PI20105MGPAP</span>
					  </div>	
					  <div className="bit-link-order-card-bottom">	  
							<div className="bit-reksa-item">
								<div className="bit-reksa-pic">
									<img src={Schroder} alt="reksa pic"/>
								</div>
								<div>
									<h3>Schroder Dana Likuid</h3>
									<p>Pasar Uang</p>
								</div>
							</div>
							<div className="bit-link-order-nominal">
								<div class="gray-text">Jumlah Investasi</div>
								<div class="font-bold">Rp. 5,000,000</div>
							</div>	
						</div>	
					</div>
	  			</div>
	  		</div>
	  	);
	  }
} 

export default OrderDetail;