/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component, Fragment} from 'react';
import { Modal } from 'antd-mobile';
import { Repeat, X } from 'react-feather';
import { serviceUserBankList } from '../../../services/user.services';
import InfoSwitchBankModal from './InfoSwitchBankModal';
class SwitchBankModal extends Component {
	state = {
		open: false,
		loading: false,
		selected: this.props.selected,
		banks: [],
	}
	static updateUserBank (userObject, listbankObject) {
		
		let {
			transfer_destination
		} = userObject;
		
		const {
			bank_color,
			bank_id,
			bank_name,
			bank_slot,
			user_bank_account_name,
			user_bank_account_number,
		} = listbankObject;

		const bankObjectUpdated = {
			id: bank_id,
			name: bank_name,
			color: bank_color,
		};

		const transferDestinationObjectUpdated = {
			bank: bankObjectUpdated,
			bank_account: user_bank_account_name,
			bank_number: user_bank_account_number,
			user_bank_slot: bank_slot,
		};

		userObject.transfer_destination = Object.assign(transfer_destination, transferDestinationObjectUpdated);

		return userObject;

	}
	componentDidUpdate(prevProps, prevState) {
		const {isSell} = this.props;

		if(this.state.open && !prevState.open) {
			this.setState({
				loading: true
			});
			serviceUserBankList()	
				.then(resp => {

					const {
						data
					} = resp;

					let banks = data;
					if(isSell) {
						banks = data.filter(item => item.bank_status === 1);
					}

					const toChange = {
						banks,
					};

					if(!this.state.selected) {
						let selected = '';
						banks.some(item => {
							if(item.default > 0) {
								selected = item.bank_slot;
								return true;
							}
							return false;
						});
						toChange['selected'] = selected;
					}

					this.setState({
						...toChange,
						loading: false,
					});

				});	
		}
		if(!this.state.open && prevState.open && this.state.selected !== '') {
			const bankselectedindex = this.state.banks.findIndex(item => item.bank_slot === this.state.selected); 
			if(bankselectedindex > -1) 
				this.props.onAfterSwitch({
					...this.state.banks[bankselectedindex] 
				}); 
		}
	}
	handleClickSwitch = (selected) => {
		const curr = Object.freeze(this.state);
		this.props.onSwitch(
			{
				autoclose: this.props.autoclose,
				selected,
			},
			() => {
				return new Promise((res) => {
					this.setState({
						selected:selected
					}, () => {
						res();
					});
				}); 
			}, 
			this.handleCloseModal,
			() => {
				return new Promise((res) => {
					this.setState({
						selected: curr.selected
					}, () => res());	
				});
			} 
		);
	}
		
	handleOpen = () => this.setState({open: true})
	handleCloseModal = () => this.setState({open: false})
	render () {
		return <Fragment>
			<Modal
				visible={this.state.open}
				animationType="slide-up"
				maskStyle={{"transform": "translateZ(1px)"}}
				popup
				onClose={() => {
					if(this.props.outerclose) 
						this.handleCloseModal();
				}}
				className="bit-link-switch-bank-modal"
			>
				<span onClick={this.handleCloseModal} className="modal-close-flat"><X size="16" color="#6b6b6b" /></span>
				<h3>{this.props.title}</h3>
				<div className="bit-margin-bottom-20">
					<div className="bit-bank-change">
						{
							(this.state.loading)?
								<div className="bit-link-shimmer"></div>:
								(this.state.banks.length < 1)? 
									<div>List bank belum ada</div>:
									this.state.banks.map(item => {
										const disableSwitch = item.bank_status !== 1;
										return (
											<label onClick={() => !disableSwitch && this.handleClickSwitch(item.bank_slot)} key={`${item.bank_id}#${item.user_bank_account_number}`} className="am-checkbox-wrapper bit-change-row">
												{!disableSwitch && <span className={`am-checkbox ${this.state.selected === item.bank_slot &&'am-checkbox-checked'}`}>
													<input type="checkbox" className="am-checkbox-input" />
													<span className="am-checkbox-inner"></span>
												</span>}
												{
													disableSwitch && <span className='badge-on-progress'>Sedang diperiksa</span>
												}
												<div className="bit-change-info">
													<div className="paynote-0 bit-flex-paynote">
														<span className="paynote-1 bca prox-condensed-bold" style={{"backgroundColor": "rgb(36, 120, 180)"}}>{item.bank_name}</span>
													</div>
													<div className="paynote-0">
														<span className="paynote-2 bankName">{item.user_bank_account_name}</span>
														<span className="paynote-3">{item.user_bank_account_number}
														</span>
													</div>
												</div>
											</label>
										);
									})
						}
					</div>
					
					{/* <div className="bit-link-bank-separator"></div>
					<h3 className="margin-bottom-10 font-size-11 font-normal">Dalam Proses</h3>
					<div className="bit-bank-change">
	  					<label className="am-checkbox-wrapper bit-change-row">
						
							<div className="bit-link-shimmer"></div>
							
	  						<div className="bit-change-info">
	  							<div className="paynote-0 bit-flex-paynote">
	  								<span className="paynote-1 bca prox-condensed-bold" style={{"backgroundColor": "rgb(36, 120, 180)"}}>BCA</span>
	  							</div>
	  							<div className="paynote-0">
	  								<span className="paynote-2 bankName">Imam Tauhid</span>
	  								<span className="paynote-3">7610097931</span>
	  							</div>
	  						</div>
	  					</label>
	  				</div> */}
					{this.state.banks.length < 3 && this.props.renderAddButton(this.handleCloseModal, {loading: this.props.loading, bank_length: this.state.banks.length})}
				</div>
			</Modal>
			{this.props.onRenderButton(this.handleOpen)}
			{/* <ActivityIndicator
				toast
				text={'Permintaan sedang di proses'}
				animating={this.state.loading}
			/> */}
		</Fragment>;
		
	}
}

SwitchBankModal.defaultProps = {
	title: 'Ganti Rekening Penerima',
	banks: [],
	onRenderButton: (openModal) => {
		return (
			<span onClick={openModal} className="bit-info-icon-right">
				{/* <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 24 24" fill="none" stroke="#2478B4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="8"></line></svg> */}
				<Repeat height="15" color="#2478B4"/>
			</span>
		);
	},
	renderAddButton: (closeModal, {loading} = { loading: false}) => {
		return <InfoSwitchBankModal onRenderButton={handleOpen => {
			const disabled = loading;
			return 	<button disabled={disabled} onClick={handleOpen} className={disabled? "bit-link-btn-full-disable margin-top-20": "bit-link-btn-red-full margin-top-20"}>Tambah Bank</button>;
		}}/>;
	},
	onAfterSwitch: () => {},
	onSwitch: ({ selected, autoclose } = {}, handleToggle, handleClose) => {
		handleToggle().then(() => {
			if(autoclose)
				handleClose();
		});
	},
	disableSwitch: false,
	autoclose: true,
	outerclose: false,
	isSell: false,
};

export default SwitchBankModal;