import React, { Component, Fragment } from 'react';
import { Modal, Checkbox, ActivityIndicator } from 'antd-mobile';
import { X } from 'react-feather';
import NumberFormat from 'react-number-format';
import BuyReksa from './../../../assets/images/buy-reksa.svg';
import InvestmentCompanyLogo from './../../Common/InvestmentCompanyLogo';
import { handleResponseGeneral } from '../../../utils/handleResponse';
import { numberToGeneralValueFormat } from '../../../utils/string-helper';

import getSafely from '../../../utils/safely';
import store from './../../../Store';
import * as Analytics from './../../../utils/Analytics';

import {
	openErrorModal
} from './../../Global/GlobalErrorModal/errorModal.reducer';

import GlobalDocumentsModal from '../../Global/GlobalDocumentsModal/GlobalDocumentsModal';

import { servicePostOrderBuy } from './../../../services/product.services';
import { serviceFetchUserBalanceLinkaja } from './../../../services/userpartner.services';
import { connect } from 'react-redux';


const SUGGESTIONS = [
	100000,
	500000,
	1000000,
	5000000,
	10000000,
	50000000,
	100000000,
];

function getOS() {
	if(navigator.userAgent.match(/Android/i)) 
		return 'android';
	else
		return 'ios';
}

function redirect(uri) {
	const os = getOS();
	if(os === 'android') {
		document.location=uri;      
	}else{
		window.location.replace(uri);
	}
}

async function getSaldoLinkAjaImage () {
	return serviceFetchUserBalanceLinkaja()
		.then(response => {
			return handleResponseGeneral(response);
		});
}

async function postBuy (toBuy) {
	return servicePostOrderBuy(toBuy)
		.then(response => {
			return handleResponseGeneral(response);
		}).catch(err => {
			const message = getSafely(['response', 'data','message'], err);
			const type = getSafely(['response', 'data','type'], err);
			store.dispatch(openErrorModal({
				type: type,
				message: message
			}));
		});
}

function recordInputItem (productId) {

	const buyType = 'single';

	const state = store.getState();

	const productName = getSafely(['reksadana', 'byId', productId, 'name'], state, []);

	let param_amp = {
		'company_id': [productId],
		'company_name': [productName],
		'is_robo': (buyType === 'single')? 0:1,
	};

	Analytics.recordEvent({
		page: 'buy',
		path: 'input_money',
		param: buyType,
		param_amp,
	});

}

function recordPurchaseSuccess (boughtOrderId, amount) {
	let chosenPaymentMethod = 'linkaja';
	let type = 'single';
	Analytics.recordEvent({
		page: 'buy',
		path: 'success',
		param: [boughtOrderId, chosenPaymentMethod],
		param_amp: {
			order_id: boughtOrderId,
			payment_method: chosenPaymentMethod,
			is_robo: type === 'robo' ? 1 : 0,
			amount: amount,
		},
	});
}

class BuyModal extends Component {

	constructor (props) {

		super(props);
		this.state = {
			image: null,
			type: 'base64',
			currentAmount: props.defaultAmount,
			confirmCurrentAmount: 0,
			isAgree: 0,
			isValid: 0,
			step: props.open? 1: 0,
		};

	}

	componentDidMount () {
		if(this.props.requestInitImage && !this.state.image)
			getSaldoLinkAjaImage()
				.then(response => {
					const {
						data
					} = response;
					const {
						image,
						type,
					} = data;
					this.setState({
						image: image,
						type: type,
					});
				});
	}

	componentDidUpdate(prevProps, prevState) {
		
		const {
			step: _step,
		} = prevState;

		const {
			step,
			loading
		} = this.state;

		if(step !== _step && step === 1) {

			this.setState({
				currentAmount: this.props.defaultAmount
			});

			if(!loading && !this.state.image)
				getSaldoLinkAjaImage()
					.then(response => {
						const {
							data
						} = response;
						const {
							image,
							type,
						} = data;
						this.setState({
							image: image,
							type: type,
						});
					});
		}

		if(step !== _step && step===2) {
			this.setState({
				isAgree: false,
				image: null,
			});
		}

	}

	checkIfAmountIsValid = () => {

		const {
			items
		} = this.props;
		
		const { currentAmount } = this.state;
		
		let isValid = true;

		items.some(item => {

			const minBuy = getSafely(['minbuy'], item);

			if (currentAmount === null || currentAmount === undefined || isNaN(currentAmount)) {
				isValid = false;
				return true;
			}

			if(currentAmount < minBuy) {
				isValid = false;
				return true;
			}

			return false;

		});
		return isValid;
	
	}

	handleCheckTerms = (el) => {
		const {
			checked
		} = el.target;
		this.setState({
			isAgree: checked
		});
	}

	handleDisplayImage = () => {
		if(!this.state.image)
			return null;
		return (<img src={'data:image/gif;base64, ' + this.state.image} alt="your selected product" />);
	}

	onClose = key => () => {
		this.setState({
			step: 0,
			image: null,
		}, () => {
			this.props.onComplete();
		});
	}
	
	closeModal = () => {
		this.setState({
			step: 0,
			image: null,
		}, () => {
			this.props.onComplete();
		});	
	}

	openModal = modalType => () => {

		let toChangeState = {};
		
		const productIds = this.props.productIds || [];
		const productId = productIds.join(',');

		if (this.state.step === 1) {
		
			toChangeState = {
				...toChangeState,
				confirmCurrentAmount: this.state.currentAmount
			};

			recordInputItem(productId);
		
		} 
		
		if (this.state.step === 2) {

			postBuy({
				symbol: productId,
				amount: this.state.currentAmount,
				categoryid: this.props.categoryid,
			})
				.then(response => {

					if(!response) {
						return response;
					}
					const url = getSafely([
						'data', 'payment_detail', 'url'
					], response);

					recordPurchaseSuccess(productId, this.state.currentAmount);

					const os = getOS();

					if(os === 'android') {
						redirect(url);
					} else {
						let newlink = document.createElement('a');
						newlink.setAttribute('href', url);
						newlink.click();
					}
				
				}).catch(err => {
					const message = getSafely(['response', 'data','message'], err);
					const type = getSafely(['response', 'data','type'], err);
					store.dispatch(openErrorModal({
						type: type,
						message: message
					}));
				});
			
			return this.setState({
				step: 0
			}, () => {
				this.props.onComplete();
			});
			
		}

		toChangeState = {
			...toChangeState,
			step: this.state.step + 1,
		};

		this.setState(toChangeState);
	}

	log = (name) => {
		return (value) => {
			console.log(`${name}: ${value}`);
		};
	}

	handleAmountChange = ({floatValue}) => {
		this.setState({
			currentAmount: floatValue
		});
	}
	handleLabelSelect = (suggestion) => {
		
		const element = suggestion.currentTarget;

		let {
			amount: _amount
		} = element.dataset;

		let amount = 0;

		if(typeof _amount === 'string') {
			amount = Number(_amount);
		}

		this.setState({
			currentAmount: amount
		});
		
	}
	renderSuggestions = () => {
		
		return SUGGESTIONS.map(suggestion => {
			return (
				<span
					key={suggestion}
					data-amount={suggestion}
					onClick={this.handleLabelSelect}
				>
					{numberToGeneralValueFormat(suggestion)}
				</span>
			);
		});
	};

	renderButton = () => {
		const enableBuy = this.props?.transactionConfiguration?.enable_subs; 

		return this.props.renderButton(this.openModal, enableBuy);
	}
	
	renderInputAmountModal = ({index}) => {
		const isOpen = this.state.step === index;
		const disableButton = 
			!this.checkIfAmountIsValid() || 
			!!this.props.isLoading;
		const item = this.props.items[index - 1 || 0];
		return (
			<Modal
				key={index}
				popup
				visible={isOpen}
				onClose={this.onClose}
				animationType="slide-up"
				isOpen={isOpen} 
				onRequestClose={this.closeModal}
				className="bit-link-buy-modal"
			>
				<div className="bit-link-modal-header">
					<div>
						<h3>Mau Investasi Berapa Hari Ini?</h3>
					</div>
					<div>
						<X color="#666" size={16} onClick={this.onClose()}/>
					</div>	
				</div>	 

				<div className="bit-link-saldo">
					<div>Saldo LinkAja</div>
					{this.handleDisplayImage()}
				</div>
				<div className="buy-money-input">
					<NumberFormat
						getInputRef={el => (this.amountInput = el)}
						value={this.state.currentAmount}
						pattern="\d*"
						inputMode="numeric"
						thousandSeparator
						prefix="Rp"
						placeholder={`Minimal Pembelian Rp ${item.minbuy}`}
						onValueChange={this.handleAmountChange}
					/>
				</div>
						
				<div className="loaderbar">Loading</div>
				<div className="loaderbarhack"></div>
				<div className="buyInputSuggest">
					{this.renderSuggestions()}
				</div>
				<button 
					disabled={disableButton} 
					className={disableButton? 
						"bit-link-btn-red-full disable": 
						"bit-link-btn-red-full"
					} 
					onClick={this.openModal("buy-2")}>Beli</button>
			</Modal>  
		);
	}

	renderItems = () => {
		return (
			<Fragment>
				{this.props.items.map(item => {
					const {
						name,
						type,
					} = item;
					const ojkCode = item?.ojkCode || item?.investment_manager?.ojkCode;
					return (
						<div key={name} className="bit-reksa-item bit-reksa-blue-wrap">
							<div className="bit-reksa-pic">
								<InvestmentCompanyLogo 
									type="investment-manager"
									size="square"
									ojkCode={ojkCode}
								/>
							</div>
							<div>
								<h3>{name}</h3>
								<p>{type}</p>
							</div>
						</div>
					);
				})}
			</Fragment>
		);	
	}

	renderConfirmAmountModal = ({index}) => {

		const {
			confirmCurrentAmount,
			isAgree,
		} = this.state;

		const symbols = this.props.items.map(item => item.symbol || getSafely(['product', 'symbol'], item));

		const isOpen = this.state.step === index;

		const displayAmount = numberToGeneralValueFormat(confirmCurrentAmount);

		return (
			<Modal
				key={index}
				popup
				visible={isOpen}
				onClose={this.onClose}
				animationType="slide-up"
				isOpen={isOpen} onRequestClose={this.closeModal}
				className="bit-link-buy-modal-confirmation"
			>
				<div className="bit-link-modal-header">
					<div>
						<h3>Investasi Kamu Hari Ini</h3>
					</div>
					<div>
						<X color="#666" size={16} onClick={this.onClose()}/>
					</div>	
				</div>	 
				<div className="bit-link-payment-wrap">
					{this.renderItems()}
					<div className="bit-link-payment-row">
						<div>Biaya Pembelian</div>
						<div className="font-bold">Gratis</div>
					</div>
					<div className="bit-link-payment-row bit-link-total">
						<div>Total</div>
						<div>{displayAmount}</div>
					</div>
				</div>
				<Checkbox
					className="confirmRadio"
					checked={isAgree}
					onChange={this.handleCheckTerms} 
				>	
					<p>
							Saya menyetujui pembelian reksa dana di halaman ini dan telah membaca dan menyetujui seluruh
							isi Prospektus dan keterangan ringkas serta
							memahami risiko atas keputusan investasi
							yang saya buat. &nbsp;
					</p>
				</Checkbox>
				<div className="bit-link-prospectus">
					<GlobalDocumentsModal symbols={symbols} />
				</div>
				<button className={isAgree? "bit-link-btn-red-full": "bit-link-btn-red-full disable"} disabled={!isAgree} onClick={this.openModal()}>Konfirmasi</button>
			</Modal>
		);
	}
	renderStepModal = () => {
		const pipe = [
			this.renderInputAmountModal,
			this.renderConfirmAmountModal,
		].map((fn, index) => fn({
			index: index+1, 
		}));
		return pipe;
	}
	render() {
		return (
			<Fragment>
				{this.renderButton()}
				{/* Buy Modal */}
				{this.renderStepModal()}
				<ActivityIndicator 
					toast
					text={'Permintaan sedang di proses'}
					animating={this.props.isLoading}
				/>  
			</Fragment>
		);
	}
}

BuyModal.defaultProps = {
	renderButton: (openModal, enableBuy) => {
		return (
			<div onClick={openModal()} className="bit-link-transaction-action" role="button" aria-disabled={!enableBuy}>
				<img src={BuyReksa} alt="link aja"/>
				<p>Beli Reksadana</p>
			</div>
		);
	},
	defaultAmount: '',
	portfolioId: 0,
	portfolio: null,
	isLoading: false,
	items: [],
	requestInitImage: false,
	preStepRenderModal: [],
	onComplete: () => {},
};

const mapStateToProps = (state) => ({
	transactionConfiguration: state?.user?.profile?.transaction_configuration
});

export default connect(mapStateToProps)(BuyModal);