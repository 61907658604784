import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import ViewLandingVerification from '../components/Landing/LandingVerification/ViewLandingVerification';
import LandingAuthSuccess from '../components/Landing/LandingAuthSuccess';
import LandingAuthFail from '../components/Landing/LandingAuthFail';
import { compose } from 'redux';
import withPartnerStatusHOC from '../components/Common/withPartnerStatusHOC';
import ViewOtpVerification from '../components/Landing/OtpVerification/ViewOtpVerification';
import ProfileSetting from '../components/Landing/ProfileSetting';
import OrderDetail from '../components/Landing/ViewHistoryTransaction/OrderDetail';
import TermsCondition from '../components/Register/TermsCondition';
import WithAuthUser from '../components/Common/withAuthUser';
import ViewInputPin from '../components/Landing/PinVerification/ViewInputPin';
import SuccessConnected from '../components/Landing/SuccessConnected';
import DifferentNumber from '../components/Landing/DifferentNumber';
import IndexHome from '../components/Landing/IndexHome';
import ViewRegisterForm from '../components/Register/ViewRegisterForm';
import ViewRegisterRevisionForm from '../components/Register/ViewRegisterRevisionForm';
import Signature from '../components/Landing/Signature';
import CreatePin from '../components/Landing/CreatePin';
import ConfirmationPin from '../components/Landing/ConfirmationPin';
import ViewHistoryTransaction from '../components/Landing/ViewHistoryTransaction/ViewHistoryTransaction';
import ViewHistoryTransactionDetail from '../components/Landing/ViewHistoryTransaction/ViewHistoryTransactionDetail';
import ViewPortofolioDetail from '../components/Landing/Portfolio/ViewPortfolioDetail';
import Page404 from '../components/Error/Page404';
import DirectRoute from './DirectRoutes';
import history from '../utils/history';
import ChangeBankSetting from '../components/Landing/ChangeBankSetting';
import ChangeBankSendVerif from '../components/Landing/ChangeBankSendVerif';
import ChangeBankSent from '../components/Landing/ChangeBankSent';
import ChangeBankForm from '../components/Landing/ChangeBankForm';
import VerifyPin from '../components/Pin/VerifyPin';
import ChangeBankInfo from '../components/Landing/ChangeBankInfo';

export default function AppRoutes () {
	return (
		<Router
			history={history}
		>
			<Switch>
				<Route
					exact
					path="/verify-pin"
					component={VerifyPin}
				/>
				<Route
					exact
					path="/changebanksetting"
					component={ChangeBankSetting}
				/>
				<Route
					exact
					path="/changebanksendverif"
					component={ChangeBankSendVerif}
				/>
				<Route
					exact
					path="/changebanksent"
					component={ChangeBankSent}
				/>
				<Route
					exact
					path="/changebankform"
					component={ChangeBankForm}
				/>
				<Route
					exact
					path="/changebankinfo"
					component={ChangeBankInfo}
				/>
				<Route
					path={'/profile/settings'}
					component={DirectRoute}
				/>
				<Route
					exact
					path={'/'}
					component={ViewLandingVerification}
				/>
				<Route
					exact
					path="/landing-auth-success"
					component={LandingAuthSuccess}
				/>
				<Route
					exact
					path="/landing-auth-fail"
					component={LandingAuthFail}
				/>
				<Route
					exact
					path="/otp-verification"
					component={(
						compose(
							withPartnerStatusHOC({
								whitelist: [0, 1, 2],
							})
						)(ViewOtpVerification)
					)}
				/>
				<Route
					exact
					path="/profile-setting"
					component={ProfileSetting}
				/>
				<Route
					exact
					path="/order-detail"
					component={OrderDetail}
				/>
				<Route
					exact
					path="/terms-condition"
					component={TermsCondition}
				/>
				<Route
					exact
					path="/input-pin"
					component={(
						compose(
							WithAuthUser,
							// withPartnerStatusHOC({
							// 	whitelist: [3, 4],
							// })
						)(ViewInputPin)
					)}
				/>
				<Route
					exact
					path="/success-connected"
					component={SuccessConnected}
				/>
				<Route
					exact
					path="/different-number"
					component={DifferentNumber}
				/>
				<Route // need user auth
					exact
					path="/index-home"
					component={
						(compose(
							WithAuthUser,
							withPartnerStatusHOC({
								whitelist: [1,3,4],
							}),
						)(IndexHome))
					}
				/>
				<Route // need user auth
					exact
					path="/register-form"
					component={
						(compose(
							WithAuthUser,
							// withPartnerStatusHOC({
							// 	whitelist: [],
							// }), 
						)(ViewRegisterForm))
					}
				/>
				<Route //need user auth
					exact
					path="/register-revision-form"
					component={
						(compose(
							WithAuthUser,
							// withPartnerStatusHOC({
							// 	whitelist: [1,3,4],
							// }), 
						)(ViewRegisterRevisionForm))
					}
				/>
				<Route // need user auth
					exact
					path="/signature"
					component={Signature}
				/>
				<Route // need user auth
					exact
					path="/create-pin"
					component={CreatePin}
				/>
				<Route // need user auth
					exact
					path="/confirmation-pin"
					component={ConfirmationPin}
				/>
				<Route // need user auth
					exact
					path="/history-transaction"
					component={ViewHistoryTransaction}
				/>
				<Route 
					exact
					path="/history-transaction/:transactionId"
					component={ViewHistoryTransactionDetail}
				/>
				<Route // need user auth
					exact
					path="/portfolio/:categoryId/details"
					component={WithAuthUser(ViewPortofolioDetail)}
				/>
				<Route
					component={Page404}
				/>
			</Switch>
		</Router>
	);
};