import _get from 'lodash.get';

function setSafely (source, path, value) {
	// break down dot nested
	const breakdown = path.split('.');
	// check type of nested
	const types = breakdown.map(item => item.indexOf('$') < 0? false: true);
	// and build each of type to source
	return breakdown.reduce((acc, curr, index, array) => {
		// check if $ sign, if true its an array
		const isNode = types[index];
		const islast = ((index+1) === array.length)? true:false;
		if(isNode) {
			// get the index
			let _index = curr.split('$')[1];
			// insert value to bottom node
			if(islast) return acc[_index] = value;
			if(types[_index+1]) {
				return acc[_index] = [];
			}else{
				return acc[_index] = {};
			}
		}else {
			// insert value to bottom node
			if(islast) return acc[curr] = value;
			// set next type 
			if(types[index+1]) {
				return acc[curr] = [];
			}else{
				return acc[curr] = {};
			}
		}
	}, source);
}

export {
	setSafely
};

/**
 * Get object value safely if the object is deeply nested
 * @param {Array<String>} keys - Object key you want to traverse
 * @param {Object} object - The targeted object
 */
export default function getSafely (keys, object) {
	const stringCollection = keys.join('.');
	return _get(object, stringCollection);
}

/**
 * Get object value safely if the object is deeply nested
 * @param {Array<String>} keys - Object key you want to traverse
 * @param {Object} object - The targeted object
 */
export function getSafelyWithDefault (keys, object, defaultValue) {
	return keys.reduce((xs, x) => (xs && xs[x] !== undefined) ? xs[x] : defaultValue !== undefined ? defaultValue : undefined, object);
}