import React, {Component, Fragment} from 'react';

import { Modal, Toast } from 'antd-mobile';

import { Formik, Form, ErrorMessage } from 'formik';

import {connect} from 'react-redux';

import OtpInput from './../../Common/OtpInput';
import getSafely from '../../../utils/safely';

import { getUserPhoneNumber } from '../../../entities/user.selector';
import { getStatusLoadingSelector, isErrorSelector } from '../../../services/apimanager/apimanager.selector';
import apiconstant from './../../../services/apiconstant';
import { handleResponseGeneral } from '../../../utils/handleResponse';
import { X } from 'react-feather';

import {
	serviceRequestOtp,
	serviceVerifyOtp,
} from './../../../services/user.services';

const postVerifyOtp = ({otp}) => {
	return new Promise((resolve, reject) => {
		return serviceVerifyOtp({otp})
			.then(response => {
				const data = handleResponseGeneral(response);
				return resolve(data);
			})
			.catch(err => {
				return reject(err);
			});
	});
};

const postGenerateOtp = (payload) => {
	return new Promise((resolve, reject) => {
		return serviceRequestOtp({...payload})
			.then(response => {
				const generalResponse = handleResponseGeneral(response); 
				const {data} = generalResponse;
				const {
					otp
				} = data;
	
				if(otp) 
					Toast.info(otp); 
					
				return resolve(generalResponse);
			})
			.catch((err) => {
				return reject(err);
			});
	});
};

class GlobalOtpModal extends Component {

	constructor (props) {
		super(props);
		this.state = {
			isSubmitted: false,
			isModalShow: props.isOpen,
			resend: true,
			counter: 0,
		};
	}
	
	componentDidUpdate (prevProps, prevState) {

		// keep modal open in sync with props
		if(prevProps.isOpen !== this.props.isOpen) {
			const {
				isOpen
			} = this.props;
			const payload = {
				isModalShow: isOpen
			};
			if(!isOpen) payload.isSubmitted = false;
			this.setState(payload);		
		}
		if(prevState.isModalShow !== this.state.isModalShow && this.state.isModalShow) {
			this.sendNumber();
		}	
	}

	sendNumber = () => {
		return postGenerateOtp({
			...this.props.payload
		}).then(response => {
			const {
				data: {
					otp,
					resend,
					resendtime
				}
			} = response;
			this.setState({
				resend: resend,
				counter: resendtime * 1000,
			});
			return otp;
		});
	}

	handleAutoSubmit = (otp) => {
		this.setState({isSubmitted: true});
		return postVerifyOtp({otp})
			.then(response => {
				const token = getSafely(['data', 'token'], response);
				this.props.onSubmit({token});
			})
			.catch(err => {
				const status = getSafely(['response', 'status'], err);
				if(status !== 422) 
					this.props.onCatchError(err);
			});
	}

	isRegister = () => {
		const {
			querystring
		} = this.state;
		const register = getSafely(['register'], querystring);
		return register==='true';
	}

	onClose = () => {
		// this.setState({
		// 	isModalShow: false
		// });
	}

	openModal = modalType => () => {
	}
	renderModal = () => {

		const {
			// counter,
			// resend,
			phonenumber,
			isloading,
		} = this.props;

		const {
			counter,
			resend,
		} = this.state;

		return (
			<Modal
				popup
				animationType="slide-up"	
				visible={this.state.isModalShow}
				onClose={this.props.onClose}
				onRequestClose={this.props.onClose}
				className="bit-link-modal-wrapper"
			>
				<div className="bit-link-register bit-link-otp-modal">
					<div className="bit-link-title-reg">
						<div className="bit-link-modal-header margin-top-15">
							<div>
								<h3>Konfirmasi penjualan</h3>
							</div>
							<div onClick={this.props.onClose}>
								<X color="#666" size={16}/>
							</div>	
						</div>	
						<p>{`Masukkan kode yang dikirim melalui SMS ke nomor handphone kamu (+62) ${phonenumber}`}</p>
					</div>  
					<Formik

						initialValues={{ 
							otp: '', 
						}}
				
						validate={values => {
							const errors = {};
							if(!values.otp){
								errors.otp = 'This is required';
							}
							return errors;
						}}
				
						onSubmit={(values, { setSubmitting }) => {
							this.handleAutoSubmit(values.otp);
							setSubmitting(1);
						}}
				
					>  
						{({isSubmitting}) => (
							<Form>
								<div className="bit-link-pin-left">
									<OtpInput 
										name="otp"
										autosubmit={this.handleAutoSubmit}
										counter={counter}
										resendflag={resend}
										resendotp={this.sendNumber}
									/>
									<ErrorMessage name="otp" />
									{this.state.isSubmitted && this.props.err && <p>{this.props.err.message || this.props.err.text}</p>}
								</div>
								<button type="submit" disabled={isloading || isSubmitting} className={`bit-link-btn-red-full margin-top-15 ${isloading? 'disable': ''}`}>Proses</button>
							</Form>
						)} 
					</Formik>
				</div>
			</Modal>
		);
	}
	render() {
		return (
			<Fragment>
				{this.renderModal()}
			</Fragment>
		);
	}
}

GlobalOtpModal.defaultProps = {
	renderButton: openModal => {
		return (
			<div onClick={openModal}>
				<p>
					Otp Modal
				</p>
			</div>
		);
	},
	onClose: () => {},
	onCatchError: () => {}
};

const mapStateToProps = state => {
	return {
		phonenumber: getUserPhoneNumber(state),
		isloading: getStatusLoadingSelector(state, [
			apiconstant.otpRequest,
			apiconstant.otpVerify,
			apiconstant.userProfile,
		]),
		err: isErrorSelector(state, apiconstant.otpVerify)
	};
};

export default connect(mapStateToProps)(GlobalOtpModal);