import { ActivityIndicator } from 'antd-mobile';
import React, { Component, Fragment } from 'react';
import SwitchBankModal from '../../Common/SwitchBank/SwitchBankModal';

import { serviceDefaultBank } from './../../../services/bank.services';

class SettingBank extends Component {
	state = {
		loading: false
	}
	handleSwitch = selected => serviceDefaultBank({
		bank_slot: selected,
	})
	render () {
		return <Fragment>
			<SwitchBankModal
				autoclose={false}
				outerclose={true}
				title={'Bank Setup'}
				onRenderButton={handleOpen => {
					return <div onClick={handleOpen} className="bit-link-full-row">Bank Setup</div>;
				}}
				onSwitch={({ selected }, handleToggle, handleClose, handleRevertToggle) => {
					this.setState({
						loading: true
					});
					handleToggle()
						.then(() => {
							this.handleSwitch(selected);
							this.setState({loading: false});
						})
						.catch(() => {
							handleRevertToggle();
							this.setState({loading: false});							
						});
				}}
			/>
			<ActivityIndicator
				toast
				text={'Permintaan sedang di proses..'}
				animating={this.state.loading}
			/>
		</Fragment>;
	}
}

SettingBank.defaultProps = {};

export default SettingBank;