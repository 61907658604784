import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import Toast from 'antd-mobile/lib/toast';
import isEmptyObject from './../utils/isEmptyObject';
import Storage from './../utils/Storage';
import {
	handleResponseErrorCase,
	handleResponseGeneral,
	// handleResponseAuthorization,
} from './../utils/handleResponse';

import normalizeOptionUserData from './../utils/normalizeOptionUserData';
import { restoreUserPartner } from '../components/Landing/LandingVerification/ViewLandingVerification.reducer';

import {
	serviceSendCodeVerifyEmail,
	serviceSendVerificationEmail,
	serviceLogoutUser,
	serviceUpdateStatusBar,
	serviceFetchUserProfile,
} from './../services/user.services'; 

import * as Analytics from './../utils/Analytics';
import syariahReducer from '../components/Common/Syariah/syariah.reducer';

const defaultState = {
	profile: null,
	islogin: false,
	ishidestatusbar: 0,
};

const [
	hidestatusbar,
	showstatusbar,
] = [
	'HIDE_STATUSBAR',
	'SHOW_STATUSBAR'
].map(createAction);

const statusbarreducer = {
	[hidestatusbar]: (state) => {
		return update(state, {
			ishidestatusbar: { $set: 1 }
		});
	},
	[showstatusbar]: (state) => {
		return update(state, {
			ishidestatusbar: { $set: 0 }
		});
	}
};

const [resetVerifyEmail, requestveremail, failureveremail, successveremail] = [
	'RESET_VEREMAIL',
	'LOAD_VEREMAIL_REQUEST',
	'LOAD_VEREMAIL_FAILURE',
	'LOAD_VEREMAIL_SUCCESS',
].map(createAction);

const veremailReducer = {
	[resetVerifyEmail]: (state) => {
		return update(state, {
			is_fetching_veremail: {$set: false},
			err_veremail: {$set: ''}
		});
	},
	[requestveremail]: (state) => {
		return update(state, {
			is_fetching_veremail: {$set: true},
			err_veremail: {$set: ''},
		});
	},
	[failureveremail]: (state, payload) => {
		const { err } = payload;
		return update(state, {
			is_fetching_veremail: {$set: false},
			err_veremail: {$set: err},
		});
	},
	[successveremail]: (state) => {
		return update(state, {
			is_fetching_veremail: {$set: false},
		});
	},
};

const [restore, request, failure, success, logout, updatePreferenceStatus, changingPreference, updateEmail] = [
	'RESTORE_USER_DATA',
	'REQUEST_USER_PROFILE',
	'FAILURE_USER_PROFILE',
	'SUCCESS_USER_PROFILE',
	'LOGOUT_USER_PROFILE',
	'UPDATE_USER_PREFERENCESTATUS',
	'CHANGING_PREFERENCE',
	'UPDATE_EMAIL',
].map(createAction);

const userReducer = {
	[restore]: (state, payload) => {
		const { user, islogin } = payload;
		const toUpdate = {};
		if (user) toUpdate['user'] = { $set: user };
		if (typeof islogin === 'boolean')
			toUpdate['islogin'] = { $set: islogin };
		return update(state, toUpdate);
	},
	[request]: state => {
		return update(state, {
			is_fetching: { $set: true },
			done: { $set: false },
			err: { $set: '' },
		});
	},
	[failure]: (state, payload) => {
		const { err } = payload;
		return update(state, {
			is_fetching: { $set: false },
			err: { $set: err },
		});
	},
	[success]: (state, payload) => {
		const { profile } = payload;
		const toUpdate = {
			is_fetching: { $set: false },
			done: { $set: true },
			profile: { $set: profile },
			islogin: { $set: true },
		};
		return update(state, toUpdate);
	},
	[logout]: state => {
		const toUpdate = {
			profile: { $set: null },
			islogin: { $set: false },
			robo: { $set: null },
		};
		return update(state, toUpdate);
	},
	[updatePreferenceStatus]: (state, payload) => {
		const toUpdate = {
			profile: {
				preference: {
					$set: {
						...state.profile.preference,
						...payload,
					},
				},
			}
		};

		return update(state, toUpdate);
	},
	[changingPreference]: (state, payload) => {
		return update(state, {
			is_changing_preference: { $set: payload },
		});
	},
	[updateEmail]: (state, payload) => {
		const toUpdate = {
			profile: {
				email: {
					$set: payload,
				},
			}
		};

		return update(state, toUpdate);
	},
};


const mainReducer = Object.assign(
	veremailReducer,
	userReducer,
	statusbarreducer,
	syariahReducer,
);

const reducer = createReducer(mainReducer, defaultState);

export {
	resetVerifyEmail,
	updatePreferenceStatus,
	updateEmail,
};

// window.document.notifClick = function notifClick () {
// 	if(window.document.__store) window.document.__store.dispatch(pullRefreshInitialUser());
// };

/**
 * Get user bank data
 * @param {Object} state - Redux state object
 */
export const getUserBankData = state => {
	return {
		bank_name: state.entities.user.profile
			? state.entities.user.profile.bank_name
			: '',
		bank_account_number: state.entities.user.profile
			? state.entities.user.profile.bank_account_number
			: '',
		bank_account_name: state.entities.user.profile
			? state.entities.user.profile.bank_account_name
			: '',
	};
};


export function sendCodeVerificationEmail (code) {
	return dispatch => {
		dispatch(requestveremail());
		if(!code) {
			return dispatch(failureveremail({err: 'Code is not defined'}));
		}
		const toBody = {token: code};
		const req = serviceSendCodeVerifyEmail(toBody).then(payload => {
			if (isEmptyObject(payload)) {
				return dispatch(failureveremail({ err: 'Something is wrong' }));
			}
			return dispatch(successveremail());
		});
		handleResponseErrorCase(req, errObj => {
			const {
				err, // message from error case
			} = errObj;
			return dispatch(failureveremail({ err }));
		});
	};
}

export function sendVerificationEmail () {
	return (dispatch) => {
		dispatch(requestveremail());
		const req = serviceSendVerificationEmail().then(payload => {
			if (isEmptyObject(payload)) {
				return dispatch(failureveremail({ err: 'Something is wrong' }));
			}
			const { data } = handleResponseGeneral(payload);
			Toast.success('Email Telah Dikirim Ulang, Silahkan Cek Kembali', 2);
			return dispatch(successveremail({ value: data }));
		});
		handleResponseErrorCase(req, errObj => {
			const {
				err, // message from error case
			} = errObj;
			return dispatch(failureveremail({ err }));
		});
	};
}


export const setUser = ({ profile }) => {
	return dispatch => {
		const _profile = normalizeOptionUserData(profile);
		dispatch(
			success({
				profile: _profile,
			}),
		);
	};
};

export const logoutUser = () => {
	return async dispatch => {
		dispatch(logout());
		dispatch(request());
		Storage.removeObject('hide_status_bar');
		const _logout = serviceLogoutUser();
		const _timeout = new Promise(resolve => {
			setTimeout(() => resolve(true), 500);
		});
		Promise.race([
			_logout,
			_timeout
		]).then(() => {
			/* reset session from crisp */
			// clearLocalSession();
		}).catch(() => {
			/* reset session from crisp */
			// clearLocalSession();
		});
	};
};


export async function seekCredential () {
	return await Storage.getObject('ac');
}

export function setHideStatusBar() {
	return dispatch => {
		Storage.setObject('hide_status_bar', 1);
		dispatch(hidestatusbar());
		const toBody = {
			statusHide: 1
		};
		updatePreferenceStatus({ statusHide: 1 }); // hide status registration bar
		serviceUpdateStatusBar(toBody);
	};
}

export function pullRefreshInitialUser () {
	return async (dispatch, getState) => {};
}

export function restoreStatusBar() {
	return (dispatch, getState) => {
		// const r = await Storage.getObject('hide_status_bar');

		const state = getState();

		let {
			user: {
				profile
			}
		} = state;
		let preference = null;

		if (profile && profile.preference)
			preference = profile.preference;
		if (!preference)
			preference = {
				statusHide: 0
			};

		const {
			statusHide
		} = preference;
		let _statusHide = (typeof statusHide === 'number' && statusHide === 1) ? true : false;
		if (_statusHide)
			return dispatch(hidestatusbar());
		return dispatch(showstatusbar());

	};
}


export function restoreInitialUser() {
	
	return async dispatch => {
		const token = await seekCredential();
		const islogin = token ? true : false;
		// dispatch(restore({ islogin }));
		if (!islogin) return false;

		return serviceFetchUserProfile()
			.then(async payload => {
				if(!payload) 
					return false;

				let generalData = handleResponseGeneral(payload);
			
				let {
					data: { 
						roboid, 
						user,
						sinvest,
						pin, 
						pin_length, 
						active, 
						estRegistration, 
						transaction_configuration,
						reject, 
						reject_new,  
						validbank, 
						validemail, 
						preference, 
						config, 
						need_edd,
						is_amend,
						user_partner_status,
					}
				} = generalData;

				if (roboid) {
					Storage.setObject('rb', roboid);
				} else {
					Storage.setObject('rb', '');
				}


				let profile = {
					...user,
					...sinvest,
					preference,
					pin,
					pin_length,
					active,
					reject,
					reject_new,
					estRegistration,
					transaction_configuration,
					validbank,
					validemail,
					config,
					need_edd,
					is_amend,
				};
				
				profile = normalizeOptionUserData(profile);
			
				/* set user id */
				Analytics.recordUser({
					id: user.id
				});

				dispatch(restoreUserPartner({
					user_partner_status: user_partner_status 
				}));	

				return dispatch(
					success({
						profile,
					})
				);
			
			});

	};
}

export {
	failureveremail
};
export default reducer;