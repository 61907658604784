// import * as Sentry from '@sentry/browser';

import {checkNativeFlag} from './index';

function postData () {}

const noopName = name => {
	console.log(`Moengage method ${name}, not ready (just info not an error)`);
};

function getMoeObject () {

	const mockMoe = {
		add_unique_user_id: () => noopName('add_unique_user_id'),
		update_unique_user_id: () => noopName('update_unique_user_id'),
		track_event: () => noopName('track_event'),
		destroy_session: () => noopName('destroy_session'),
		add_user_attribute: () => noopName('add_user_attribute'),
	};

	return (window.Moengage)? window.Moengage: mockMoe; 
	 
}

export function moengageRecordUser(id) {
	let nativeFlag = checkNativeFlag();
	let moengageNativeFlag = checkMoengageNative();

	if (nativeFlag && moengageNativeFlag)
		return postData('_moengageSetUserId', {
			fn: '_moengageSetUserId',
			data: { id }
		});
	
	const Moengage = getMoeObject();
	Moengage.add_unique_user_id(id);
	
}

export function logEvent (page_path, param_amp) {
	const Moengage = getMoeObject();
	Moengage.track_event(page_path, param_amp);
}


function _propsEncrypt(list, obj) {
	let newdata = { ...obj };
	list.map(name => {

		// hide temporary data
		if (newdata[name]) {
			newdata[name] = '';
		}
		return newdata;
	});
	return newdata;
}


function checkMoengageNative() {
	let nativeFlag = false;
	if (window.document.inMoengageNative) {
		nativeFlag = window.document.inMoengageNative;
	}
	return nativeFlag;
}

export function logout () {
	const Moengage = getMoeObject();
	Moengage.destroy_session();
}

export function recordUserProps (props) {
	moengageRecordUserProps(props);
}

export function moengageRecordUserProps(props) {
	/*
		props
		FINISH_REGISTER: true
		SOURCE_INCOME: "Gaji"
		SYARIAH_PREFERENCE: 1
		USER_BANK: "IMAM tauhid"
		USER_CITY_FROM_ID: "3671"
		USER_EMAIL: "imam.tauhid.dar@gmail.com"
		USER_NAME: "IMAM TAUHID"
		USER_OCCUPATION: 7
	*/
	
	/**
	 * this toEncrypt make key value not pushed into moengage, we dont know yet, 
	 * but for now we just 'comment' first.
	 * already confirm to imdad
	 */
	const toEncrypt = [ 
		// 'USER_OCCUPATION',
		// 'USER_GENDER',
		// 'USER_RELIGION',
		// 'HAVING_',
		// 'ANNUAL_SALARY',
	];

	let nativeFlag = checkNativeFlag();
	let moengageNativeFlag = checkMoengageNative();
	let _props = _propsEncrypt(toEncrypt, props);
	if (nativeFlag && moengageNativeFlag)
		return postData('_moengageSetUserProps', { fn: '_moengageSetUserProps', data: _props });
	const Moengage = getMoeObject();
	Object.keys(_props).map(name => _props[name] && Moengage.add_user_attribute(name, _props[name]));

	// safeUseAmplitude(amplitude => {
	// 	amplitude.getInstance().setUserProperties(props);
	// });

}

export function moengageClearRecordUser() {

	let nativeFlag = checkNativeFlag();
	let moengageNativeFlag = checkMoengageNative();

	if (nativeFlag && moengageNativeFlag)
		return postData('_moengageClearRecordUser', { fn: '_moengageClearRecordUser', data: {} });

	// safeUseAmplitude(amplitude => {
	// 	amplitude.getInstance().setUserId(null);
	// 	amplitude.getInstance().regenerateDeviceId();
	// });
	const Moengage = getMoeObject();
	Moengage.destroy_session();
}
