import getSafely, { getSafelyWithDefault } from "../utils/safely";

export const getReksadanaProductDetail = (state, productIds) => {
	const {
		byId,
	} = state.reksadana;
	return productIds
		.filter(id => {
			return !!byId[id];
		}).map(id => {
			return byId[id];
		});
};

export const getReksadanaProspectus = (state, symbol) => {
	const prospectus = getSafely(['reksadana','byId', symbol, 'files', 'prospectus'], state) || []; 
	return prospectus;
};

export const getReksadanasProspectus = (state, symbols) => {
	let collections = [];
	symbols.map(symbol => {
		const prospectus = getReksadanaProspectus(state, symbol);
		if(prospectus) 
			collections = [
				...collections,
				...prospectus,
			]; 
		return symbol;
	});
	return collections;
};

export const getReksadanaFundfacts = (state, symbol) => {
	const fundfacts = getSafely(['reksadana', 'byId', symbol, 'files', 'factsheets'], state) || [];
	return fundfacts;
};

export const getReksadanasFundfacts = (state, symbols) => {
	let collections = [];	
	symbols.map(symbol => {
		const fundfacts = getReksadanaFundfacts(state, symbol);
		if(fundfacts)
			collections = [
				...collections,
				...fundfacts
			];
		return symbol;
	});
	return collections;
};

export const getReksadanaProducts = (state) => {
	
	const reksadana = getSafely(['reksadana'], state);
	const allIds = getSafelyWithDefault(['reksadana', 'allIds'], state, []);
	
	return allIds.map(id => {
		return reksadana.byId[id];
	});

};