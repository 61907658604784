import React, {Component, Fragment} from 'react';
import { Route, Switch } from 'react-router-dom';
import ChangeBank from '../components/Setting/ChangeBank/ChangeBank';
import Page404 from '../components/Error/Page404';

export default class DirectRoute extends Component {
	render () {
		return (
			<Fragment>
				<Switch>
					<Route  
						path={`${this.props.match.path}/change-bank/:code`} 
						component={ChangeBank}
					/>
					<Route
						component={Page404}
					/>
				</Switch>
			</Fragment>
		);
	}
}