import React from 'react';
import moment from 'dayjs';

function nodeForLoop (toLoop, callback, callbackDone) {
	
	let isReturned = false; 

	const start = 0;
	const end = toLoop && toLoop.length? toLoop.length: 0;

	let i = start;
	for(i; i < end; ++i) {
		try {
			if(typeof callback == 'function') 
				callback(toLoop[i]);
		}
		catch (err) {
			if(typeof callbackError === 'function') {
				callbackDone(err);
				isReturned = true;
				break;
			}
		}
	}

	if(!isReturned) 
		callbackDone();

}

class CustomChartFlyout extends React.Component {
	componentDidMount() {
		if (this.props.x && this.dateText) {
			// Clear any date label wrapper left
			const chartLabels = document.querySelectorAll('.chart-label-box');		
			
			nodeForLoop(chartLabels, el => {
				if(el && typeof el.remove == 'function')
					el.remove();
			}, err => {
				if(err)
					console.error(err);
			});
			// [...chartLabels].forEach(el => el.remove());

			// Calculate text rectangle wrapper
			const textBBox = this.dateText.getBBox(); // Get the text x y width height boundaries
			const textWrapperEl = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'rect',
			);

			// Style label text wrapper here
			textWrapperEl.setAttribute('x', textBBox.x - 5);
			textWrapperEl.setAttribute('y', textBBox.y - 5);
			textWrapperEl.setAttribute('height', textBBox.height + 10);
			textWrapperEl.setAttribute('width', textBBox.width + 10);
			textWrapperEl.setAttribute('stroke', '#333');
			textWrapperEl.setAttribute('stroke-width', '1');
			textWrapperEl.setAttribute('fill', 'white');

			if(textWrapperEl && textWrapperEl.classList)
				textWrapperEl.classList.add('chart-label-box'); // class if necessary

			this.groupWrapper.insertBefore(
				textWrapperEl,
				this.groupWrapper.firstChild,
			);
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.x !== this.props.x && this.dateText) {
			// Clear any date label wrapper left
			document
				.querySelectorAll('.chart-label-box')
				.forEach(el => el.remove());

			// Calculate text rectangle wrapper
			const textBBox = this.dateText.getBBox(); // Get the text x y width height boundaries
			const textWrapperEl = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'rect',
			);

			// Style label text wrapper here
			textWrapperEl.setAttribute('x', textBBox.x - 5);
			textWrapperEl.setAttribute('y', textBBox.y - 5);
			textWrapperEl.setAttribute('height', textBBox.height + 10);
			textWrapperEl.setAttribute('width', textBBox.width + 10);
			textWrapperEl.setAttribute('stroke', '#333');
			textWrapperEl.setAttribute('stroke-width', '1');
			textWrapperEl.setAttribute('fill', 'white');
			
			if(textWrapperEl && textWrapperEl.classList)
				textWrapperEl.classList.add('chart-label-box'); // class if necessary

			this.groupWrapper.insertBefore(
				textWrapperEl,
				this.groupWrapper.firstChild,
			);
		}
	}

	render() {
		const { x, y, datum, style } = this.props;
		return (
			<g ref={el => (this.groupWrapper = el)}>
				<circle cx={x} cy={y} r="5" fill="black" />
				<line
					x1={x}
					x2={x}
					y1={0}
					y2={260}
					strokeDasharray="3 3"
					stroke={style.lineColor}
				/>
				<line
					x1={0}
					x2={450}
					y1={y}
					y2={y}
					strokeDasharray="3 3"
					stroke={style.lineColor}
				/>
				<text
					ref={el => {
						this.dateText = el;
					}}
					x={x}
					y={280}
					textAnchor="middle"
				>
					<tspan style={{ fill: style.stroke }}>
						{moment(datum.formated_date).format('D MMM YY')}
					</tspan>
				</text>
			</g>
		);
	}
}

export default CustomChartFlyout;
