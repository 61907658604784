import React, { Component, Fragment } from 'react';

// import mailVerification from '../../assets/images/mailVerification.svg';
import mailVerification2 from '../../assets/images/mailVerification2.svg';


class EmailStatusDetail extends Component {
	handleResend = () => {
		this.props.onResendEmail();
	}
	handleChangeEmail = () => {
		this.props.onChangeEmail();
	}
	render() {
		const {
			email,
			loading
		} = this.props;
		return (
			<Fragment>
				<div className="mailPage">
					<img className="mailPageImg" src={mailVerification2} alt="email" />
					<div className="mailPageDesc">
						<span className="mailPageDesc1">Bibit telah mengirimkan email verifikasi ke:</span>
						<div className="mailPageDesc2">{email}</div>
					</div>
					<div>
						Silahkan cek email kamu untuk melakukan verfikasi email. 
					</div>
					<div className="mailPageBtnWrap">
						<div className="mailPageBtnChild" onClick={this.handleResend}>
							<span className="mailPageBtn" >{loading? (<Fragment>Kirim Ulang Email <div className="loader" /></Fragment>): 'Kirim Ulang Email'}</span>
						</div>
						<div className="mailPageBtnChild" onClick={this.handleChangeEmail}>
							<span className="mailPageBtn">Ganti Alamat Email</span>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

EmailStatusDetail.defaultProps = {
	email: '#####@yahoo.com',
	loading: false,
	onChangeEmail: () => {},
	onResendEmail: () => {},
};

export default EmailStatusDetail;