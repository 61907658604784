import React, { Fragment, PureComponent } from 'react';
import { Modal } from 'antd-mobile';
import { ChevronRight, AlertTriangle, X } from 'react-feather';

import image_robo_support from '../../../assets/images/image_robo_support.svg';

import './SellModal.css';

class SellModalWarning extends PureComponent {
	state = {
		visible: false
	}
	handleOpen = () => {
		this.setState({
			visible: true
		});
	}
	handleClose = () => {
		this.setState({
			visible: false
		});
	}
	render() {
		return (
			<Fragment>
				<Modal
					visible={this.state.visible}
					popup
					className="robosell-warning-modal"
					animationType="slide-up"
					onClose={this.handleClose}
				>
					{/* <span onClick={this.handleClose} className="modal-close-new"><X size="16" color="#6b6b6b" /></span> */}

					<div className="ligreen-wrap">
						<div className="bit-link-modal-header">
							<div>
								<h3>Perubahan Penjualan</h3>
							</div>
							<div>
								<X color="#666" size={16} onClick={this.handleClose}/>
							</div>	
						</div>	 
						<img className="image_robo_support" src={image_robo_support} alt=""/>
						<div className="ligreen-ttl">Penjualan Reksa Dana Kamu Berubah Karena:</div>
						<div className="ligreen">
							<span>1</span>
							<span>
								<b>Terbentur Minimal Penjualan</b>
								<p>Salah satu reksa dana yang ingin kamu jual tidak mencukupi minimal penjualan. Maka kami membatalkan penjualan reksa dana kamu tersebut.</p>
							</span>
						</div>
						<div className="ligreen">
							<span>2</span>
							<span>
								<b>Terbentur Minimal Kepemilikan</b>
								<p>Sisa dana dari salah satu reksa dana yang ingin kamu jual kurang dari minimal kepemilikan. Maka kami menjual semua kepemilikan kamu di reksa dana tersebut untuk memenuhi ketentuan dari manajer investasi.</p>
							</span>
						</div>
					</div>
				</Modal>
				{this.props.renderButton(this.handleOpen)}
			</Fragment>
		);
	}
}

SellModalWarning.defaultProps = {
	visible: false,
	renderButton: (openModal) => {
		return (
			<div className="new-robosell-warning"
				arrow="horizontal"
				onClick={openModal}
			>
				<AlertTriangle width="24" color="#FFF" />
				<span>Penjualan reksa dana kamu mengalami penyesuaian oleh kami. &nbsp;
					<b>Pelajari di sini.</b>
				</span>
				<ChevronRight color="#fff" width="24" />
			</div>
		);
	}
};

export default SellModalWarning;