/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import * as Sentry from '@sentry/browser';
import Page404 from './components/Error/Page404';


export default class RootErrorBoundaries extends React.Component {
    state = {
    	hasError: false,
    };
    
    static getDerivedStateFromError() {
    	// Update state so the next render will show the fallback UI.
    	return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    	Sentry.withScope((scope) => {
    		scope.setExtras(errorInfo);
    		scope.setTag('domain', 'generalAppRendering');
    		Sentry.captureException(error);
	  });
    }

    render(){
    	if (this.state.hasError) {
    		// You can render any custom fallback UI
    		return <div>
    			<Page404 hasRefresh={true} err_code="Oops terjadi kesalahan"/>
    		</div>;
    	}
    	return this.props.children;
    }
}