import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import { ChevronLeft , ArrowUp } from 'react-feather';
// import { List, Button } from 'antd-mobile';

class Signature extends Component {
	render() {
		const { history } = this.props;
		return (
			<div className="bit-link-main-wrap">
				<div className="bit-link-main-header">
					<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
					<div>Tanda Tangan</div>
					<div/>
				</div>
				<div className="bit-link-main-content">

					<div className="signature-canvas-box">
						<div>
						</div>
						<div className="signature-canvas-ket">
							Silahkan tanda tangan digital di dalam kotak ini <ArrowUp size="12" color="#6b6b6b" />
						</div>
					</div>
					<div className="bit-link-fixed-foot">
						<div className="tw-btn-box">
							<div className="tw-btn">
								<span onClick={this._undoSignature}>Clear</span>
							</div>
							<div className="tw-btn primary">
								<span onClick={this._saveSignature} >Save</span>

							</div>
						</div>
					</div>	
				</div>
					
			</div>
		);
	}
} 

export default Signature;