import axios from 'axios';
import getSafely from './safely';
import Storage from './Storage';
import { handleResponseGeneral } from './handleResponse';
// import Storage from './Storage';
// import isdo from './isdo';

// import { clearLocalSession } from './index';
// import { openErrorModal } from '../components/errors/errorModal.reducer';

const { REACT_APP_API_URL } = process.env;
// const { isLogin } = isdo;
const URL = REACT_APP_API_URL;
const TIMEOUT = 100000;

function handleErrors(response) {
	if (!response.ok) {
		console.error(response.statusText);
	}
	return response;
}

// // intercept api calls here to log people out if api response 401
// // usually happens when token is no longer valid
// axios.interceptors.response.use(null, function(err) {
// 	// if(err.response && err.response.status === 503) {
// 	// 	const errorMessageObj = {
// 	// 		type: 'MAINTENANCE',
// 	// 		message: 'Silahkan coba beberapa saat lagi.',
// 	// 	};
// 	// 	if (window.document.__store) {
// 	// 		window.document.__store.dispatch(openErrorModal(errorMessageObj));
// 	// 	}
// 	// 	return Promise.reject(err);
// 	// }

// 	// if (err.response && err.response.status >= 500) {
// 	// 	const errorMessageObj = {
// 	// 		type: 'SYSTEM_ERROR',
// 	// 		message: err.response.data && err.response.data.message ? err.response.data.message : 'Silahkan coba beberapa saat lagi',
// 	// 	};
// 	// 	if (window.document.__store) {
// 	// 		window.document.__store.dispatch(openErrorModal(errorMessageObj));
// 	// 	}
// 	// 	return Promise.reject(err);
// 	// }

// 	// if(err.response && err.response.status === 401) {
// 	// 	clearLocalSession();
// 	// }
// 	return Promise.reject(err);
// });

/**
 * Enhancer Interceptor Request 
 * @param {*} fn 
 */
export function interceptorRequest (fn, fnError = () => Promise.reject()) {
	axios.interceptors.request.use(fn, fnError);
}

/**
 * Enhancer Interceptor Response 
 * @param {*} fn 
 */
export function interceptorResponse (fn, fnError = () => Promise.reject()) {
	axios.interceptors.response.use(fn, fnError);
}

// // // Add a request interceptor
// axios.interceptors.request.use(function (config) {
// 	alert('diluar');
// 	// Do something before request is sent
// 	return config;
// }, function (error) {
// 	// Do something with request error
// 	return Promise.reject(error);
// });

// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
// 	alert('test');
// 	// Any status code that lie within the range of 2xx cause this function to trigger
// 	// Do something with response data
// 	return response;
// }, function (error) {
// 	// Any status codes that falls outside the range of 2xx cause this function to trigger
// 	// Do something with response error
// 	return Promise.reject(error);
// });

export default class Api {

	static _interceptorRequest = config => config;
	static _interceptorRequestError = err => Promise.reject(err);
	static _interceptorResponse = response => response;
	static _interceptorResponseError = err => Promise.reject(err);

	constructor(url = URL) {
		this._url = url;
		this._seekCredential = async () => {
			return await Storage.getObject('ac', 'access');
		};
		this._interceptorRequest = Api._interceptorRequest;
		this._interceptorRequestError = Api._interceptorRequestError;
		this._interceptorResponse = Api._interceptorResponse;
		this._interceptorResponseError = Api._interceptorResponseError;
	}

	_get() {
		return URL;
	}
	
	setInterceptorRequest (fn, fnE) {
		if(fn) this._interceptorRequest = fn;
		if(fnE) this._interceptorRequestError = fnE;
	}
	
	setInterceptorResponse (fn, fnE) {
		if(fn) this._interceptorResponse = fn;
		if(fnE) this._interceptorResponseError = fnE;
	}

	async post(endpoint, bodyparam) {

		let url = this._url + endpoint;

		let options = {
			method: 'POST',
		};
		
		return await this.call({
			url, 
			data: {
				...bodyparam	
			},
			options,
		});

	}

	async postFile(endpoint, bodyparam) {
		let url = endpoint;

		const instance = axios.create();

		instance.interceptors.request.use(this._interceptorRequest, this._interceptorRequestError);
		instance.interceptors.response.use(this._interceptorResponse, this._interceptorResponseError);

		return instance.post(url, bodyparam);
	}

	async get(endpoint, queryparam = {}) {

		let url = this._url + endpoint;

		let options = {
			method: 'GET',
		};

		return await this.call({
			url, 
			params: {
				...queryparam	
			},
			options,
		});

	}
	async call (obj, errHandling = false) {
		
		// let cancel;
		let ac = await this._seekCredential();

		const options = getSafely(['options'], obj);
		if(!getSafely(['headers'], options)) 
			options['headers'] = {};

		if(!getSafely(['headers', 'Content-Type'], options))
			options['headers']['Content-Type'] = 'application/json';

		if (ac) ac = 'Bearer ' + ac;
		if (ac) options.headers['Authorization'] = ac;

		const config = {
			url: obj.url,
			timeout: TIMEOUT,
			headers: options.headers,
			data: JSON.stringify(obj.data || {}),
			method: options.method || 'get',
			params: obj.params,
			responseType: options.responseType? options.responseType:'json',
			// cancelToken: new CancelToken(function (c) {
			// 	cancel = c;
			// }),
			validateStatus: function (status) {
				return status >= 200 && status < 300; // default
			},
		};

		const instance = axios.create();

		instance.interceptors.request.use(this._interceptorRequest, this._interceptorRequestError);
		instance.interceptors.response.use(this._interceptorResponse, this._interceptorResponseError);

		return instance.request(config);
		
	 }

	getRaw() {
		return axios.get(this._url).then(handleErrors);
	}
	putFile(endpoint, bodyparam, mime, length) {
		let url = endpoint;
		return axios.put(url, bodyparam, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	}

}

export function responseAxiosHOF (fnService) {

	return payload => {
		return fnService(payload)
			.then(resp => handleResponseGeneral(resp));
	};

}