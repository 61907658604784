import { combineReducers } from 'redux';

import apimanager from './services/apimanager/apimanager.reducer';
import userportfolio from './entities/userportfolio.reducer';
import userpartner from './entities/userpartner.reducer';
import reksadana from './entities/reksadana.reducer';
import user from './entities/user.reducer';
import order from './entities/order.reducer';
import viewotpverification from './components/Landing/OtpVerification/ViewOtpVerification.reducer';
import errorModal from './components/Global/GlobalErrorModal/errorModal.reducer';

// need to refactor
import bankPicker from './components/Common/BankPicker/bankPicker.reducer';

export default combineReducers({
	apimanager,
	userportfolio,
	userpartner,
	user,
	order,
	reksadana,
	bankPicker,
	viewotpverification,
	errorModal,
});