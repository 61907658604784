import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { ChevronLeft } from 'react-feather';
import { Link, withRouter } from 'react-router-dom';
import { ActivityIndicator } from 'antd-mobile';
// import getSafely from './../../utils/safely';
import FormRegister from './FormRegister/FormRegister';
import { submitRegister as _submitRegister } from './ViewRegisterForm.reducer';

import {
	isLoadingSelector,
	isErrorSelector,
} from './../../services/apimanager/apimanager.selector';

import list from './../../services/apiconstant';
import { getUserId, getUserPhone, getUserProfileStatus } from '../../entities/user.selector';

// import NikRegistered from './../../assets/images/nik-registered.svg';

class ViewRegisterForm extends Component {
	componentDidMount () {

		const {history, status} = this.props;

		if(status >= 1) 
			return history.push('/input-pin?register=true'); 

	}
	render() {
		const { 
			history,
			submitRegister,
		} = this.props;
		
		return (
			<div className="bit-link-main-wrap">
				<div className="bit-link-main-header">
					<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
					<div>Register</div>
					{/* <Link className="bit-link-skip" to="index-home">Skip</Link> */}
					<div><Link className="bit-link-skip" to=""></Link></div>
				</div>
				<div className="bit-link-main-content">
					<div className="bit-link-register">
						<FormRegister 
							{...this.props}
							mapErrorKey={{
								file_edd: 'selfie',
								file_identity: 'ktp',
								signature: 'signaturedata',
							}}
							onSubmit={submitRegister}
						/>
					</div>
				</div>
				<ActivityIndicator
					toast
					text="Memproses"
					animating={this.props.isloading}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const toReturn = {
		userid: getUserId(state),
		userphone: getUserPhone(state),
		status: getUserProfileStatus(state),
		err: isErrorSelector(state, list.registerSubmit),
		isloading: 
			isLoadingSelector(state, list.registerSubmit) ||
			isLoadingSelector(state, list.bankList) 
	};

	return toReturn;

};

const mapStateToDispatch = dispatch => bindActionCreators({
	submitRegister: _submitRegister
}, dispatch);

const composed = compose(
	connect(mapStateToProps, mapStateToDispatch),
	withRouter, 
);

export default composed(ViewRegisterForm);