import React, {Fragment, PureComponent} from 'react';
import {compose} from 'redux';
import { Link, withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import getSafely from './../../../utils/safely';

import RoboOk from './../../../assets/images/robo-ok.svg';

class ViewConnectAccount extends PureComponent {
	render() {
		const {
			name = '',
			phonenumber,
			code,
		} = this.props;
		return (
			<Fragment>
				<div className="bit-link-centered-img">
					<img src={RoboOk} alt="robo" />
				</div>    
				<p>Nomor Kamu terdaftar sebagai</p>
				<p className="font-bold">{name || code+phonenumber}</p>
				<Link to={`/otp-verification`}>
					<button className="bit-link-btn-red margin-top-15">Lanjutkan</button>
				</Link>	
			</Fragment>
		);
	}
}

ViewConnectAccount.defaultProps = {
	code: 62
};

const mapStateToProps = state => {
	const {
		userpartner: {
			profile
		}
	} = state;
	const phonenumber = getSafely(['phone'], profile);
	const fullname = getSafely(['fullname'], profile);
	return {
		phonenumber,
		name: fullname,
	};
};

const composed = compose(
	connect(mapStateToProps),
	withRouter,
);

export default composed(ViewConnectAccount);