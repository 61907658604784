import React, { 
	Component, 
} from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Button, Steps } from 'antd-mobile';
import { withRouter } from 'react-router-dom';
import { Check, X, Mail, User, Pocket } from 'react-feather';
import {handlerReject} from './../../utils';
import {
	sendVerificationEmail as _sendVerificationEmail,
	setHideStatusBar as _setHideStatusBar,
	restoreStatusBar as _restoreStatusBar,
} from './../../entities/user.reducer';

moment.extend(localizedFormat); // dayjs .format('LL')

const { Step } = Steps;

const REVISI_REGISTER_PATH = '/register-revision-form';

function ButtonSubmitResent ({loading, onClick, children, isvisible}) {
	if(!isvisible) return (null);
	return (<Button loading={loading} onClick={onClick} >{children}</Button>);
}

class ViewRegisterAccountStatusRaw extends Component {
	state = {
		show: false,
		done: 0, // 0 - not reach here, 1 - reach here and done, 2 - reach here but rejected
	}
	componentDidMount() {
		const {
			islogin,
		} = this.props;
		if(!islogin) return false;
		this.props.restoreStatusBar();
		const toUpdate = {
			show: true
		};
		this.setState(toUpdate);
	}
	componentWillReceiveProps (nextProps) {
		const {
			islogin
		} = nextProps;
		const {
			islogin: _islogin
		} = this.props;
		
		if(islogin !== _islogin && islogin) {
			const toUpdate = {
				show: true
			};
			this.setState(toUpdate);
		} 

	}
	_renderLastStep = () => {
		const { done } = this.props;
		let output = null;
		switch (done) {
			case 1:
				output = <Step title="Selesai"/>;
				break;
			case 2:
				output = <Step status="error" title="Ditolak" />;
				break;
			default:
				output = <Step title="Selesai" />;
		}
		return output;
	}
	_nextClick = () => {
		const {current_url, history } = this.props;
		history.push(current_url);
	}
	_resendEmailClick = () => {
		const {sendVerificationEmail} = this.props;
		sendVerificationEmail();
	}
	_renderButtonClose = () => {
		const {
			closeflag,
			setHideStatusBar,
		} = this.props;
		if(!closeflag)
			return null;
		return (
			<div className="closehome" onClick={() => setHideStatusBar()} ><X size="14" color="#6b6b6b" /></div>
		);
	}
	render() {
		const {
			neverShowAgain, 
			current, 
			message, 
			btn_label, 
			status, 
			validbank, 
			validemail,
			loading_veremail,
			msg_reject,
			email,
			err_veremail
		} = this.props;
		if(!this.state.show || neverShowAgain) 
			return (null);
		const step = current -1;
		return (
			<div className="bit-link-card">
				{this._renderButtonClose()}
				<div className="bit-link-register-step">
					<div className="index-registration-step">
						<Steps
							direction="horizontal"
							size="small"
							current={step}
						>
							<Step
								title="Registrasi"
							/>
							<Step
								title="Verifikasi"
							/>
							
							{this._renderLastStep()}
						</Steps>
					</div>

					{
						(current === 2) && 
						<div className="verifikasi-box">
							<div className={(validemail === 0)? "verifikasi-step": "verifikasi-step done"}>
								<div><Mail size="13" color="#6b6b6b" /></div>
								<div>
									Verifikasi Email<span>{(validemail === 1) && <Check size="12" color="#fff"></Check>}</span>
								</div>
							</div>
							<div className={(validbank === 0 || validbank === 2)? "verifikasi-step": "verifikasi-step done"}>
								<div><Pocket size="13" color="#6b6b6b" /></div>
								<div>
								Verifikasi oleh Bibit<span>{(validbank === 1) && <Check size="12" color="#00ab6b"></Check>}</span>
								</div>
							</div>
							<div className={(status === 4)? "verifikasi-step done": "verifikasi-step"}>
								<div><User size="13" color="#6b6b6b" /></div>
								<div>
								Verifikasi oleh KSEI <span>{(status === 4) && <Check size="12"color="#00ab6b"></Check>}</span>
								</div>
							</div>
						</div>
					}

					{message && <p className="bit-link-register-desc">{message}</p>}
					{msg_reject}
					{!validemail && 
						<>
							{err_veremail && <p className="bit-link-register-desc red">{err_veremail}</p>}
							<ButtonSubmitResent isvisible={email} loading={loading_veremail} onClick={this._resendEmailClick}>{'Kirim Ulang Email Verifikasi'}</ButtonSubmitResent>
						</>
					}
					{btn_label && <Button onClick={this._nextClick} >{btn_label}</Button>}
				</div>
				{/* <p className="bit-link-register-desc">Registrasi Kamu berhasil, <br/>Yuk mulai investasi sekarang.</p> */}
			</div>
		);
		
	}
}

ViewRegisterAccountStatusRaw.defaultProps = {
	status: 0,
	pin: 0,
	done: 0,
	islogin: false,
	current: 1,
	current_url: 'register-form',
	btn_label: 'Lanjutkan Registrasi',
	neverShowAgain: false,
	estimate: '24 September 2018',
	message: 'Lanjutkan proses registrasi kamu agar bisa mulai berinvestasi.',
	msg_reject: null
};

const mapStateToProps = (state) => {
	const {
		user
	} = state;
	
	let {
		done, // 0 - not reach here, 1 - reach here and done, 2 - reach here but rejected
		current, // assume if already login profiling done
		pin,
		status,
		message,
		current_url,
		btn_label,
		estimate,
	} = ViewRegisterAccountStatusRaw.defaultProps;

	let {
		profile: _profile,
		// islogin,
		is_fetching_veremail: loading_veremail,
		err_veremail,
		ishidestatusbar: neverShowAgain, // ishidestatusbar true == harus di hide, nevershowagain true == harus di hide
	} = user;

	let islogin = !!_profile;
	let validbank = 0;
	let validemail = 0;
	let closeflag = 0;
	let isvisible = 1;
	let email = '';
	let msg_reject = null;

	if(_profile) {
		const {
			pin: _pin,
			active: _status,
			estRegistration,
			validbank: _validbank,
			validemail: _validemail,
			reject: _reject,
			reject_new: _reject_new,
			email: _email
		} = _profile;
		
		const {
			preference: {
				statusHide
			}
		} = _profile;
		
		neverShowAgain = neverShowAgain || statusHide;
		
		email = _email;
		estimate = (estRegistration)? moment(moment.unix(estRegistration)).format('LL'): ' unknown date ';
		pin = _pin;
		status = _status;
		validbank = _validbank;
		validemail = _validemail;
		msg_reject =  _reject_new? 
			handlerReject(_reject_new): 
			(_reject)? handlerReject(_reject):
				null;
	}

	/**
	 * status:
	 * 0 = User baru daftar nomor telepon
	 * 1 = User submit KYC
	 * 2 = KYC sedang di proses BO (data KYC uda ditandain BO)
	 * 3 = Import ke S-Invest
	 * 4 = Di-approve S-Invest / active
	 * 9 = Di-reject BO dengan alasan
	 * 10 = Di-reject oleh S-Invest
	 * 11 = User sudah request hapus account dan menunggu konfirmasi dari BO
	 * 12 = BO menghapus account user
   */

	/**
	 * validbank:
	 * 0: invalid
	 * 1: valid
	 * 2: account name banknya berbeda dengan nama yang di input ketika registrasi
	 */
	if((status >= 1 &&  status <= 3) && pin === 1) {
		current = 2;
		current_url = '/';
		btn_label = '';
		message = "Pendaftaran Bibit Kamu Sedang Dalam Proses Verifikasi. Estimasi Selesai: \n"+estimate;
		 message = message.split('\n').map(i => {
			return <div key={i}>{i}</div>;
		});
		const last= <b>{message.pop()}</b>;
		message.push(last);
	}else if (pin === 1 && status > 1) {// current 3
		current = 3;
		if(status === 9) { // rejected
			current = 2;
			done = 3;
			btn_label = 'Revisi';
			current_url = REVISI_REGISTER_PATH;
			message = (<div className="rejectedMsg"><b>Registrasi Kamu belum berhasil.</b><br />Data yang perlu kamu perbaiki:</div>);
		}
		else if (status === 10) { // rejected
			done = 2;
			btn_label = 'Revisi';
			current_url = REVISI_REGISTER_PATH;
			message = 'Registrasi Kamu gagal. Silahkan klik tombol dibawah untuk memperbaiki data Kamu.';
		}
		else if(status === 4 && (validemail === 0 || validbank === 0 || validbank === 2)) {
			current = 2;
			done = 3;
			btn_label = '';
			current_url = '';
			message = '';
		}
		else if (status === 4) { // done
			done = 1;
			btn_label = '';
			current_url = '';
			message = 'Registrasi Anda Berhasil!';
			closeflag = 1;
		}
	}
	return {
		done,
		current,
		current_url,
		btn_label,
		pin,
		status,
		islogin,
		message,
		estimate,
		validbank,
		validemail,
		err_veremail,
		loading_veremail,
		closeflag,
		isvisible,
		neverShowAgain,
		msg_reject,
		email,
	};

};

const mapDispatchToProps = (dispatch) => bindActionCreators({
	sendVerificationEmail: _sendVerificationEmail,
	setHideStatusBar: _setHideStatusBar,
	restoreStatusBar: _restoreStatusBar,
}, dispatch);

const composed = compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps)
);

export default composed(ViewRegisterAccountStatusRaw);