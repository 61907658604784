import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
	profile: null,
	status: -1,
	token: null,
	callback_url: '', 
};

const [setUserPartner] = [
	'SET_USERPARTNER',
].map(createAction);

const reducer = createReducer({
	[setUserPartner]: (state, payload) => {

		const { 
			token,
			user_partner_status,
			callback_url,
			...profile
		} = payload;
		
		const toUpdate = {};
        
		if(user_partner_status > -1) {
			toUpdate['status'] = {$set: user_partner_status};
		}
        
		if(token) {
			toUpdate['token'] = {$set: token};
		}
        
		if(profile) {
			toUpdate['profile'] = {$set: profile};
		}
		
		if(callback_url) {
			toUpdate['callback_url'] = {$set: callback_url};
		}

		return update(state, toUpdate);

	}
}, defaultState);

export {
	setUserPartner
};

export default reducer;