import 'polyfill-array-includes';
import 'array-findindex-polyfill';

import React, { Fragment, Component } from 'react';

import AppRoutes from './routes/AppRoutes';
import dispatcher from './utils/dispatcher';

import { LocaleProvider } from 'antd-mobile';
import * as Sentry from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';

import enUS from 'antd-mobile/lib/locale-provider/en_US';


import ErrorModal from './components/Global/GlobalErrorModal/IndexErrorModal';

import 'antd-mobile/dist/antd-mobile.css';
import './../src/bibit-link.css';
import './../src/common.css';

import './App.css';
// import ModalInputPin from './components/Landing/PinVerification/ModalInputPin';
import { version } from './../package.json';
import { restoreInitialUser } from './entities/user.reducer';
import Storage from './utils/Storage';
import GlobalLoader from './components/Global/GlobalLoader';

import getSafely from './utils/safely';
import parseQuery from './utils/parseQuery';

const { REACT_APP_SENTRY_ENVIRONTMENT, REACT_APP_SENTRY_DSN } = process.env;

Sentry.init({ 
	environment: REACT_APP_SENTRY_ENVIRONTMENT,
	dsn: REACT_APP_SENTRY_DSN,
	release: version,
	maxBreadcrumbs: 50,
	integrations: [new ExtraErrorData()],
});

function renderModal() {
	return (
		<Fragment>
			<ErrorModal />
			{/* <ModalInputPin /> */}
		</Fragment>
	);
}

class App extends Component {

	state = {
		ready: false
	};

	componentDidMount () {
		const search = window.location.search;
		const querystring = parseQuery(search);
		const urltoken = getSafely(['urltoken'], querystring);
		if(urltoken)
			return this.setState({
				ready: true
			});
		Storage
			.getObject('ac')
			.then(ac => {
				if(ac)
					dispatcher(restoreInitialUser())
						.then(() => {
							this.setState({
								ready: true
							});
						});
				else
					this.setState({
						ready: true
					});
					
			});
	}

	renderLoader () {
		return (
			<div className="bit-link-wrap">
				<div className="bit-link-header" />
				<div className="bit-link-content-centered">	
					<GlobalLoader/>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className="App fullheight" >
				<LocaleProvider locale={enUS}>
					{
						!this.state.ready? 
							this.renderLoader():
							<AppRoutes />
					}
				</LocaleProvider>
				{renderModal()}
			</div>
		);
	}

}

export default App;
