import React, { PureComponent, Fragment } from 'react';
import moment from 'dayjs';
import { connect } from 'react-redux';

import ReksaChartDisplay from './ReksaChartDisplay';
import { addReksadanaEntry } from './../../../../entities/reksadana.reducer';
import { handleResponseGeneral } from './../../../../utils/handleResponse';
import { serviceFetchProductsChart, serviceFetchProductsChartAum } from '../../../../services/product.services';

import { DEFAULT_PERIOD, DEFAULT_SUPPORTED_CHART_PERIOD } from './ReksaChart.constant';

const mapStateToProps = (state, ownProps) => {
	return {
		fundData: state.reksadana.byId[ownProps.symbol],
	};
};

const mapDispatchToProps = dispatch => ({
	addToReksadanaEntry: fundData => dispatch(addReksadanaEntry(fundData)),
});

const supportedChartPeriod = DEFAULT_SUPPORTED_CHART_PERIOD;

const validateSupportedChartPeriod = (cb, keyMode) => {
	return (symbol, period) => {
		const _supportedChartPeriod = supportedChartPeriod[keyMode];
		const _period = (_supportedChartPeriod.findIndex(item => item.toLowerCase() === period.toLowerCase()) > -1)? period:'1Y'; 
		cb(symbol, _period);
	};
};

const withValidatePeriod = function withValidatePeriod (Comp) {
	return (props) => {
		const {
			chartPeriod,
			supportedChartPeriod: _supportedChartPeriod
		} = props;
		const period = (
			_supportedChartPeriod && 
			_supportedChartPeriod.findIndex(item => item.toLowerCase() === chartPeriod.toLowerCase()) 
			> -1)? 
			chartPeriod:
			DEFAULT_PERIOD;
		return <Comp 
			{...props}
			chartPeriod={period}
		/>;
	};
};

const ReksaChartDisplayWithValidatePeriod = withValidatePeriod(ReksaChartDisplay);

class ReksaChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			chartLoading: false,
			chartLoadingAum: false,
			chartData: {},
			chartDataAum: {},
			chartPeriod: props.chartPeriodProps || DEFAULT_PERIOD,
			chartPeriodAum: props.chartPeriodPropsAum || DEFAULT_PERIOD,
			chartHeader: {},
			priceError: '',
			aumError: '',
			mode: 'nav',
		};
	}

	// Check if this component is still mounted
	_isMounted = false;

	optionMode = [
		'nav',
		'aum'
	];

	apiURL = {
		'nav': ({symbol, period}) => serviceFetchProductsChart({symbol, period}),
		'aum': ({symbol, period}) => serviceFetchProductsChartAum({symbol, period})
	};

	componentDidMount() {
		this._isMounted = true;
		const { symbol } = this.props;
		const { 
			chartPeriod, 
			// chartPeriodAum 
		} = this.state;
		validateSupportedChartPeriod(this.getChartData, 'nav')(symbol, chartPeriod);
		validateSupportedChartPeriod(this.getChartDataAum, 'aum')(symbol, chartPeriod);
	}

	componentDidUpdate(prevProps, prevState) {
		const { symbol } = this.props;
		const { 
			chartPeriod, 
			// chartPeriodAum 
		} = this.state;
		if (prevState.chartPeriod !== chartPeriod) {
			// this.getChartData(symbol, chartPeriod);
			// this.getChartDataAum(symbol, chartPeriod);
			validateSupportedChartPeriod(this.getChartData, 'nav')(symbol, chartPeriod);
			validateSupportedChartPeriod(this.getChartDataAum, 'aum')(symbol, chartPeriod);
		}
		// if (prevState.chartPeriodAum !== chartPeriodAum) {
		// 	this.getChartDataAum(symbol, chartPeriodAum);
		// }
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getChartData = (symbol, period) => {
		this.setState({ chartLoading: true });
		this.apiURL['nav']({symbol, period})
			.then(rawResponse => {

				const response = handleResponseGeneral(rawResponse);
				if (this._isMounted && response.data.chart.length < 2) {
					return this.setState(prevState => ({
						...prevState,
						chartLoading: false,
						chartData: {},
						chartPeriod: period,
						chartHeader: {
							...this.state.chartHeader,
							nav: {}
						},
						priceError: 'Data belum tersedia.',
					}));
				}

				if (this._isMounted) {
					const {
						chart,
						...rest
					} = response.data;
					return this.setState(prevState => ({
						...prevState,
						priceError: '',
						chartLoading: false,
						chartPeriod: period,
						chart: chart,
						chartData: {
							...prevState.chartData,
							[symbol]: response.data.chart.map(item => ({
								...item,
								date: moment.unix(item.date).toDate(),
							})),
						},
						chartHeader: {
							...this.state.chartHeader,
							nav: rest
						}
					}));
				}

			})
			.catch(err => console.error(err));
	};

	getChartDataAum = (symbol, period) => {
		this.setState({ chartLoadingAum: true });
		this.apiURL['aum']({symbol, period})
			.then(rawResponse => {

				const response = handleResponseGeneral(rawResponse);

				if (this._isMounted && response.data.chart.length < 2) {
					return this.setState(prevState => ({
						...prevState,
						chartLoadingAum: false,
						chartDataAum: {},
						chartPeriodAum: period,
						chartHeader: {
							...this.state.chartHeader,
							aum: {}
						},
						aumError: 'Data belum tersedia.',
					}));
				}

				if (this._isMounted) {
					const {
						chart, 
						...rest
					} = response.data;
					return this.setState(prevState => ({
						...prevState,
						aumError: '',
						chart: chart,
						chartLoadingAum: false,
						chartPeriodAum: period,
						chartDataAum: {
							...prevState.chartDataAum,
							[symbol]: response.data.chart.map(item => ({
								...item,
								date: moment.unix(item.date).toDate(),
							})),
						},
						chartHeader: {
							...this.state.chartHeader,
							aum: rest
						}
					}));
				}

			})
			.catch(err => console.error(err));
	}

	handleChangePeriod = period => {
		const { onChangePeriod } = this.props;
		this.setState({ chartPeriod: period });
		onChangePeriod(period);
	};

	handleChangePeriodAum = period => {
		const { onChangePeriodAum } = this.props;
		this.setState({ chartPeriodAum: period });
		onChangePeriodAum(period);
	};

	handleOpenCompare = () => {
		console.log('CLICKED');
	};

	render() {
		const { 
			chartData, 
			// chartDataAum, 
			chartPeriod, 
			// chartPeriodAum, 
			chartLoading, 
			// chartLoadingAum, 
			// chartHeader,
			priceError,
			// aumError, 
		} = this.state;
		return (
			<Fragment>

				<div className="bit-reksa-dot">
					<span></span>
					<span></span>
					<span></span>
					<span className={this.state.mode === 'nav'? "active-move active-move-left": "active-move active-move-right"}></span>
				</div>
				
				<div className="bit-reksa-holder">
					
					<div className="reksadana-chart">
						{!chartLoading && (
							<ReksaChartDisplayWithValidatePeriod
								error={priceError}
								chartData={chartData}
								chartPeriod={chartPeriod}
								onChangePeriod={this.handleChangePeriod}
								onClickCompare={this.handleOpenCompare}
								supportedChartPeriod={supportedChartPeriod['nav']}
							/>
						)}
						{chartLoading && (
							<div className="boxloader" style={{background: 'none'}}>
								<div className="spinner" />
							</div>
						)}
					</div>
				</div>
			</Fragment>
		);
	}
}

ReksaChart.defaultProps = {
	symbol: '',
	chartPeriodProps: DEFAULT_PERIOD,
	chartPeriodPropsAum: DEFAULT_PERIOD,
	onChangePeriod: () => { },
	onChangePeriodAum: () => { },
	addToReksadanaEntry: (reksadana) => { },
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ReksaChart);
