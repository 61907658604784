// import { postData } from './Parent';

import { amplitudeLogEvent, amplitudeRecordUser, amplitudeRecordUserProps, amplitudeClearRecordUser } from './AmplitudeAdapter';

/**
 * Record Custom Event 
 * @param {String} event Analytics event object 
 * @param {String} opt.eventAction Properties action 
 * @param {String} opt.eventLabel Properties label 
 * @param {int} opt.eventValue Properties value 
 */
export function recordCustomEvent({event, ...opt}) {
	/* 
	 No longer needed after sentry installation 
	*/
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push({
		event,
		...opt
	});
}

/**
 * Record an analytics event
 * @param {Object} event Analytics event object
 * @param {String} event.page Page where the event happen
 * @param {String} event.path The event happened, specified by our analyst
 * @param {String} event.param The data of the event sent to Holistic channel
 * @param {String} event.param_amp The data of the event sent to Amplitude channel
 * @param {String} event.device The device where the event happened. Can be web, ios, android
 */
export function recordEvent(event) {
	/* eslint-disable no-unused-vars */

	const {
		page,
		path,
		param_amp,
		param_branch = {},
	} = event;
	/* eslint-enable */

	if (!page || !path) {
		return console.warn('To record analytics event, page and path must be defined');
	}
		 

	amplitudeLogEvent(page + '_' + path, param_amp, param_branch);

}

export function recordUser({ id, ...props }) {
	amplitudeRecordUser('user_' + id);
	if (props) 
		amplitudeRecordUserProps(props);

}

export function clearRecordUser() {
	amplitudeClearRecordUser();
}

// for current stage, amplitude native only works on android
export function checkNativeFlag() {

	let nativeFlag = false;

	if (window.document.__deviceInfo) {
		nativeFlag = true;
	}

	return nativeFlag;

}

export default Object.assign({}, {
	recordEvent,
	recordUser,
});



