import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import SellModal from './SellModal';

import getSafely from './../../../utils/safely';

import list from './../../../services/apiconstant';

import {
	getStatusLoadingSelector
} from './../../../services/apimanager/apimanager.selector';

import {
	fetchPortfolioCategoriesDetailData
} from './../../../entities/userportfolio.reducer';

import {
	getPortfolioSelectedCategory,
	getPortfolioSelectedItems,
} from './../../../entities/userportfolio.selector';

const mapStateToProps = (state, ownProps) => {
	const {
		portfolioId,
		productIds
	} = ownProps;

	const isLoading = getStatusLoadingSelector(state, [
		list.orderSellPreview,
		list.orderSell
	]);

	const items =  getPortfolioSelectedItems(state, productIds) || [];
	let totalporto = 0;
	let _items = items.map(item => {
		totalporto = totalporto + item.marketvalue;
		return {
			...item,
			name: getSafely(['product', 'name'], item),
			type: getSafely(['product', 'type'], item),
			ojkCode: getSafely(['product', 'investment_manager', 'ojkCode'], item),
			minbuy: getSafely(['product', 'minbuy'], item),
			minsell: getSafely(['product', 'minsell'], item),
		};
	});
	return {
		portfolioId: portfolioId,
		portfolio: getPortfolioSelectedCategory(state, portfolioId),
		items: _items,
		totalporto: totalporto,
		isLoading: isLoading,
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchPortfolioCategoriesDetailData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SellModal);