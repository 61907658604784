import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import {compose} from 'redux';
import EmailStatus from './EmailStatus';
import { getUserEmail, getValidEmailStatus } from '../../entities/user.selector';

import ModalEmailVerify from './ModalEmailVerify';

class ViewEmailStatus extends Component {
	render() {
		if(!this.props.email || this.props.validemail) 
			return null;
		return (
			<Fragment>
				<ModalEmailVerify 
					renderButton={onOpen => {
						return <EmailStatus
							email={this.props.email}                
							onClick={() => {
								// this.props.history.push('/email-status');
								onOpen(); // open modal 
							}}              
						/>;
					}}
				/>
				
			</Fragment>
		);
	}
}


ViewEmailStatus.defaultProps = {
	validemail: 1,
	email: '',
};

const mapStateToProps = (state) => {
	return {
		email: getUserEmail(state),
		validemail: getValidEmailStatus(state),
	};
};

const composed = compose(
	withRouter,
	connect(mapStateToProps),
);

export default composed(ViewEmailStatus);