import React, {Component, Fragment} from 'react';
import { Modal, SearchBar, List, ActivityIndicator, InputItem, WhiteSpace, Text} from 'antd-mobile';
import { ChevronDown } from 'react-feather';
import notFound from '../../../assets/images/not-found-bank-img.svg';
import AppNavbar from './../AppNavbar';
import isNumeric  from './../../../utils/isNumeric';
import styles from './BankPicker.module.css';

const { Item } = List;

class BankPicker extends Component {

	state = {
		height: (document.documentElement.clientHeight * 2) / 4,
		selected: null
	}

	componentDidMount () {

		const {
			getLoadBank,
			banks,
			valueId
		} = this.props;

		getLoadBank();
		
		if(banks.length > 0) {
			let output = this._findBankById(banks, valueId);
			this.setState({
				selected: output
			});
		}

	}

	componentWillReceiveProps (nextProps) {

		const { selected } = this.state; 
		const { banks, valueId } = nextProps;
		const { banks: _banks, valueId: _valueId} = this.props;

		if(banks.length !== _banks.length && banks.length > 0) {
			if(!selected && !valueId) {
				this.setState({
					selected: null
				});
			}else if(valueId) {
				const bank = this._findBankById(banks, valueId);
				this.setState({
					selected: bank
				});
			}
		}
		if(valueId !== _valueId && valueId && banks.length > 0) {
			const bank = this._findBankById(banks, valueId);
			this.setState({
				selected: bank
			});
		}
	}

	componentWillUnmount () {
		this.props.changeFilter({keyword: ''});
	}

	_findBankById = (banks, id) => {
		let output = null;
		if(!banks) banks = []; 
		output = banks.find(bank => {
			if(isNumeric(id)) 
				id = parseInt(id, 10);
			return bank.id === id;
		});
		return output;
	}

	_clickItemCreator = (data) => {
		const {onClickItem, onClose, changeFilter} = this.props;
		return (e) => {
			this.setState({
				selected: data
			}, () => {
				onClickItem(data);
				changeFilter({keyword: ''});
				onClose();
			});
		};
	}

	_renderBanks = () => {
		
		const {
			banks: datas,
			// disabled,
		} = this.props;
		
		const {
			height
		} = this.state;

		const output = datas.map((data, index) => {
			const { name } = data;
			return (
				<Item
					key={index}
					onClick={this._clickItemCreator(data)}
				>
					{name}
				</Item>
			);
		});

		const style = {
			height: height,
			overflowY: 'scroll'
		};

		if(output.length === 0) {
			return (
				<div className="bank-picker">
					<div className={styles['not-found']}>
						<img src={notFound} alt="bank not found" />
						<WhiteSpace />
						<Text className={styles['title']}>Bank Tidak Tersedia</Text>
						<Text className={styles['description']}>Cek penulisan bank atau gunakan nama lain</Text>
					</div>
				</div>
			);
		}

		return (
			<div className="bank-picker">
				<List
					style={style}
				>
					{output}
				</List>
			</div>			
		);

	}

	handleOnClose = () => {
		const {onClose, changeFilter} = this.props;

		onClose();
		changeFilter({keyword: ''});

	};

	render () {

		const {
			changeFilter,
			onOpen,
			isvisible,
			loading,
			disabled,
		} = this.props;

		const {
			selected
		} = this.state;

		let name = (selected && selected.name)? selected.name: '-';

		return (
			<Fragment>
				<Modal
					popup
					visible={isvisible}
					animationType="slide-up"
					className="modal-radius bit-link-modal-bank"
				>
					<AppNavbar 
						title="Pilih Bank"
						leftContent={null}
						rightContent={<div onClick={this.handleOnClose} >Tutup</div>}
					/>
					<SearchBar
						placeholder="Cari"
						onChange={(text) => changeFilter({keyword: text})}
						cancelText=" "
						className={styles['searchbar']}

					/>
					{this._renderBanks()}
				</Modal>
				{this.props.children}
				{disabled? 
					<InputItem
						value={name}
						disabled={true}
						placeholder=""
						onChange={() => {}}
					/>:
					<Item onClick={onOpen}>
						{name} <ChevronDown size="20" color='#b5b5b5' />
					</Item>
				}
				
				<ActivityIndicator
					toast
					text="Preparing your form..."
					animating={loading}
				/>
			</Fragment>
		);
	}
}

const noop = () => console.log('not implemented yet');

BankPicker.defaultProps = {
	banks: [],
	getLoadBank: noop,
	onClickItem: noop,
	onClose: noop,
	onOpen: noop,
	valueId: '',
	isvisible: false,
	disabled: false
};

export default BankPicker;