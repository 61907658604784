import { checkNativeFlag } from './index';

import * as MoeAdapter from './MoengageAdapter';

function postData () {}

function safeUseAmplitude(cb) {
	if (window.amplitude) {
		const {
			amplitude
		} = window;
		return cb(amplitude);
	}
	return;
}

function checkAmplitudeNative() {
	let nativeFlag = false;
	if (window.document.inAmplitudeNative) {
		nativeFlag = window.document.inAmplitudeNative;
	}
	return nativeFlag;
}

export function amplitudeLogEvent(page_path, param_amp, param_branch = {}) {


	MoeAdapter.logEvent(page_path, param_amp);

}

export function amplitudeRecordUser(id) {

	MoeAdapter.moengageRecordUser(id);

}

export function amplitudeRecordUserProps(props) {

	MoeAdapter.recordUserProps(props);

}

export function amplitudeClearRecordUser() {

	let nativeFlag = checkNativeFlag();
	let amplitudeNativeFlag = checkAmplitudeNative();
	if (nativeFlag && amplitudeNativeFlag)
		return postData('_amplitudeClearRecordUser', { fn: '_amplitudeClearRecordUser', data: {} });
	
	MoeAdapter.logout();
		
	safeUseAmplitude(amplitude => {
		amplitude.getInstance().setUserId(null);
		amplitude.getInstance().regenerateDeviceId();
	});
}
