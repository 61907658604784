import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';

const defaultState = {
	pending_request: [],
	error_response: {}
};

const [
	add,
	remove,
	add_error,
	remove_error,
	remove_all_error,
] = [
	'ADD_PENDING_REQUEST',
	'REMOVE_PENDING_REQUEST',
	'ADD_ERROR_RESPONSE',
	'REMOVE_ERROR_RESPONSE',
	'REMOVE_ALLERROR_RESPONSE'
].map(createAction);

const reducer = createReducer({
	[add]: (state, url) => {
		return update(state, {
			pending_request: {$push: [url]}
		});
	},
	[remove]: (state, url) => {
		const {
			pending_request
		} = state;
		const index = pending_request.findIndex(iurl => iurl === url);
		return update(state, {
			pending_request: {$splice: [[index, 1]]}
		});
	},
	[add_error]: (state, error) => {
		const {
			url
		} = error;
		return update(state,  {
			error_response: {
				[url]: {$set: error}	
			}
		});
	},
	[remove_error]: (state, url) => {
		return update(state, {
			error_response: {$unset: [url]}
		});
	},
	[remove_all_error]: (state) => {
		return update(state, {
			error_response: {$set: {}}
		});
	}
}, defaultState);

export {
	add,
	remove,
	add_error,
	remove_error,
	remove_all_error,
};

export default reducer;