import React, { Component, Fragment } from 'react';
import * as Sentry from '@sentry/browser';
import { X } from 'react-feather';

import {Modal, Slider, Checkbox, ActivityIndicator, Toast} from 'antd-mobile';
import NumberFormat from 'react-number-format';
import history from './../../../utils/history';
import getSafely from './../../../utils/safely';
import {
	numberToGeneralValueFormat
} from './../../../utils/string-helper';

import {
	handleResponseGeneral
} from './../../../utils/handleResponse';

import SellReksa from './../../../assets/images/sell-reksa.svg';
import LinkAja from './../../../assets/images/link-aja.svg';

import store from './../../../Store';
import {
	openErrorModal
} from './../../Global/GlobalErrorModal/errorModal.reducer';
import GlobalOtpModal from '../../Global/GlobalOtpModal/GlobalOtpModal';

import ModalInsufficentFund from './ModalInsufficentFund';

import './SellModal.css';
import SellModalWarning from './SellModalWarning';
import InvestmentCompanyLogo from '../InvestmentCompanyLogo';
// import GlobalDocumentsModal from '../../Global/GlobalDocumentsModal/GlobalDocumentsModal';

import { servicePostOrderSell, servicePostSellPreview } from './../../../services/product.services'; 
import SwitchBankModal from '../SwitchBank/SwitchBankModal';
// import { dispatchErrorModal } from '../../../utils/dispatcher';

async function postSellPreview ({ amount, products, type, categoryid, code}) {
	const toSend = {
		symbol: products[0],
		amount,
		type,
		categoryid: categoryid
	};
	if(code) 
		toSend.code = code;
	return servicePostSellPreview(toSend)
		.then(response => handleResponseGeneral(response))
		.catch(err => {
			const message = getSafely(['response', 'data','message'], err);
			const _type = getSafely(['response', 'data','type'], err);
			store.dispatch(openErrorModal({
				type: _type,
				message: message
			}));
		}); 
}

async function postSell ({ amount, products, type, categoryid, token, code, slotbank}) {
	const toSend = {
		symbol: products[0],
		amount,
		type,
		categoryid: categoryid,
		token,
		bank: slotbank,
	};
	if(code) 
		toSend.code = code;
	return servicePostOrderSell(toSend)
		.then(response => handleResponseGeneral(response));
}

const AgreementRekeningPribadi = `
Saya menyetujui penjualan reksa dana yang tertera di halaman ini. Saya mengerti bahwa total dana yang dicairkan merupakan perkiraan. Dana yang didapat bisa berubah sesuai dengan harga penutupan reksa dana di hari penjualan. Dana yang dicairkan akan kamu terima maksimum dalam 7 hari bursa.
`;
const AgreementRekeningLinkaja = `
Saya menyetujui penjualan reksa dana yang tertera di halaman ini. Saya menyetujui bahwa dana akan dicairkan dalam bentuk saldo uang elektronik di aplikasi LinkAja.
`;

class SellModal extends Component {
	constructor (props) {
		
		super(props);

		this.state = {

			sell1: false,
			sell2: false,
			sell3: false,

			step: 0,

			totalscale: 30,
			currentscale: 30,
			current: this.props.totalporto,
			totalporto: this.props.totalporto,

			tempPreview: null,

			agreementCheck: false,
			token: '',

			isInsufficent: false,
			code: '',
		};

	}
	componentDidMount() {
		const {
			portfolioId
		} = this.props;
		if(portfolioId) {
			this.props.fetchPortfolioCategoriesDetailData(portfolioId);
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const {
			sell1,
		} = this.state;
		const {
			sell1: _sell1,
		} = prevState;
 
		if(sell1 !== _sell1 && sell1) {
			this.setState({
				totalporto: this.props.totalporto,
				current: this.props.totalporto,
				agreementCheck: false,
				totalscale: 30,
				currentscale: 30,
			});
		}
	}
	log = (name) => {
		return (value) => {
			console.log(`${name}: ${value}`);
		};
	}
	checkIfAmountIsValid = () => {

		const {
			items
		} = this.props;
		
		const { current } = this.state;
		
		let isValid = true;

		items.some(item => {

			let minSell = getSafely(['minsell'], item) || 0;
			let marketvalue = getSafely(['marketvalue'], item);

			const disabled = [

				// current value is not valid or empty
				current === null || current === undefined || isNaN(current) || !current,
				
				// current value less than minimum sell
				current < minSell,
				
				// current value more than market value
				current > marketvalue,

			].some(item => item);

			// current value same as market value (sell all)
			const isSellAll = (Number(current) === Number(marketvalue)) || this.state.currentscale === this.state.totalscale;

			if(disabled && !isSellAll) {
				isValid = false;
				return true;
			}
			
			return false;
			
		});
		
		return isValid;
	
	}
	onClose = () => {
		this.setState({
			step: 0,
		});
	}
	
	closeModal = modalType => () => {

		return this.setState({
			step: 0,
		});

	};
	openModal = modalType => () => {
		if (modalType === "sell-1") {

			return this.setState({
				step: 1,
				code: '',
				totalscale: 30,
				currentscale: 30,
				current: this.props.totalporto,
				totalporto: this.props.totalporto,
			});

		} else if (modalType === "sell-2") {
			
			const item = this.props.items[0];
			
			let amount = (this.state.totalscale === this.state.currentscale)? this.state.totalporto :this.state.current;

			const toPreview = {
				products: [item.product.symbol],
				amount: amount,
				type: item.product.type,
				categoryid: this.props.portfolioId,
			};

			if(this.state.code) 
				toPreview.code = this.state.code;
			return postSellPreview(toPreview).then(resp => {
				if(resp) {
					
					const {
						data
					} = resp;
					console.log('data', data);
					this.setState({
						sell1: false,
						sell2: true,
						step: 2,
						tempPreview: data
					});
					
				}
			});
		} else if (modalType === "sell-3") {
			
			return this.setState({
				step: 3
			});
			
		} else if (modalType === "sell-4") {
			
			const item = this.props.items[0];

			const user_bank_slot = getSafely(['tempPreview', 'transfer_destination', 'user_bank_slot'], this.state);
			
			let amount = (this.state.totalscale === this.state.currentscale)? this.state.totalporto :this.state.current;

			const toSell = {
				products: [item.product.symbol],
				amount: amount,
				type: item.product.type,
				categoryid: this.props.portfolioId,
				token: this.state.token,
			};

			if(user_bank_slot) 
				toSell.slotbank = user_bank_slot;

			if(this.state.code) 
				toSell.code = this.state.code;
			
			postSell(toSell)
				.then(response => {
					if(!response) {
						toSell.response = response;
						throw new Error('Response from network is empty');
					}
					const {
						data,
						// message,
						type
					} = response;
					if(type === "failed_connect_linkaja") {
						const {code} = data;
						return this.setState({
							isInsufficent: true,
							code: code,
						});
					}
					this.setState({
						step: 0,
					});
					Toast.success('Penjualan berhasil');
					setTimeout(() => {
						history.push('/history-transaction');
					}, 2000);
					return this.setState({
						step: 4
					});
				})
				.catch(err => {
					const message = getSafely(['response', 'data','message'], err);
					const _type = getSafely(['response', 'data','type'], err);
					store.dispatch(openErrorModal({
						type: _type,
						message: message
					}));
					Sentry.withScope(function(scope) {
						scope.setLevel('error');
						scope.setExtra('params', toSell);
						Sentry.captureException(err);
					});
				})
				.finally(() => {
					this.props.onCallbackAfterSell();
				});

		}
	};
	handleClickInsufficent = () => {
		const openModal = this.openModal('sell-2');
		openModal();
		this.setState({
			isInsufficent: false,
		});
	}
	handleChange = value => {
		const customScale = value/this.state.totalscale;
		const currentAmount = customScale * this.state.totalporto;
		this.setState({
			current: currentAmount,
			currentscale: value,
		});
	}
	handleInputChange = values => {
		let {
			floatValue: _floatValue,
		} = values;
		if(!_floatValue) {
			_floatValue = 0;
		}
		const item = this.props.items[0];
		_floatValue = (_floatValue > item.marketvalue)? item.marketvalue: _floatValue;
		const customValue = _floatValue? _floatValue / this.state.totalporto: _floatValue;
		const currentScale = this.state.totalscale * customValue;
		this.setState({
			currentscale: Math.floor(currentScale),
			current: _floatValue,
		});
	}
	renderButton = () => {
		return this.props.renderButton(this.openModal);
	}
	renderItems = () => {
		return (
			<Fragment>
				{this.props.items.map(item => {
					const {
						product: {
							name,
							type,
						},
						ojkCode,

					} = item;
					return (
						<div key={name} className="bit-reksa-item bit-reksa-blue-wrap">
							<div className="bit-reksa-pic">
								<InvestmentCompanyLogo 
									type="investment-manager"
									size="square"
									ojkCode={ojkCode}
								/>
							</div>
							<div>
								<h3>{name}</h3>
								<p>{type}</p>
							</div>
						</div>
					);
				})}
			</Fragment>
		);	
	}
	handleChangeChecked = ({target}) => {
		const {
			checked
		} = target;
		this.setState({
			agreementCheck: checked
		});
	}
	renderFirstModal = (index) => {
		const isOpen = this.state.step === index;
		return (
			<Modal
				key={index}
				popup
				visible={isOpen}
				onClose={this.closeModal('sell1')}
				animationType="slide-up"
				isOpen={isOpen} 
				onRequestClose={this.closeModal("sell-1")}
				className="bit-link-buy-modal"
			>
				<div className="bit-link-modal-header">
					<div>
						<h3>Penjualan Reksa Dana</h3>
					</div>
					<div>
						<X color="#666" size={16} onClick={this.closeModal('sell1')}/>
					</div>	
				</div>	 
				<div className="bit-link-card margin-top-20">
					{this.renderItems()}
				</div>
				<div className="bit-link-input-slide">  
					<p>Berapa yang ingin kamu jual?</p>
					<div>
						<label>Rupiah</label>
						<NumberFormat
							getInputRef={el => (this.amountInput = el)}
							value={this.state.current}
							defaultValue={this.props.totalporto}
							pattern="\d*"
							inputMode="numeric"
							thousandSeparator
							decimalScale={0}
							prefix=""
							placeholder={`Minimal Penjualan Rp`}
							onValueChange={this.handleInputChange}
							// onAfterChange={(e) => {
							// console.log('after change', e);
							// }}
						/>  
						<div className="bit-link-slide">  
							<Slider
								style={{ marginLeft: 30, marginRight: 30 }}
								defaultValue={this.state.totalscale}
								value={this.state.currentscale}
								min={0}
								max={this.state.totalscale}
								onChange={this.handleChange}
								// onAfterChange={this.log('afterChange')}
							/>
						</div>	
					</div>  
				</div>	  
				<button 
					disabled={!this.checkIfAmountIsValid() || this.props.isLoading}
					className={this.checkIfAmountIsValid() && !this.props.isLoading?"bit-link-btn-red-full":'bit-link-btn-red-full disable'} 
					onClick={this.openModal("sell-2")}
				>Jual</button>
			</Modal> 
		);
	}

	_isAmountAdjust = () => {
		const allocated = getSafely(['allocated'], this.state.tempPreview);
		const ordered = getSafely(['ordered'], this.state.tempPreview);
		if(Math.round(allocated) !== Math.round(ordered)) 
			return true;
		return false;
	}
	renderSecondModal = (index) => {
		const {
			// sell2,
			tempPreview,
		} = this.state;
		const isOpen = this.state.step === index;
		const items = getSafely([
			'items'
		], tempPreview);

		// const symbols = this.props.items.map(_item => _item.symbol || getSafely(['product', 'symbol'], _item));

		let item = null;

		if(items && items[0]) {
			item = items[0];
		}

		const product = getSafely(['preview', 'product'], item);
		const ordered = getSafely(['ordered'], tempPreview);
		const allocated = getSafely(['allocated'], this.state.tempPreview);
		const payment_type = getSafely([
			'transfer_destination', 
			'payment_type',
		], tempPreview);

		const bank_source_name = getSafely(['bank_source', 'name'], tempPreview);
		const transfer_destination_bank_name = getSafely(['transfer_destination', 'bank', 'name'], tempPreview);
		const transfer_destination_bank_account = getSafely(['transfer_destination', 'bank_account'], tempPreview);
		const transfer_destination_bank_number = getSafely(['transfer_destination', 'bank_number'], tempPreview);
		const user_bank_slot = getSafely(['tempPreview', 'transfer_destination', 'user_bank_slot'], this.state);


		return (
			<Modal
				key={index}
				popup
				visible={isOpen}
				onClose={this.closeModal('sell2')}
				animationType="slide-up"
				isOpen={isOpen} 
				onRequestClose={this.closeModal("sell-2")}
				className="bit-link-buy-modal-confirmation"
			>
				<div className="bit-link-modal-header">
					<div>
						<h3>Penjualan Reksa Dana</h3>
					</div>
					<div>
						<X color="#666" size={16} onClick={this.closeModal('sell2')}/>
					</div>	
				</div>	 
				<div className="bit-link-payment-wrap">
					<div className="bit-link-reksa-product">  
						<div className="bit-link-payment-row bit-link-border-vertical">
							<div>{product}</div>
							<div className="font-bold">{`Rp ${numberToGeneralValueFormat(this._isAmountAdjust() ? allocated : ordered )}`}</div>
						</div>
					</div>	
					<div className="bit-link-payment-row">
						<div>Komisi Penjualan</div>
						<div className="font-bold">Gratis</div>
					</div>
					{this._isAmountAdjust() && <div className="bit-link-payment-row">
						<SellModalWarning />
					</div>}
					<div className="bit-link-payment-row bit-link-total">
						<div>Total</div>
						<div>{`Rp ${numberToGeneralValueFormat(this._isAmountAdjust() ? allocated : ordered )}`}</div>
					</div>
				</div> 
				{payment_type === 0 && <div className="bit-link-sell-desc">
					<p>Hasil penjualan akan di kirimkan ke <span className="font-bold">Rekening Bank</span> kamu.</p>
					<div className="pemilik-rekening-box bit-margin-top-min-5">
						<div className="bit-first-rek-box">
							<div className="pemilik-rekening-title">
									Sumber Rekening
							</div>
							<div>
								<span className="bit-link-bank">
									{bank_source_name}
								</span>
							</div>
						</div>
						<div className="bit-last-rek-box">
							<SwitchBankModal
								isSell={true}
								selected={user_bank_slot}
								onAfterSwitch={selected => {
									const updatedObject = SwitchBankModal.updateUserBank(this.state.tempPreview, selected);
									// tempPreview
									this.setState({
										tempPreview: updatedObject
									});
								}}
							/>
							<div className="pemilik-rekening-title">
									Rekening Kamu
							</div>
							<div>
								<span className="bit-link-bank">
									{transfer_destination_bank_name}
								</span>
								<div className="bit-link-bank-owner">{transfer_destination_bank_account}</div>
								<div className="bit-link-bank-number">{transfer_destination_bank_number}</div>
							</div>
						</div>	
					</div>  
				</div>}	
				{payment_type === 1 && <div className="bit-link-sell-desc">
					<p>Hasil penjualan akan di kirimkan ke akun <span className="font-bold">LinkAja</span> kamu.</p>
					<div className="bit-link-sell-flex">
						<div><img src={LinkAja} alt="link aja"/> Link Aja</div>	  
						<div>
							<h3>{transfer_destination_bank_number}</h3>
							<p>{transfer_destination_bank_account}</p>
						</div>	
					</div>  
				</div>} 
				
				<Checkbox	
					className="confirmRadio"
					onChange={this.handleChangeChecked}
				>	
					<p>
						{`
							${payment_type < 1? AgreementRekeningPribadi: AgreementRekeningLinkaja} 
						`}
					</p>
				</Checkbox>
				{/* <div className="bit-link-prospectus">
					<GlobalDocumentsModal symbols={symbols} />
				</div> */}
				<button disabled={this.state.agreementCheck? false: true} className={this.state.agreementCheck?"bit-link-btn-red-full":"bit-link-btn-red-full disable"} onClick={this.openModal("sell-3")}>Konfirmasi</button>
			</Modal>
		);
	}
	renderThirdModal = (index) => {
			
		const isOpen = this.state.step === index;
		
		const item = this.props.items[0];
		const symbol = getSafely(['product', 'symbol'], item);
		const type = getSafely(['product', 'type'], item);
		
		const toSell = {
			products: [symbol],
			amount: this.state.current,
			type: type,
			categoryid: this.props.portfolioId,
		};

		return <GlobalOtpModal
			key={index}
			isOpen={isOpen}
			payload={{
				...toSell
			}}
			onClose={() => {
				this.setState({
					step: 0
				});
			}}
			onSubmit={({token}) => {
				this.setState({
					token: token 
				});
				const openModal4 = this.openModal('sell-4');
				openModal4();
			}}
			onCatchError={err => {
				this.setState({
					step: 0
				});
			}}
		/>;
	
	}
	renderStepModal = () => {
		const pipe = [
			this.renderFirstModal,
			this.renderSecondModal,
			this.renderThirdModal,
		].map((fn, index) => fn(index + 1));
		return pipe;
	}
	render() {
		return (
			<Fragment>
				{this.renderButton()}
				{this.renderStepModal()}
				<ModalInsufficentFund 
					visible={this.state.isInsufficent} 
					onClick={this.handleClickInsufficent}
					onClose={this.onClose}
				/>
				<ActivityIndicator
					toast
					text={'Permintaan sedang di proses'}
					animating={this.props.isLoading}
				/>
			</Fragment>
		);
	}
}

SellModal.defaultProps = {
	renderButton: (openModal) => {
		return (
			<div onClick={openModal("sell-1")}>
				<img src={SellReksa} alt="link aja"/>
				<p>Jual Reksadana</p>
			</div>
		);
	},
	portfolioId: 0,
	portfolio: null,
	items: [],
	isLoading: false,
	onCallbackAfterSell: () => {},
};

export default SellModal;