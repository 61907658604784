import { bindActionCreators } from "redux";
import BankPicker from "./BankPicker";
import { connect } from "react-redux";

import {
	getLoadBank,
	changeFilter
} from './bankPicker.reducer';

const mapStateToProps = state => {
	let {
		bankPicker: {
			is_fetching: loading,
			err,
			data: banks,
			keyword
		}
	} = state;

	let _banks = banks;

	if(keyword !== '')
		_banks = banks.filter(item => {
			let { name } = item;
			if(typeof name.toLowerCase === 'function')
				name = name.toLowerCase();
			if(typeof keyword.toLowerCase === 'function')
				keyword = keyword.toLowerCase();
			if(name.includes(keyword)) return true;
			return false;
		});

	const bankslength = (_banks)? _banks.length: 0;

	return {
		loading,
		err,
		banks: _banks,
		bankslength,
		keyword
	};
};

const mapStateToDispatch = dispatch => bindActionCreators({
	getLoadBank,
	changeFilter
}, dispatch);

export default connect(mapStateToProps, mapStateToDispatch)(BankPicker);