/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import OnProccess from './../../assets/images/bankOnProcess.svg';

class ChangeBankInfo extends Component {
	  
	  render() {
	  	return (
	  		<div className="bit-link-main-wrap bit-change-bank-setting">
	  			<div className="bit-link-main-header">
				  	<div/>
	  				<div>Add Bank Account</div>
	  				<div/>
	  			</div>
	  			<div className="bit-link-main-content">
	  				<div className="bit-bank-info">
						  <img src={OnProccess} alt=""/>
						  <h3>Sedang Dalam Proses</h3>
						  <p>Kami sedang memproses permohonan perubahan data bank kamu</p>
	  				</div>	
	  				<a href="https://www.linkaja.id/home/dashboard" className="bit-link-btn-red-full margin-top-20">Kembali ke Home</a>
	  			</div>
	  		</div>
	  	);
	  }
} 

export default (ChangeBankInfo);