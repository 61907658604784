
export const orderStatus = {
	wait_payment: 'Menunggu Pembayaran',
	conf_payment: 'Pembayaran Sedang Diverifikasi',
	process: 'Pembayaran Telah Diterima',
	waiting : 'Menunggu Verifikasi Manajer Investasi',
	waiting_unit : 'Menunggu Verifikasi Manajer Investasi',
	incoming: 'Verifikasi Manajer Investasi Berhasil',
	accept: 'Pembelian Berhasil',
	reject_sinvest: 'Menunggu Verifikasi Bank Kustodian',
	reject_cs: 'Pesanan Ditolak',
	expire: 'Expired',
};

export const orderStatusSell = {
	conf_payment: 'Order Jual Diterima',
	process: 'Order Jual Diterima',
	waiting: 'Menunggu Verifikasi Manajer Investasi',
	waiting_unit: 'Menunggu Verifikasi Manajer Investasi',
	incoming: 'Verifikasi Manajer Investasi Berhasil',
	accept: 'Order Jual Berhasil',
	reject_sinvest: 'Menunggu Verifikasi Manajer Investasi',
	reject_cs: 'Penjualan Ditolak',
	expire: 'Expired',
};

export const orderStatusReferral = {
	wait_payment: 'Cashback Reksa Dana Sedang Diproses',
	conf_payment: 'Cashback Reksa Dana Sedang Diproses',
	process: 'Pembelian Cashback Reksa Dana Disetujui',
	waiting : 'Menunggu Verifikasi Manajer Investasi',
	waiting_unit : 'Menunggu Verifikasi Manajer Investasi',
	incoming: 'Verifikasi Manajer Investasi Berhasil',
	accept: 'Pembelian Berhasil',
	reject_sinvest: 'Menunggu Verifikasi Bank Kustodian',
	reject_cs: 'Pesanan Ditolak',
	expire: 'Expired',
};

export const orderStatusVoucherCashback = {
	wait_payment: 'Cashback Voucher Sedang Diproses',
	conf_payment: 'Cashback Voucher Sedang Diproses',
	process: 'Pembelian Cashback Voucher Disetujui',
	waiting : 'Menunggu Verifikasi Manajer Investasi',
	waiting_unit : 'Menunggu Verifikasi Manajer Investasi',
	incoming: 'Verifikasi Manajer Investasi Berhasil',
	accept: 'Pembelian Berhasil',
	reject_sinvest: 'Menunggu Verifikasi Bank Kustodian',
	reject_cs: 'Pesanan Ditolak',
	expire: 'Expired',
};

export const orderStatusPackagedPayment = {
	wait_payment: 'Menunggu Pembayaran',
	conf_payment: 'Pembayaran Berhasil Diterima',
	process: 'Pembayaran Telah Diterima',
	waiting : 'Menunggu Verifikasi Manajer Investasi',
	waiting_unit : 'Menunggu Verifikasi Manajer Investasi',
	incoming: 'Verifikasi Manajer Investasi Berhasil',
	accept: 'Pembelian Berhasil',
	reject_sinvest: 'Menunggu Verifikasi Bank Kustodian',
	reject_cs: 'Pesanan Ditolak',
	expire: 'Expired',
};