import * as Sentry from '@sentry/browser';
import { formatUploadFileName, createBase64UploadPromise } from './../../utils/Aws';
import getSafely from './../../utils/safely';
import { handleResponseGeneral, handleResponseAuthorization } from './../../utils/handleResponse';

import history from './../../utils/history';
import { servicePostRegisterProfile } from '../../services/user.services';

import { getUserId } from '../../entities/user.selector';
import { recordCustomEvent } from '../../utils/Analytics';
import { updateEmail } from '../../entities/user.reducer';

function changeInputIncrement (out) {
	return out + 1;
}

function validURL(str) {
	var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
	  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
	  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
	  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
	  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
	  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
	return !!pattern.test(str);
}

export function submitRegister (values) {
	return (dispatch, getState) => {
		const state = getState();
		const userid = getUserId(state);
		const {
			email,
			education,
			earning: income,
			source: income_source,
			bank,
			bank_account_number,
			bank_account_name,
			ktp: file_identity,
			selfie: file_edd,
			signatureBase64: file_signature,
			identity,
			source_other: income_source_other,
		} = values;

		let dataSignatureP = null;
		let bypassSignature = false;

		const KEY_NAME = 'signature';

		if(!validURL(file_signature)) {
			
			// let fileName = 'signature.jpeg';
			let name_signature = formatUploadFileName(KEY_NAME);
			// name_signature = 'registrations/'+ userid + '/' + name_signature;
			dataSignatureP = createBase64UploadPromise(
				name_signature,
				file_signature, 
				false,
				{
					uploadfor: 'signature',
					code: userid
				}
			);

		}else {

			bypassSignature = true;
			dataSignatureP = Promise.resolve({
				url: file_signature
			});

		}

		return dataSignatureP.then(response => {
				
			const signatureResponse = handleResponseGeneral(response);

			let _file_signature = (bypassSignature)? 
				file_signature:
				getSafely(['data', 'url'], signatureResponse);
		
			const toSend = {
				email,
				education: changeInputIncrement(education),
				income: changeInputIncrement(income),
				income_source: changeInputIncrement(income_source),
				bank,
				identity,
				bank_account_number,
				bank_account_name,
				file_identity,
				file_edd,
				file_signature: _file_signature,
				income_source_other,
			};
		
			if(income_source_other)
				toSend['income_source_other'] = income_source_other;

			return servicePostRegisterProfile(toSend);

		}).then(payload => {

			return handleResponseAuthorization(payload)
				.then(dataResponse => {
					Sentry.withScope(function(scope) {
						scope.setLevel('info');
						scope.setExtra('params', {
							bank_account_name,
						});
						Sentry.captureMessage('register success');
					});
					const pin = getSafely(['pin'], dataResponse);
					dispatch(updateEmail(email));
					if(!pin) {
						history.push('/input-pin?register=true');
					}else {
						history.push('/index-home');
					}
					return true;
				});

			// console.log('response register ', dataResponse);
	

			// dispatch(setUser({
			// 	profile
			// }));

		}).catch(err => {
			console.error(err);
			recordCustomEvent({
				event: "bibit.log",
				eventAction: "window.error",
				eventLabel: "register_revision_submit_failed",
				eventValue: 'something is failed',
			});
			Sentry.withScope(function(scope) {
				scope.setLevel('error');
				scope.setExtra('payload', {
					file_identity, 
					file_edd,
					file_signature,
				});
				Sentry.captureMessage(err);
			});
		});

	};
}