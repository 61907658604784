import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { startAppInitLoad } from '../Landing/LandingVerification/ViewLandingVerification.reducer';

import GlobalLoader from '../Global/GlobalLoader';
import {factoryRedirect} from '../Global/GlobalRedirect';


import ViewLandingRegister from '../Landing/LandingVerification/ViewLandingRegister';
import ViewConnectAccount from '../Landing/LandingVerification/ViewConnectAccount';


import list from '../../services/apiconstant';
import { isLoadingSelector, isErrorSelector } from '../../services/apimanager/apimanager.selector';


import LogoBibit from './../../assets/images/logo-bibit.svg';
import { getPartnerStatus } from '../../entities/userpartner.selector';
import GlobalHeader from '../Global/GlobalHeader/GlobalHeader';

const GlobalRedirect = factoryRedirect([
	{
		status: 0,
		target: ViewLandingRegister,
	}, 
	{
		status: 1, // register not completed 
		target: ViewConnectAccount,
	},
	{
		status: 2, // already registered but never login 
		target: ViewConnectAccount,
	},
	{
		status: 3,
		target: '/index-home',
	},
	{
		status: 4,
		target: '/index-home',
	}
]);

class Auth extends Component {
	state = {
		isloaded : false
	};
	componentDidMount () {
		const {status} = this.props;
		if(status > -1) {
			this.setState({
				isloaded: true
			});
		}
	}
	componentDidUpdate (prevProps, prevState) {
		const {
			status
		} = prevProps;
		if(this.props.status !== status && this.props.status > -1) {
			this.setState({
				isloaded: true
			});
		}
	}
	renderEntry = () => {
		const {
			isloading,
		} = this.props;
		return (
			<div>
				<div className="bit-link-wrap">
					<div className="bit-link-header">
						<GlobalHeader />
					</div>
					<div className="bit-link-register">
						<div className="bit-link-title-reg">
							<h5>Reksa Dana</h5>
							<h1>Mulai Investasi <br />Sekarang</h1>
						</div>    
						<div className="bit-link-content-centered">		
							{isloading && 
								<GlobalLoader 
									label="Pengecekan akun" 
								/>
							}
							{!isloading && 
								<GlobalRedirect 
									status={this.props.status} 
								/>
							}
						</div>
					</div>
					<div className="bit-link-footer">
						<div>Powered by</div> 
						<div><img src={LogoBibit} alt="logo bibit" /></div>
					</div>
				</div>
			</div>
		);
	};
	render () {

		const { status, whitelist } = this.props;

		const status_whitespace = whitelist.findIndex(item => {
			const bool = item === status;
			return bool;
		});
		return (
			<div>
				{	
					(!this.state.isloaded)?
						this.renderEntry():
						(status_whitespace < 0)?
							this.renderEntry():
							this.props.onRender({...this.props})
				}
			</div>
		);
	}
}

Auth.defaultProps = {
	onRender: () => {},
	whitelist: [],
};

const mapStateToProps = state => {

	const {
		userpartner
	} = state;

	return {
		...userpartner,
		status: getPartnerStatus(state),
		isloading: isLoadingSelector(state, list.partner_key_check),
		err: isErrorSelector(state, list.partner_key_check),
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	startAppInitLoad
}, dispatch);

const PartnerAuth = connect(mapStateToProps, mapDispatchToProps)(Auth);

const withPartnerStatusHOC =  ({whitelist, blacklist}) => (Comp) => (props) => {
	return <PartnerAuth 
		{...props}
		whitelist={whitelist}
		blacklist={blacklist}
		onRender={_props => {
			return <Comp 
				{..._props}
			/>;
		}} 
	/>;
};

export default withPartnerStatusHOC;