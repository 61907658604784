import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import LogoBibit from './../../assets/images/logo-bibit.svg';
import GlobalHeader from '../Global/GlobalHeader/GlobalHeader';

class ConfirmationPin extends Component {
	render() {
		return (
			<div>
				<div className="bit-link-wrap">
					<div className="bit-link-header">
						<GlobalHeader />
					</div>
					<div className="bit-link-register">
						<div className="bit-link-title-reg">
							<h5>Reksa Dana</h5>
							<h1>Konfirmasi Pin Bibit</h1>
						</div>    
						<div className="bit-link-pin-center">
							<input className="loginborder draw" placeholder=" " id="input-1" />
							<input className="loginborder draw" placeholder=" " id="input-2" />
							<input className="loginborder draw" placeholder=" " id="input-3" />
							<input className="loginborder draw" placeholder=" " id="input-4" />
						</div>
						<p>PIN akan digunakan untuk mengamankan transaksimu</p>
						<Link to={`/index-home`}>
							<button className="bit-link-btn-red-full margin-top-15">Proses</button>
						</Link>	
					</div>
					<div className="bit-link-footer">
						<div>Powered by :</div> 
						<div><img src={LogoBibit} alt="logo bibit" /></div>
					</div>
				</div>
			</div>
		);
	}
} 

export default ConfirmationPin;