import Api from './../utils/newapimanager';
import list from './apiconstant';

const api = Api;

export const serviceSyariahEnable = () => 
	api.post(list.userPreferenceUpdate, {linkaja_syariah: 1});


export const serviceSyariahDisable = () => 
	api.post(list.userPreferenceUpdate, {linkaja_syariah: 0});
