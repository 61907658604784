import { createSelector } from 'reselect';

const currentUrl = manipulatedState => manipulatedState.url; 
const pendingRequestSelector = state => state.apimanager.pending_request;
const errorResponseSelector = state => state.apimanager.error_response;

const _isLoadingSelector = createSelector(currentUrl, pendingRequestSelector, (url, pending_request) => {
	return pending_request.findIndex(req => req.indexOf(url) > -1) > -1;
});

export const isLoadingSelector = (state, url) => {
	const inputSelector = {
		apimanager: state.apimanager,
		url: url
	};
	return _isLoadingSelector(inputSelector);
};

export const getStatusLoadingSelector = (state, urls) => {
	return urls.some(url => {
		return isLoadingSelector(state, url);
	});
};

const _isErrorSelector = createSelector(currentUrl, errorResponseSelector, (url, error_response) => {
	const key = Object.keys(error_response).find(name => name.indexOf(url) > -1);
	return (error_response[key])? error_response[key]: null;
});

export const isErrorSelector = (state, url) => {
	const inputSelector = {
		apimanager: state.apimanager,
		url: url,
	};
	return _isErrorSelector(inputSelector);
};

export const getStatusErrorSelector = (state, urls) => {
	return urls.map(url => {
		return isErrorSelector(state, url);
	});
};