import * as Sentry from '@sentry/browser';
import { formatUploadFileName, createBase64UploadPromise } from './../../utils/Aws';
import { 
	handleResponseGeneral, handleResponseAuthorization, 
} from './../../utils/handleResponse';
import getSafely from './../../utils/safely';
import history from './../../utils/history';
import { servicePostRegisterProfile } from '../../services/user.services';
import { getUserId } from '../../entities/user.selector';
import { recordCustomEvent } from '../../utils/Analytics';

function changeInputIncrement (out) {
	return out + 1;
}

export function submitRegister (values) {
	return (dispatch, getState) => {
		const state = getState();
		const userid = getUserId(state);
		const {
			email,
			education,
			earning: income,
			source: income_source,
			bank,
			bank_account_number,
			bank_account_name,
			ktp: file_identity,
			selfie: file_edd,
			signatureBase64: file_signature,
			identity,
			source_other: income_source_other,
		} = values;

		let dataSignatureP = Promise.resolve();

		const KEY_NAME = 'signature';

		if(file_signature) {
			recordCustomEvent({
				event: "bibit.log",
				eventAction: "window.error",
				eventLabel: "signature_isfilled"
			});

			// let fileName = 'signature.jpeg';

			let name_signature = formatUploadFileName(KEY_NAME);
			
			// name_signature = PREFIX_PATH_UPLOAD + '/'+ userid + '/' + name_signature;
			
			dataSignatureP = createBase64UploadPromise(
				name_signature,
				file_signature, 
				false,
				{
					uploadfor: 'signature',
					code: userid
				}
			);
		} else {
			recordCustomEvent({
				event: "bibit.log",
				eventAction: "window.error",
				eventLabel: "signature_isempty"
			});
		}

		let stringToSend = '';

		return dataSignatureP.then(response => {

			recordCustomEvent({
				event: "bibit.log",
				eventAction: "window.error",
				eventLabel: "signature_submit_success",
			});
				
			const signatureResponse = handleResponseGeneral(response);

			const _file_signature = getSafely(['data', 'url'], signatureResponse);

			const toSend = {
				email,
				education: changeInputIncrement(education),
				income: changeInputIncrement(income),
				income_source: changeInputIncrement(income_source),
				bank,
				identity,
				bank_account_number,
				bank_account_name,
				file_identity,
				file_edd,
				file_signature: _file_signature,
			};
		
			stringToSend = JSON.stringify(toSend);

			if(income_source_other)
				toSend['income_source_other'] = income_source_other;

			return servicePostRegisterProfile(toSend);

		}).then(payload => {
			
			recordCustomEvent({
				event: "bibit.log",
				eventAction: "window.error",
				eventLabel: "register_submit_success",
				eventValue: stringToSend || 'fallback value success register'
			});

			return handleResponseAuthorization(payload)
				.then(dataResponse => {
					Sentry.withScope(function(scope) {
						scope.setLevel('info');
						scope.setExtra('params', dataResponse);
						Sentry.captureMessage('register success');
					});
					history.push('/input-pin?register=true');
					return true;
				});

		}).catch(err => {
			console.error(err);
			recordCustomEvent({
				event: "bibit.log",
				eventAction: "window.error",
				eventLabel: "register_submit_failed",
				eventValue: stringToSend || 'something is failed',
			});
		});
		
	};
}