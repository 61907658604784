import React, { Component, Fragment } from 'react';
import { Field } from 'formik';
import CodeInput from 'react-code-input';
import CountdownTimer from './CountdownTimer';
import Wa from './Wa';
import getSafely from './../../utils/safely';

const MAX_FIELDS = 4;

class OtpInput extends Component {
	state = {
		reset: 0
	}
	componentDidMount () {
		this._bindOnFocus();
		if (getSafely(['codeInput', 'textInput', 'length'], this) > 0) {
			this.codeInput.textInput[0].focus();
		}
	}
	componentDidUpdate (prevProps, prevState) {
		if(this.state.reset !== prevState.reset) {
			return this._bindOnFocus();
		}
	}
	_resendNumber = () => {
		const {
			resendotp
		} = this.props;
		const {
			reset
		} = this.state;
		resendotp();
		this.setState({
			reset: reset+1
		});
	}
	_resetValue = (self) => {
		self.setState({
			reset: this.state.reset + 1
		}, () => {
			// console.log('reset -- ', this.state.reset);
		});
	}
	_bindOnFocus = () => {

		const {
			fallback
		} = this.state;

		if(!fallback) {
			if(getSafely(['textInput'], this.codeInput)) {
				this.codeInput.textInput.map((item, index) => {
					item.onfocus = this._handleOnFocus(index);
					return false;
				});
				this.codeInput.textInput[0].focus();

			}
		}

	}
	_handleOnFocus = (index) => {
		const self = this;
		return function onfocus () {
			const maxIndex = (self.codeInput.textInput.length > 0)? self.codeInput.textInput.length-1: 0;
			const minIndex = 0;
			if(minIndex === index) {
				self._resetValue(self);
			}else {

				let firstValue = (self.codeInput.textInput[minIndex])? self.codeInput.textInput[minIndex].value: null;
				let currentValue = (self.codeInput.textInput[index])? self.codeInput.textInput[index].value: null;
				let prevValue = (self.codeInput.textInput[index-1])? self.codeInput.textInput[index-1].value: null;

				/*
				if this is the last index
				*/
				if(index === maxIndex) {
					if(!currentValue && !prevValue) {
						self._resetValue(self);
					}
					if(currentValue){
						self._resetValue(self);
					}
					if(!firstValue) {
						self._resetValue(self);
					}
				}

				/*
				if this is the middle index
				*/
				else {
					if(currentValue){
						self._resetValue(self);
					}
					if(!firstValue) {
						self._resetValue(self);
					}
				}

			}
		};
	}

	render() {
		const {
			autosubmit,
			name,
			counter,
			resendflag,
			resendfallback
		} = this.props;

		return (
			<Fragment>
				<Field
					name={name}
					render={({field, form}) => {
						return (
							<Fragment>
								<CodeInput
									ref={el => this.codeInput = el}
									key={this.state.reset}
									autoFocus
									type='number'
									fields={MAX_FIELDS}
									onChange={otp => {
										form.setFieldValue(field.name, otp);
										if(otp.length === MAX_FIELDS) {
											autosubmit(otp);
											setTimeout(() => {
												if(this.codeInput && this.codeInput.textInput) 
													this.codeInput.textInput[MAX_FIELDS-1].blur();
											}, 500);
										}
									}}
									inputMode="numeric"
									pattern="\d*"
								/>
								<div className="bit-pin-time">
									<CountdownTimer
										timestamp={counter}
										complete={(resendflag) ? (
											<span className="login-register-right-text" onClick={this._resendNumber} >Kirim Ulang Kode</span>
										): resendfallback}
									/>
								</div>

							</Fragment>
						);
					}}
				/>

			</Fragment>
		);
	}
}

OtpInput.defaultProps = {
	autosubmit: () => {},
	resendotp: () => {},
	counter: Date.now(),
	resendflag: false,
	resendfallback: <Wa />,
};

export default OtpInput;