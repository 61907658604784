export function getUserPortfolio (state) {
	return state.userportfolio;
}

export function getUserPortfolioStatus (state) {
	const portfolio = getUserPortfolio(state);
	const {portfolio_status} = portfolio;
	return portfolio_status;
}

export function getUserPortfolioCategory (state, categoryId) {
	if(!categoryId) 
		return {};
	const portfolio = getUserPortfolio(state).categories.byId[categoryId];
	return portfolio;
}

export const getPortfolioCategories = (state) => {
	const categories = getUserPortfolio(state).categories;
	return categories.allIds.map(
		(item) => getUserPortfolio(state).categories.byId[item]
	);
};
	
export const getPortfolioItems = (state) => {
	const items = getUserPortfolio(state).items;
	return items.allIds.map(
		(item) => getUserPortfolio(state).items.byId[item]
	);
};

export const getPortfolioFirstItem = (state) => {
	const items = getPortfolioItems(state);
	const firstItem = items[0];
	return firstItem? [firstItem]: [];
};

export const getPortfolioFirstCategory = state => {
	const {
		byId,
		allIds,
	} = getUserPortfolio(state).categories;
	const firstPortfolio = allIds[0]? byId[allIds[0]]: null; 
	return firstPortfolio;
};

export const getPortfolioSelectedCategory = (state, categoryId) => {
	const {
		byId,
	} = getUserPortfolio(state).categories;
	const selectedPortfolio = byId[categoryId] || null;
	return selectedPortfolio;
};

export const getPortfolioSelectedItems = (state, itemIds) => {
	const items = getPortfolioItems(state);
	const _itemIds = itemIds || [];
	const collectionItems = items.filter(item => {
		const {
			product
		} = item;
		const {
			symbol
		} = product;
		const resultFilter = _itemIds.indexOf(symbol) > -1; 
		return resultFilter;
	});

	return collectionItems && collectionItems.length > 0? collectionItems: [];
};

export const getPortfolioDefaultCategoryId = (state) => {
	const {
		allIds,
	} = getUserPortfolio(state).categories;
	const defautCategoryId = allIds[0] || 0;
	return defautCategoryId;
};