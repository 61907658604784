import 'window-crypto';
import React from 'react';
import store from './../Store';
import Storage from './Storage';

import Er404 from './../assets/images/404.svg';

import { getPartnerCallback } from '../entities/userpartner.selector';
import numbro from 'numbro';

import * as Analytics from './../utils/Analytics';

export function backToMainApp () {
	const state = store.getState();
	const redirecturl = getPartnerCallback(state);
	return window.location.replace(redirecturl);
}

export function generateRandomNumber () {
	const col = 3;
	const lowrndm = (window.crypto.getRandomValues(new Uint32Array(col)));
	let rndm;
	lowrndm.map(num => rndm = num+''+rndm);
	return rndm;
}

export function generateRandomString (length = 5) {
	let random13chars = function () {
		const rndm = generateRandomNumber();
		return rndm.toString(16).substring(2, 15);
	};
	let loops = Math.ceil(length / 13);
	return new Array(loops).fill(random13chars).reduce((string, func) => {
		return string + func();
	}, '').substring(0, length);
}

export function extractProductAnalytic (list, products, option = {}) {
	return {
		'company_id': products,
		'company_name': products.map(id => 
			list[id]? list[id].name: ''
		),
	};
}

export function maskingText (puretext = '') {
	if(puretext)
		return '';
	let mask = '';
	let len = puretext.length;
	let percentageTrim = Math.floor(len*.2);
	let first4 = puretext.substring(0, percentageTrim);
	len = len - first4.length;
	let last5 = puretext.substring(puretext.length - percentageTrim);
	len = len - last5.length;
	mask = '*'.repeat(len);

	return first4 + mask + last5;
}

export function isColorMinus (number) {

	const isNumber = numbro.unformat(number);
	
	let datanumber = 0;
	if(typeof number === 'number') {
		datanumber = number;
	}
	
	if(typeof number !== 'number' && isNumber) {
		datanumber = isNumber;
	}

	const result = Math.sign(datanumber);

	if(result === 0 || (result > -1 && result < 1)) {
		return 'neutral';
	} 

	if(result > 0) {
		return 'plus';
	}

	if(result < 0) {
		return 'minus';
	}
	
}

export function  getUserProfileStore() {
	const { user } = store && store.getState();
	return user;
}

export function clearLocalSession () {

	Storage.removeObject('ap');
	Storage.removeObject('ac');
	Storage.removeObject('rb');

	Analytics.clearRecordUser();
	
	window.location.href = '/';
}

export function handlerReject(reject, options = null){
	
	let {
		type,
		message
	} = reject;

	let output = '';

	if(type === 'reject_internal') {
		try {
			let _output = JSON.parse(message);
			let cn = 'one';
			let longest = (options)? options.longest: 0;
			if(Array.isArray(_output)) {
				_output = [..._output];
				if(_output.length > 1) cn = 'many';
				_output = _output.map((_item, index) => {
					if(typeof _item === 'string') {
						return <li key={index}>
							<p>{(longest && _item.length > longest)? _item.substring(0, longest)+'...': _item}</p>
						</li>; 
					}
					const {reason, note} = _item;
					return <li key={index}>
						<p >{(longest && reason.length > longest)? reason.substring(0, longest)+'...': reason}</p>
						{note && <p className="bit-error-desc-reject">{(longest && note.length > longest)? note.substring(0, longest)+'...': note}</p>}
					</li>;

				});
			}
			output = <ul className={cn}>{_output}</ul>;	
		}
		catch (err) {
			console.error(err);
			output = <div className="bit-link-error-404">
				<img src={Er404} alt="error 404"/>
			</div>;
		} 
	} else {
		output = <div className="bit-link-error-404">
			<img src={Er404} alt="error 404"/>
		</div>;	
	}	
	return output;
}

export function obscureText (text = '', percentage = 0.6) {
	if(!text) return text;
	const {floor} = Math;
	const length = text.length;
	const trim_length = floor(length * percentage);
	const _rest = length - trim_length;
	const start_index = floor(_rest / 2);
	const new_text = spliceSlice(text, start_index, trim_length, generateString(trim_length));
	return new_text;
}

export function spliceSlice(str, index, count, add) {
	// We cannot pass negative indexes directly to the 2nd slicing operation.
	if (index < 0) {
		index = str.length + index;
		if (index < 0) {
			index = 0;
		}
	}
	return str.slice(0, index) + (add || "") + str.slice(index + count);
}

export function generateString(len = 1, character = '*'){
	const str = new Array(len + 1).join(character);
	return str;
}