import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BuyModal from './BuyModal';

import {
	getReksadanaProductDetail,
} from './../../../entities/reksadana.selector';

import {
	fetchPortfolioCategoriesDetailData,
} from './../../../entities/userportfolio.reducer';

import {
	getStatusLoadingSelector
} from './../../../services/apimanager/apimanager.selector';

import list from './../../../services/apiconstant';

import getSafely from '../../../utils/safely';

const mapStateToProps = (state, ownProps) => {

	const {
		portfolioId,
		productIds: _productIds,
	} = ownProps;

	let productIds = [];
	if(_productIds) {
		productIds = _productIds;
	}

	const isLoading = getStatusLoadingSelector(state, [
		list.userBalanceLinkaja,
		list.orderBuy,
	]);

	let items = getReksadanaProductDetail(state, productIds);

	items = items.map(item => {
		return {
			...item,
			ojkCode: getSafely(['investment_manager, ojkCode'], item) || getSafely(['ojkCode'], item),
		};
	});

	return {
		portfolioId: portfolioId,
		items: items,
		items_length: items.length,
		isLoading: isLoading,
	};

};

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchPortfolioCategoriesDetailData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BuyModal);