import Api from "../utils/newapimanager";
import list from "../services/apiconstant";

const api = Api;

export const serviceBankList = () => 
	api.get(list.bankList);

export const serviceAddBank = ({token, pin, bank, bank_account_name, bank_account_number, file_prove, file_bank_account} = {}) => 
	api.post(list.userChangeBankUpdate, {
		token, pin, bank, bank_account_name, bank_account_number, file_prove, file_bank_account
	});

export const serviceDefaultBank = ({bank_slot}) => 
	api.post(list.userDefaultBankUpdate, {
		bank_slot
	});


export const serviceCheckPin = ({pin, type, token} = {}) => 
	api.post(list.authCheckPin, {
		pin, type, token
	});