import React from 'react';
import { numberToAveragedLetter, numberToGeneralValueFormat } from './../../../../utils/string-helper';

class CustomLabel extends React.PureComponent {
	componentDidMount() {
		// Calculate text rectangle wrapper
		const textBBox = this.closeText.getBBox(); // Get the text x y width height boundaries
		const textWrapperEl = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'rect',
		);

		// Style label text wrapper here
		textWrapperEl.setAttribute('x', textBBox.x - 5);
		textWrapperEl.setAttribute('y', textBBox.y - 5);
		textWrapperEl.setAttribute('height', textBBox.height + 10);
		textWrapperEl.setAttribute('width', textBBox.width + 10);
		textWrapperEl.setAttribute('stroke', '#333');
		textWrapperEl.setAttribute('stroke-width', '1');
		textWrapperEl.setAttribute('fill', 'white');

		if(textWrapperEl && textWrapperEl.classList)
			textWrapperEl.classList.add('chart-label-box'); // class if necessary

		this.groupWrapper.insertBefore(
			textWrapperEl,
			this.groupWrapper.firstChild,
		);
	}

	componentDidUpdate() {
		// Calculate text rectangle wrapper
		const textBBox = this.closeText.getBBox(); // Get the text x y width height boundaries
		const textWrapperEl = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'rect',
		);

		// Style label text wrapper here
		textWrapperEl.setAttribute('x', textBBox.x - 5);
		textWrapperEl.setAttribute('y', textBBox.y - 5);
		textWrapperEl.setAttribute('height', textBBox.height + 10);
		textWrapperEl.setAttribute('width', textBBox.width + 10);
		textWrapperEl.setAttribute('stroke', '#333');
		textWrapperEl.setAttribute('stroke-width', '1');
		textWrapperEl.setAttribute('fill', 'white');
		
		if(textWrapperEl && textWrapperEl.classList)
			textWrapperEl.classList.add('chart-label-box'); // class if necessary

		this.groupWrapper.insertBefore(
			textWrapperEl,
			this.groupWrapper.firstChild,
		);
	}

	reformatValue = (value) => {

		let _value = value;
		if(_value === 'undefined') return _value;
		if (_value.toString().length >= 10) {
			_value = numberToAveragedLetter(
				_value,
				true,
				{
					mantissa: 2
				}
			);
		} else {
			_value = numberToGeneralValueFormat(
				_value,
				{
					mantissa: 2
				},
			);
		}
		return _value;
	}

	render() {
		const { x, y, text, textAnchor, style } = this.props;
		return (
			<g ref={el => (this.groupWrapper = el)}>
				<text
					ref={el => (this.closeText = el)}
					x={x}
					y={y}
					textAnchor={textAnchor}
				>
					{text.map((item, i) => {
						return <tspan key={i} dx={0} dy={0} style={style[i]}>
							{this.reformatValue(item)}
						</tspan>;
					})}
				</text>
			</g>
		);
	}
}

export default CustomLabel;
