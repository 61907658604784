import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import LogoBibit from './../../assets/images/logo-bibit.svg';
import RoboOk from './../../assets/images/robo-ok.svg';
import GlobalHeader from '../Global/GlobalHeader/GlobalHeader';

class LandingAuthSuccess extends Component {
	render() {
		return (
			<div>
				<div className="bit-link-wrap">
					<div className="bit-link-header">
						<GlobalHeader />
					</div>
					<div className="bit-link-register">
						<div className="bit-link-title-reg">
							<h5>Reksa Dana</h5>
							<h1>Mulai Investasi <br />Sekarang</h1>
						</div>    
						<div className="bit-link-content-centered">
							<div className="bit-link-centered-img">
								<img src={RoboOk} alt="robo" />
							</div>    
							<p>Nomor Kamu terdaftar sebagai</p>
							<p className="font-bold">Yoman Setiawan</p>
							<Link to={`/otp-verification`}>
								<button className="bit-link-btn-red margin-top-15">Lanjutkan</button>
							</Link>	
						</div>
					</div>
					<div className="bit-link-footer">
						<div>Powered by :</div> 
						<div><img src={LogoBibit} alt="logo bibit" /></div>
					</div>
				</div>
			</div>
		);
	}
} 

export default LandingAuthSuccess;