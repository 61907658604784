import React, { Component, Fragment } from 'react';
import { NavBar } from 'antd-mobile';

class AppNavbar extends Component {
	render() {
		const {attrTitle} = this.props;
		return (
			<Fragment>
				<NavBar mode="light" {...this.props} title={attrTitle}>{this.props.title}</NavBar>
			</Fragment>
		);
	}
}

AppNavbar.defaultProps = {
	title: ''
};

export default AppNavbar;