import { Base64 } from 'js-base64';
import LZString from 'lz-string';

window.Base64 = Base64;

async function setObject(key, value) {
	const hashValue = JSON.stringify(value);
	const _hashValue = LZString.compressToUTF16(hashValue);

	localStorage.setItem(key, _hashValue);

	return true;
}
async function getObject(key, _key = '') {
	let hashValue = '';
	hashValue = localStorage.getItem(key);
	try {
		const _hashValue = LZString.decompressFromUTF16(hashValue);
		const __hashValue = JSON.parse(_hashValue);
		if (_key) return __hashValue[_key];
		return __hashValue;
	} catch (error) {
		return null;
	}

}
async function removeObject (key) {
	localStorage.removeItem(key);
}
function setSecurely(key, value) {
	const hashValue = Base64.encode(value);
	localStorage.setItem(key, hashValue);
	return true;
}
function getSecurely(key) {
	const hashValue = localStorage.getItem(key);
	return Base64.decode(hashValue);
}
function set(key, value) {
	localStorage.setItem(key, value);
	return true;
}
function get(key) {
	return localStorage.getItem(key);
}
function setCombine(key, ...args) {
	return localStorage.setItem(key, args.join('|**|'));
}
function getCombine(key) {
	const data = localStorage.getItem(key);
	return (data) ? data.split('|**|') : [];
}
function reset() {
	localStorage.clear();
	return true;
}
export default {
	setObject,
	getObject,
	removeObject,
	setSecurely,
	getSecurely,
	set,
	get,
	setCombine,
	getCombine,
	reset
};
