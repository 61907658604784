import React, {Component} from 'react';
import LogoBibit from './../../assets/images/logo-bibit.svg';
import RoboOkOnly from './../../assets/images/robo-ok-only.svg';
import GlobalHeader from '../Global/GlobalHeader/GlobalHeader';

class SuccessConnected extends Component {
	render() {
		return (
			<div>
				<div className="bit-link-wrap">
					<div className="bit-link-header">
						<GlobalHeader />
					</div>
					<div className="bit-link-register">
						<div className="bit-link-title-reg">
							<h5>Reksa Dana</h5>
						</div>    
						<div className="bit-link-content-centered bit-align-center">
							<div className="bit-link-centered-img">
								<img src={RoboOkOnly} alt="robo" />
							</div>    
							<h3>Akun Bibit <br/>Berhasil Tersambung</h3>
						</div>
					</div>
					<div className="bit-link-footer">
						<div>Powered by :</div> 
						<div><img src={LogoBibit} alt="logo bibit" /></div>
					</div>
				</div>
			</div>
		);
	}
} 

export default SuccessConnected;