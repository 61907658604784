import React from 'react';
import {Field} from 'formik';
import { ChevronDown} from 'react-feather';
import {Picker, List} from 'antd-mobile';

const {Item} = List;

export default function BasePicker (props) {
	return <Field {...props} render={({ field, form}) => {
		
		let selected = props.data[field.value];
		if(!selected) {
			selected = {
				label: '-'
			};
		}
		return <Picker
			{...props}
			{...field}
			value={[field.value]}
			onChange={value => {
				return form.setFieldValue(field.name, value.pop());
			}}	
			disabled={false}
			dismissText="Batal"
			okText="Pilih"
			cols={1}		
		>
			<Item>{selected.label}<ChevronDown size="20" color='#b5b5b5'></ChevronDown></Item>
		</Picker>;
	}}/>;
	// return (
	// 	<Field name={name} render={({ field, form }) => {
	// 		return <InputItem
	// 			{...field}
	// 			onChange={value => {
	// 				return form.setFieldValue(field.name, value);
	// 			}}
	// 			disabled={false}
	// 			placeholder={placeholder}
	// 		/>;
	// 	}}/>
	// );
}