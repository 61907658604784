import React, { Component, Fragment } from 'react';
import { X } from 'react-feather';
import { Modal } from 'antd-mobile';

class DocumentModal extends Component {
	state={
		isModalShow: false
	}
	handleClose=() => {
		this.setState({
			isModalShow: false
		});
	}
	handleOpen=() => {
		this.setState({
			isModalShow: true
		});
	}
	renderDocuments = () => {
		return (
			<Fragment>
				{this.props.documents.map((item, index) => {
					const urldoc = 'https://linkaja.id/home/web?redirectUrlExternal='+item.file;
					return (
						<a key={index} href={urldoc} className="bit-link-prospektus-list" target="_blank" rel="noopener noreferrer">
							<p>{item.name}</p>
							<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#6b6b6b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
						</a>
					);
				})}
			</Fragment>
		);
	}
	render() {
		return (
			<Fragment>
				{this.props.renderButton(this.handleOpen)}
				<Modal
					popup
					animationType="slide-up"	
					visible={this.state.isModalShow}
					onClose={this.handleClose}
					className="bit-link-modal-wrapper"
				>
					<div className="bit-link-register bit-link-otp-modal">
						<div className="bit-link-modal-header margin-top-15">
							<div>
								<h3>{this.props.title}</h3>
							</div>
							<div>
								<X color="#666" size={16} onClick={this.handleClose}/>
							</div>
						</div>	
						{this.renderDocuments()}
					</div>
				</Modal>
			</Fragment>
		);
	}
}

DocumentModal.defaultProps = {
	documents: [],
	documentsLength: 0,
	title: 'Fund Fact Sheet',
	renderButton: openModal => {
		return (
			<span onClick={openModal}>
				Baca Selengkapnya
			</span>
		);
	}
};

export default DocumentModal;