import update from 'immutability-helper';
import { createAction, createReducer } from 'redux-act';
import {
	handleResponseGeneral,
	// handleResponseAuthorization,
} from './../utils/handleResponse';

import {
	servicePortfolioCategories, 
	servicePortfolioCategoriesDetailDataByCategoryId
} from './../services/userportfolio.services';

import getSafely from '../utils/safely';

const defaultState = {
	portfolio_status: {
		invested: 0,
		marketvalue: 0,
		percentage: 0,
		profitloss: 0,
		totalpending: 0,
		totalporto: 0,
	},
	categories: {
		byId: {},
		allIds: [],
	},
	// temporary items
	temp_items: {
		byId: {},
		allIds: [],
	},
	items: {
		byId: {},
		allIds: [],
	},
	robo: {
		byId: {},
		allIds: [],
	},
	pending: {
		byId: {},
		allIds: [],
	},
};

const [setCategory, setCategoryItem, updateCategory, setCategoryItemTemp] = [
	'SET_PORTFOLIOCATEGORY',
	'SET_PORTFOLIOCATEGORYITEM',
	'UPDATE_PORTFOLIOCATEGORY',
	'SET_PORTFOLIOCATEGORYITEM_TEMPORARY',
].map(createAction);

const portoReducer = {
	[setCategory]: (state, payload) => {
		const {
			result,
			...status
		} = payload;
		
		const toUpdate = {};

		const newcategories = {};
		const newcategoriesIds = result.map(item => {
			newcategories[item.id] = item;
			return item.id;
		});

		toUpdate['portfolio_status'] = {$set: {...status}};
		toUpdate['categories'] = {
			byId: {
				$set: {
					...state.categories.byId,
					...newcategories
				}
			},
			allIds: {
				$set: newcategoriesIds
			}
		};
		return update(state, toUpdate);
	},
	[setCategoryItem]: (state, payload) => {

		const {
			results
		} = payload;

		const itemCategory = {};
		const itemIds = results.filter(item => {
			if(item && item.product && item.product.symbol) 
				return true;
			return false;
		}).map(item => {
			itemCategory[item.product.symbol] = item;
			return item.product.symbol;
		});
		
		const toUpdate = {};

		toUpdate['items'] = {
			byId: {
				$set: {
					...state.items.byId,
					...itemCategory,
				},
			},
			allIds: {
				$set: itemIds
			},
		};

		return update(state, toUpdate);
	},
	[updateCategory]: (state, payload) => {
		const {
			categories
		} = state;
		let category = {
			...categories.byId[payload.id],
			...payload
		};
		const toUpdate = {};
		toUpdate['categories'] = {
			byId: {
				[payload.id]: {$set: category}
			}
		};
		return update(state, toUpdate);
	},
	[setCategoryItemTemp]: (state, payload) => {

		const {
			results
		} = payload;

		const itemCategory = {};
		const itemIds = results.map(item => {
			itemCategory[item.product.symbol] = item;
			return item.product.symbol;
		});
		
		const toUpdate = {};

		toUpdate['temp_items'] = {
			byId: {
				$set: {
					...state.temp_items.byId,
					...itemCategory,
				},
			},
			allIds: {
				$set: itemIds
			},
		};

		return update(state, toUpdate);

	}
};

const reducer = createReducer(portoReducer, defaultState);

export function fetchPortfolioCategories () {
	return dispatch => {
		return servicePortfolioCategories()
			.then(response => {
				const generalResponse = handleResponseGeneral(response);
				const data = getSafely(['data'], generalResponse);
				dispatch(setCategory(data));
			});       
	};
}

/**
 * Getting user portfolio based on the category
 * @param {String|Int} categoryId - Category ID
 */
export function fetchPortfolioCategoriesDetailData(categoryId) {
	return (dispatch, getState) => {
		return servicePortfolioCategoriesDetailDataByCategoryId(categoryId)
			.then((response) => {
				const generalData = handleResponseGeneral(response);

				const portfolioDetailData = getSafely(['data'], generalData);
			
				// Robo specific portfolio category handling
				if (portfolioDetailData.robo) {
					console.log('robo in not implemented yet');
				}

				portfolioDetailData.result = portfolioDetailData.results;
				portfolioDetailData.id = categoryId;

				// Non robo portfolio category handling
				dispatch(setCategoryItem(portfolioDetailData));

			});
	};
}


/* 
	middleware
*/
export function createUserportfolioMiddleware () {
	return store => {
		return next => {
			return action => {

				const {
					type,
					payload
				} = action;
		
				if (type === setCategoryItem.getType()) {
					const categoryObject = parseDetailToCategory(payload);
					store.dispatch(updateCategory(categoryObject));
				}

				return next(action);

			};
		};
	};
	
}

/*
	parse object
*/
function parseDetailToCategory(detailPayload) {
	const {
		results,
		result,
		...output
	} = detailPayload;
	return output;
}

export default reducer;