import React, {Component} from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import {createBase64UploadPromise, formatUploadFileName, fileToBase64, LIMIT_UPLOAD_FILE} from './../../utils/Aws';

import { isLoadingSelector, isErrorSelector } from './../../services/apimanager/apimanager.selector';
import { handleResponseGeneral } from './../../utils/handleResponse';

import apiconstant from '../../services/apiconstant';
import getSafely from '../../utils/safely';
import { recordCustomEvent } from '../../utils/Analytics';
import BaseImagePicker from '../Base/BaseImagePicker';
import { dispatchErrorModal } from '../../utils/dispatcher';

class IdentityPicker extends Component {
	handleUpload = async (image, handleCloseModal) => {

		const {
			userid,
		} = this.props;

		
		const KEY_NAME = 'identity';


		const filename = formatUploadFileName(KEY_NAME);

		if(image?.size > LIMIT_UPLOAD_FILE){
			return dispatchErrorModal({type:'REQUEST_ENTITY_TOO_LARGE'});
		}

		try {
			
			const base64Image = await fileToBase64(image);
 
			const dataResponseImage = createBase64UploadPromise(
			// path_filename,
				filename,
				base64Image,
				true,
				{
					uploadfor: 'ktp',
					code: userid
				}
			);

			dataResponseImage
				.then(response => {
					const generalResponse = handleResponseGeneral(response);
					const url = getSafely(['data', 'url'], generalResponse);
					this.props.onComplete(url);
					recordCustomEvent({
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "identity_upload_success"
					});
					handleCloseModal();
				})
				.catch((err) => {
					console.error(err);
					recordCustomEvent({
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "identity_upload_failed"
					});
					this.props.onCatch(err);
				});
			
		} catch (error) {
			
			dispatchErrorModal();
			Sentry.withScope(function(scope) {
				scope.setTag("image", image);
				scope.setLevel('warning');
				// will be tagged with my-tag="my value"
				scope.setExtra('error', error);
				scope.setExtra('image', image);
				Sentry.captureException(new Error('fileToBase64 input image'));
			});

		}

	}
	render () {
		return (
			<BaseImagePicker
				{...this.props}
				onPickerChange={(files, handleCloseModal) => {
					const file = files[0];
					this.handleUpload(file, handleCloseModal);
				}}
				onPickerChangeAfter={() => {
					let logObject = this.props.value? {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "identity_revision_image_chosen"
					}:{
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "identity_image_chosen"
					};
					recordCustomEvent(logObject);
				}}
				onClickButtonImageAfter={() => {
					let logObject = this.props.value? {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "identity_image_revision_clicked"
					}: {
						event: "bibit.log",
						eventAction: "window.error",
						eventLabel: "identity_image_clicked"
					};
					recordCustomEvent(logObject);
				}}
			/>
		);
	}
}

IdentityPicker.defaultProps = {
	onComplete: () => {},
	onCatch: () => {},
	value: ''
};

const mapStateToProps = state => {
	const {
		awsToken,
		awsBucket,
	} = apiconstant;
	return {
		isLoading: isLoadingSelector(state, awsToken) || isLoadingSelector(state, awsBucket),
		error: isErrorSelector(state, awsBucket) || isLoadingSelector(state, awsBucket),
	};
};

export default connect(mapStateToProps)(IdentityPicker);