// import update from 'immutability-helper';
// import { createAction, createReducer } from 'redux-act';
// import {setUser} from './../../../services/user.reducer';

import Storage from './../../../utils/Storage';

import { setUserPartner } from './../../../entities/userpartner.reducer';
import { restoreInitialUser, seekCredential } from './../../../entities/user.reducer';

// import normalizeOptionUserData from './../../../utils/normalizeOptionUserData';
import { handleResponseUserPartner } from './../../../utils/handleResponse';

import getSafely from '../../../utils/safely';
import { servicePostPartnerKeyCheck } from '../../../services/userpartner.services';


// refresh storage with new urltoken
function refreshUserPartner (querystring) {
	const urltoken = getSafely(['urltoken'], querystring);
	const lasturltoken = Storage.get('urltoken');
	if(!!urltoken && urltoken !== lasturltoken) {
		Storage.reset();
	}
}

async function retrieveUserPartner () {
	return await Storage.getObject('ap');
}

export function restoreUserPartner (userpartner) {
	return async dispatch => {
		let localuserpartner = await retrieveUserPartner();
		if(!localuserpartner) 
			localuserpartner = {};
		if(!userpartner) 
			userpartner = {};
		Object.assign(localuserpartner, userpartner);
		return dispatch(setUserPartner(localuserpartner));
	};
}

export function startAppInitLoad (querystring) {
	return async dispatch => {


		// update url token if new
		refreshUserPartner(querystring);

		// check if localstorage access token is not empty
		const userauth = await seekCredential();
		const access = getSafely(['access'], userauth);

		// check if localstorage is not empty
		const userpartner = await retrieveUserPartner();
		
		// if the user had token, it forced to status to become 3
		if(access) {
			return dispatch(restoreInitialUser());
		}
		
		// check if user partner already exists in localstorage
		// and restore user user partner 
		if(userpartner) 
			return dispatch(restoreUserPartner(userpartner));
		
		const urltoken = getSafely(['urltoken'], querystring);

		if(!urltoken) {
			return console.error('url token is not found');
		}

		// store last urltoken
		Storage.set('urltoken', urltoken);

		// if empty restore user from network
		return servicePostPartnerKeyCheck({urltoken})
			.then(response => {
				let data = handleResponseUserPartner(response);
				dispatch(setUserPartner(data));
				dispatch(restoreInitialUser());
				
			}).catch(err => {
				console.log('error response', err);
			});
		
	};
}