import { ActivityIndicator } from 'antd-mobile';
import React, { Component } from 'react';
import { serviceCheckPin } from '../../../services/bank.services';
import PinInput from '../../Common/PinInput/PinInput';
import { getDynamicParam } from './ChangeBank';

class ChangeBankPin extends Component {
	state = {
		errorMessage: undefined
	}

	handleSubmitPin = (value) => {
		this.setState({
			errorMessage: ''
		});
		
		const token = getDynamicParam(this.props, 'code');

		serviceCheckPin({
			token: token, 
			pin: value, 
			type: 'change_bank'
		}).then(resp => {
			this.props.onSubmitPin(value);
		}).catch(err => {
			if(err.response){
				this.setState({
					errorMessage: err.response?.data?.message
				});
			}
		});
	}
	render () {
		return <div className="bit-link-main-wrap bit-change-bank-setting">
			<div className="bit-link-main-header">
				<div>Konfirmasi Pin Bibit</div>
				<div/>
			</div>
			<div className="bit-link-main-content">
				<PinInput
					max={this.props.pinlength}
					onSubmitPin={this.handleSubmitPin}
					errorMessage={this.state.errorMessage}
				/>
				<ActivityIndicator 
					toast
					text={'Permintaan sedang di proses'}
					animating={this.props.isloading}
				/>  
			</div>
		</div>;
	}

}

ChangeBankPin.defaultProps = {
	onSubmitPin: pin => pin,
	isloading: false,
	err_message: '',
	pinlength: 6,
};

export default ChangeBankPin;