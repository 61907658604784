import Api from './../utils/newapimanager';
import list from './apiconstant';

// const api = new Api();
const api = Api;

export const servicePortfolioCategories = () => 
	 api.get(list.portfolio);

/**
 * Getting user portfolio based on the category
 * @param {String|Int} categoryId - Category ID
 */
export const servicePortfolioCategoriesDetailDataByCategoryId = categoryId => 
	api.get(list.portfolioCategory(categoryId));