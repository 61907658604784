import React, { Fragment, PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
	VictoryChart,
	VictoryLine,
	VictoryTooltip,
	VictoryAxis,
	VictoryVoronoiContainer,
} from 'victory';
import moment from 'dayjs';
import CustomChartFlyout from './CustomChartFlyout';
import CustomLabel from './CustomLabel';
import chartTheme from './BibitChartTheme';
import * as stringHelper from './../../../../utils/string-helper';
import { DEFAULT_SUPPORTED_CHART_PERIOD } from './ReksaChart.constant';

const supportedChartPeriod = DEFAULT_SUPPORTED_CHART_PERIOD['nav'];

class ReksaChartDisplay extends PureComponent {
	handleChangePeriod = event => {
		event.preventDefault();
		event.stopPropagation();
		const { period } = event.currentTarget.dataset;
		this.props.onChangePeriod(period);
	};

	decideChartXAxisFormat = period => {
		switch (period) {
			case '1D':
			case '1M':
			case '3M':
			case '6M':
			case '1Y':
			case 'YTD':
				return 'D MMM';
			default:
				return 'MMM YY';
		}
	};

	render() {
		const { chartData, chartPeriod,
			// supportedChartPeriod,
			// onClickCompare,
			error } = this.props;
		// const supportedChartPeriod = ['1D', '1M', '3M', 'YTD', '1Y', '3Y', '5Y'];
		// test
		return (
			<Fragment>
				{error && <div className="empty-chart">{error}</div>}
				{!error &&
					<VictoryChart
						animate={false}
						theme={chartTheme}
						containerComponent={
							<VictoryVoronoiContainer
								labels={data => {
									return data.datum.value;
								}}
								labelComponent={
									<VictoryTooltip
										flyoutComponent={<CustomChartFlyout />}
										labelComponent={<CustomLabel />}
									/>
								}
							// voronoiDimension="x"
							/>
						}
						padding={{ top: 30, left: 30, right: 80, bottom: 50 }}
						domainPadding={{ y: 20 }}
					>
						{Object.keys(chartData).map((chartName, index) => {
							if (index === 0) {
								return (
									<VictoryLine
										key={chartName}
										data={chartData[chartName]}
										style={{
											data: { stroke: '#00ab6b' },
											labels: { fill: '#333' },
										}}
										scale={{ x: 'time', y: 'linear' }}
										x="date"
										y="value"
									/>
								);
							}
							return (
								<VictoryLine
									key={chartName}
									data={chartData[chartName]}
									style={{
										data: { stroke: 'red' },
										labels: { fill: '#333' },
									}}
									scale={{ x: 'time', y: 'linear' }}
									x="date"
									y="value"
								/>
							);
						})}
						<VictoryAxis
							tickCount={chartPeriod === '1D' ? 1 : 3}
							tickFormat={t =>
								moment(t).format(
									this.decideChartXAxisFormat(chartPeriod),
								)
							}
							orientation="bottom"
							scale="time"
						/>
						<VictoryAxis
							// the first axis will configure Y axis
							dependentAxis
							orientation="right"
							groupComponent={<g transform={'translate(-25,0)'} />}
							tickFormat={t => {
								let output = t;
								if (output.toString().length >= 10) {
									output = stringHelper.numberToAveragedLetter(
										t,
										true,
										{
											mantissa: 2
										}
									);
								}
								return output;
							}}
						/>
					</VictoryChart>
				}
				<div className="reksa-chart-nav linkaja" role="navigation">
					<ul role="menu">
						{supportedChartPeriod.map(item => {
							const chartPeriodClassName = classNames({
								active: item.toLowerCase() === chartPeriod.toLowerCase(),
							});
							return (
								<li key={item} className={chartPeriodClassName}>
									<a
										href="/"
										onClick={this.handleChangePeriod}
										data-period={item}
										role="menuitem"
									>
										{item}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</Fragment>
		);
	}
}

ReksaChartDisplay.propTypes = {
	chartData: PropTypes.object.isRequired,
};

// ReksaChartDisplay.defaultProps = {
// 	supportedChartPeriod: ['1D', '1M', '3M', 'YTD', '1Y', '3Y', '5Y']
// };

export default ReksaChartDisplay;
