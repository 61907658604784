/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import LeftArrow from './../../assets/images/left-arrow.svg';
import DiffNumber from './../../assets/images/different-number.svg';
import { Modal } from 'antd-mobile';
import { X } from 'react-feather';


class DifferentNumber extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  modal2: false,
		};
	  }
	  showModal = key => (e) => {
	  	e.preventDefault(); 
	  	this.setState({
		  [key]: true,
	  	});
	  }
	  onClose = key => () => {
	  	this.setState({
		  [key]: false,
	  	});
	  }
	  

	  render() {
	  	const { history } = this.props;
	  	return (
	  		<div>
	  			<div className="bit-link-wrap">
	  				<div className="bit-link-header">
	  					<div onClick={() => history.goBack()}><img src={LeftArrow} alt="left arrow"/></div>
	  					<div>Pendaftaran</div>
	  					<div />
	  				</div>
	  				<div className="bit-link-register">   
	  					<div className="bit-link-content-centered">
	  						<div className="bit-link-centered-img">
	  							<img src={DiffNumber} alt="different number"/>
	  						</div>    
	  						<h3>Nomor Ponsel Berbeda</h3>
	  						<p className="margin-top-10">Untuk alasan keamanan, kamu tidak bisa memiliki akun Bibit dan LinkAja dengan nomor ponsel berbeda</p>
	  						<button className="bit-link-btn-red margin-top-15" onClick={this.showModal('modal2')}>Bantuan</button>
	  					</div>
	  				</div>
	  				<Modal
	  					popup
	  					visible={this.state.modal2}
	  					onClose={this.onClose('modal2')}
	  					animationType="slide-up"
	  				>
						 <div className="bit-link-modal-header">
	  						<div>
	  							<h3>Bantuan</h3>
	  						</div>
	  						<div>
	  							<X color="#666" size={16} onClick={this.onClose('modal2')}/>
	  						</div>	
	  					</div>	 
						 <div className="bit-link-tutor-button">Cara mengganti nomor ponsel Bibit</div> 
						 <div className="bit-link-tutor-button">Cara mengganti nomor ponsel LinkAja</div> 
	  				</Modal>
	  			</div>
	  		</div>
	  	);
	  }
} 

export default DifferentNumber;