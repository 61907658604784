import React, { Component } from 'react';
import ChevronRight from "react-feather/dist/icons/chevron-right";
import mailVerification from './../../assets/images/mailVerification.svg';

import './EmailStatus.css';

class EmailStatus extends Component {
	render () {
		const {
			onClick, 
		} = this.props;
		return (
			<div className="edd-notif" onClick={onClick}>
				<div className="edd-notif-img"><img src={mailVerification} alt="" /></div>
				<div className="edd-notif-text">
					<span>Sudah Verifikasi Email Kamu?</span>
					<span className="edd-notif-link ">Cek Email Kamu Sekarang </span>
					<ChevronRight size="18" color="#E82529" />
				</div>
			</div> 
		);
	}
}

EmailStatus.defaultProps = {
	onClick: () => {}
};

export default EmailStatus;