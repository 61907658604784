import numbro from 'numbro';

/**
 * Convert any string to first letter capitalized words
 * ex: faisal bicara bakteri -> Faisal Bicara Bakteri
 * @param {String} str - String you want to convert
 * @return {String} - Formatted string
 */
export function toTitleCase(str) {
	return str.replace(/\w\S*/g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
}

/**
 * Convert number to usual NAV value format
 * @param {Number} num - The number to be converted
 * @param {Object} opt - Numbro.js options if you want specific config
 * @return {String}
 */
export function numberToGeneralValueFormat(num, opt) {
	if (typeof num === 'undefined' || num === null) {
		return '-';
	}

	return numbro(num).format({
		thousandSeparated: true,
		mantissa: 0,
		...opt,
	});
}

/**
 * Convert number to be string with averaged letter (K, M, B, T)
 * @param {Number} num - The number to be converted
 * @param {Boolean} upperCased - Whether the letters should be uppercased
 * @param {Object} opt - Numbro.js options if you want specific config
 * @return {String}
 */
export function numberToAveragedLetter(
	num,
	upperCased = false,
	opt = {},
) {
	const number = typeof num === 'undefined' || num === null ? 0 : num;
	const text = numbro(number).format({
		average: true,
		spaceSeparated: false,
		...opt,
	});
	return upperCased ? text.toUpperCase() : text;
}

/**
 * Convert number to have sign in front of the number, formatted with specified decimal.
 * Useful for point changes just like in stock charts
 * @param {Number} num - The number to be converted
 * @param {Object} opt - Numbro.js options if you want specific config
 * @return {String}
 */
export function numberToPointChangeFormat(num, opt = {}) {
	if (typeof num === 'undefined' || num === null) {
		return '-';
	}

	return numbro(num).format({
		thousandSeparated: true,
		forceSign: num !== 0,
		mantissa: 2,
		...opt,
	});
}

/**
 * Convert number to be percentage with sign in front of the number, formatted with specified decimal.
 * Useful for percentage changes just like in stock charts
 * @param {Number} num - The number to be converted
 * @param {Object} opt - Numbro.js options if you want specific config
 * @return {String}
 */
export function numberToPercentagePointChangeFormat(num, opt = {}) {
	if (typeof num === 'undefined' || num === null) {
		return '-';
	}

	return numbro(num).format({
		thousandSeparated: true,
		forceSign: num !== 0,
		output: 'percent',
		spaceSeparated: false,
		mantissa: 2,
		...opt,
	});
}

/**
 * Convert capitalized currency to its currency letter
 * @param {String} curr - Currency in 3 capitalized letter (ex: IDR)
 */
export function determineCurrencyLetter(curr = 'IDR') {
	switch (curr) {
		case 'IDR':
			return 'Rp';
		case 'USD':
			return '$';
		default:
			return 'Rp';
	}
}

/**
 * Remove trailing slash from a url
 * @param {String} url - The url to be cleaned
 * @return {String} New url without trailing slash
 */
export function removeTrailingSlash(url) {
	if (typeof url !== 'string') throw new Error('Parameter passed must be string');
	return url.replace(/\/$/, "");
}

/**
 * Parse voucher data and return the voucher amount based on type
 * @param {String} voucher - Voucher object
 */
export const getDiscountValue = (voucher) => {
	if(voucher.voucherType === "PERCENT") {
		return `${voucher.amountDiscount}%`;
	}
	if(voucher.voucherType === "AMOUNT") {
		const { amountDiscount } = voucher;
		let del3digit = amountDiscount.slice(0, -3);
		return `${del3digit}K`;
	}
	return null;
};

export default Object.assign(
	{},
	{
		toTitleCase,
		numberToAveragedLetter,
		numberToPointChangeFormat,
		numberToPercentagePointChangeFormat,
		removeTrailingSlash,
		getDiscountValue,
	},
);
