import React, { Component } from 'react';
import {
	bindActionCreators,
	compose,
} from 'redux';
import {
	connect
} from 'react-redux';

import ReksaChart from './ReksaChart/ReksaChart';
import InvestmentCompanyLogo from './../../Common/InvestmentCompanyLogo';
import { numberToPercentagePointChangeFormat } from './../../../utils/string-helper';

import {
	getPortfolioDefaultCategoryId
} from './../../../entities/userportfolio.selector';

import {
	fetchProducts,
} from './../../../entities/reksadana.reducer';

import Investime from './../../../assets/images/investime.svg';
import { ChevronDown } from 'react-feather';
import BuyModal from '../../Common/BuyModal/SelectedBuyModal';
import { getReksadanaProducts } from '../../../entities/reksadana.selector';
import { isColorMinus } from '../../../utils';
import { DEFAULT_PERIOD } from './ReksaChart/ReksaChart.constant';


class ChartAccordion extends React.Component {	
	render() {
	  return (
			<div 
				ref={a => this._acc = a} 
				onClick={this._handleClick}>
				{this.props.children}
			</div>
	  );
	}
}

const decideDescription = (period) => {
	switch(period) {
		case '1D':
			return '1 Hari';
		case '1M':
			return '1 Bulan';
		case '3M':
			return '3 Bulan';
		case 'YTD':
			return 'YTD';
		case '1Y':
		default: 
			return '1 tahun';
	}
		
};

class ItemProductCard extends Component {
	state = {
		active: '',
		period: DEFAULT_PERIOD,
	};
	render () {

		const {
			name,
			type,
			simplereturn,
			symbol,	
			investment_manager,
			isActive,
		} = this.props;

		const periodLower = this.state.period.toLowerCase();
		
		let percentage = simplereturn[periodLower]? simplereturn[periodLower]: '0%';
		percentage = numberToPercentagePointChangeFormat(percentage);
		const {
			ojkCode
		} = investment_manager;

		return (

			<div key={symbol} className={isActive? "bit-chart-accord active": "bit-chart-accord"}>
				<div className="bit-chart-head" onClick={() => {
					this.props.onChangeActive(symbol);
				}}>
					<div className="bit-link-item-row">
						<div className="bit-reksa-item">
							<div className="bit-reksa-pic">
								<InvestmentCompanyLogo
									type="investment-manager"
									size="square"
									ojkCode={ojkCode}
								/>
								{/* <img src={Schroder} alt="reksa pic"/> */}
							</div>
							<div>
								<h3>{name}</h3>
								<p>{type}</p>
							</div>
						</div>
						<div className="bit-reksa-return">
							<div>
								<h5 className={isColorMinus(percentage)}>{percentage}*</h5>
								<p>{`Keuntungan ${decideDescription(this.state.period)}`}</p>
							</div>	
						</div>
						<ChevronDown className="bit-link-down-arr" height="18" color="#E82529"/>
					</div>  
				</div>
				<div className="bit-chart-content">
					<ReksaChart 
						symbol={symbol}
						chartPeriodProps={this.state.period}
						onChangePeriod={period => {
							this.setState({
								period: period
							});
						}}
					/>
					<BuyModal
						portfolioId={this.props.defaultCategoryId}
						productIds={[symbol]}
						renderButton={(openModal, enableBuy) => (
							<button 
								className="bit-link-btn-red-full margin-top-15 bit-link-transaction-action"
								aria-disabled={!enableBuy}
								onClick={openModal("buy-1")}>Beli Sekarang</button>
						)}
					/>
					<p className="bit-link-perform-info">*Merupakan kinerja masa lalu dan tidak mencerminkan kinerja masa mendatang</p>
					
				</div>
			</div>
		
		);
	}
}

class ViewOverviewProductCard extends Component {
	constructor (props) {
		super(props);
		this.state = {
			active: '',
		};
	}
	componentDidMount () {
		// show all products
		this.props.fetchProducts();
	}
	renderCards = () => {
		const {
			reksadana,
			reksadana_count,
		} = this.props;

		let toOutput = [];

		

		// check if reksadana is empty
		let toLoop = reksadana? reksadana: [];
		toOutput = toLoop.map(item => {
			const {
				symbol
			} = item;
			let isActive = (reksadana_count < 2)? true: 
				(this.state.active === symbol)? true: false;
			return (<ItemProductCard 
				key={symbol}
				{...item}
				isActive={isActive}
				onChangeActive={active => this.setState({
					active: active
				})}
			/>);
		});
		return (
			<div className="bit-link-invest-bottom">
				<ChartAccordion>
					{toOutput}
				</ChartAccordion>
			</div>
		);
	}
	render() {
		return (
			<div className="bit-link-card nomargin">
				<div className="bit-link-invest-top">
					<div>
						<h5>Mulai Investasi Sekarang</h5>
						<p>Raih keuntungan yang lebih tinggi dari deposito</p>
					</div>
					<div><img src={Investime} alt="investime icon"/></div>
				</div>
				{this.renderCards()}
			</div>
		);
	}
}

ViewOverviewProductCard.defaultProps = {
	reksadana: [],
};

const mapStateToProps = state => {

	const items = getReksadanaProducts(state);

	return {
		reksadana_count: items.length,
		reksadana: items,
		defaultCategoryId: getPortfolioDefaultCategoryId(state)
	};
	
};

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchProducts
}, dispatch);

const composed = compose(
	connect(mapStateToProps, mapDispatchToProps)
);

export default composed(ViewOverviewProductCard);