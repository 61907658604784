/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft} from 'react-feather';
import ChangeBank from './../../assets/images/emailSend.png';

class ChangeBankSent extends Component {
	  render() {
	  	const { history } = this.props;
	  	return (
	  		<div className="bit-link-main-wrap bit-change-send-verif">
	  			<div className="bit-link-main-header">
	  				<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
	  				<div>Ubah Akun Bank</div>
	  				<div/>
	  			</div>
	  			<div className="bit-link-main-content">
				  	<div class="login-register-icon">
	  					<img src={ChangeBank} alt="change bank"/>
					</div>
					<div class="login-register-heading">
					Email Verifikasi Berhasil Dikirim, Silahkan Cek Email Anda.
					</div>
					<div className="bit-link-bottom-stay-content">
	  					<Link to="/" className="bit-link-btn-red-full margin-top-20">Kembali ke halaman utama</Link>
					</div>
	  			</div>
	  		</div>
	  	);
	  }
} 

export default (ChangeBankSent);