import React,{Fragment} from 'react';
import { Link } from 'react-router-dom';

import BitInvest from './../../../assets/images/bibit-invest.svg';

export default function ViewLandingRegister (props) {
	return (
		<Fragment>
			<div className="bit-link-centered-img">
				<img src={BitInvest} alt="bibit invest" />
			</div>    
			<p>Nomor kamu belum terdaftar sebagai pengguna bibit</p>
			<Link to={`/otp-verification?register=true`}>
				<button className="bit-link-btn-red margin-top-15">Ayo Daftar</button>
			</Link>	
			<Link className="bit-already-txt" to="/different-number">Sudah punya akun di bibit ?</Link>
		</Fragment>
	);
}