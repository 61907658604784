/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Fragment } from 'react';
import { ActivityIndicator, Button, List } from 'antd-mobile';
import { Field, Form, Formik } from 'formik';
import BankPicker from '../Common/BankPicker/BankPickerContainer';
import BaseInput from '../Base/BaseInput';
import BankAccountPicker from '../Common/BankAccountPicker';
import BankAccountSelfiePicker from '../Common/BankAccountSelfiePicker';
import { serviceAddBank } from '../../services/bank.services';
import { isLoadingSelector } from '../../services/apimanager/apimanager.selector';
import list from "../../services/apiconstant";
import { connect } from 'react-redux';

class ChangeBankForm extends React.PureComponent {
	
	state = {
		openBank: false
	}

	renderForm = () => {
		return (
			<Formik
				enableReinitialize
				initialValues={{bank: '', bank_account_number: '', bank_account_name: '', file_bank_account: '', file_prove: ''}}
				validate={() => {
					let errors = {};
					return errors;
				}}
				onSubmit={(values, { setSubmitting, setErrors }) => {
					
					setSubmitting(true);
					
					const {
						bank_account_number,
						bank,
						bank_account_name,
						file_bank_account,
						file_prove,
					} = values;

					serviceAddBank({
						token: this.props.token, 
						pin: this.props.pin, 
						bank, 
						bank_account_name, 
						bank_account_number,
						file_prove, 
						file_bank_account
					}).then(resp => {
						this.props.onSuccessSubmit(resp);
						setSubmitting(false);
					}).catch(err => {
						if(err.response?.data?.errors){
							setErrors(err.response?.data?.errors);
						}
						this.props.onErrorSubmit(err);
						setSubmitting(false);
					});

				}}
			>
				{({ handleSubmit, isSubmitting,  isValid}) => {
					const isDisabled = (isSubmitting);
					return (
						<Form onSubmit={(e) => e.preventDefault()}>
							<div className="cb-wrap bit-change-bank-form">
								<List renderHeader={() => 'Nomor Rekening'}>
									<BaseInput 
										name="bank_account_number"
										placeholder="contoh: 123123123"
										type="number"
									/>
									<span className="red"></span>
								</List>
								<List renderHeader={() => 'Nama Bank'}>
									<Field name="bank" render={({ field, form }) => {
										const {
											errors,
											setFieldValue,
										} = form;	
										return(
											<Fragment>
												<BankPicker 
													isvisible={this.state.openBank}
													onClickItem={bank => {
														const {id} = bank;
														this.setState({openBank: false}, () => {
															setFieldValue(field.name, id);
														});
													}}
													valueId={field.value}
													onClose={() => this.setState({openBank: false})}
													onOpen={() => this.setState({openBank: true})}
												/>
												{
													errors[field.name] && 
													<div className="error">{errors[field.name]?.msg}</div>
												}
											</Fragment>
										);
									}}/>
							
								</List>

								<List renderHeader={() => 'Nama Pemilik Rekening'}>
									<BaseInput 
										name="bank_account_name"
										placeholder="contoh: John Doe"
									/>
									<span className="red"></span>
								</List>
								<p className="kettxt">
						Pastikan nama kamu sama dengan nama yang terdaftar di rekening bank kamu.
								</p>

								<div className="bit-link-upload-txt">Upload Foto Verifikasi</div>
								<div className="bit-link-card nomargin">
									<Field name="file_bank_account" render={({field, form}) => {
										const {
											errors,
											setFieldValue,
										} = form;	
										return (
											<Fragment>
												<BankAccountPicker 
												
													title="Foto Buku Tabungan / E-Statement"
													buttonTitle="Foto Buku Tabungan / E-Statement"
											
													userid={this.props.userid} 
													value={field.value}
													onComplete={outputUrl => {
														setFieldValue(field.name, outputUrl);
													}} 
													token={this.props.token}
												/>
												{
													errors[field.name] && 
												<div className="error">{errors[field.name]?.msg}</div>
												}

											</Fragment>
										);
									}}/>
									<Field name="file_prove" render={({field, form}) => {
										const {
											errors,
											setFieldValue,
										} = form;
										return (
											<Fragment>
												<BankAccountSelfiePicker

													title="Foto Selfie dengan Buku Tabungan / E-Statement"
													buttonTitle="Foto Selfie dengan Buku Tabungan / E-Statement"
											
													userid={this.props.userid} 
													value={field.value}
													onComplete={outputUrl => {
														setFieldValue(field.name, outputUrl);
													}}
													token={this.props.token}
												/>
												{
													errors[field.name] && 
													<div className="error">{errors[field.name]?.msg}</div>
												}
											</Fragment>
										);
									}}/>
								</div>
							</div>
							<div className="primary-btn-fixed-wrap margin-top-20">
								<Button
									type="primary"
									className="primary-btn" 
									isDisabled={isDisabled || !isValid}
									disabled={isDisabled || !isValid}
									onClick={handleSubmit}
								>
									Tambah
								</Button>
								<br />
							</div>
						</Form>
					);
				}}
			</Formik>

		);
	}
	render() {
		return (
			<div className="bit-link-main-wrap bit-change-bank-setting">
				<div className="bit-link-main-header">
	  				<div>Tambah Rekening Bank</div>
	  				<div/>
	  			</div>
				<div className="bit-link-main-content">
					{this.renderForm()}
				</div>	
				<ActivityIndicator
					toast
					text="Memproses"
					animating={this.props.isloading}
				/>
			</div>
		);
	}
}

ChangeBankForm.defaultProps = {
	onSuccessSubmit: () => {},
	onErrorSubmit: () => {},
};

const mapStateToProps = state => {
	return {
		isloading: isLoadingSelector(state, list.userChangeBankUpdate)
	};
};

export default connect(mapStateToProps)(ChangeBankForm);