import React, {Component, Fragment, createRef} from 'react';
import { 
	Formik, 
	Form, 
	Field, 
	// ErrorMessage 
} from 'formik';
// import { ChevronRight } from 'react-feather';

import { Link } from 'react-router-dom';
import { List, Modal } from 'antd-mobile';

import BaseInput from './../../Base/BaseInput';
import BasePicker from './../../Base/BasePicker';
import BankPicker from './../../Common/BankPicker/BankPickerContainer';
import IdentityPicker from './../../Common/IdentityPicker';
import IdentitySelfiePicker from './../../Common/IdentitySelfiePicker';
import SignatureInput from './../../Common/SignatureInput';

import NikRegistered from './../../../assets/images/nik-registered.svg';
import { getSafelyWithDefault } from '../../../utils/safely';

class FormRegister extends Component {
	constructor(props) {
		super(props);
		const defaultValues = props.defaultValues? 
			props.defaultValues:
			FormRegister.defaultProps.defaultValues;
		this.state = {
			isSubmitting: false,
			modalNikRegistered: false,
			modalAlreadyRegistered: false,
			modalMaxBankSlot : false,
			defaultValues: defaultValues
		};

		this.formikRef = createRef();
	}
	componentDidMount () {
		const {
			userphone
		} = this.props;
		const newDefaultValues = {
			...this.state.defaultValues, phonenumber: userphone,
		};
		this.setState(function(prevState, props) {
			return {
				...prevState,
				defaultValues: newDefaultValues
			};
		});
	}
	componentDidUpdate(prevProps, prevState) {
		if(prevProps.err !== this.props.err && this.props.err) {

			const errors = getSafelyWithDefault(['data', 'errors'], this.props.err, {});
			const type = getSafelyWithDefault(['data', 'type'], this.props.err, '');

			if(type === 'max_bank_slot' && !this.state.modalMaxBankSlot) 
				this.setState({
					modalMaxBankSlot: true
				});
			
			if(type === 'user_already_registered' && !this.state.modalAlreadyRegistered) 
				this.setState({
					modalAlreadyRegistered: true
				});

			const keys = Object.keys(errors);

			const _errors={};

			keys.map(item => {

				const data = errors[item];
				const alias = this.props.mapErrorKey[item];
				if(alias){
					_errors[alias]=data.msg;
				} 
				else{
					// Custom error message for blank email
					if ((data?.param === 'email') && (data?.value === '')){
						_errors[item]='Alamat email kamu dibutuhkan';
					}else{
						_errors[item]=data.msg;
					}
				}

				if((alias === 'identity' || item === 'identity') && !this.state.modalNikRegistered)
					this.setState({
						modalNikRegistered: true
					});
				
				return item;

			});
			this.formikRef.current.setErrors(_errors);
		}
	}
	_getData = (name) => {
		const datas = this.props[name];
		if(typeof datas.map !== 'function') return [];
		return datas.map((item, index) => ({ label: item, value: index }));
	}
	showModal = key => (e) => {
		e.preventDefault(); 
		this.setState({
			[key]: true,
		});
	}
	onClose = key => () => {
		this.setState({
			[key]: false,
		});
	}
	renderModal = () => {
		return (
			<Fragment>
				<Modal
					popup
					visible={this.state.modalNikRegistered}
					onClose={this.onClose('modalNikRegistered')}
					animationType="slide-up"
				>
					<div className="bit-link-modal-centered">
						<img src={NikRegistered} alt="" />
						<h3>NIK kamu sudah terdaftar</h3>
						<button className="bit-link-btn-red margin-top-15" onClick={this.onClose('modalNikRegistered')}>Kembali</button>
					</div>	 
				</Modal>
				<Modal
					popup
					visible={this.state.modalMaxBankSlot}
					onClose={this.onClose('modalMaxBankSlot')}
					animationType="slide-up"
				>
					<div className="bit-link-modal-centered">
						<img src={NikRegistered} alt="" />
						<h3>Bank slot kamu melebihi batas</h3>
						<button className="bit-link-btn-red margin-top-15" onClick={this.onClose('modalMaxBankSlot')}>Kembali</button>
					</div>	 
				</Modal>
				<Modal
					popup
					visible={this.state.modalAlreadyRegistered}
					onClose={this.onClose('modalAlreadyRegistered')}
					animationType="slide-up"
				>
					<div className="bit-link-modal-centered">
						<img src={NikRegistered} alt="" />
						<h3>Akun kamu sudah terdaftar, coba login ulang.</h3>
						<button className="bit-link-btn-red margin-top-15" onClick={this.onClose('modalAlreadyRegistered')}>Kembali</button>
					</div>	 
				</Modal>
			</Fragment>
		);
	}

handleSubmit = (values) => this.props.onSubmit(values);

/**
 *
 *
 * @returns
 * @memberof FormRegister
 */
render() {
	return (
		<Fragment>
			<Formik
				enableReinitialize
				initialValues={this.state.defaultValues}
				ref={this.formikRef}
				onSubmit={(values, { setSubmitting }) => {
					this.handleSubmit(values)
						.then(() => setSubmitting(false));
					this.setState({
						isSubmitting: true
					});
				}}
			>
				{({ handleSubmit, isSubmitting,  errors}) => {
					const isDisabled = (isSubmitting);
					return (<Form >
						<List renderHeader={() => 'Alamat Email'}>
							<BaseInput name="email" placeholder="jhondoe@email.com" />
						</List>
						{/* <List renderHeader={() => 'Nomor Telepon'}>
								<BaseInput disabled={true} name="phonenumber" placeholder="0858888888" />
							</List> */}
						{/* <List renderHeader={() => 'Nomor KTP'}>
								<BaseInput name="identity" placeholder="12314213213" />
							</List> */}
						<List renderHeader={() => 'Pendidikan'}>
							<BasePicker name="education"  data={this._getData('educationOptions')} />			
						</List>
						<List renderHeader={() => 'Pendapatan Pertahun'}>
							<BasePicker  name="earning" data={this._getData('earningOptions')} />			
						</List>
						<List renderHeader={() => 'Sumber Penghasilan'}>
							<BasePicker  name="source" data={this._getData('earningsourceOptions')} />
						</List>
						<List renderHeader={() => 'Nama Bank'}>
							<Field name="bank" render={({ field, form}) => {
								return <BankPicker 
									isvisible={this.state.openBank}
									onClickItem={bank => {
										const {id} = bank;
										this.setState({openBank: false}, () => {
											form.setFieldValue(field.name, id);
										});
									}}
									valueId={field.value}
									onClose={() => this.setState({openBank: false})}
									onOpen={() => this.setState({openBank: true})}
								/>;
							}}/>
							{	
								Boolean(errors['bank']) && 
								<div className="error">{errors['bank']}</div>
							}
							
						</List>
						<List renderHeader={() => 'Nomor Rekening'}>
							<BaseInput type="number" name="bank_account_number" placeholder="12314213213" />
						</List>
						<List renderHeader={() => 'Nama Pemilik Rekening'}>
							<BaseInput name="bank_account_name" placeholder="John Doe" />
						</List>
						<p className="bit-link-blue-txt">Pastikan nama kamu sama dengan nama yang tertera di buku tabungan.</p>
						<div className="bit-link-upload-txt">Upload Foto Identitas</div>
						<div className="bit-link-card nomargin">
							<Field name="ktp" render={({field, form}) => {
								const {
									errors,
									setFieldValue,
									touched,
								} = form;						 
								return (
									<Fragment>
										<IdentityPicker 
												
											title="Upload E-KTP"
											buttonTitle="Foto KTP"
											
											userid={this.props.userid} 
											value={field.value}
											onComplete={outputUrl => {
												setFieldValue(field.name, outputUrl);
											}} 
										/>
										{
											touched[field.name] &&
												errors[field.name] && 
												<div className="error">{errors[field.name]}</div>
										}
									</Fragment>
								);
							}}/>
							<Field name="selfie" render={({field, form}) => {
								const {
									errors,
									setFieldValue,
									touched,
								} = form;
								return (
									<Fragment>
										<IdentitySelfiePicker

											title="Upload EDD"
											buttonTitle="Upload Selfie KTP"
											
											userid={this.props.userid} 
											value={field.value}
											onComplete={outputUrl => {
												setFieldValue(field.name, outputUrl);
											}} 
										/>
										{
											touched[field.name] &&
												errors[field.name] && 
												<div className="error">{errors[field.name]}</div>
										}
									</Fragment>
								);
							}}/>
							{/* <Link to={`/upload-selfie-ktp`}>
									<div className="bit-link-upload-form">
										<div className="bit-link-col-1">
											<span>
											</span>
										</div>
										<div className="bit-link-col-1">
										Foto Selfie dengan KTP
										</div>
										<ChevronRight size="18" color="#b5b5b5" />
									</div>
								</Link>	 */}
						</div>	
						<div className="bit-link-upload-txt">Tanda Tangan (klik dibawah ini)</div>
						<Field name="signaturedata" render={({field, form}) => {
							const {
								errors,
								touched,
							} = form;
							return (
								<Fragment>
									<SignatureInput
										value={field.value}
										defaultPreview={this.state.defaultValues.signatureBase64}
										onComplete={(base64, value) => {
											form.setFieldValue(field.name, value);
											form.setFieldValue('signatureBase64', base64);
										}}
									/>
									{
										touched['signatureBase64'] &&
												errors['file_signature'] && 
												<div className="error">{errors['file_signature']}</div>
									}
								</Fragment>
							);
						}} />
						{/* <Link to={`/signature`}>
								<div className="bit-link-signature"></div>
							</Link>	 */}
						<div className="bit-link-regist-term">Saya telah membaca dan menyetujui 
							<Link to="/terms-condition"> syarat dan ketentuan </Link> 
								Pembukaan Rekening Efek Reksa Dana dan Transaksi
								Online Reksa Dana di PT Bibit Tumbuh Bersama
						</div>
						{/* <Link to={`/create-pin`}> */}
						<button 
							type="submit" 
							className="submit-daftar bit-link-btn-red-full margin-top-15"
							disabled={isDisabled}
						>Daftar</button>
						{/* </Link>	 */}
						{/* <Field type="email" name="email" />
						<ErrorMessage name="email" component="div" />
						<Field type="password" name="password" />
						<ErrorMessage name="password" component="div" />
						<button type="submit" disabled={isSubmitting}>
						Submit
					</button> */}
					</Form>
					);
				}}
			</Formik>
			{this.renderModal()}
		</Fragment>
	);
}
}

FormRegister.defaultProps = {
	onSubmit: () => {},
	mapErrorKey: {},
	defaultValues: { 
		email: '', 
		phonenumber: '08581776XXXX',
		identity: '',
		education: 0,
		earning: 0,
		source: 0,
		bank: 0,
		bank_account_name: '',
		bank_account_number: '',
		ktp: '',
		selfie: '',
		signaturedata: [],
		signatureBase64: '', 
		userid: '',
	},
	educationOptions: [ // bukan index array
		'SD',
		'SMP',
		'SMA',
		'Diploma',
		'S1',
		'S2',
		'S3',
		'Lainnya'
	],
	earningOptions: [ // bukan index array
		'< 10 Juta / tahun',
		'Rp10 Juta - 50 Juta / tahun',
		'> Rp50 Juta - 100 Juta / tahun',
		'> Rp100 Juta - 500 Juta / tahun',
		'> Rp500 Juta - 1 Miliar / tahun',
		'> Rp1 Miliar / tahun'
	],
	earningsourceOptions: [
		'Gaji',
		'Keuntungan Bisnis',
		'Bunga Simpanan',
		'Warisan',
		'Dari Orang Tua / Anak',
		'Undian',
		'Dari Suami / istri',
		'Tabungan',
		'Hasil Investasi',
		'Lainnya'
	],
	banks: [],
	maritalOptions: [{"id":1,"name":"Lajang"},{"id":2,"name":"Menikah"},{"id":3,"name":"Cerai"}],
	occupationOptions: [
		{
			"id": 1,
			"name": "Pelajar"
		},
		{
			"id": 2,
			"name": "Ibu Rumah Tangga"
		},
		{
			"id": 3,
			"name": "Wirausaha"
		},
		{
			"id": 4,
			"name": "Pegawai Negeri Sipil"
		},
		{
			"id": 5,
			"name": "TNI/Polri"
		},
		{
			"id": 6,
			"name": "Pensiunan"
		},
		{
			"id": 7,
			"name": "Guru"
		},
		{
			"id": 8,
			"name": "Pegawai Swasta"
		},
		{
			"id": 9,
			"name": "Lainnya",
		},
	],
	regiligionOptions: [
		{"id":1,"name":"Islam"},
		{"id":2,"name":"Protestan"},
		{"id":3,"name":"Katolik"},
		{"id":4,"name":"Hindu"},
		{"id":5,"name":"Budha"},
		{"id":6,"name":"Kong Hu Cu"}
	],
};

export default FormRegister;