import React, {Component} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ChevronLeft , Calendar } from 'react-feather';

// import { Link } from 'react-router-dom';
// import { List, Button } from 'antd-mobile';

import GlobalLoader from './../../Global/GlobalLoader';


import {
	fetchOrderList
} from './../../../entities/order.reducer';

import {
	getOrders
} from './../../../entities/order.selector';

import {
	getStatusLoadingSelector
} from './../../../services/apimanager/apimanager.selector';

import list from './../../../services/apiconstant';

import GlobalHeader from '../../Global/GlobalHeader/GlobalHeader';
import InvestmentCompanyLogo from '../../Common/InvestmentCompanyLogo';
import Puller from '../../Puller/Puller';

class HistoryTransaction extends Component {
	componentDidMount () {
		this.props.fetchOrderList();
	}
	renderCard = () => {
		return this.props.orders.map((order, index) => {
			const {
				product: {
					name,
					type,
					investment_manager: {
						ojkCode
					}
				},
				code,
				status_text,
				method,
				estAmount_text,
				amount_text,
				amount,
				lastupdate_text,
			} = order;
			return (
				<div key={code+index} onClick={() => this.props.history.push('/history-transaction/'+code)} className="bit-link-history-row">
					<div className="bit-reksa-item">
						<div className='bit-link-info'>
							<div className="bit-reksa-pic">
								<InvestmentCompanyLogo
									type="investment-manager"
									size="square"
									ojkCode={ojkCode}
								/>
							</div>
							<div>
								<h3>{name}</h3>
								<p>{type}</p>
							</div>
						</div>
						<div className={method === 'sell'?"bit-link-sell-badge": "bit-link-buy-badge"}>{method === 'sell'? 'Penjualan': 'Pembelian'}</div>
					</div>
					<div className="bit-link-history-flex">
						<div className="font-green-bibit">{`Rp${method === 'sell' && amount? amount_text: estAmount_text}`}</div>
						<div><Calendar height="14px" color="#666666"/>{lastupdate_text}</div>
					</div>
					<div className="bit-link-transaction-status">{status_text}</div>
				</div>
			);
		});
	}
	renderLoading = () => {
		return (
			<div className="bit-link-loader-wrap">
				<div>
					<GlobalLoader />
				</div>	
			</div>
		);
	}
	render() {
		const { history } = this.props;
		return (
			<div className="bit-link-main-wrap">
				<div className="bit-link-main-header">
					<GlobalHeader 
						title='Riwayat'
						renderButtonLeft={() => 
							<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
						}
					/>
				</div>
				<div className="bit-link-main-content bit-link-puller-wrap bit-link-history-wrap">
					<Puller
						loading={this.props.isLoading}
						onRefresh={() => this.props.fetchOrderList()}
					>
						{!this.props.isLoading && this.renderCard()}
						{this.props.isLoading && this.renderLoading()}
					</Puller>
				</div>
			</div>
		);
	}
} 

const mapStateToProps = state => {
	
	const isLoading = getStatusLoadingSelector(state, [
		list.orderHistory,
	]);

	const orders = getOrders(state);

	return {
		orders: orders,
		ordersLength: orders.length,
		isLoading: isLoading,
	};
};
const mapStateToDispatch = dispatch => bindActionCreators({
	fetchOrderList
}, dispatch);

const composed = connect(mapStateToProps, mapStateToDispatch);

export default composed(HistoryTransaction);