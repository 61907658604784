import React, { Fragment, Component } from 'react';
import { Modal, ActivityIndicator } from 'antd-mobile';
import PreferensiSyariah from './../../../assets/images/preferensi-syariah.svg';
import { getSyariahPreference } from '../../../entities/user.selector';
import { connect } from 'react-redux';

import {
	enableSyariah,
	disableSyariah,
} from './syariah.reducer';
import { bindActionCreators } from 'redux';
import { serviceSyariahDisable, serviceSyariahEnable } from '../../../services/syariah.services';
import { isLoadingSelector } from '../../../services/apimanager/apimanager.selector';
import apiconstant from '../../../services/apiconstant';

class InitFlagSyariah extends Component {
	state = {
		open: false
	}
	componentDidMount () {
		if(typeof this.props.syariah !== 'number') 
			this.setState({
				open: true
			});
	}
	handleClose = () => this.setState({open: false})
	handleOpen = () => this.setState({open: true})
	handleDisable = () => {
		serviceSyariahDisable()
			.then(() => this.props.disableSyariah());
		this.handleClose();
	}
	handleEnable = () => {
		serviceSyariahEnable()
			.then(() => this.props.enableSyariah());
		this.handleClose();
	}
	render () {
		return (
			<Fragment>
				<Modal
					popup
					maskClosable={false}
					visible={this.state.open}
					onClose={this.handleClose}
					animationType="slide-up"
					className="bit-link-modal-setting"
				>
					<div className="bit-modal-setting-content">
						<img src={PreferensiSyariah} alt="preferensi syariah"/>
						<p>Mulai investasi sesuai prinsip syariah</p>
						<div className="bit-link-dual-button">
							<button onClick={this.handleDisable}>Nanti</button>
							<button onClick={this.handleEnable}>Aktifkan</button>
						</div>  
					</div>
				</Modal> 
				<ActivityIndicator 
					toast
					text={'Permintaan sedang di proses'}
					animating={this.props.isLoading}
				/> 
			</Fragment>
			
		);
	}
}

const mapStateToProps = (state) => {
	const {
		userPreferenceUpdate,
	} = apiconstant;
	const isSyariah = getSyariahPreference(state);
	return {
		syariah: isSyariah,
		isLoading: isLoadingSelector(state, userPreferenceUpdate),
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	enableSyariah,
	disableSyariah,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InitFlagSyariah);