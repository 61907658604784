import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { ChevronLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { ActivityIndicator } from 'antd-mobile';
import { withRouter } from 'react-router-dom';

// import getSafely from './../../utils/safely';
// import { Modal } from 'antd-mobile';

import FormRegister from './FormRegister/FormRegister';
import { submitRegister as _submitRegister } from './ViewRegisterRevisionForm.reducer';

import {
	isLoadingSelector,
	isErrorSelector,
} from './../../services/apimanager/apimanager.selector';

import list from './../../services/apiconstant';
import { getUserProfile, getUserId, getUserPhone, getUserProfileStatus } from '../../entities/user.selector';

// import NikRegistered from './../../assets/images/nik-registered.svg';

class ViewRegisterRevisionForm extends Component {
	_profileToRegister = profile => {
		const {
			bank,
			bank_account_name,
			bank_account_number,
			file_edd,
			file_identity,
			file_signature,
			id,
			phone,
			email,
			identity,
			education,
			income,
			income_source,
		} = profile || {};
		return { 
			email: email, 
			phonenumber: phone,
			identity: identity,
			education: education,
			earning: income,
			source: income_source,
			bank: bank,
			bank_account_name: bank_account_name,
			bank_account_number: bank_account_number,
			ktp: file_identity,
			selfie: file_edd,
			signaturedata: [],
			signatureBase64: file_signature, 
			userid: id,
		};
	}
	
	render() {
		const { 
			history,
			submitRegister,
			profile,
		} = this.props;
		const defaultValues = this._profileToRegister(profile);
		return (
			<div className="bit-link-main-wrap">
				<div className="bit-link-main-header">
					<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => {
						history.goBack();
					}}/>
					<div>Revisi</div>
					<Link className="bit-link-skip" to="index-home">Skip</Link>
				</div>
				<div className="bit-link-main-content">
					<div className="bit-link-register">
						<FormRegister 
							{...this.props}
							mapErrorKey={{
								file_edd: 'selfie',
								file_identity: 'ktp',
								signature: 'signaturedata',
							}}
							defaultValues={defaultValues}
							onSubmit={submitRegister}
						/>
					</div>
				</div>
				<ActivityIndicator
					toast
					text="Memproses"
					animating={this.props.isloading}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const profile = getUserProfile(state);
	const toReturn = {
		userid: getUserId(state),
		userphone: getUserPhone(state),
		status: getUserProfileStatus(state),
		err: isErrorSelector(state, list.registerSubmit),
		isloading: 
			isLoadingSelector(state, list.registerSubmit) ||
			isLoadingSelector(state, list.bankList) ||
			isLoadingSelector(state, list.awsToken) ||
			isLoadingSelector(state, list.awsBucket),
		profile: profile,
	};

	return toReturn;
};

const mapStateToDispatch = dispatch => bindActionCreators({
	submitRegister: _submitRegister
}, dispatch);

const composed = compose(
	connect(mapStateToProps, mapStateToDispatch),
	withRouter
);

export default composed(ViewRegisterRevisionForm);