import Api from './../utils/newapimanager';
import list from './apiconstant';

// const api = new Api();
const api = Api;

export const servicePostEmailChange = ({email}) => 
	api.post(list.emailRegisterChange, {email});

export const servicePostResendEmail = ({email}) => 
	api.post(list.emailVerification, {email});