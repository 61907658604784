import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {bindActionCreators, compose} from 'redux';


import { getUserProfileStore } from './../../../utils';

import getSafely from './../../../utils/safely';
import parseQuery from './../../../utils/parseQuery';

import LogoBibit from './../../../assets/images/logo-bibit.svg';

import PinInput from '../../Common/PinInput/PinInput';

import { isErrorSelector, isLoadingSelector } from './../../../services/apimanager/apimanager.selector';

import ActivityIndicator from 'antd-mobile/lib/activity-indicator';

import { 
	postSubmitPin,
	postCheckPin
} from './ViewInputPin.reducer';

import list from './../../../services/apiconstant';
import { getPinLength } from '../../../entities/user.selector';
import GlobalHeader from '../../Global/GlobalHeader/GlobalHeader';

class ViewInputPin extends Component {
	state={
		max: 6,
		querystring: null,
		data: {},
		step: 0,
		isSubmit: false,
	}
	componentDidMount() {
		const {
			location: {
				search
			},
			status,
			history,
			pin,
		} = this.props;

		if(status >= 1 && pin > 0 && this.isRegister()) 
			return history.push('/index-home'); 

		const _search = parseQuery(search);
		this.setState({	
			querystring: _search
		});
		this.setMaximumPinLength();

	}
	componentDidUpdate(prevProps) {
		const {err} = this.props;
		const type = getSafely(['data', 'type'], err);
		if(type === 'failed_pin_already_setup') {
			const {
				history
			} = this.props;
			history.push('/success-connected');
			setTimeout(() => {
				history.push('/index-home');
			}, 3000);
		}
	}
	setMaximumPinLength = () => {
		const userProfile = getUserProfileStore();
		const pinLength = getSafely(['profile', 'pin_length'], userProfile);
		const { max } = this.props; //this will force to 6 for change pin
		this.setState({
			max: max ? max : pinLength || 6,
		});
	}
	isRegister = () => {
		const {
			querystring
		} = this.state;
		const register = getSafely(['register'], querystring);
		return register==='true';
	}
	handleSubmit = async (pin) => {

		if(this.isRegister()) {

			this.setState({
				data: {
					...this.state.data,
					confirmpin: pin,
				},
				isSubmit: true
			}, () => {

				const {data} = this.state;

				const {
					pin: _pin,
					confirmpin
				} = data;

				return this.props.postSubmitPin(_pin, confirmpin).catch(() => {
					this.setState({
						data: {},
						step: 0
					});
				});

			});
		}
		else{
			this.setState({
				data: {
					...this.state.data,
					pin: pin
				}
			}, () => {
				const {data} = this.state;
				const {
					pin: _pin
				} = data;
				return this.props.postCheckPin(_pin).catch(() => {
					this.setState({
						data: {},
						step: 0
					});
				});
			});
		}

	}
	stephandleSubmit = async (pin) => {
		this.setState({
			data: {
				...this.state.data,
				pin: pin
			},
			step: 1,
			isSubmit: false
		});

	}
	renderCheckPin = () => {
		return (
			<Fragment>
				<div className="bit-link-title-reg">
					<h5>Reksa Dana</h5>
					<h1>{'Masukan Pin Bibit'}</h1>
				</div> 
				<PinInput
					key={this.state.step}
					max={this.props.max}
					onSubmitPin={this.handleSubmit}
					errorMessage={this.props.err_message}
				/>
			</Fragment>
		);
	}
	renderRegisterPin = () => {
		return (
			<Fragment>
				<div className="bit-link-title-reg">
					<h5>Reksa Dana</h5>
					<h1>{this.state.step === 0 ?'Buat Pin Bibit': 'Konfirmasi Pin Bibit'}</h1>
				</div> 
				<PinInput
					key={this.state.step}
					max={this.props.max}
					onSubmitPin={(this.state.step === 0)? this.stephandleSubmit: this.handleSubmit}
					errorMessage={this.customErrorMessage()}
					isPinSubmitDefault={this.state.isSubmit}
				/>
			</Fragment>
		);
	}

	/**
	 * Use this function to customizing error message
	 * or update error message from BE response
	 */
	customErrorMessage = () => {
		const currentErrorMessage = this.props.err_message;
		if (this.isRegister() && currentErrorMessage === 'Terjadi kesalahan! periksa kembali data yang Kamu masukan.') {
			return 'Konfirmasi PIN salah';
		}
		return currentErrorMessage;
	}

	render() {

		// const {
		// 	onBack,
		// 	renderOnBack,
		// 	loading,
		// 	title,
		// 	headerText,
		// 	descLabel,
		// 	showSupport,
		// 	errorMessage,
		// } = this.props;

		const { isloading } = this.props;
		return (
			<div>
				<div className="bit-link-wrap">
					<div className="bit-link-header">
						<GlobalHeader />
					</div>
					<div className="bit-link-register">
						{this.isRegister()? 
							this.renderRegisterPin(): 
							this.renderCheckPin()}
						<ActivityIndicator 
							toast
							text="Loading..."
							animating={isloading}
						/>
					</div>
					<div className="bit-link-footer">
						<div>Powered by :</div> 
						<div><img src={LogoBibit} alt="logo bibit" /></div>
					</div>
				</div>
			</div>
		);
	}
} 

const mapStateToProps = state => {
	
	const pin_length = getPinLength(state) || 6;

	const isloading = isLoadingSelector(state, list.pinSetup) ||
						isLoadingSelector(state, list.pinCheck);
	const err = isErrorSelector(state, list.pinSetup) ||
				isErrorSelector(state, list.pinCheck);
	let err_message = getSafely(['message'], err);
	if(!err_message) {
		err_message = '';
	}
	return {
		isloading: isloading,
		err: err,
		err_message,
		max: pin_length,
		status: getSafely(['userpartner', 'user_partner_status'], state),
		pin: getSafely(['user', 'profile', 'pin'], state),
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	postSubmitPin,
	postCheckPin
}, dispatch);

const composed = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
);

export default composed(ViewInputPin);