import React, {Fragment	, PureComponent} from 'react';
import PropTypes from 'prop-types';
import Info from 'react-feather/dist/icons/info';
import { Modal } from 'antd-mobile';
import './InfoModal.css';

class InfoModal extends PureComponent {
	state = {
		visible: false,
	};

	handleToggleModal = e => {
		if(typeof e.preventDefault === 'function')
			e.preventDefault();
		if(typeof e.stopPropagation === 'function')
			e.stopPropagation();
		const { onToggleModal } = this.props;
		if (onToggleModal) {
			return onToggleModal();
		}
		this.setState(prevState => ({ visible: !prevState.visible }));
	};

	render() {
		const { visible } = this.state;
		const { renderButton } = this.props;
		return (
			<Fragment>
				{renderButton(this.handleToggleModal)}
				<Modal
					visible={visible}
					popup
					animationType="slide-up"
					onClose={this.handleToggleModal}
					{...this.props}
					className="info-modal-wrap"
				>
					{/* <span onClick={this.handleToggleModal} className="modal-close-new"><X size="16" color="#6b6b6b" /></span> */}
					<div className="info-modal-body">{this.props.children}</div>
				</Modal>
			</Fragment>
		);
	}
}

InfoModal.defaultProps = {
	renderButton: (handleToggleModal) => <span className="infoIcon" onClick={handleToggleModal}><Info size="11"  color="#639fca" /></span>
};

InfoModal.propTypes = {
	showClose: PropTypes.bool,
	modalProps: PropTypes.object,
	children: PropTypes.node,
	header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default InfoModal;
