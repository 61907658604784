import Api from '../utils/newapimanager';
import list from "../services/apiconstant";

// const api = new Api();
const api = Api;

export const serviceFetchReksadanaById = (fundId) => 
	api.get(list.products(fundId));

export const serviceFetchAllReksadana = () => 
	api.get(list.allproducts);

export const servicePostOrderBuy = (toBuy) => 
	api.post(list.orderBuy, toBuy);
    
export const servicePostSellPreview = ({ symbol, amount, type, categoryid }) => 
	api.post(list.orderSellPreview, {
		symbol, amount, type, categoryid
	});

export const servicePostOrderSell = ({ symbol, amount, type, categoryid, token, bank }) => 
	api.post(list.orderSell, {
		symbol, amount, type, categoryid, token, bank
	});

export const serviceFetchOrderDetailByCode = (code) => 
	api.get(list.orderDetailByCode(code));

export const serviceFetchOrderHistory = () =>
	api.get(list.orderHistory);
    
export const serviceFetchDocumentBySymbol = (symbol) =>
	api.get(list.symbolDocuments(symbol));

export const serviceFetchProductsChart = ({symbol, period}) => 
	api.get(list.productsChart(symbol), {period});

export const serviceFetchProductsChartAum = ({symbol, period}) => 
	api.get(list.productsChartAum(symbol), {period});