import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PullToRefresh from 'pulltorefreshjs';
import { generateRandomString } from './../../utils';

const TIMEOUT = 1500; // set timeout to 1500

class Puller extends Component {
	constructor (props) {
		super(props);
		this.state = {
			scrolled: false,
			identifier: props.identifier? props.identifier: this._generateIdentifier(),
		};
		this.resolve = () => {};
	}
	componentDidMount () {
		this.pltr = PullToRefresh.init({
			mainElement: '#'+ this.state.identifier,
			triggerElement: '#'+ this.state.identifier,
			distThreshold: 80,
			distMax: 100,
			onRefresh: this.handleRefresh,
			shouldPullToRefresh: this.shouldPullToRefresh
		});
		this.scrollel = ReactDOM.findDOMNode(this).parentElement;
		this.scrollel.addEventListener('scroll', this.handleScroll);
	}

	componentDidUpdate(prevProps) {
		if(this.props.loading !== prevProps.loading) {
			if(!this.props.loading && typeof this.resolve === 'function') {
				this.resolve();
				clearTimeout(this.timeout);
			}
		}
	}
	componentWillUnmount () {
		this.scrollel.removeEventListener('scroll', this.handleScroll);
		this.pltr.destroy();
	}
	_generateIdentifier = () => {
		const random = generateRandomString();
		const name = 'puller';
		return name + '-' + random;
	}
	handleRefresh = () => {

		this.props.onRefresh();

		const p = new Promise(resolve => this.resolve = resolve);
		this.timeout = setTimeout(() => {
			if(typeof this.resolve === 'function')
				this.resolve();
		}, TIMEOUT);

		return p;
	
	}

	handleScroll = () => {
		const top = this.scrollel.scrollTop;
		if (top > 0) {
			this.setState({ scrolled: true });
		} else {
			this.setState({ scrolled: false });
		}
	}
	shouldPullToRefresh = () => {return !this.state.scrolled;}
	render () {
		return (
			<div id={this.state.identifier} className={this.props.className}>
				{this.props.children}
			</div>
		);
	}
}

Puller.defaultProps = {
	onRefresh: () => {},
	children: null,
	classElementScroll: 'bit-link-main-content',
	className: 'puller-box',
	identifier: '',
};

export default Puller;