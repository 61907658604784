/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { X } from 'react-feather';
import { Modal } from 'antd-mobile';
import ChangeBank from './../../../assets/images/rekening-bank.svg';

class InfoSwitchBankModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false
		};
	  }
	  handleOpen = () => {
	  	this.setState({
		  show: true,
	  	});
	  }
	  handleClose = () => {
	  	this.setState({
		  show: false,
	  	});
	  }
	  
	  render() {
	  	// const { history } = this.props;
	  	return (
	  		<Fragment>
	  			<Modal
	  				popup
	  				visible={this.state.show}
	  				onClose={this.handleClose}
	  				animationType="slide-up"
	  			>
	  				<div className="bit-link-modal-header">
	  					<div>
	  						<h3>Sebelum menambah rekening bank</h3>
	  					</div>
	  					<div>
	  						<X color="#666" size={16} onClick={this.handleClose}/>
	  					</div>	
	  				</div>	 
	  				<div className="bit-tax-modal">
	  					<div className="bit-change-img">
	  						<img src={ChangeBank} alt="change bank"/>
	  					</div>	
	  					<div className="bit-change-rek-info">
	  						<p>Pendaftaran rekening bank memerlukan waktu 3 hari kerja</p> 
	  						<p>Kamu dapat mendaftar maksimal 3 rekening bank terdaftar</p> 
	  						<p>Rekening bank yang sudah terdaftar tidak bisa diubah atau dihapus</p> 
	  						<p>Pastikan rekening bank kamu aktif (ada transaksi 1 bulan terakhir)</p> 
	  						<p>Pastikan nama rekening bank sama dengan nama yang terdaftar di Bibit</p>
	  					</div>
	  					<div className="cust-twins-no-color">
	  						<span onClick={this.handleClose}>  
							  	Batal
	  						</span>	
	  						<Link to="/changebanksendverif">  
								Lanjut
	  						</Link>	
	  					</div>  
	  				</div>	
	  			</Modal>
	  			{this.props.onRenderButton(this.handleOpen)}
	  		</Fragment>
	  	);
	  }
} 

InfoSwitchBankModal.defaultProps = {
	onRenderButton: (handleOpen) => {
		return (
			<div onClick={handleOpen}>
                show
			</div>
		);
	}
};

export default InfoSwitchBankModal;