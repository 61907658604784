import React, { Component, Fragment } from 'react';
import { Checkbox, ActivityIndicator, List } from 'antd-mobile';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BuyModal from './BuyModal';

import {
	fetchPortfolioCategoriesDetailData
} from './../../../entities/userportfolio.reducer';

import {
	getPortfolioFirstItem,
} from './../../../entities/userportfolio.selector';
import { Modal } from 'antd-mobile';
import { X } from 'react-feather';
import { getReksadanaProducts } from '../../../entities/reksadana.selector';
import getSafely from '../../../utils/safely';
import { numberToPercentagePointChangeFormat } from '../../../utils/string-helper';

import BuyReksa from './../../../assets/images/buy-reksa.svg';
import { getStatusLoadingSelector } from '../../../services/apimanager/apimanager.selector';

import list from './../../../services/apiconstant';
import InvestmentCompanyLogo from '../InvestmentCompanyLogo';

const {
	CheckboxItem
} = Checkbox;

class PickerProductModal extends Component {
	state = {
		selected: 0,
		open: 0,
		submitted: 0,
	}
	handleChange = value => {
		this.setState({
			selected: value
		}, () => {
			this.props.onSelect({selected: value});
		});
	}
	handleSubmit = () => {
		this.props.onSubmitSelect({selected: this.state.selected});
		this.setState({
			submitted: 1
		}, () => {
			this.handleClose();
		});
	}
	handleClose = () => {
		this.setState({
			open: 0
		});
	}
	handleOpen = () => {
		this.setState({
			open: 1
		});
	}
	handleReset = () => {
		this.setState({
			submitted: 0,
			selected: 0
		});
	}
	renderItems = () => {

		const reksadana = getSafely(['reksadana'], this.props);
		return reksadana.map((item, index) => {
			const id = getSafely(['id'], item);
			const name = getSafely(['name'], item);
			const return1y = getSafely(['simplereturn', '1y'], item);
			const percentage = numberToPercentagePointChangeFormat(return1y);
			const ojkCode = getSafely(['investment_manager', 'ojkCode'], item);
			const isChecked = index === this.state.selected;
			return (
				<CheckboxItem 
					key={id} 
					className="bit-link-reksa-radio"
					checked={isChecked}
					onChange={() => this.handleChange(index)}
				>
					
					<div className="bit-reksa-item bit-flex-start">
						<div className="bit-reksa-pic">
							<InvestmentCompanyLogo
								type="investment-manager"
								size="square"
								ojkCode={ojkCode}
							/>
						</div>
						<div>
							<h3>{name}</h3>
							<p>Keuntungan <span>{percentage}</span> per tahun</p>
						</div>
					</div>
				</CheckboxItem>
			);
		});
	}
	render () {
																																							
		let selectedReksadana = (this.props.reksadana_count > 0)? this.props.reksadana[this.state.selected]: {};
		let ojkCode = (selectedReksadana && selectedReksadana.investment_manager)? selectedReksadana.investment_manager.ojkCode:''; 
		selectedReksadana.ojkCode = ojkCode;
		const items = [selectedReksadana];
		const enableBuy = this.props?.transactionConfiguration?.enable_subs;


		return (
			<Fragment>
				{!this.state.open && !!this.state.submitted && 
					this.props.render({
						open: 1, 
						onReset: this.handleReset,
						items: items,
					})
				}
				{!this.state.submitted && this.props.renderButton(this.handleOpen, enableBuy)}
				<Modal
					popup
					visible={this.state.open}
					onClose={this.handleClose}
					animationType="slide-up"
					isOpen={this.state.open} 
					onRequestClose={this.handleClose}
					className="bit-link-buy-modal bit-reksa-modal"
				>
					<div className="bit-link-modal-header bit-link-modal-head-product">
						<div>
							<h3>Pilih Produk Yang Mana?</h3>
						</div>
						<div>
							<X color="#666" size={16} onClick={this.handleClose}/>
						</div>	
					</div>
					<div className="bit-select-product-wrap">
						<List>
							{this.renderItems()}
						</List>
					</div>	
					<div className="bit-link-fixed-btn-cont">
						<button className="bit-link-btn-red-full" onClick={this.handleSubmit}>Pilih</button>
					</div>

					{/* ---------------------------------- */}
				</Modal>
				<ActivityIndicator 
					toast
					text={'Permintaan sedang di proses'}
					animating={this.props.isLoading}
				/>
			</Fragment>
		);
	}
}

PickerProductModal.defaultProps = {
	reksadana: [],
	onSelect: () => {},
	onSubmitSelect: () => {},
	render: () => {},
	renderButton: (openModal, enableBuy) => {
		return <div onClick={openModal} className="bit-link-transaction-action" role="button" aria-disabled={!enableBuy}>
			<img src={BuyReksa} alt="link aja"/>
			<p>Beli Reksadana</p>
		</div>;
	},
	onReset: () => {}
};

const WithPickerProductModal = (Comp) => (props) => {
	return <PickerProductModal 
		{...props}
		render={({open, onReset, items}) => {
			const productIds = items.map(item => {
				return item.symbol;
			});
			return <Comp 
				{...props}
				items={items}
				productIds={productIds}
				open={open}
				requestInitImage={true}
				onComplete={() => {
					onReset();
				}}
			/>;
		}}
	/>;
};

const mapStateToProps = (state) => {

	const items = getPortfolioFirstItem(state);
			
	const reksadana = getReksadanaProducts(state);

	const isLoading = getStatusLoadingSelector(state, [
		list.userBalanceLinkaja,
		list.orderBuy,
	]);

	return {
		items: items,
		reksadana: reksadana,
		reksadana_count: reksadana.length,
		isLoading: isLoading,
		transactionConfiguration: state?.user?.profile?.transaction_configuration
	};

};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		fetchPortfolioCategoriesDetailData,
	}, dispatch);
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(WithPickerProductModal(BuyModal));