import React, {Component} from 'react';
import PropTypes from 'prop-types';
import noImage from '../../assets/images/no-image.svg';

const { PUBLIC_URL } = process.env;

/**
 * Render financial institution logo image
 * @param {String} type    - Company type, must be between: investment-manager or custodian
 * @param {String} size    - Picture size, must be between: square or orig
 * @param {String} ojkCode - The Company's listed OJK Code
 */
class InvestmentCompanyLogo extends Component {
	handleErrorImage = e => {
		e.target.onerror = null;
		e.target.src = noImage;
	};

	render() {
		const { type, size, ojkCode } = this.props;
	
		if (!ojkCode) {
			return <img src={noImage} alt="Company" />;
		}

		let origImageUrl =`${PUBLIC_URL}/assets/logos/${type}/${size}/${ojkCode}.svg`;
		
		if (size === 'orig') {
			origImageUrl = `${PUBLIC_URL}/assets/logos/${type}/${size}/${ojkCode}.png`;
			return (
				<img
					src={origImageUrl}
					alt={ojkCode}
					onError={this.handleErrorImage}
				/>
			);
		}
		
		return (
			<img
				src={origImageUrl}
				alt={ojkCode}
				onError={this.handleErrorImage}
			/>
		);
	}
}

InvestmentCompanyLogo.propTypes = {
	type: PropTypes.oneOf(['investment-manager', 'custodian']),
	size: PropTypes.oneOf(['square', 'orig']),
	ojkCode: PropTypes.string,
};

InvestmentCompanyLogo.defaultProps = {
	type: 'investment-manager',
	size: 'square',
	ojkCode: '',
};

export default InvestmentCompanyLogo;