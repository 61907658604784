/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// import { ChevronRight} from 'react-feather';

import LogoBibit from './../../assets/images/logo-bibit.svg';
// import SellReksa from './../../assets/images/sell-reksa.svg';

// import EmailStatus from './../EmailStatus/EmailStatus';

import {
	fetchPortfolioCategories,
} from './../../entities/userportfolio.reducer';

import {
	getPortfolioCategories
} from './../../entities/userportfolio.selector';

import ViewRegisterAccountStatus from './../Register/ViewRegisterAccountStatus';
import ViewPortfolioOverviewCard from './../Landing/Portfolio/ViewPortfolioOverviewCard';
import ViewOverviewProductCard from './Product/ViewOverviewProductCard';
import ViewShortcutBuyAndSell from './ViewShortcutBuyAndSell';
import ViewEmailStatus from './../EmailStatus/ViewEmailStatus';
import { getPartnerCallback } from '../../entities/userpartner.selector';

import { restoreInitialUser } from './../../entities/user.reducer';
import GlobalHeader from '../Global/GlobalHeader/GlobalHeader';
import { compose, bindActionCreators } from 'redux';
import Puller from './../../components/Puller/Puller';


// import SellModal from './../Common/SellModal/UnselectedSellModal';
// import BuyModal from './../Common/BuyModal/BuyModal';

class IndexHome extends Component {
	render() {
		const { loading, transactionConfiguration } = this.props;
		const enableBuy = transactionConfiguration?.enable_subs; 
	  	return (
	  		<div className="bit-link-main-wrap">
	  			<div className="bit-link-main-content bit-link-puller-wrap padding-wrap">
					<div className="bit-link-main-header bit-stay-header">
						<GlobalHeader />
					</div>
				  	<Puller
						loading={loading}
						onRefresh= {() => {
							this.props.fetchPortfolioCategories();
							return this.props.restoreInitialUser();
						}}
					>
						{!enableBuy && <div className='bit-transaction-message'>
							<div>
							Mohon maaf, saat ini sedang ada kendala pada sistem LinkAja, sehingga transaksi redemption reksa dana saja yang dapat diproses.
							</div>
						</div>}
						<ViewEmailStatus />
						<ViewRegisterAccountStatus />
						<ViewPortfolioOverviewCard />
						<ViewShortcutBuyAndSell
							categoryId={this.props.randomCategoryId}
						/>
						<ViewOverviewProductCard />	
						<div className="bit-link-footer-stay">
							<div>Powered by :</div> 
							<div><img src={LogoBibit} alt="logo bibit" /></div>
						</div>
					</Puller>  
	  			</div>
	  		</div>
	  	);
	}
} 

const mapStateToProps = state => {
	const portfolios = getPortfolioCategories(state) || [];
	const portfolioId = portfolios.length > 0? portfolios[0].id: null;
	const redirecturl = getPartnerCallback(state);
	return {
		randomCategoryId: portfolioId,
		redirecturl: redirecturl,
		transactionConfiguration: state?.user?.profile?.transaction_configuration

	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchPortfolioCategories,
	restoreInitialUser
}, dispatch);

const composed = compose(
	// withPuller,
	connect(mapStateToProps, mapDispatchToProps)
);

export default composed(IndexHome);