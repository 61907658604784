import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import LinkAja from './../../../assets/images/link-aja.svg';
import Settings from './../../../assets/images/settings.svg';
import { backToMainApp } from '../../../utils';
import InitFlagSyariah from '../../Common/Syariah/InitFlagSyariah';
import SettingFlagSyariah from '../../Common/Syariah/SettingFlagSyariah';
import { getUserActive, getUserPin } from '../../../entities/user.selector';
import { Modal } from 'antd-mobile';
import { X } from 'react-feather';
import SettingBank from './SettingBank';

const ENABLE_SETTING = true;

class Setting extends Component {
	state = {
		open: false
	}
	handleClose = () => this.setState({
		open: false
	});
	handleOpen = () => this.setState({
		open: true
	});
	renderContentSetting = () => {
		return (
			<Modal
				popup
				visible={this.state.open}
				onClose={this.handleClose}
				animationType="slide-up"
				className="bit-link-modal-setting"
			>
				<div className="bit-link-modal-header">
					<div>
						<h3><img src={Settings} alt="settings"/> <span>Pengaturan</span></h3>
					</div>
					<div>
						<X color="#666" size={16} onClick={this.handleClose}/>
					</div>	
				</div>	 
				<div className="bit-modal-setting-content">
					<div className="bit-modal-setting-row">
						<SettingFlagSyariah />
						<InitFlagSyariah />	
					</div>
					<div className="bit-modal-setting-row">
						<SettingBank/>
					</div>
				</div>
			</Modal>
		);
	}
	render() {
		const {showSetting} = this.props;
		return (
			<Fragment>
				{showSetting && this.props.onRenderButton(this.handleOpen)}
				{showSetting &&  this.renderContentSetting()}
			</Fragment>	
		);
	}
}

Setting.defaultProps = {
	onRenderButton : (handleOpen) => {
		return <div onClick={handleOpen}><img src={Settings} alt="settings"/></div>;
	}
};

const mapStateToProps = state => {
	const active = getUserActive(state);
	const pin = getUserPin(state);
	return {
		showSetting: (active === 4) && 
			(pin === 1)? true: false,
	};
};

const SettingComposed = connect(mapStateToProps)(Setting);

class GlobalHeader extends Component {
	render() {
		const { title, showSetting } = this.props;
		return (
			<Fragment>
				{this.props.renderButtonLeft()}
				{!!title && <div>{title}</div>}
				<div>
					{showSetting && this.props.renderButtonRight(!this.props.disableRightButton)}
				</div>
			</Fragment>
		);
	}
}

GlobalHeader.defaultProps = {
	title: '',
	renderButtonLeft: () => {
		return <div onClick={() => backToMainApp()}><img src={LinkAja} alt="link aja"/></div>;
	},
	renderButtonRight: (config_enable) => {
		return (config_enable && ENABLE_SETTING)? 
			<SettingComposed/>:
			<div></div>;
	},
	disableRightButton: false,
};

const globalHeaderMapStateToProps = state => {
	const active = getUserActive(state);
	const pin = getUserPin(state);
	return {
		showSetting: (active === 4) && 
			(pin === 1)? true: false,
	};
};

export default connect(globalHeaderMapStateToProps)(GlobalHeader);