import React, { PureComponent } from 'react';
import { compose, bindActionCreators } from 'redux';
import {connect} from 'react-redux';

import GlobalLoader from './../../Global/GlobalLoader';
import {factoryRedirect} from './../../Global/GlobalRedirect';

import ViewLandingRegister from './../../Landing/LandingVerification/ViewLandingRegister';
import ViewConnectAccount from './../../Landing/LandingVerification/ViewConnectAccount';

import {startAppInitLoad} from './ViewLandingVerification.reducer';
import { isLoadingSelector, isErrorSelector } from './../../../services/apimanager/apimanager.selector';

import apiconstant from './../../../services/apiconstant';
import parseQuery from './../../../utils/parseQuery';
import getSafely from './../../../utils/safely';

import LogoBibit from './../../../assets/images/logo-bibit.svg';

import Er404 from './../../../assets/images/404.svg';
import './../../../common.css';
import './../../../bibit-link.css';
import GlobalHeader from '../../Global/GlobalHeader/GlobalHeader';
import {ReactComponent as DeactiveImage} from '../../../assets/images/deactiveAccount.svg';

const GlobalRedirect = factoryRedirect([
	{
		status: 0,
		target: ViewLandingRegister,
	}, 
	{
		status: 1,
		target: ViewConnectAccount,
	},
	{
		status: 2,
		target: ViewConnectAccount,
	},
	{
		status: 3,
		target: '/index-home',
	},
	{
		status: 4,
		target: '/index-home',
	}
]);

class ViewLandingVerification extends PureComponent {
	state = {
		querystring: null
	}
	componentDidMount () {

		const {
			location: {
				search
			}
		} = this.props;
		const _search = parseQuery(search);
		// console.log('::-- INITIALIZATION QUERYSTRING --::', _search);
		this.setState({	
			querystring: _search
		});
		this.props.startAppInitLoad(_search);
	}
	render() {
		const {
			querystring,
		} = this.state;
		const {
			isloading,
			error,
		} = this.props;
		const phonenumber = getSafely(['phonenumber'], querystring);

		if(!isloading && error?.data?.type === 'user_has_been_deactivated') {
			return (
				<div className="bit-link-error-404">
					<DeactiveImage />
					<div className="bit-deactive-title">Akun Kamu Telah Dinonaktifkan</div>
					<div className='bit-deactive-message'>
					Silakan menghubungi customer service Bibit di <b>info@bibit.id</b> jika ada pertanyaan mengenai akun Kamu.
					</div>
				</div>
			);
		}

		if(!isloading && error) 
			return (
				<div className="bit-link-error-404">
					<img src={Er404} alt="error 404"/>
				</div>);
		return (
			<div>
				<div className="bit-link-wrap">
					<div className="bit-link-header">
						<GlobalHeader disableRightButton={true} />
					</div>
					<div className="bit-link-register">
						<div className="bit-link-title-reg">
							<h5>Reksa Dana</h5>
							<h1>Mulai Investasi <br />Sekarang</h1>
						</div>    
						<div className="bit-link-content-centered">
							{
								phonenumber && 
								(<div className="bit-link-phone">
									{phonenumber}
								</div>)
							}				
							{isloading && 
								<GlobalLoader 
									label="Pengecekan akun" 
								/>	
							}
							{!isloading && 
								(<GlobalRedirect 
									status={this.props.status} 
								/>)
							}
						</div>
					</div>
					<div className="bit-link-footer">
						<div>Powered by</div> 
						<div><img src={LogoBibit} alt="logo bibit" /></div>
					</div>
				</div>
			</div>
		);
	}
} 

const mapStateToProps = state => {
	const {
		partner_key_check,
	} = apiconstant;
	const {
		userpartner: {
			status
		}
	} = state;
	return {
		isloading: isLoadingSelector(state, partner_key_check),
		error: isErrorSelector(state, partner_key_check),
		status: status || 0,
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	startAppInitLoad
}, dispatch);

const composed = compose(
	connect(
		mapStateToProps, 
		mapDispatchToProps
	)
);

export default composed(ViewLandingVerification);