import { compose } from 'redux';
import moment from 'dayjs';
import * as statusConstant from './order.constant';

import { numberToGeneralValueFormat } from './../utils/string-helper';
import getSafely from '../utils/safely';

function translateOrderStatus(order){
	const { method } = order;
	const variableConstant = method === 'sell'? 'orderStatusSell': 'orderStatus';
	order.status_text = getSafely([variableConstant, order.status], statusConstant);
	return order;
}

function translateLastUpdate(order) {
	order.lastupdate_text = moment(order.lastupdate).format('D MMM YYYY'); 
	return order;
}

function translateEstAmount (order) {
	order.estAmount_text = numberToGeneralValueFormat(order.estAmount);
	return order;
}

function translateAmount (order) {
	order.amount_text = numberToGeneralValueFormat(order.amount);
	return order;
}

// Order object modifier
export const translated = compose(
	translateOrderStatus,
	translateLastUpdate,
	translateEstAmount,
	translateAmount,
);

export const getOrder = (state, code) => {
	const {byId} = state.order;
	return translated(byId[code]);
};

/**
 * sort last update in array Order Object 
 */
// https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
function compare( a, b ) {

	const momenta = moment(a.lastupdate);
	const momentb = moment(b.lastupdate);

	const res = momenta.diff(momentb);

	if ( res > 0 ){
		return -1;
	}
	if ( res < 0 ){
		return 1;
	}
	return 0;
}

export const getOrders = state => {
	const {byId} = state.order;
	return Object.keys(byId).map(name => getOrder(state, name)).sort(compare);
};



