import React, {Component, Fragment} from 'react';
import moment from 'dayjs';

class CountdownTimer extends Component {
	constructor (props) {
		super(props);
		this.state = {
			current: Date.now()
		};
	}
	componentDidMount () {
		this.interval = setInterval(() => {
			this.setState({
				current: Date.now()
			});
		}, 1000);
	}
	componentWillUnmount () {
		if(this.interval) clearInterval(this.interval);
	}
	_renderEnd() {
		const {
			complete
		} = this.props;
		return (
			<Fragment>
				{complete}
			</Fragment>
		);
	}
	render () {
		const {
			current
		} = this.state;
		const {
			timestamp
		} = this.props;
		const _timestamp = moment(timestamp);
		const _current = moment(current);
		const end = moment(_timestamp).diff(_current);
		if(end < 0) return this._renderEnd();
		return (
			<Fragment>
				{moment(end).format('mm:ss')}
			</Fragment>
		);
	}
}

CountdownTimer.defaultProps = {
	timestamp: moment(moment(Date.now()).add(10, 'seconds')).toISOString(),
	complete: null
};


export default CountdownTimer;