import React from 'react';
import Er404 from './../../assets/images/404.svg';

export default function Page404 ({err_code, hasRefresh} = {err_code: '', hasRefresh: false}) {
	const handleRefreshApp = () => {
		window.location.reload();
	  };
      
	return (
		<div className="bit-link-error-404">
			<img src={Er404} alt="error 404"/>
			{err_code && <span>{err_code}</span>}
			{hasRefresh && 
				<button onClick={handleRefreshApp}  className="bit-link-btn-red-full margin-top-20">
					Coba Lagi
				</button>
			}
		</div>
	);
}