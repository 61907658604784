import * as Analytics from './index';
import { getValue } from './../DataAlias';
import moment from 'dayjs';

export function createAnalytics(options = {}) {
	return store => next => action => handleAnalyticAction(store, next, action, options);
}

function handleAnalyticAction (store, next, action, options) {
	const {
		type, payload,
	} = action;

	// set profile user 
	if(type === 'SUCCESS_USER_PROFILE') {
		const {
			profile: {
				id,
				sid,
				income_source,
				preference,
				bank_account_name,
				email,
				city,
				occupation,
				fullname,
				firstname,
				religion,
				gender,
				survey,
				birth_date
			}
		} = payload;
		const FINISH_REGISTER = !!sid;
		// const USER_GENDER;
		const SOURCE_INCOME = getValue('earningsources', income_source);
		const SYARIAH_PREFERENCE = preference ? preference.syariah : 0;
		const USER_NAME = fullname;
		const USER_EMAIL = email;
		// const USER_ROBO_RISK = email;
		const USER_BANK = bank_account_name;
		const USER_OCCUPATION = occupation;
		const USER_CITY_FROM_ID = city;
		const FIRST_NAME = firstname;
		const RELIGION = religion;
		const GENDER = gender;
		
		let INCOME = 0;
		let ANNUAL_SALARY = '0';

		if(survey && survey.income) {
			INCOME = survey.income;
			ANNUAL_SALARY = INCOME * 12;
			if(ANNUAL_SALARY < 50000) {
				ANNUAL_SALARY = '0';
			}else if (ANNUAL_SALARY < 100000) { 
				ANNUAL_SALARY = '50K';
			}else if (ANNUAL_SALARY < 250000) { 
				ANNUAL_SALARY = '100K';
			}else if (ANNUAL_SALARY < 1000000) { 
				ANNUAL_SALARY = '250K';
			}else if (ANNUAL_SALARY < 10000000) { 
				ANNUAL_SALARY = '1000K';
			} else {
				ANNUAL_SALARY = '10000K';
			}
		}

		let dataPush = {
			id,
			FINISH_REGISTER,
			SOURCE_INCOME,
			SYARIAH_PREFERENCE,
			USER_NAME,
			USER_EMAIL,
			// USER_ROBO_RISK,
			USER_BANK,
			USER_OCCUPATION,
			USER_CITY_FROM_ID,
			RELIGION,
			GENDER,
			FIRST_NAME,
			INCOME,
			ANNUAL_SALARY
		};
		const birthDayIsValid = birth_date ? moment(birth_date).isValid() : false;
		if(birthDayIsValid) {
			dataPush.BIRTHDAY = moment(birth_date).unix();
		}

		Analytics.recordUser({
			...dataPush
		});

	}
	return next(action);
}