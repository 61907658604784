import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BuyModal from './BuyModal';

import {
	getPortfolioSelectedItems,
} from './../../../entities/userportfolio.selector';

import {
	getStatusLoadingSelector,
	getStatusErrorSelector,
} from './../../../services/apimanager/apimanager.selector';

import list from './../../../services/apiconstant';

import {
	fetchPortfolioCategoriesDetailData,
} from './../../../entities/userportfolio.reducer';
import getSafely from '../../../utils/safely';

const mapStateToProps = (state, ownProps) => {
	const {
		portfolioId,
		productIds: _productIds,
	} = ownProps;
	let productIds = [];
	if(_productIds) {
		productIds = _productIds;
	}

	const isLoading = getStatusLoadingSelector(state, [
		list.userBalanceLinkaja,
		list.orderBuy,
	]);

	const [
		eUserBalanceLinkaja,
		eOrderBuy
	] = getStatusErrorSelector(state, [
		list.userBalanceLinkaja,
		list.orderBuy,
	]);

	let _items = getPortfolioSelectedItems(state, productIds) || [];
	_items = _items.map(item => {
		return {
			...item,
			name: getSafely(['product', 'name'], item),
			type: getSafely(['product', 'type'], item),
			ojkCode: getSafely(['product', 'investment_manager', 'ojkCode'], item),
			minbuy: getSafely(['product', 'minbuy'], item),
			minsell: getSafely(['product', 'sell'], item),
		};
	});

	return {
		portfolioId: portfolioId,
		items: _items,
		isLoading: isLoading,
		errors: {
			eUserBalanceLinkaja,
			eOrderBuy,
		}
	};
};

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchPortfolioCategoriesDetailData
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BuyModal);