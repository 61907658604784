import React, { Component, Fragment } from 'react';
import { ActivityIndicator, List } from 'antd-mobile';
import { connect } from 'react-redux';
import {
	Formik,
	Form,
} from 'formik';


import list from './../../services/apiconstant';

import IconSendEmail from './../../assets/images/emailSend.png';
import { isLoadingSelector, isErrorSelector } from '../../services/apimanager/apimanager.selector';
import BaseInput from '../Base/BaseInput';
import { servicePostEmailChange } from '../../services/email.services';
import { getSafelyWithDefault } from '../../utils/safely';
import { failureveremail } from '../../entities/user.reducer';

const postSubmitEmail = ({email}) => {
	return servicePostEmailChange({email});
};

class EmailChangeForm extends Component {
	state = {
		email: '',
		isSubmit: false,
	}
	componentDidMount () {
		this.setState({
			email: '',
			isSubmit: false
		});
	}
	componentDidUpdate (prevProps, prevState) {
		if(prevProps.err !== this.props.err && this.props.err) {
		
			// const {
			// 	data: {
			// 		errors
			// 	}
			// } = this.props.err;

			const errors = getSafelyWithDefault(['data', 'errors'], this.props.err, {});
			const keys = Object.keys(errors);
			const _errors={};
			keys.map(item => {
				const data = errors[item];
				const alias = this.props.mapErrorKey[item];
				if(alias) 
					_errors[alias]=data.msg;
				else
					_errors[item]=data.msg;
				return item;
			});

			
			this.formikRef.setErrors(_errors);
		
		}
	
	}
	handleSubmit = ({email}) => {
		const { dispatch } = this.props;
		postSubmitEmail({email})
			.then(response => {
				this.setState({
					isSubmit: true
				});
				this.props.onSubmit({email});
			}).catch((err) => {
				// skip error if api return email object
				if(err?.response?.data?.errors?.email){
					return;
				}
				dispatch(failureveremail({err: err?.response?.data?.message}));

				this.props.onSubmit({email, isError: true, errorMessage: err?.response?.data?.message });
			});
	}
	handleChangeInput = value => {
		this.setState({
			email: value
		});
	}

	renderForm = () => {
		const {
			loading,
		} = this.props;
	
		return (
			<Fragment>
				<div
					className="login-register-icon"
					style={{ textAlign: 'left' }}
				>
					<img src={IconSendEmail} alt="send icon" />
				</div>
				{/* <div
					className="login-register-desc"
					// style={{ textAlign: desccriptionTextPosition }}
				>
				</div> */}
				<Formik
					enableReinitialize
					initialValues={this.state.defaultValue}
					ref={ref => this.formikRef = ref}
					errors={{email: ''}}
					validate={(values, formikProps) => {
						const errors={};
						return errors;
					}}
					onSubmit={(values, {setSubmitting}) => {
						setSubmitting(false);
						this.handleSubmit(values);
					}}
				>
					{({isSubmitting}) => {
						return <Form className="bit-modal-change-email">
							<List renderHeader={() => 'Masukkan Alamat Email Baru Anda.'}>
								<BaseInput name="email" placeholder="jhondoe@email.com" />
							</List>
							<div className="primary-btn-fixed-wrap">
								<button className="bit-link-btn-red-full" type="submit" >Kirim</button>
							</div>

						</Form>;
					}}
				</Formik>
				
				{/* <InputItem
					disabled={disableInput}
					value={this.state.email}
					inputMode='email'
					placeholder='nama_saya@gmail.com'
					onChange={this.handleChangeInput}
				/>
					 */}
				<ActivityIndicator
					toast
					text="Loading..."
					animating={loading}
				/>
			</Fragment>
		);
	}
	handleBack = () => {
		this.props.onBack();
	}
	renderSuccess = () => {

		return (
			<Fragment>
				<div
					className="login-register-icon"
					style={{ textAlign: 'left' }}
				>
					<img src={IconSendEmail} alt="icon change email" />
				</div>
				 
				<div
					className="login-register-heading"
					style={{ textAlign: 'left' }}
				>
						Email Verifikasi Berhasil Dikirim, Silahkan Cek Email Anda.
				</div>
				<div
					onClick={this.handleBack}
					className="bit-link-btn-white-full  text-center"
				>
					kembali ke halaman utama
				</div>
			</Fragment>
		);
	}

	render() {
		if(!this.state.isSubmit)
			return this.renderForm();
		return this.renderSuccess(); 
	}
}

EmailChangeForm.defaultProps = {
	loading: false,
	mapErrorKey: {},
	onBack: () => {}
};

const mapStateToProps = state => {
	return {
		loading: isLoadingSelector(state, list.emailRegisterChange),
		err: isErrorSelector(state, list.emailRegisterChange),
	};
};

export default connect(mapStateToProps)(EmailChangeForm);