/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft , X } from 'react-feather';
import { Modal } from 'antd-mobile';
import ChangeBank from './../../assets/images/rekening-bank.svg';

class ChangeBankSetting extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  modalBeforeChangeBank: false,
		};
	  }
	  showModal = key => (e) => {
	  	e.preventDefault(); 
	  	this.setState({
		  [key]: true,
	  	});
	  }
	  onClose = key => () => {
	  	this.setState({
		  [key]: false,
	  	});
	  }
	  
	  render() {
	  	const { history } = this.props;
	  	return (
	  		<div className="bit-link-main-wrap bit-change-bank-setting">
	  			<div className="bit-link-main-header">
	  				<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
	  				<div>Rekening Bank</div>
	  				<div/>
	  			</div>
	  			<div className="bit-link-main-content">
	  				<div className="bit-bank-change">
	  					<label className="am-checkbox-wrapper bit-change-row">
	  						<span className="am-checkbox am-checkbox-checked">
	  							<input type="checkbox" className="am-checkbox-input" data-bankid="31#7610097931" checked="" />
	  							<span className="am-checkbox-inner"></span>
	  						</span>
	  						<div className="bit-change-info">
	  							<div className="paynote-0 bit-flex-paynote">
	  								<span className="paynote-1 bca prox-condensed-bold" style={{"backgroundColor": "rgb(36, 120, 180)"}}>BCA</span>
	  							</div>
	  							<div className="paynote-0">
	  								<span className="paynote-2 bankName">Imam Tauhid</span>
	  								<span className="paynote-3">7610097931</span>
	  							</div>
	  						</div>
	  					</label>
	  				</div>	
	  				<button className="bit-link-btn-red-full margin-top-20" onClick={this.showModal('modalBeforeChangeBank')}>Tambah Rekening</button>
	  			</div>

	  			<Modal
	  				popup
	  				visible={this.state.modalBeforeChangeBank}
	  				onClose={this.onClose('modalBeforeChangeBank')}
	  				animationType="slide-up"
	  			>
	  				<div className="bit-link-modal-header">
	  					<div>
	  						<h3>Sebelum menambah rekening bank</h3>
	  					</div>
	  					<div>
	  						<X color="#666" size={16} onClick={this.onClose('modalBeforeChangeBank')}/>
	  					</div>	
	  				</div>	 
	  				<div className="bit-tax-modal">
	  					<div className="bit-change-img">
	  						<img src={ChangeBank} alt="change bank"/>
	  					</div>	
	  					<div className="bit-change-rek-info">
	  						<p>Pendaftaran rekening bank memerlukan waktu 3 hari kerja</p> 
	  						<p>Kamu dapat mendaftar maksimal 3 rekening bank terdaftar</p> 
	  						<p>Rekening bank yang sudah terdaftar tidak bisa diubah atau dihapus</p> 
	  						<p>Pastikan rekening bank kamu aktif (ada transaksi 1 bulan terakhir)</p> 
	  						<p>Pastikan nama rekening bank sama dengan nama yang terdaftar di Bibit</p>
	  					</div>
	  					<div className="cust-twins-no-color">
	  						<Link to="/">  
							  	Batal
	  						</Link>	
	  						<Link to="/changebanksendverif">  
								Lanjut
	  						</Link>	
	  					</div>  
	  				</div>	
	  			</Modal>
	  		</div>
	  	);
	  }
} 

export default (ChangeBankSetting);