import React, { Component } from 'react';

const NUMBER = '6287777665000'; // no stockbit wa
const LINK_NUMBER = 'https://wa.me/'+NUMBER;

class Wa extends Component {
	render() {
		return (
			<a href={LINK_NUMBER} className="login-register-right-text">Hubungi Kami</a>
		);
	}
}

export default Wa;