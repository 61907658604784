import React, { Fragment } from 'react';
import {Field,} from 'formik';
import {InputItem} from 'antd-mobile';

export default function BaseInput (props) {
	return (
		<Field {...props} render={({ field, form }) => {
			const {
				errors,
				setFieldValue,
			} = form;
	
			return <Fragment>
				<InputItem
					{...props}
					{...field}
					onChange={value => {
						return setFieldValue(field.name, value);
					}}
				/>
				{
					Boolean(errors[field.name]) && 
					<div className="error">{errors[field.name]?.msg || errors[field.name]}</div>
				}
			</Fragment>;
		}}/>
	);
}