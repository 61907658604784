/* eslint-disable no-mixed-spaces-and-tabs */
import React, {Component} from 'react';
import { ChevronLeft } from 'react-feather';
import { List, InputItem } from 'antd-mobile';

class ProfileSetting extends Component {

	  render() {
	  	const { history } = this.props;
	  	return (
	  		<div className="bit-link-main-wrap">
	  			<div className="bit-link-main-header">
	  				<ChevronLeft height="18" color="#95989A" className="bit-link-left-arrow" onClick={() => history.goBack()}/>
	  				<div>Personal Detail</div>
	  				<div></div>
	  			</div>
	  			<div className="bit-link-main-content">
	  				<div className="bit-link-register bit-link-profile">
	  					<List renderHeader={() => 'SID'}>
	  						<InputItem
	  							value="IDD0501O2786249"
	  							disabled={true}
	  							placeholder="IDD0501O2786249"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Nama Lengkap'}>
	  						<InputItem
	  							value="Wisma Kusuma"
	  							disabled={true}
	  							placeholder="Wisma Kusuma"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Status Pernikahan'}>
	  						<InputItem
	  							value="Lajang"
	  							disabled={true}
	  							placeholder="Lajang"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Agama'}>
	  						<InputItem
	  							value="Islam"
	  							disabled={true}
	  							placeholder="Islam"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Pekerjaan'}>
	  						<InputItem
	  							value="E-Sport Athlete"
	  							disabled={true}
	  							placeholder="E-Sport Athlete"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Pendapatan Pertahun'}>
	  						<InputItem
	  							value="500 Juta - 800 Juta / tahun"
	  							disabled={true}
	  							placeholder="500 Juta - 800 Juta / tahun"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Sumber Penghasilan'}>
	  						<InputItem
	  							value="Gaji"
	  							disabled={true}
	  							placeholder="Gaji"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Pendidikan'}>
	  						<InputItem
	  							value="S3"
	  							disabled={true}
	  							placeholder="S3"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Alamat KTP'}>
						  	<div className="addressBox">
							  	Perumahan Bukit Indah Blok D12 NO 5 RT 005 RW006 Serua Ciputat
	  						</div>
	  					</List>
	  					<List renderHeader={() => 'Alamat Korespondensi'}>
						 	 <div className="addressBox">
							  	Perumahan Bukit Indah Blok D12 NO 5 RT 005 RW006 Serua Ciputat
	  						</div>
	  					</List>
	  					<List renderHeader={() => 'Nomor Rekening'}>
	  						<InputItem
	  						value="0953400500"
								type="number"
	  						disabled={true}
	  						placeholder="0953400500"
	  						/>
	  					</List>
	  					<List renderHeader={() => 'Nama Pemilik Rekening'}>
	  						<InputItem
	  							value="Wisnu Kusuma"
	  							disabled={true}
	  							placeholder="Wisnu Kusuma"
	  						/>
	  					</List>
	  					{/* <Link to={`/create-pin`}>
	  						<button className="bit-link-btn-red-full margin-top-15">Daftar</button>
	  					</Link>	 */}
	  				</div>	
	  			</div>
	  		</div>
	  	);
	  }
} 

export default ProfileSetting;